import React, { useEffect, useState } from 'react'
import useCache from 'customHooks/useCache'
import { Grid } from '@mui/material'
import { InputSelect } from 'fields/fieldSelect'
import { categorieAttivita } from 'util'

const modelCategorie = {
    id: 'categoria',
    tipo: 'fieldSelect',
    associazione: 'categoria',
    label: 'Categoria:',
    value: '',
    details: {
        complex: false,
        scelte: categorieAttivita,
        svuota : true
    },
}
const modelLuogo = {
    id: 'luogo',
    tipo: 'fieldSelect',
    associazione: 'luogo',
    label: 'Comune:',
    value: '',
    details: {
        complex: false,
        scelte: [],
        svuota : true
    },
}
const modelOrdinaPer = {
    id: 'ordinaPer',
    tipo: 'fieldSelect',
    associazione: 'ordinaPer',
    label: 'Ordina per:',
    value: '',
    details: {
        complex: true,
        toShow: 'testo',
        toSave: 'scelta',
        scelte: [
            { scelta: 0, testo: 'Alfabetico(A-Z)' },
            { scelta: 1, testo: 'Alfabetico(Z-A)' },
            { scelta: 2, testo: 'Imprese sostenitrici' },
            { scelta: 3, testo: 'Totale raccolto' },
        ],
        svuota : false
    },
}

export default function elementoFiltriEnti(props) {
    const { listaEntiWindow } = useCache()
    const [formLuogo, setFormLuogo] = useState(modelLuogo)
    const { categoria, luogo, ordinaPer } = props.filtri

    const handleChange = (campo, valore) => props.aggiornaFiltri(Object.assign(props.filtri, { [campo]: valore }))

    useEffect(() => {
        setFormLuogo(
            Object.assign(modelLuogo, {
                details: {
                    svuota: true,
                    scelte: listaEntiWindow.reduce((acc, ente) => {
                        const comune = ente.sedeLegale.comune
                        if (!acc.includes(comune)) acc.push(comune)
                        return acc
                    }, []),
                },
            }),
        )
    }, [listaEntiWindow])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
                <InputSelect value={categoria} model={modelCategorie} errore='' azione={handleChange} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <InputSelect value={luogo} model={formLuogo} errore='' azione={handleChange} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <InputSelect value={ordinaPer} model={modelOrdinaPer} errore='' azione={handleChange} />
            </Grid>
        </Grid>
    )
}
