import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import colors from 'assets/theme/base/colors'
import MDBox from 'components/MDBox'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import Slide from '@mui/material/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />
})

export default function popUpModificheNonSalvateForm(props) {
    const handleClose = (cambiaVista) => {
        props.chiudi(cambiaVista)
    }

    return (
        <Dialog PaperProps={{sx : {backgroundColor : colors.warning.main}}} TransitionComponent={Transition} open={props.aperto} onClose={() => handleClose(false)} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle sx={{ color: colors.white.main }} id='titolo-pop-up-field-list'>
                MODIFICHE NON SALVATE
            </DialogTitle>
            <DialogContent sx={{ color: colors.white.main }}>
            <MDBox sx={{ margin: 'auto' }} width='200px' height='200px' p={2}>
                    <ErrorOutlineRoundedIcon sx={{ height: '100%', width: '100%' }} color='white' />
                </MDBox>
                Sei sicuro di voler continuare senza salvare?
            </DialogContent>
            <DialogActions>
                <MDButton color='white' variant='text' onClick={() => handleClose(true)}>
                    si
                </MDButton>
                <MDButton color='white' variant='contained' onClick={() => handleClose(false)}>
                    no
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
