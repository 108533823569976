import React, { Component } from 'react'
import FormInput from './formInput'
import FormOutput from './formOutput'
import MDButton from 'components/MDButton'
//import { AGGIORNA_STATO_MODIFICA } from 'data/azioni/AGGIORNA_STATO_MODIFICA'
import isEqual from 'lodash-es/isEqual'
import valida from 'fields/validazione'
//import ElementoDialer from 'componenti/elementoDialer'
//import PopUpModificheNonSalvateForm from 'popUp/popUpModificheNonSalvateForm'
import { Grid } from '@mui/material'

export default class vediForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statoForm: {},
            statoIniziale: {},
            pending: {},
            errori: [],
            vista: props.vista || 'output',
            popUpAperto: false,
        }
    }

    modifica() {
        this.cambiaVista('input')
    }

    annulla() {
        if (this.props.annulla !== undefined) {
            this.props.annulla()
            return
        }
        if (this.isPristine()) {
            this.cambiaVista('output')
            this.reset()
        }
        if (!this.isPristine()) {
            this.setState({ popUpAperto: true })
            //this.cambiaVista('output')
            //this.reset()
        }
    }

    chiudiPopUp(cambiaVista) {
        this.setState({ popUpAperto: false })
        if (cambiaVista) {
            this.cambiaVista('output')
        }
    }

    cambiaVista(vista) {
        this.setState({ vista: vista })
        this.reset()
        //AGGIORNA_STATO_MODIFICA(vista === 'input')
    }

    dialerSchiacciato() {
        if (this.state.vista === 'output') {
            this.modifica()
            return
        }
        if (this.state.vista !== 'output') {
            this.annulla()
        }
    }

    renderBottoni() {
        if (this.props.noBottoni) {
            return ''
        }
        
        if (this.props.isUltimoStep) {
            return (
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='error' onClick={() => this.annulla()}>
                        annulla
                    </MDButton>
                    <MDButton variant='contained' color='primary' onClick={() => this.props.indietro(this.state.statoForm)}>
                        indietro
                    </MDButton>
                    <MDButton variant='contained' color='success' onClick={() => this.getValue('output')}>
                        salva
                    </MDButton>
                </div>
            )
        }
        if (this.props.isPrimoStep) {
            return (
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='error' onClick={() => this.annulla()}>
                        annulla
                    </MDButton>
                    <MDButton variant='contained' color='primary' onClick={() => this.getValue('output')}>
                        avanti
                    </MDButton>
                </div>
            )
        }
        if (this.props.isStepCentrale) {
            return (
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='error' onClick={() => this.annulla()}>
                        annulla
                    </MDButton>
                    <MDButton variant='contained' color='primary' onClick={() => this.props.indietro(this.state.statoForm)}>
                        indietro
                    </MDButton>
                    <MDButton variant='contained' color='primary' onClick={() => this.getValue('output')}>
                        avanti
                    </MDButton>
                </div>
            )
        }
        if (this.props.bottoniFiltri) {
            return (
                <div className='contenitore-bottoni-destra'>
                    {this.props.annulla === undefined ? (
                        ''
                    ) : (
                        <MDButton color='error' onClick={() => this.annulla()}>
                            annulla
                        </MDButton>
                    )}
                    <MDButton color='primary' onClick={() => this.props.svuota()}>
                        svuota
                    </MDButton>
                    <MDButton color='primary' onClick={() => this.getValue('output')}>
                        filtra
                    </MDButton>
                </div>
            )
        }
        if (this.state.vista === 'output') {
            return (
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='primary' onClick={() => this.modifica()} mb={-3}>
                        modifica
                    </MDButton>
                </div>
            )
        }
        if (this.props.noAnnulla) {
            return (
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='success' onClick={() => this.getValue('output')}>
                        salva
                    </MDButton>
                </div>
            )
        }
        if(this.props.noBottoneVerde){
            return (
                <div className='contenitore-bottoni-destra'>
                    <MDButton variant='contained' color='error' onClick={() => this.annulla()}>
                        annulla
                    </MDButton>
                    <MDButton variant='contained' color='primary' onClick={() => this.getValue('output')}>
                        aggiungi
                    </MDButton>
                </div>
            )
        }
        return (
            <div className='contenitore-bottoni-destra'>
                <MDButton variant='contained' color='error' onClick={() => this.annulla()}>
                    annulla
                </MDButton>
                <MDButton variant='contained' color='success' onClick={() => this.getValue('output')}>
                    salva
                </MDButton>
            </div>
        )
    }

    componentDidUpdate(vecchieProps) {
        if (!isEqual(vecchieProps.dati, this.props.dati) || !isEqual(vecchieProps.form, this.props.form)) {
            this.inizializza()
        }
    }

    renderForm() {
        if (this.state.vista === 'input') {
            return <FormInput isSubInput={this.props.isSubInput} form={this.props.form} dati={this.state.statoForm} azione={(a, b) => this.aggiornaStatoForm(a, b)} errori={this.state.errori} />
        }
        return <FormOutput skeleton={this.props.skeleton || false} form={this.props.form} dati={this.state.statoForm} />
    }
    /* {this.renderForm()} */

    render() {
        return (
            <Grid container direction='column' justifyContent='space-between' height='100%'>
                {/* <PopUpModificheNonSalvateForm aperto={this.state.popUpAperto} chiudi={(cambiaVista) => this.chiudiPopUp(cambiaVista)} /> */}
                <div style={{ width: '100%' }}>{this.renderForm()}</div>
                <Grid item>{this.renderBottoni()}</Grid>
            </Grid>
        )
    }

    componentDidMount() {
        this.inizializza()
    }

    getValoreBandiere(model) {
        if (this.props.dati[model.id].id === undefined) {
            return { id: [], descrizione: '' }
        }
        if (typeof this.props.dati[model.id].id === 'string') {
            if (this.props.dati[model.id].id === '') {
                return { id: [], descrizione: '' }
            } else {
                return { id: [this.props.dati[model.id].id], descrizione: this.props.dati[model.id].descrizione || '' }
            }
        }
        return { id: this.props.dati[model.id].id, descrizione: this.props.dati[model.id].descrizione || '' }
    }

    getValore(model) {
        if (this.props.dati === undefined) {
            return model.value
        }
        if (this.props.dati[model.id] === undefined) {
            return model.value
        }
        if (model.tipo === 'fieldSceltaBandierina') {
            return this.getValoreBandiere(model)
        }
        if (model.tipo === 'fieldData') {
            if (this.props.dati[model.id] === undefined) {
                return model.value
            }
            if (this.props.dati[model.id].seconds === undefined) {
                return this.props.dati[model.id]
            }
            return this.props.dati[model.id].toDate()
        }
        return this.props.dati[model.id] || model.value
    }

    inizializza() {
        let stato = {}
        let errori = []
        this.props.form.parts.forEach((model) => {
            stato[model.id] = this.getValore(model)
            errori.push('')
        })

        this.setState({ statoForm: stato, errori: errori, statoIniziale: Object.assign({}, stato) })
    }

    isPristine() {
        return isEqual(this.state.statoIniziale, this.state.statoForm)
    }

    aggiornaStatoForm(proprieta, valore) {
        let nuovoStato = Object.assign(this.state.statoForm)
        //console.log('girooooooo : ', nuovoStato)
        nuovoStato[proprieta] = valore
        return new Promise((resolve, reject) => {
            this.setState({ statoForm: nuovoStato }, () => {
                this.statoFormAggiornato()
                resolve()
            })
        })
    }

    statoFormAggiornato() {
        if (this.props.formAggiornato !== undefined) {
            this.props.formAggiornato(this.state.statoForm)
        }
    }

    async reset() {
        await this.inizializza()
    }

    async getValue() {
        let { errore, listaErrori } = await valida(this.state.statoForm, this.props.form)
        if (errore) {
            this.setState({ errori: listaErrori })
        }
        if (!errore) {
            this.setState({ errori: listaErrori })
            if (this.props.salva !== undefined) {
               /*  console.log("datiiiiii : ", this.props.dati);
                console.log("stateeeee : ", this.state.statoForm); */
                await this.props.salva(Object.assign(this.props.dati, this.state.statoForm))
                if (this.props.reset) {
                    this.reset()
                }
                if (!this.props.soloInput) {
                    this.cambiaVista('output')
                }
                return
            }
            if (!this.props.soloInput) {
                this.cambiaVista('output')
            }
        }
    }
}
