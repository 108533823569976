export const formCreaNetwork = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome :',
            value: '',
            details: {
                type: 'text',
            },
            validazione : 'testoRichiesto'
        },
        {
            id: 'areaGeografica',
            tipo: 'fieldText',
            associazione: 'areaGeografica',
            label: 'Area geografica :',
            value: '',
            details: {
                type: 'text',
            },
            validazione : 'testoRichiesto'
        },
        {
            id: 'descrizione',
            tipo: 'fieldTextArea',
            associazione: 'descrizione',
            label: 'Descrizione :',
            value: '',
            details: {
                grid : {lg : {input : 4, output : 4}},
                type: 'text',
            },
            validazione : 'testoRichiesto'
        },
    ],
}
