import React from 'react'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import ElementoMostraEnte from 'componenti/elementoMostraEnte'

export default function elementoListaEnti(props) {
    const renderLista = (
        <Grid container rowGap={{xs : 2, sm : 0}} spacing={{xs : 0, sm : 2}} mx='auto' maxWidth='1200px'>
            {props.lista.map((ente) => {
                return (
                    <Grid item xs={12} sm={6} lg={4} xxl={4} key={ente.id}>
                        <ElementoMostraEnte ente={ente} {...props} />
                    </Grid>
                )
            })}
        </Grid>
    )

    const renderErrore = (
        <MDBox width='100%' bgColor='white' p={2} shadow='lg' borderRadius='lg'>
            <MDTypography color='text'>{props.testoErrore}</MDTypography>
        </MDBox>
    )
    return props.lista.length === 0 ? renderErrore : renderLista
}

elementoListaEnti.defaultProps = {
    lista: [],
    testoErrore: '',
}
