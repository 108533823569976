import React, { useEffect, useState } from 'react'
import useCache from 'customHooks/useCache'
import MDBox from 'components/MDBox'
import { List, Grid } from '@mui/material'
import { getListaMailImprese } from 'getter/getterImpresa'
import { getListaMailEnti } from 'getter/getterEnte'
import MDTypography from 'components/MDTypography'
import ElementoMostraEmailUtente from 'componenti/elementoMostraEmailUtente'

export default function paginaListaEmailetiqua(props) {
    const [listaImprese, setListaImprese] = useState([])
    const [listaEnti, setListaEnti] = useState([])
    const [lista, setLista] = useState([])
    const [selezionato, setSelezionato] = useState('imprese')
    const { listaImpreseWindow, listaEntiWindow } = useCache()

    useEffect(() => {
        if (selezionato === 'imprese') setLista(listaImprese)
        if (selezionato === 'non profit') setLista(listaEnti)
    }, [selezionato, listaEnti, listaImprese])

    useEffect(() => {
        setListaImprese(getListaMailImprese())
        setListaEnti(getListaMailEnti())
    }, [listaImpreseWindow, listaEntiWindow])

    const renderTab = (testo) => {
        const styleDefault = {
            py: 1,
            color: 'info',
            bgColor: 'white',
            borderRadius: 'md',
            className: 'centrato',
            sx: { border: 'solid 0.2px', cursor: 'pointer' },
        }
        const styleSelezionato = {
            py: 1,
            color: 'white',
            bgColor: 'info',
            borderRadius: 'md',
            className: 'centrato',
            sx: { cursor: 'pointer' },
        }
        const stile = selezionato === testo ? styleSelezionato : styleDefault
        return (
            <MDBox {...stile} onClick={() => setSelezionato(testo)}>
                <MDTypography color={selezionato === testo ? 'white' : 'info'} fontWeight='medium'>
                    {testo}
                </MDTypography>
            </MDBox>
        )
    }

    console.log('listaaa : ', lista)

    return (
        <MDBox sx={{ maxWidth: '1000px', width: '100%' }} mx='auto' className='flex-verticale'>
            <MDBox shadow='lg' p={2} bgColor='white' mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        {renderTab('imprese')}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {renderTab('non profit')}
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox shadow='lg' p={2} bgColor='white'>
                <List>
                    {lista.map((utente) => {
                        return <ElementoMostraEmailUtente key={utente.id} utente={utente} />
                    })}
                </List>
            </MDBox>
        </MDBox>
    )
}
