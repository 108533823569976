import React from 'react'
import { connect } from 'react-redux'
import ElementoVediNetwork from 'componenti/elementoVediNetwork'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'

export const paginaVediNetwork = (props) => {
    const renderPagina = <ElementoVediNetwork id={props.idNetwork} idUtente={props.idUtente} navigate={props.navigate} />
    return <ElementoRenderPagina boxProps={{maxWidth : '1000px'}} renderPagina={renderPagina} />
}

const mapStateToProps = (state) => ({
    idNetwork: state.url[4],
    idUtente: getIdUtenteLoggato(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediNetwork)
