const initialState = {
    stato: 1,
    utente: null,
    id: '',
}

export const statoUtente = (state = initialState, action) => {
    switch (action.type) {
        case 'CAMBIA_STATO':
            let newState = { stato: action.payload.stato, id: action.payload.id, utente: action.payload.utente }
            return newState
            break
        default:
            return state
            break
    }
}
