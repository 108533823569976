import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getListaModalitaDonazione } from 'getter/getterProgetti'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getProgettoSelezionato } from 'getter/getterProgetti'
import ElementoModalitaDonazione from 'componenti/elementoModalitaDonazione'
import ElementoDonazione from 'componenti/elementoDonazione'

export const paginaSostieniProgetto = (props) => {
    const [scelta, setScelta] = useState(null)
    const handleScelta = (nuovaScelta) => setScelta(nuovaScelta)

    const renderDescrizioneDonazione = (
        <>
            <MDTypography mt={1} variant='h3' fontWeight='medium'>
                Trasforma le tue donazioni in investimenti in marketing deducibili dalle tasse!
            </MDTypography>
            <MDTypography variant='h3' fontWeight='medium'>
                Sostieni un progetto e noi spingeremo il nostro bacino di consumatori a compiere acquisti presso il tuo negozio.
            </MDTypography>
            <MDBox color='primary' sx={{ borderBottom: 'solid', width: 'max-content', cursor: 'pointer' }}>
                <MDTypography mt={2} variant='h5' color='primary' fontWeight='medium'>
                <a href="https://www.etiqua.net/istruzioni-negozi/" target="_blank" rel="noopener noreferrer">Guarda come funziona </a>
                </MDTypography>
            </MDBox>
        </>
    )

    const renderIntestazione = (
        <MDBox mx='auto' py={5} mb={1} sx={{ flexDirection: 'column', maxWidth: '1000px' }}>
            <MDTypography variant='h2' color='primary' fontWeight='bold' align='center'>
                Sostieni {props.progetto.nome || ''}
            </MDTypography>
            <MDTypography align='center' color='primary'>
                {scelta?.spiegazione || ''}
            </MDTypography>
            {scelta === null ? null : renderDescrizioneDonazione}
        </MDBox>
    )

    const renferListaModalita = (
        <MDBox className='flex-verticale'>
            <MDBox className='centrato' mb={2}>
                <MDTypography variant='h3' fontWeight='medium'>
                    In che modo posso sostenere il progetto
                </MDTypography>
            </MDBox>
            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                {props.listaModalita.map((elemento) => {
                    return (
                        <Grid item sx={12} md={4} key={elemento.tipo}>
                            <ElementoModalitaDonazione modalita={elemento} handleClick={handleScelta} />
                        </Grid>
                    )
                })}
            </Grid>
        </MDBox>
    )

    const renderPagina = (
        <MDBox sx={{ display: 'flex', flexDirection: 'column' }}>
            {renderIntestazione}
            {scelta === null ? renferListaModalita : <ElementoDonazione modalita={scelta} handleScelta={handleScelta} />}
        </MDBox>
    )

    return renderPagina
}

paginaSostieniProgetto.defaultProps = {
    isLanding: true,
}
function mapStateToProps(state) {
    return {
        listaModalita: getListaModalitaDonazione(),
        progetto: getProgettoSelezionato(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaSostieniProgetto)
