import React, { useEffect, useState } from 'react'
import { getUrlDaStorage } from 'getter/getterimmagini'
import MDAvatar from 'components/MDAvatar'
import MDTypography from 'components/MDTypography'

export default function elementoMostraLogoInLista(props) {
    const [url, setUrl] = useState('')

    useEffect(async () => {
        const logo = props.dati.logo || ''
        if (logo === '' && url === '') return
        if (logo === '' && url !== '') {
            setUrl('')
            return
        }
        const path = `${props.id}/logo/thumbNail.png`
        const nuovo = await getUrlDaStorage(path)
        setUrl(nuovo)
    }, [props.dati])

    if (url === '') {
        const primaLettera = props.dati?.nome !== undefined ? props.dati?.nome[0] : ''
        return (
            <MDAvatar bgColor='warning' size='sm'>
                <MDTypography fontWeight='bold' color='white' align='center'>
                    {primaLettera.toUpperCase()}
                </MDTypography>
            </MDAvatar>
        )
    }

    return <MDAvatar src={url} alt='fotoProfilo' size='sm' />
}
