import { store } from '../store'

export const APRI_POP_UP = (oggettoEvento) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'APRI_POP_UP',
            payload: oggettoEvento,
        })
        window.dispatchEvent(new CustomEvent('apriPopUp', { detail: oggettoEvento }))
        resolve()
    })
}
