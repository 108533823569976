import { store } from 'data/store'

export const getListaRichiesteContatto = () => {
    const state = store.getState()
    const lista = state.reducerRichiesteContatto
    var letti = []
    var nonLetti = []
    if (lista === undefined) return { letti, nonLetti }
    lista.forEach((elemento) => {
        if (elemento.letto) letti.push(elemento)
        if (!elemento.letto) nonLetti.push(elemento)
    })
    return { letti, nonLetti }
}
