import React from 'react'
import './../galleria.css'
import MDBox from 'components/MDBox'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

export default function elementoVisualizzaQrCode(props) {
    const chiudi = () => props.handleQrCodeAperto(false)

    return (
        <div className={props.aperto ? 'model open' : 'model'}>
            <MDBox component='img' src={props.url} alt='img-selezionata' sx={{width : '300px !important'}}></MDBox>
            <CloseRoundedIcon sx={{ color: 'white' }} onClick={chiudi} />
        </div>
    )
}
