import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Divider } from '@mui/material'
import useForm from 'customHooks/useForm'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { MODIFICA_DATI_UTENTE_LOGGATO } from 'data/azioni/MODIFICA_DATI_UTENTE_LOGGATO'
import useCache from 'customHooks/useCache'
import { getDatiConsumatore } from 'getter/getterConsumatore'
import { getListaProgettiSostenutiConsumatore } from 'getter/getterConsumatore'
import { formConsumatoreResponsabileNoCOndizioni } from 'formModel/formConsumatoreResponsabile'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getUrlDaStorage } from 'getter/getterimmagini'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import ElementoMostraProfilo from 'componenti/elementoMostraProfilo'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import ElementoMostraProgetto from 'componenti/elementoMostraProgetto'
import { getProgettoDaId } from 'getter/getterProgetti'
import ElementoCarosello from 'componenti/elementoCarosello'
import { Card } from '@mui/material'
import MDButton from 'components/MDButton'
import Transaction from 'layouts/pages/account/billing/components/Transaction'
import { getUltimiSetteAcquistiConsumatore } from 'getter/getterConsumatore'
import { getListaAcquistiResponsabiliConsumatore } from 'getter/getterConsumatore'

const viste = ['Dati']

export const paginaVediConsumatore = (props) => {
    const [statoInfoBase, azioniInfoBase, renderFormInfoBase] = useForm(formConsumatoreResponsabileNoCOndizioni, props.dati, false, false)
    const [listaProgettiSostenuti, setListaProgettiSostenuti] = useState([])
    const [listaImmagini, setListaImmagini] = useState([])
    const [transazioniAperte, setTransazioniAperte] = useState(false)
    const [listaTransazioni, setListaTransazioni] = useState([])

    const handleTransazioniAperte = () => setTransazioniAperte(!transazioniAperte)

    useEffect(() => {
        if (transazioniAperte) {
            setListaTransazioni(getListaAcquistiResponsabiliConsumatore())
        }
        if (!transazioniAperte) {
            setListaTransazioni(getUltimiSetteAcquistiConsumatore())
        }
    }, [props.ultimiAcquisti, transazioniAperte])

    const { listaProgettiWindow } = useCache()

    const getUrlImmagine = async (immagine) => {
        const pathThumbNail = !immagine.isLogo ? `${props.id}/immagini/${immagine.id}/thumbNail.png` : `${props.id}/logo/thumbNail.png`
        const pathOriginale = !immagine.isLogo ? `${props.id}/immagini/${immagine.id}/originale.png` : `${props.id}/logo/originale.png`

        const url = await Promise.all([getUrlDaStorage(pathThumbNail), getUrlDaStorage(pathOriginale)])
        return { thumbNail: url[0], originale: url[1], id: immagine.id }
    }

    useEffect(async () => {
        if (props.listaImmagini.length === 0) return

        const promesse = props.listaImmagini.map(async (immagine) => {
            return await getUrlImmagine(immagine)
        })

        const risultati = await Promise.all(promesse)
        const lista = risultati.map((result) => {
            return {
                originale: result.originale,
                thumbnail: result.thumbNail,
                id: result.id,
            }
        })

        setListaImmagini(lista)
    }, [props.listaImmagini])

    useEffect(() => {
        setListaProgettiSostenuti(getListaProgettiSostenutiConsumatore())
    }, [listaProgettiWindow, props.dati])

    const salva = async (azioni, stato) => {
        MODIFICA_STATO_SPINNER(true)
        try {
            await azioni.validaForm()
            await MODIFICA_DATI_UTENTE_LOGGATO(stato)
            MODIFICA_STATO_SPINNER(false)
            azioni.vediOutput()
        } catch (error) {
            MODIFICA_STATO_SPINNER(false)
        }
    }

    const renderForm = (form, bottoni) => {
        return (
            <MDBox sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
                {form}
                {props.isMioProfilo ? bottoni : null}
            </MDBox>
        )
    }

    const renderInfoBase = renderForm(renderFormInfoBase, <ElementoBottoniForm valida={() => salva(azioniInfoBase, statoInfoBase.statoForm)} vista={statoInfoBase.vista} annulla={azioniInfoBase.annullaControlla} vediInput={azioniInfoBase.vediInput} />)

    const renderProgetti = (
        <MDBox className='flex-verticale' mx='auto' sx={{width : '100%', maxWidth : '400px'}}>
            <MDBox className=' flex-verticale' sx={{ maxWidth: '500px', height: 'max-content' }}>
                <ElementoEtichettaForm testo='Progetti sostenuti con i tuoi acquisti' />
                <MDBox borderRadius='lg' sx={{ height: '100%', width: '100%' }}>
                    <ElementoCarosello
                        testo='Nessun progetto sostenuto'
                        lista={listaProgettiSostenuti.map((progetto) => {
                            return { id: progetto.idProgetto, render: <ElementoMostraProgetto progetto={getProgettoDaId(progetto.idProgetto)} navigate={props.navigate} /> }
                        })}
                    />
                </MDBox>
            </MDBox>
        </MDBox>
    )

    const renderTransazioni = (
        <MDBox sx={{ height: 'max-content' }}>
            <MDBox display='flex' justifyContent='space-between' alignItems='center' pt={3} px={2}>
                <MDTypography variant='h6' fontWeight='medium' textTransform='capitalize'>
                    I tuoi acquisti
                </MDTypography>
                <MDBox display='flex' alignItems='flex-start'>
                    <MDButton variant='outlined' color='info' onClick={handleTransazioniAperte}>
                        {transazioniAperte ? 'Nascondi' : 'vedi tutti'}
                    </MDButton>
                </MDBox>
            </MDBox>
            <MDBox pt={3} pb={2} px={2}>
                <MDBox mb={2}>
                    <MDTypography variant='caption' color='text' fontWeight='bold' textTransform='uppercase'>
                        Ultimi 7 acquisti
                    </MDTypography>
                </MDBox>
                <MDBox component='ul' display='flex' flexDirection='column' p={0} m={0} sx={{ listStyle: 'none' }}>
                    {listaTransazioni.map((transazione) => {
                        return <Transaction key={transazione.id} {...transazione} />
                    })}
                </MDBox>
            </MDBox>
        </MDBox>
    )

    const renderGriglia = (
        <Grid container p={3}>
            <Grid item md={12} lg={5}>
                <Card >{renderTransazioni}</Card>
            </Grid>
            <Grid item md={12} lg={7}>
                {renderProgetti}
            </Grid>
        </Grid>
    )

    const renderPagina = (
        <>
            <MDBox mb={2} bgColor='white' borderRadius='lg' shadow='lg' className='flex-verticale' pb={2}>
                <ElementoMostraProfilo nacondiBottoneImmagini={true} immagini={listaImmagini} nome={`${props.dati.nome} ${props.dati.cognome}`} {...props} tabs={viste} viste={[renderInfoBase]} />
                {/* <Divider /> */}
                {/* <Divider />
            {renderProgetti} */}
            </MDBox>
            {props.ultimiAcquisti.length === 0 ? renderProgetti : renderGriglia}
        </>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

paginaVediConsumatore.defaultProps = {
    isLanding: true,
    isMioProfilo: false,
}

const mapStateToProps = (state, ownprops) => ({
    dati: getDatiConsumatore(),
    listaImmagini: [],
    id: getIdUtenteLoggato(),
    ultimiAcquisti: getListaAcquistiResponsabiliConsumatore(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediConsumatore)
