import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { doc, updateDoc, deleteField } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { ref, deleteObject } from 'firebase/storage'

export const ELIMINA_IMMAGINE_UTENTE = async (idImmagine) => {
    MODIFICA_STATO_SPINNER(true)
    const idUtente = getIdUtenteLoggato()
    const pathThumbNail = `${idUtente}/immagini/${idImmagine}/thumbNail.png`
    const pathOriginale = `${idUtente}/immagini/${idImmagine}/originale.png`

    
    
    await deleteObject(ref(storage, pathThumbNail))
    await deleteObject(ref(storage, pathOriginale))
    await updateDoc(doc(db, 'utenti', idUtente), { [`immagini.${idImmagine}`]: deleteField() })
    MODIFICA_STATO_SPINNER(false)
    return
}
