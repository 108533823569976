import { store } from '../store'

export const LEGGI_IMPRESA = (dati) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'LEGGI_IMPRESA',
            payload: dati,
        })
    })
}
