import { store } from '../store'

export const LEGGI_ENTE = (dati) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'LEGGI_ENTE',
            payload: dati,
        })
    })
}
