import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import React from 'react'
import { connect } from 'react-redux'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { isProgettoInAttesa } from 'getter/getterProgetti'
import { MODIFICA_STATO_PROGETTO } from 'data/azioni/MODIFICA_STATO_PROGETTO'
import { isRaccoltaCompletata } from 'getter/getterProgetti'
import { isRaccoltaValidata } from 'getter/getterProgetti'

export const elementoNavigaziioneProgettoEtiqua = (props) => {
    const handleClick = (valore) => props.handleVista(valore)

    const isSelezionato = (valore) => valore === props.selezionato
    const rifiutaProgetto = () => MODIFICA_STATO_PROGETTO(props.idProgetto, 'rifiutato', [])
    const rifiuta = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: 'Sei sicuro di voler rifiutare questo progetto ? ', azione: rifiutaProgetto } })

    const styleDefault = {
        py: 1,
        color: 'info',
        bgColor: 'white',
        borderRadius: 'md',
        className: 'centrato',
        sx: { border: 'solid 0.2px', cursor: 'pointer' },
    }
    const styleSelezionato = {
        py: 1,
        color: 'white',
        bgColor: 'info',
        borderRadius: 'md',
        className: 'centrato',
        sx: { cursor: 'pointer' },
    }
    return (
        <Grid container spacing={0.5} p={1} maxWidth={'1000px'} mx='auto' mb={2} justifyContent={'center'}>
            <Grid item xs={12} sm={12} md={12} lg={3}>
                <MDBox {...(isSelezionato('opzioni') ? styleSelezionato : styleDefault)} onClick={() => handleClick('opzioni')}>
                    <MDTypography color={isSelezionato('opzioni') ? 'white' : 'info'} fontWeight='medium'>
                        Opzioni
                    </MDTypography>
                </MDBox>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={3}>
                <MDBox {...(isSelezionato('impatto') ? styleSelezionato : styleDefault)} onClick={() => handleClick('impatto')}>
                    <MDTypography color={isSelezionato('impatto') ? 'white' : 'info'} fontWeight='medium'>
                        Impatto
                    </MDTypography>
                </MDBox>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={3}>
                <MDBox {...(isSelezionato('home') ? styleSelezionato : styleDefault)} onClick={() => handleClick('home')}>
                    <MDTypography color={isSelezionato('home') ? 'white' : 'info'} fontWeight='medium'>
                        home
                    </MDTypography>
                </MDBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
                <MDBox {...(isSelezionato('dettagli') ? styleSelezionato : styleDefault)} onClick={() => handleClick('dettagli')}>
                    <MDTypography color={isSelezionato('dettagli') ? 'white' : 'info'} fontWeight='medium'>
                        dettagli
                    </MDTypography>
                </MDBox>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    const idProgetto = state.url[4]
    return {
        isInAttesa: isProgettoInAttesa(idProgetto),
        isCompletato: isRaccoltaCompletata(idProgetto),
        isValidata: isRaccoltaValidata(idProgetto),
        idProgetto: idProgetto,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoNavigaziioneProgettoEtiqua)
