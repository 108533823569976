import React, { useState, useEffecr, useEffect } from 'react'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { Icon } from '@mui/material'
import useInputFileImmagini from 'customHooks/useInputFileImmagini'
import useForm from 'customHooks/useForm'
import { getUrlDaStorage } from 'getter/getterimmagini'
import MDTypography from 'components/MDTypography'
import { formDocumentiAllegatiImpresa } from 'formModel/formImpresaResponsabile'
import ElementoBottoniForm from './elementoBottoniForm'
import { MODIFICA_DATI_UTENTE_LOGGATO } from 'data/azioni/MODIFICA_DATI_UTENTE_LOGGATO'
import { AGGIORNA_DOCUMENTI_IMPRESA } from 'data/azioni/AGGIORNA_DOCUMENTI_IMPRESA'
import { ELIMINA_DOCUMENTO_IMPRESA } from 'data/azioni/ELIMINA_DOCUMENTO_IMPRESA'

export default function elementoDocumentiImpresa(props) {
    const [urlBilancioSociale, setUrlBilancioSociale] = useState('')
    const [urlCodiceEtico, setUrlCodiceEtico] = useState('')
    const [urlRatingESG, setUrlRatingESG] = useState('')
    const [stato, azioni, renderForm] = useForm(formDocumentiAllegatiImpresa, props.dati, false, false)

    const inserisciDocumento = async (file, dato) => {
       await AGGIORNA_DOCUMENTI_IMPRESA(file, dato)
    }
    const rimuovi = async (dato) => {
       await ELIMINA_DOCUMENTO_IMPRESA( dato)
    }

    const aggiorna = async () => {
        try {
            await azioni.validaForm()
            await MODIFICA_DATI_UTENTE_LOGGATO(stato.statoForm)
            azioni.vediOutput()
        } catch (error) {
            return
        }
    }

    const [renderBilancioSociale, caricaBilancioSociale] = useInputFileImmagini('bilancio-sociale', (thumbnail, file) => inserisciDocumento(file, 'bilancioSociale'), 'pdf')
    const [renderCodiceEtico, caricaCodiceEtico] = useInputFileImmagini('codice-etico', (thumbnail, file) => inserisciDocumento(file, 'codiceEtico'), 'pdf')
    const [renderRatingESG, caricaRatingESG] = useInputFileImmagini('rating-esg', (thumbnail, file) => inserisciDocumento(file, 'ratingESG'), 'pdf')

    useEffect(async () => {
        if (props.dati.bilancioSociale) setUrlBilancioSociale(await getUrlDaStorage(`${props.id}/documenti/bilancioSociale.pdf`))
        if (props.dati.codiceEtico) setUrlCodiceEtico(await getUrlDaStorage(`${props.id}/documenti/codiceEtico.pdf`))
        if (props.dati.ratingESG) setUrlRatingESG(await getUrlDaStorage(`${props.id}/documenti/ratingESG.pdf`))
        
        if (!props.dati.bilancioSociale) setUrlBilancioSociale("")
        if (!props.dati.codiceEtico) setUrlCodiceEtico("")
        if (!props.dati.ratingESG) setUrlRatingESG("")
    }, [props.dati])

    const renderBottoni = (link, dato, azione) => {
        if (!props.isMioProfilo) return null
        if (link === '') {
            return (
                <Grid item>
                    <MDButton color='dark' variant='contained' size='small' iconOnly onClick={() => azione()}>
                        <Icon>add</Icon>
                    </MDButton>
                </Grid>
            )
        }
        return (
            <>
                <Grid item>
                    <MDButton color='dark' variant='contained' size='small' iconOnly onClick={() => azione()}>
                        <Icon>edit</Icon>
                    </MDButton>
                </Grid>
                <Grid item>
                    <MDButton color='error' variant='contained' size='small' iconOnly onClick={() => rimuovi(dato)}>
                        <Icon>remove</Icon>
                    </MDButton>
                </Grid>
            </>
        )
    }

    const renderRiga = (label, link, dato, azione) => {
        return (
            <MDBox className='flex-verticale'>
                <MDTypography variant='caption' fontWeight='medium' color='primary'>
                    {label}
                </MDTypography>
                <MDBox className='flex-orizzontale'>
                    <MDTypography width='100%' variant='string' color='dark'>
                        {link === '' ? 'NO' : 'SI - '}
                        {link === '' ? null : (
                            <MDTypography width='100%' onClick={() => window.open(link)} variant='string' color='dark' sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                SCARICA
                            </MDTypography>
                        )}
                    </MDTypography>
                    <Grid container direction='row' spacing={2}>
                        {renderBottoni(link, dato, azione)}
                    </Grid>
                </MDBox>
            </MDBox>
        )
    }

    return (
        <>
            {renderBilancioSociale}
            {renderCodiceEtico}
            {renderRatingESG}
            <Grid container spacing={2} direction='row'>
                <Grid item xs={12} md={12} lg={12}>
                    {renderForm}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {renderRiga('Bilancio sociale', urlBilancioSociale, 'bilancioSociale', caricaBilancioSociale)}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {renderRiga('Codice etico', urlCodiceEtico, 'codiceEtico', caricaCodiceEtico)}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    {renderRiga('Rating ESG', urlRatingESG, 'ratingESG', caricaRatingESG)}
                </Grid>
            </Grid>
            {props.isMioProfilo ? <ElementoBottoniForm allineaSinistra={true} vista={stato.vista} annulla={azioni.annullaControlla} valida={aggiorna} vediInput={azioni.vediInput} /> : null}
        </>
    )
}
