import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { store } from 'data/store'
import colors from 'assets/theme/base/colors'
import MDBox from 'components/MDBox'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import Slide from '@mui/material/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />
})

export function popUpModificheNonSalvate(props) {
    const handleClose = (paginaCambiata, e) => {
        store.dispatch({
            type: 'CHIUDI_POP_UP_MODIFICHE_NON_SALVATE',
            payload: paginaCambiata,
        })
        if (paginaCambiata && props.isAzione) {
            e.preventDefault()
            props.link()
        }
    }
    return (
        <Dialog PaperProps={{ sx: { backgroundColor: colors.warning.main } }} TransitionComponent={Transition} open={props.aperto} onClose={() => handleClose(false)} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle sx={{ color: colors.white.main }} id='titolo-pop-up-field-list'>
                MODIFICHE NON SALVATE
            </DialogTitle>
            <DialogContent sx={{ color: colors.white.main }}>
                <MDBox sx={{ margin: 'auto' }} width='200px' height='200px' p={2}>
                    <ErrorOutlineRoundedIcon sx={{ height: '100%', width: '100%' }} color='white' />
                </MDBox>
                Sei sicuro di voler continuare senza salvare?
            </DialogContent>
            <DialogActions>
                <NavLink to={props.link} sx={{ textDecoration: 'none' }}>
                    <MDButton variant='text' color='white' onClick={(e) => handleClose(true, e)}>
                        si
                    </MDButton>
                </NavLink>
                <MDButton color='white' variant='contained' onClick={(e) => handleClose(false, e)}>
                    no
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

function mapStateToProps(state) {
    return {
        aperto: state.reducerStatoModifica.popUpAperto,
        link: state.reducerStatoModifica.link,
        isAzione: state.reducerStatoModifica.isAzione,
    }
}

export default connect(mapStateToProps, null)(popUpModificheNonSalvate)
