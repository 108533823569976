export const profiloSuperAmministratore = { cache: 'cacheSuperAmministratore', tipo: 'super-amministratore', label: 'super amministratore' }
export const profiloConsumatore = { cache: 'cacheConsumatoreResponsabile', tipo: 'consumatore-responsabile', label: 'Consumatore responsabile' }
export const profiloImpresa = { cache: 'cacheImpresaResponsabile', tipo: 'impresa-responsabile', label: 'Impresa responsabile' }
export const profiloEnte = { cache: 'cacheEnteNonProfit', tipo: 'ente-non-profit', label: 'Ente non profit' }
export const cacheProgetti = 'cacheProgetti'
export const cacheNetwork = 'cacheNetwork'
export const listaProfiliApp = [profiloConsumatore, profiloImpresa, profiloEnte]
export const listaProfiliGenerale = [profiloSuperAmministratore, profiloConsumatore, profiloImpresa, profiloEnte]

export const formeGiuridicheImprese = ['Imprese Individuali', 'Ss - società semplice', 'Srl - Società a responsabilità limitata', 'Sas - Società in accomandita semplice', 'Snc - Società in Nome Collettivo', 'Spa - Società Per Azioni', 'Sapa - Società in accomandita per azioni', 'Società Cooperativa.', 'Reti di imprese.', 'Gruppo Europeo di Interesse Economico GEIE']
export const formeGiuridicheEnte = ['Associazione, Fondazione, Comitato', 'Cooperativa', 'Associazione non riconosciuta']
export const settoriImprese = [
    'Abbigliamento e moda',
    'Affari Internazionali',
    'Agricoltura',
    'Amministrazione del governo',
    'Animazione',
    'Applicazione della legge',
    'Architettura',
    'Arredamento',
    'Arti e mestieri',
    'Assicurazione',
    'Automazione industriale',
    'Automotive',
    'Aviazione e aerospazio',
    'Banche',
    'Belle Arti',
    'Beni di consumo',
    'Beni di lusso e gioielli',
    'Biotecnologia',
    'Cibo e bevande',
    'Cinema e film',
    'Commercio internazionale e sviluppo',
    'Compagnie aeree',
    'Consulenza manageriale',
    'Contabilità',
    'Cosmetici',
    'Costruzioni',
    'Cura della salute mentale',
    'Design grafico',
    'Design',
    'Difesa e spazio',
    'Dispositivo medico',
    'Educazione superiore',
    'E-learning',
    'Elettronica di consumo',
    'Forniture e attrezzature aziendali',
    'Gestione degli investimenti',
    'Gestione dell’educazione',
    'Gestione delle organizzazioni non profit',
    'Giochi mobili',
    'Giochi per computer',
    'Gioco d’azzardo e casinò',
    'Giornali',
    'Hardware per computer',
    'Immobili commerciali',
    'Importazione ed esportazione',
    'Ingegneria civile',
    'Ingegneria meccanica o industriale',
    'Internet',
    'Intrattenimento',
    'Investment Banking',
    'Librerie',
    'Logistica e catena di approvvigionamento',
    'Macchinari',
    'Magistratura',
    'Marittimo',
    'Marketing e pubblicità',
    'Materiali da costruzione',
    'Media online',
    'Media radiotelevisivi',
    'Medicina alternativa',
    'Mercati dei capitali',
    'Militare',
    'Miniere e metalli',
    'Musei',
    'Musica',
    'Nanotecnologia',
    'Organizzazione civica e sociale',
    'Ospedale e assistenza sanitaria',
    'Ospitalità',
    'Pesca',
    'Petrolio ed energia',
    'Pratica legale',
    'Pratica medica',
    'Prodotti chimici',
    'Prodotti lattiero-caseari',
    'Produzione alimentare',
    'Produzione di media',
    'Produzione elettrica ed elettronica',
    'Raccolta di fondi',
    'Relazioni con il governo',
    'Rete di computer',
    'Ricerche di mercato',
    'Risoluzione alternativa delle controversie',
    'Risorse Umane',
    'Salute, benessere e fitness',
    'Servizi al consumatore',
    'Servizi ambientali',
    'Servizi d’informazione',
    'Servizi finanziari',
    'Servizi individuali e familiari.',
    'Servizi legali',
    'Servizi per eventi',
    'Servizi per le strutture',
    'Sicurezza dei computer e delle reti',
    'Software per computer',
    'Tecnologia dell’informazione e servizi',
    'Tempo libero, viaggi e turismo',
    'Ufficio esecutivo',
    'Ufficio legislativo',
    'Vetro, ceramica e calcestruzzo',
]
export const scelteTipoBusiness = ['B2B (vendita ad altre imprese)', 'B2C (vendita al dettaglio)', 'B2C ONLY ONLINE']
export const listaProvince = [
    {
        nome: 'Agrigento',
        sigla: 'AG',
        regione: 'Sicilia',
    },
    {
        nome: 'Alessandria',
        sigla: 'AL',
        regione: 'Piemonte',
    },
    {
        nome: 'Ancona',
        sigla: 'AN',
        regione: 'Marche',
    },
    {
        nome: 'Arezzo',
        sigla: 'AR',
        regione: 'Toscana',
    },
    {
        nome: 'Ascoli Piceno',
        sigla: 'AP',
        regione: 'Marche',
    },
    {
        nome: 'Asti',
        sigla: 'AT',
        regione: 'Piemonte',
    },
    {
        nome: 'Avellino',
        sigla: 'AV',
        regione: 'Campania',
    },
    {
        nome: 'Bari',
        sigla: 'BA',
        regione: 'Puglia',
    },
    {
        nome: 'Barletta-Andria-Trani',
        sigla: 'BT',
        regione: 'Puglia',
    },
    {
        nome: 'Belluno',
        sigla: 'BL',
        regione: 'Veneto',
    },
    {
        nome: 'Benevento',
        sigla: 'BN',
        regione: 'Campania',
    },
    {
        nome: 'Bergamo',
        sigla: 'BG',
        regione: 'Lombardia',
    },
    {
        nome: 'Biella',
        sigla: 'BI',
        regione: 'Piemonte',
    },
    {
        nome: 'Bologna',
        sigla: 'BO',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Bolzano/Bozen',
        sigla: 'BZ',
        regione: 'Trentino-Alto Adige/Südtirol',
    },
    {
        nome: 'Brescia',
        sigla: 'BS',
        regione: 'Lombardia',
    },
    {
        nome: 'Brindisi',
        sigla: 'BR',
        regione: 'Puglia',
    },
    {
        nome: 'Cagliari',
        sigla: 'CA',
        regione: 'Sardegna',
    },
    {
        nome: 'Caltanissetta',
        sigla: 'CL',
        regione: 'Sicilia',
    },
    {
        nome: 'Campobasso',
        sigla: 'CB',
        regione: 'Molise',
    },
    {
        nome: 'Carbonia-Iglesias',
        sigla: 'CI',
        regione: 'Sardegna',
    },
    {
        nome: 'Caserta',
        sigla: 'CE',
        regione: 'Campania',
    },
    {
        nome: 'Catania',
        sigla: 'CT',
        regione: 'Sicilia',
    },
    {
        nome: 'Catanzaro',
        sigla: 'CZ',
        regione: 'Calabria',
    },
    {
        nome: 'Chieti',
        sigla: 'CH',
        regione: 'Abruzzo',
    },
    {
        nome: 'Como',
        sigla: 'CO',
        regione: 'Lombardia',
    },
    {
        nome: 'Cosenza',
        sigla: 'CS',
        regione: 'Calabria',
    },
    {
        nome: 'Cremona',
        sigla: 'CR',
        regione: 'Lombardia',
    },
    {
        nome: 'Crotone',
        sigla: 'KR',
        regione: 'Calabria',
    },
    {
        nome: 'Cuneo',
        sigla: 'CN',
        regione: 'Piemonte',
    },
    {
        nome: 'Enna',
        sigla: 'EN',
        regione: 'Sicilia',
    },
    {
        nome: 'Fermo',
        sigla: 'FM',
        regione: 'Marche',
    },
    {
        nome: 'Ferrara',
        sigla: 'FE',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Firenze',
        sigla: 'FI',
        regione: 'Toscana',
    },
    {
        nome: 'Foggia',
        sigla: 'FG',
        regione: 'Puglia',
    },
    {
        nome: 'Forlì-Cesena',
        sigla: 'FC',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Frosinone',
        sigla: 'FR',
        regione: 'Lazio',
    },
    {
        nome: 'Genova',
        sigla: 'GE',
        regione: 'Liguria',
    },
    {
        nome: 'Gorizia',
        sigla: 'GO',
        regione: 'Friuli-Venezia Giulia',
    },
    {
        nome: 'Grosseto',
        sigla: 'GR',
        regione: 'Toscana',
    },
    {
        nome: 'Imperia',
        sigla: 'IM',
        regione: 'Liguria',
    },
    {
        nome: 'Isernia',
        sigla: 'IS',
        regione: 'Molise',
    },
    {
        nome: "L'Aquila",
        sigla: 'AQ',
        regione: 'Abruzzo',
    },
    {
        nome: 'La Spezia',
        sigla: 'SP',
        regione: 'Liguria',
    },
    {
        nome: 'Latina',
        sigla: 'LT',
        regione: 'Lazio',
    },
    {
        nome: 'Lecce',
        sigla: 'LE',
        regione: 'Puglia',
    },
    {
        nome: 'Lecco',
        sigla: 'LC',
        regione: 'Lombardia',
    },
    {
        nome: 'Livorno',
        sigla: 'LI',
        regione: 'Toscana',
    },
    {
        nome: 'Lodi',
        sigla: 'LO',
        regione: 'Lombardia',
    },
    {
        nome: 'Lucca',
        sigla: 'LU',
        regione: 'Toscana',
    },
    {
        nome: 'Macerata',
        sigla: 'MC',
        regione: 'Marche',
    },
    {
        nome: 'Mantova',
        sigla: 'MN',
        regione: 'Lombardia',
    },
    {
        nome: 'Massa-Carrara',
        sigla: 'MS',
        regione: 'Toscana',
    },
    {
        nome: 'Matera',
        sigla: 'MT',
        regione: 'Basilicata',
    },
    {
        nome: 'Medio Campidano',
        sigla: 'VS',
        regione: 'Sardegna',
    },
    {
        nome: 'Messina',
        sigla: 'ME',
        regione: 'Sicilia',
    },
    {
        nome: 'Milano',
        sigla: 'MI',
        regione: 'Lombardia',
    },
    {
        nome: 'Modena',
        sigla: 'MO',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Monza e della Brianza',
        sigla: 'MB',
        regione: 'Lombardia',
    },
    {
        nome: 'Napoli',
        sigla: 'NA',
        regione: 'Campania',
    },
    {
        nome: 'Novara',
        sigla: 'NO',
        regione: 'Piemonte',
    },
    {
        nome: 'Nuoro',
        sigla: 'NU',
        regione: 'Sardegna',
    },
    {
        nome: 'Ogliastra',
        sigla: 'OG',
        regione: 'Sardegna',
    },
    {
        nome: 'Olbia-Tempio',
        sigla: 'OT',
        regione: 'Sardegna',
    },
    {
        nome: 'Oristano',
        sigla: 'OR',
        regione: 'Sardegna',
    },
    {
        nome: 'Padova',
        sigla: 'PD',
        regione: 'Veneto',
    },
    {
        nome: 'Palermo',
        sigla: 'PA',
        regione: 'Sicilia',
    },
    {
        nome: 'Parma',
        sigla: 'PR',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Pavia',
        sigla: 'PV',
        regione: 'Lombardia',
    },
    {
        nome: 'Perugia',
        sigla: 'PG',
        regione: 'Umbria',
    },
    {
        nome: 'Pesaro e Urbino',
        sigla: 'PU',
        regione: 'Marche',
    },
    {
        nome: 'Pescara',
        sigla: 'PE',
        regione: 'Abruzzo',
    },
    {
        nome: 'Piacenza',
        sigla: 'PC',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Pisa',
        sigla: 'PI',
        regione: 'Toscana',
    },
    {
        nome: 'Pistoia',
        sigla: 'PT',
        regione: 'Toscana',
    },
    {
        nome: 'Pordenone',
        sigla: 'PN',
        regione: 'Friuli-Venezia Giulia',
    },
    {
        nome: 'Potenza',
        sigla: 'PZ',
        regione: 'Basilicata',
    },
    {
        nome: 'Prato',
        sigla: 'PO',
        regione: 'Toscana',
    },
    {
        nome: 'Ragusa',
        sigla: 'RG',
        regione: 'Sicilia',
    },
    {
        nome: 'Ravenna',
        sigla: 'RA',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Reggio di Calabria',
        sigla: 'RC',
        regione: 'Calabria',
    },
    {
        nome: "Reggio nell'Emilia",
        sigla: 'RE',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Rieti',
        sigla: 'RI',
        regione: 'Lazio',
    },
    {
        nome: 'Rimini',
        sigla: 'RN',
        regione: 'Emilia-Romagna',
    },
    {
        nome: 'Roma',
        sigla: 'RM',
        regione: 'Lazio',
    },
    {
        nome: 'Rovigo',
        sigla: 'RO',
        regione: 'Veneto',
    },
    {
        nome: 'Salerno',
        sigla: 'SA',
        regione: 'Campania',
    },
    {
        nome: 'Sassari',
        sigla: 'SS',
        regione: 'Sardegna',
    },
    {
        nome: 'Savona',
        sigla: 'SV',
        regione: 'Liguria',
    },
    {
        nome: 'Siena',
        sigla: 'SI',
        regione: 'Toscana',
    },
    {
        nome: 'Siracusa',
        sigla: 'SR',
        regione: 'Sicilia',
    },
    {
        nome: 'Sondrio',
        sigla: 'SO',
        regione: 'Lombardia',
    },
    {
        nome: 'Taranto',
        sigla: 'TA',
        regione: 'Puglia',
    },
    {
        nome: 'Teramo',
        sigla: 'TE',
        regione: 'Abruzzo',
    },
    {
        nome: 'Terni',
        sigla: 'TR',
        regione: 'Umbria',
    },
    {
        nome: 'Torino',
        sigla: 'TO',
        regione: 'Piemonte',
    },
    {
        nome: 'Trapani',
        sigla: 'TP',
        regione: 'Sicilia',
    },
    {
        nome: 'Trento',
        sigla: 'TN',
        regione: 'Trentino-Alto Adige/Südtirol',
    },
    {
        nome: 'Treviso',
        sigla: 'TV',
        regione: 'Veneto',
    },
    {
        nome: 'Trieste',
        sigla: 'TS',
        regione: 'Friuli-Venezia Giulia',
    },
    {
        nome: 'Udine',
        sigla: 'UD',
        regione: 'Friuli-Venezia Giulia',
    },
    {
        nome: "Valle d'Aosta/Vallée d'Aoste",
        sigla: 'AO',
        regione: "Valle d'Aosta/Vallée d'Aoste",
    },
    {
        nome: 'Varese',
        sigla: 'VA',
        regione: 'Lombardia',
    },
    {
        nome: 'Venezia',
        sigla: 'VE',
        regione: 'Veneto',
    },
    {
        nome: 'Verbano-Cusio-Ossola',
        sigla: 'VB',
        regione: 'Piemonte',
    },
    {
        nome: 'Vercelli',
        sigla: 'VC',
        regione: 'Piemonte',
    },
    {
        nome: 'Verona',
        sigla: 'VR',
        regione: 'Veneto',
    },
    {
        nome: 'Vibo Valentia',
        sigla: 'VV',
        regione: 'Calabria',
    },
    {
        nome: 'Vicenza',
        sigla: 'VI',
        regione: 'Veneto',
    },
    {
        nome: 'Viterbo',
        sigla: 'VT',
        regione: 'Lazio',
    },
]
export const categorieAttivita = ['Ambiente e animali', 'Arte e Cultura', 'Cooperazione ed economia solidale', ' Filantropia e promozione del volontariato', 'Infrastrutture ed eliminazione delle barriere architettoniche', 'Istruzione e formazione', 'Lavoro dignitoso e crescita economica', 'Protezione civile e Emergenza', 'Religione ed Esercizio di culto', 'Salute', 'Sport, ricreazione e tempo libero', 'Tutela dei diritti e promozione della cittadinanza', 'Uguaglianza di genere']
export const raggioAzione = ['comunale', 'provinciale', 'regionale', 'nazionale', 'internazionale']
export const albiElenchi = ['Nessuna', 'Impresa sociale', 'Cooperativa sociale', 'OSC (ex ONG)', 'Registro delle Persone Giuridiche', 'Onlus', 'Organizzazione di volontariato(ODV)', 'Associazioni Sportive dilettantistiche (ASD)', 'Associazione di Promozione Sociale(APS)']
export const categorieAttivitaProgetto = ['Sconfiggere la povertà', 'Ambiente e animali', 'Arte e cultura', 'Assistenza sociale e socio-sanitaria', 'Cooperazione ed economia solidale', 'Filantropia e promozione del volontariato', 'Infrastrutture ed eliminazione delle barriere', 'Istruzione e formazione', 'Lavoro dignitoso e crescita economica', 'Protezione civile e Emergenza', 'Religione ed Esercizio di culto', 'Salute', 'Sport, ricreazione e tempo libero', 'Tutela dei diritti e promozione della cittadinanza', 'Uguaglianza di genere']
export const beneficiari = ['Neet (giovani che non studiano e non lavorano)', 'Comunità estere', 'Vittime di catastrofi naturali e epidemie', 'Anziani', 'Bambini e adolescenti', 'Gruppi di appassionati', 'Persone con disabilità', 'Vittime di violenza e discriminazione', 'Animali', 'Persone affette da malattia', 'Migranti, minoranze e rifugiati', 'Esclusione sociale, povertà', 'Persone con dipendenze', 'Cittadini, collettività', 'Famiglie, adulti']
export const welfareAziendale = ['Rimborsi diretti a cedolino paga (Es: servizi di trasporto, servizi all’infanzia e istruzione, servizi di assistenza agli anziani o a familiari non autosufficienti a carico).', 'Servizi ad attivazione diretta (Es. istruzione, cultura, tempo libero, viaggi, ecc.).', 'Rimborsi spese sanitarie', 'Previdenza Complementare', 'Card Prepagate', 'Altro']
export const social_activity = ['promosso attività di volontariato d’impresa a favore del territorio', 'contribuito al restauro di un bene che è parte del patrimonio locale', 'promosso azioni di volontariato a favore del verde pubblico', 'sostenuto mostre, fiere ed eventi locali', 'collaborato con le scuole del territorio', 'promosso borse di studio', 'sostenuto organizzazioni non profit del territorio', 'partecipato a iniziative culturali promosse dagli enti locali', 'sostenuto squadre sportive giovanili locali', 'Altro']
export const ambientale = ['stimolato i collaboratori a risparmiare energia', 'offerto contributi per la mobilità sostenibile', 'installato impianti per la produzione di energie rinnovabili', 'adottato strumenti per la riduzione e lo smaltimento dei rifiuti', 'condiviso con i propri fornitori un percorso per una filiera sostenibile', 'migliorato la logistica in un’ottica di sostenibilità', 'adottato una politica di acquisti verdi', 'eliminato l’uso della plastica', 'promosso la vita dei prodotti', 'informato i clienti sulla gestione del “fine vita” dei prodotti', 'organizzato eventi sostenibili', 'modernizzato gli impianti produttivi', 'Altro']
export const certificazioni = ['ISO 9001 - Qualità', 'ISO 14001 - Ambiente', 'ISO 45001 - salute e sicurezza sul lavoro', 'ISO 22000 - sicurezza alimentare', 'ISO 26000 - responsabilità sociale', 'SA 8000 - responsabilità sociale', 'FSC - certificazione forestale', 'PEFC - certificazione forestale', 'IFS - sicurezza alimentare', 'BRC - sicurezza alimentare']
