const initialState = {
    modificheNonSalvate: false,
    popUpAperto: false,
    link: '',
    isAzione : false
}

export const reducerStatoModifica = (state = initialState, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            return {
                modificheNonSalvate: false,
                popUpAperto: false,
                link: '',
                isAzione : false
            }
        case 'AGGIORNA_STATO_MODIFICA':
            return Object.assign(state, { modificheNonSalvate: action.payload })
        case 'APRI_POP_UP_MODIFICHE_NON_SALVATE':
            return Object.assign(state, { link: action.payload.link, popUpAperto: true , isAzione: action.payload.isAzione})
        case 'CHIUDI_POP_UP_MODIFICHE_NON_SALVATE':
            let nuovoStato = {
                modificheNonSalvate: !action.payload,
                popUpAperto: false,
                link: '',
                isAzione : false
            }

            return nuovoStato
        default:
            return state
    }
}
