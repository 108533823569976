import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { ref, uploadBytes } from 'firebase/storage'
import { doc, updateDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { newId } from 'data/strumenti'

export const NUOVA_IMMAGINE_UTENTE = async (thumbnail, immagine) => {
    console.log('immagineeee');
    MODIFICA_STATO_SPINNER(true)
    const idUtente = getIdUtenteLoggato()
    const idImmagine = newId()

    const caricaInStorage = async (file, path) => {
        return await uploadBytes(ref(storage, path), file)
    }

    const aggiornaDb = async () => {
        return await updateDoc(doc(db, 'utenti', idUtente), { [`immagini.${idImmagine}`]: new Date() })
    }

    const pathThumbNail = `${idUtente}/immagini/${idImmagine}/thumbNail.png`
    const pathOriginale = `${idUtente}/immagini/${idImmagine}/originale.png`

    await Promise.all([caricaInStorage(thumbnail, pathThumbNail), caricaInStorage(immagine, pathOriginale), aggiornaDb()])
    MODIFICA_STATO_SPINNER(false)
    return
}
