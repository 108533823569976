import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { ref, uploadBytes } from 'firebase/storage'
import { doc, updateDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { newId } from 'data/strumenti'

export const NUOVO_LOGO_UTENTE = async (thumbnail, immagine) => {
    console.log('logoooo');
    MODIFICA_STATO_SPINNER(true)
    const idUtente = getIdUtenteLoggato()

    const caricaInStorage = async (file, path) => {
        return await uploadBytes(ref(storage, path), file)
    }

    const aggiornaDb = async () => {
        return await updateDoc(doc(db, 'utenti', idUtente), { logo: newId() })
    }

    const pathThumbNail = `${idUtente}/logo/thumbNail.png`
    const pathOriginale = `${idUtente}/logo/originale.png`

    await Promise.all([caricaInStorage(thumbnail, pathThumbNail), caricaInStorage(immagine, pathOriginale)])
    await aggiornaDb()
    MODIFICA_STATO_SPINNER(false)
    return
}
