import { store } from '../store'

export const APRI_POP_UP_MODIFICHE_NON_SALVATE = (link, isAzione ) => {
    return new Promise((resolve, reject) => {
        
        store.dispatch({
            type: 'APRI_POP_UP_MODIFICHE_NON_SALVATE',
            payload: {link : link, isAzione : isAzione || false},
        })
        resolve()
    })
}
