import { listaProvince } from 'util'
import { categorieAttivita } from 'util'
import { formeGiuridicheEnte } from 'util'

const subInputSedeLegale = (testoBianco) => {
    return {
        parts: [
            {
                id: 'comune',
                tipo: 'fieldText',
                associazione: 'comune',
                label: 'Comune:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'provincia',
                tipo: 'fieldSelect',
                associazione: 'provincia',
                label: 'Provincia:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    complex: true,
                    scelte: listaProvince,
                    toShow: 'nome',
                    toSave: 'nome',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'via',
                tipo: 'fieldText',
                associazione: 'via',
                label: 'Indirizzo :',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'numero',
                tipo: 'fieldText',
                associazione: 'numero',
                label: 'N:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
        ],
    }
}

export const formInfoBaseEnte = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome ente:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'formaGiuridica',
            tipo: 'fieldSelect',
            associazione: 'formaGiuridica',
            label: 'Forma giuridica:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: formeGiuridicheEnte,
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'partitaIvaCf',
            tipo: 'fieldText',
            associazione: 'partitaIvaCf',
            label: 'Partita IVA o Codice Fiscale:',
            value: '',
            details: {
                type: 'text',
                inputProps: { maxLength: 11 },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'email',
            tipo: 'fieldText',
            associazione: 'email',
            label: 'Email contatto:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'categoriaAttivita',
            tipo: 'fieldSelect',
            associazione: 'categoriaAttivita',
            label: 'Categoria di attività:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: categorieAttivita,
            },
            validazione: 'testoRichiesto',
        },

        {
            id: 'sedeLegale',
            tipo: 'fieldSubInput',
            associazione: 'sedeLegale',
            label: 'Sede legale:',
            value: {},
            details: {
                grid: { lg: { input: 4, output: 4 } },
                subModel: subInputSedeLegale(false),
            },
        },
        {
            id: 'brevedescrizione',
            tipo: 'fieldTextArea',
            associazione: 'brevedescrizione',
            label: 'La tua Non Profit in un tweet:',
            value: '',
            details: {
                inputProps: { maxLength: 240 },
                type: 'text',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'accettazioneTerminiECondizioni',
            tipo: 'fieldAccettaTerminiECondizioni',
            associazione: 'accettazioneTerminiECondizioni',
            label: 'Accetto termini e condizioni:',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
                testoLink: 'scopri di piu',
                //link: 'terminiECondizioni.html',
            },
            validazione: 'controllaSeVero',
        }
    ],
}
export const formInfoAggiuntiveEnte = {
    parts: [
        /* {
            id: 'iscrizioneAlbiElenchi',
            tipo: 'fieldSelect',
            associazione: 'iscrizioneAlbiElenchi',
            label: 'Iscrizioni ad albi ed elenchi:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: formeGiuridicheEnte,
            },
            validazione: 'testoRichiesto',
        }, */
        {
            id: 'descrizione',
            tipo: 'fieldTextArea',
            associazione: 'descrizione',
            label: 'Descrivi la tua non profit ai tuoi clienti e stakeholder:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'mission',
            tipo: 'fieldTextArea',
            associazione: 'mission',
            label: 'Mission:',
            value: '',
            details: {
                placeHolder: 'Es. La nostra missione è trasformare le donazioni in un potente strumento per il cambiamento, promuovendo la responsabilità sociale delle imprese e creando sinergie con il Terzo Settore.',
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        /* {
            id: 'vision',
            tipo: 'fieldTextArea',
            associazione: 'vision',
            label: 'Vision:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        }, */
        {
            id: 'legaleRappresentante',
            tipo: 'fieldText',
            associazione: 'legaleRappresentante',
            label: 'Legale rappresentante:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
    ],
}
export const formDimensioneEnte = {
    parts: [
        {
            id: 'personaleAttivo',
            tipo: 'fieldText',
            associazione: 'personaleAttivo',
            label: 'N° personale attivo in Italia:',
            value: '',
            details: {
                type: 'number',
            },
        },
        {
            id: 'associati',
            tipo: 'fieldText',
            associazione: 'associati',
            label: 'N° associati :',
            value: '',
            details: {
                type: 'number',
            },
        },
        {
            id: 'volontari',
            tipo: 'fieldText',
            associazione: 'volontari',
            label: 'N° volontari:',
            value: '',
            details: {
                type: 'number',
            },
        },
        {
            id: 'firmatari5x1000',
            tipo: 'fieldText',
            associazione: 'firmatari5x1000',
            label: 'N° firmatari 5x1000:',
            value: '',
            details: {
                type: 'number',
            },
        },
    ],
}
export const formContattiEnte = (testoBianco) => {
    return {
        parts: [
            {
                id: 'email',
                tipo: 'fieldText',
                associazione: 'email',
                label: 'email:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    allineaCentro: true,
                    textColor: testoBianco ? 'white' : undefined,
                    inputProps: { maxLength: 11 },
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'sedeLegale',
                tipo: 'fieldSubInput',
                associazione: 'sedeLegale',
                label: 'Sede legale:',
                value: {},
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
                    subModel: subInputSedeLegale(true),
                    allineaCentro: true,
                },
            },
           
             {
                id: 'partitaIvaCf',
                tipo: 'fieldText',
                associazione: 'partitaIvaCf',
                label: 'Partita IVA o Codice Fiscale:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    allineaCentro: true,
                    textColor: testoBianco ? 'white' : undefined,
                    inputProps: { maxLength: 11 },
                },
                validazione: 'testoRichiesto',
            },
        ],
    }
}
export const formLinkContattiEnte = (testoBianco) => {
    return {
        parts: [
            {
                id: 'sitoWeb',
                tipo: 'fieldText',
                associazione: 'sitoWeb',
                label: 'Url sito web:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    isLink: true,
                    allineaCentro: true,
                },
            },
            {
                id: 'telefono',
                tipo: 'fieldText',
                associazione: 'telefono',
                label: 'Telefono :',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'linkedin',
                tipo: 'fieldText',
                associazione: 'linkedin',
                label: 'linkedin:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'facebook',
                tipo: 'fieldText',
                associazione: 'facebook',
                label: 'Facebook:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'instagram',
                tipo: 'fieldText',
                associazione: 'instagram',
                label: 'Instagram:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'youtube',
                tipo: 'fieldText',
                associazione: 'youtube',
                label: 'Youtube:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'twitter',
                tipo: 'fieldText',
                associazione: 'twitter',
                label: 'Twitter:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
        ],
    }
}
export const formEnteNonProfit = {
    parts: [],
}
export const formAttivitaSostenitori = {
    parts: [
        {
            id: 'totaleRaccolteFondiAttive',
            tipo: 'fieldText',
            associazione: 'totaleRaccolteFondiAttive',
            label: 'Raccolte fondi attive:',
            value: '',
            details: {
                type: 'number',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'totaleRaccolteFondiCompletate',
            tipo: 'fieldText',
            associazione: 'totaleRaccolteFondiCompletate',
            label: 'Raccolte fondi completate:',
            value: '',
            details: {
                type: 'number',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'totaleImpreseSostenitrici',
            tipo: 'fieldText',
            associazione: 'totaleImpreseSostenitrici',
            label: 'Imprese Sostenitrici:',
            value: '',
            details: {
                type: 'number',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'totaleFondiRaccolti',
            tipo: 'fieldText',
            associazione: 'totaleFondiRaccolti',
            label: 'Fondi Raccolti:',
            value: '',
            details: {
                type: 'number',
            },
            validazione: 'testoRichiesto',
        },
    ],
}

export const formRiassuntoEnte = {
    parts: [
        {
            id: 'categoriaAttivita',
            tipo: 'fieldSelect',
            associazione: 'categoriaAttivita',
            label: 'Categoria di attività:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: categorieAttivita,
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'descrizione',
            tipo: 'fieldTextArea',
            associazione: 'descrizione',
            label: 'Descrivi la tua non profit ai tuoi clienti e stakeholder:',
            value: '',
            details: {
                type: 'text',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'mission',
            tipo: 'fieldTextArea',
            associazione: 'mission',
            label: 'Mission:',
            value: '',
            details: {
                type: 'text',
                placeHolder: 'Es. La nostra missione è trasformare le donazioni in un potente strumento per il cambiamento, promuovendo la responsabilità sociale delle imprese e creando sinergie con il Terzo Settore.',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            //validazione: 'testoRichiesto',
        },
        {
            id: 'personaleAttivo',
            tipo: 'fieldText',
            associazione: 'personaleAttivo',
            label: 'N° personale attivo in Italia:',
            value: '',
            details: {
                type: 'number',
            },
        },
        {
            id: 'associati',
            tipo: 'fieldText',
            associazione: 'associati',
            label: 'N° associati :',
            value: '',
            details: {
                type: 'number',
            },
        },
        {
            id: 'volontari',
            tipo: 'fieldText',
            associazione: 'volontari',
            label: 'N° volontari:',
            value: '',
            details: {
                type: 'number',
            },
        },
        {
            id: 'firmatari5x1000',
            tipo: 'fieldText',
            associazione: 'firmatari5x1000',
            label: 'N° firmatari 5x1000:',
            value: '',
            details: {
                type: 'number',
            },
        },
    ],
}
