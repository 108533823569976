import React, { useEffect, useState } from 'react'
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import bgImage from 'assets/images/bg-etiqua-login.jpg'
import { InputText } from 'fields/fieldText'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { Card } from '@mui/material'
import ElementoNavigazionePreLogin from 'componenti/elementoNavigazionePreLogin'

export default function paginaPasswordDimenticata(props) {
    const [mail, setMail] = useState('')
    const [errore, setErrore] = useState('')
    const [successo, setSuccesso] = useState('')

    const form = {
        id: 'mail',
        tipo: 'fieldText',
        associazione: 'mail',
        label: 'Email',
        value: '',
        details: {
            id: 'mail',
            type: 'email',
        },
    }

    const inviaMail = () => {
        setErrore('')
        setSuccesso('')
        var auth = getAuth()
        auth.languageCode = 'it'
        if (mail === '' || mail === null || mail === undefined) {
            setErrore('Inserisci un email')
            return
        }
        sendPasswordResetEmail(auth, mail)
            .then(() => {
                setSuccesso('Email inviata')
            })
            .catch((error) => {
                if ((error = 400)) {
                    setErrore('Errore: Email non riconosciuta')
                }
            })
    }

    useEffect((e) => {
        setTimeout(() => {
            document.getElementById('mail').addEventListener('keypress', (e) => {
                if (e.keyCode === 13 && !e.shiftKey) {
                    document.getElementById('invia').click()
                }
            })
        }, 1000)
    }, [])

    return (
        <>
            <ElementoNavigazionePreLogin transparent={true} />
            <BasicLayout image={bgImage}>
                <Card sx={{ p: 2 }}>
                    <MDBox variant='gradient' bgColor='info' borderRadius='lg' coloredShadow='info' mx={2} mt={-3} p={2} mb={1} textAlign='center'>
                        <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
                            Password dimenticata ?
                        </MDTypography>
                        <MDTypography variant='caption' fontWeight='medium' color='white' mt={1}>
                            Inserisci la tua mail per reimpostare la password
                        </MDTypography>
                    </MDBox>

                    <MDBox component='form' role='form' sx={{ mt: 2 }}>
                        <MDBox mb={2}>
                            <InputText model={form} value={mail} errore='' azione={(a, b) => setMail(b)} />
                        </MDBox>

                        <MDTypography variant='caption' color='error' fontWeight='bold'>
                            {errore}
                        </MDTypography>
                        <MDTypography variant='caption' color='success' fontWeight='bold'>
                            {successo}
                        </MDTypography>
                        <MDBox display='flex' alignItems='center' ml={-1}></MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton id='invia' color='dark' size='large' fullWidth onClick={() => inviaMail()}>
                                invia mail
                            </MDButton>
                            <MDButton color='error' size='large' fullWidth variant='text' onClick={() => props.navigate('/login')}>
                                annulla
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </Card>
            </BasicLayout>
        </>
    )
}
