import React from 'react'
import TextField from '@mui/material/TextField'
import { OutputText } from './fieldText'

const test = `*Quando il progetto sarà accessibile? Speciva se possibile:
- date (es. 5-8 dicembre 2023) o arco temporale (es. per tutto il mese di Luglio)
- giorni in cui è possibile accedervi (es. solo il sabato, martedì e giovedì ecc…)
- fasce orarie (solo mattino, solo a cena, dalle 14:00 alle 18:00 ecc…)`

export function InputTextArea(props) {
    let inputProps = props.model.details?.inputProps || { sx: {} }
    if (props.model.details?.maiuscolo) {
        inputProps.sx = { textTransform: 'uppercase !important' }
    }
    if (props.model.details?.testoBianco) {
        inputProps.sx = { color: 'white !important' }
    }
    return (
        <TextField
            fullWidth
            inputProps={inputProps}
            color={props.model.details?.color || 'primary'}
            variant={props.model.details?.variant || 'outlined'}
            id={props.model.id}
            value={props.value}
            error={props.errore !== ''}
            placeholder={props.model.details?.placeHolder || ''}
            type={props.model.details?.type}
            classes={props.model.details?.classi}
            helperText={props.errore}
            onChange={(e, valore) => {
                props.azione(props.model.id, e.target.value)
            }}
            label={props.model.label}
            multiline
        />
    )
}

export function OutputTextArea(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    return <OutputText skeleton={props.skeleton || false} model={model} value={props.value} />
}
