import { doc, writeBatch } from 'firebase/firestore'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { newId } from 'data/strumenti'

export const NUOVO_NETWORK = async (dati) => {
    console.log('entroooo');
    MODIFICA_STATO_SPINNER(true)
    const idUtente = getIdUtenteLoggato()
    const idNetwork = newId()

    dati.id = idNetwork
    dati.capoFila = idUtente
    dati.membri = [idUtente]
    dati.dataCreazione = new Date()

    var batch = writeBatch(db)

    const percorsoNetwork = doc(db, 'cacheNetwork', idNetwork)
    const percorsoUtente = doc(db, 'utenti', idUtente)

    batch.set(percorsoNetwork, dati)
    batch.update(percorsoUtente, { network: idNetwork })
    try {
        
        await batch.commit()
    } catch (error) {
        console.log('error : ', error);
    }
    MODIFICA_STATO_SPINNER(false)
    return
}