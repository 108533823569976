import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Grid } from '@mui/material'
import { getListaRichiesteContatto } from 'getter/getterRichiesteContatto'
import ElementoMostraRichiestaContatto from 'componenti/elementoMostraRichiestaContatto'

export const paginaRichiesteContatto = (props) => {
    const [selezionato, setSelezionato] = useState('non letti')
    const [lista, setLista] = useState([])

    const aggiornaLista = () => {
        if (selezionato === 'non letti') setLista(props.nonLetti)
        if (selezionato !== 'non letti') setLista(props.letti)
    }

    useEffect(() => {
        aggiornaLista()
    }, [selezionato, props.letti, props.nonLetti])

    const renderTab = (testo) => {
        const styleDefault = {
            py: 1,
            color: 'info',
            bgColor: 'white',
            borderRadius: 'md',
            className: 'centrato',
            sx: { border: 'solid 0.2px', cursor: 'pointer' },
        }
        const styleSelezionato = {
            py: 1,
            color: 'white',
            bgColor: 'info',
            borderRadius: 'md',
            className: 'centrato',
            sx: { cursor: 'pointer' },
        }
        const stile = selezionato === testo ? styleSelezionato : styleDefault
        return (
            <MDBox {...stile} onClick={() => setSelezionato(testo)}>
                <MDTypography color={selezionato === testo ? 'white' : 'info'} fontWeight='medium'>
                    {testo}
                </MDTypography>
            </MDBox>
        )
    }

    return (
        <>
            <MDBox shadow='lg' p={2} bgColor='white' mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        {renderTab('non letti')}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {renderTab('letti')}
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container spacing={2}>
                {lista.map((elemento) => {
                    return (
                        <Grid key={elemento.id} item xs={12} lg={6} xl={6} xxl={4}>
                            <ElementoMostraRichiestaContatto dati={elemento} />
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

function mapStateToProps(state) {
    const dati = getListaRichiesteContatto()
    return {
        letti: dati.letti,
        nonLetti: dati.nonLetti,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaRichiesteContatto)
