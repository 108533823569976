import { store } from 'data/store'
import { getIdUtenteLoggato, getProfiloUtenteLoggato, getUtenteLoggato } from './getterUtenteLoggato'
import { getImpattoProgettoDaId, getImpreseSostenitriciProgetto, getProgettoDaId, getTotaleRaccoltoProgetto, isProgettoAttivo, isProgettoInAttesa } from './getterProgetti'
import { profiloEnte } from 'util'

export const getDatiEnte = () => {
    const state = store.getState()
    if (state.url[3] === 'paginaMioProfilo') return getUtenteLoggato()
    return state.reducerEnte
}

export const getIdEnte = () => {
    const state = store.getState()
    if (state.url[3] === 'paginaMioProfilo') return getIdUtenteLoggato()
    return state.url[4]
}

export const getEnteDaId = (id) => {
    const index = window.listaEnti.findIndex((ente) => ente.id === id)
    if (index === -1) return {}
    return window.listaEnti[index]
}

export const getListaProgettiEnte = (id) => {
    return window.listaProgetti.filter((raccolta) => {
        return raccolta.idEnte === id
    })
}

export const getListaProgettiAttiviEnte = (id) => {
    const progetti = getListaProgettiEnte(id)
    return progetti.filter((progetto) => {
        return isProgettoAttivo(progetto.id)
    })
}

export const getListaProgettiCompletatiEnte = (id) => {
    const progetti = getListaProgettiEnte(id)
    return progetti.filter((progetto) => {
        const raccolto = getTotaleRaccoltoProgetto(progetto.id)
        return raccolto >= progetto.traguardi.totale
    })
}
export const getListaProgettiInAttesaEnte = (id) => {
    const progetti = getListaProgettiEnte(id)
    return progetti.filter((progetto) => {
        return isProgettoInAttesa(progetto.id)
    })
}
export const getListaProgettiRifiutatiEnte = (id) => {
    const progetti = getListaProgettiEnte(id)
    return progetti.filter((progetto) => {
        const rifiutato = progetto.stato === 'rifiutato'
        return rifiutato
    })
}

export const getListaImpreseDonazioniLiberaliEnte = (id) => {
    const cacheEnte = getEnteDaId(id)
    if (cacheEnte.progetti === undefined) return []
    if (cacheEnte.progetti.donazioniLiberali === undefined) return []
    return Object.keys(cacheEnte.donazioniLiberali).map((idImpresa) => {
        return { idImpresa: idImpresa, totale: cacheEnte.donazioniLiberali[idImpresa] }
    })
}
export const getListaImpreseDonazioniPercentualiEnte = (id) => {
    const cacheEnte = getEnteDaId(id)
    if (cacheEnte.sostegni === undefined) return []
    Object.keys(cacheEnte.sostegni).forEach((idImpresa) => {
        const sostegnoImpresa = cacheEnte.sostegni[idImpresa]
        if (sostegnoImpresa) {
        }
    })
}

export const getListaImpreseSostenitriciEnte = (id) => {
    const cacheEnte = getEnteDaId(id)
    var imprese = []
    var listaIdImprese = []
    if (cacheEnte.progetti === undefined) return []
    Object.keys(cacheEnte.progetti).forEach((idProgetto) => {
        const sostenitriciProgetto = getImpreseSostenitriciProgetto(idProgetto)
        sostenitriciProgetto.forEach((impresa) => {
            //console.log('impresa : ', impresa);

            if (listaIdImprese.includes(impresa.idImpresa)) {
                const index = listaIdImprese.indexOf(impresa.idImpresa)
                imprese[index].totale += impresa.totale
            }
            if (!listaIdImprese.includes(impresa.idImpresa)) {
                imprese.push({ idImpresa: impresa.idImpresa, totale: impresa.totale })
                listaIdImprese.push(impresa.idImpresa)
            }
        })
    })
    return imprese
}

export const getTotaleRicavatoEnte = (idEnte) => {
    return getListaProgettiEnte(idEnte).reduce((acc, progetto) => {
        acc += getTotaleRaccoltoProgetto(progetto.id)
        return acc
    }, 0)
}

export const getInfoEnte = () => {
    const idEnte = getIdEnte()
    return {
        progettiAttivi: getListaProgettiAttiviEnte(idEnte),
        progettiCompletati: getListaProgettiCompletatiEnte(idEnte),
        impreseSostenitrici: getListaImpreseSostenitriciEnte(idEnte),
        totaleRaccolto: getTotaleRicavatoEnte(idEnte),
    }
}

export const getListaEntiFiltrata = (filtri) => {
    const { categoria, luogo, ordinaPer } = filtri

    if (categoria === '' && luogo === '' && ordinaPer === '') return window.listaEnti

    var listaFiltrata = window.listaEnti.filter((ente) => {
        const comparazioneCategoria = () => {
            if (categoria === '') return true
            return categoria === ente.categoriaAttivita
        }
        const comparazioneLuogo = () => {
            if (luogo === '') return true
            return ente.sedeLegale.comune === luogo
        }

        return comparazioneCategoria() && comparazioneLuogo()
    })
    return listaFiltrata.sort((a, b) => {
        const alfabetico = () => {
            var valore = 0
            const nomeA = (a.nome.trim()).toUpperCase()
            const nomeB = (b.nome.trim()).toUpperCase()
            if (nomeA.toUpperCase() > nomeB.toUpperCase()) valore = 1
            if (nomeA.toUpperCase() < nomeB.toUpperCase()) valore = -1
            return valore
        }
        const alfabeticoInverso = () => {
            var valore = 0
            const nomeA = (a.nome.trim()).toUpperCase()
            const nomeB = (b.nome.trim()).toUpperCase()
            if (nomeA.toUpperCase() > nomeB.toUpperCase()) valore = -1
            if (nomeA.toUpperCase() < nomeB.toUpperCase()) valore = 1
            return valore
        }
        const datiA = getDatiCardEnte(a.id)
        const datiB = getDatiCardEnte(b.id)
        const totaleA = datiA.progetto === '' ? 0 : datiA.progetto.totale
        const totaleB = datiB.progetto === '' ? 0 : datiB.progetto.totale
        if (ordinaPer === 0) return alfabetico()
        if (ordinaPer === 1) return alfabeticoInverso()
        if (ordinaPer === 2) return datiB.imprese - datiA.imprese
        if (ordinaPer === 3) return totaleB - totaleA
    })
}

export const getImmaginiEnte = () => {
    const ente = getDatiEnte()
    const logo = ente.logo ?? ''
    if (logo === '' && ente.immagini === undefined) return []
    if (logo !== '' && ente.immagini === undefined) return [{ isLogo: true, id: logo }]
    var immagini = Object.keys(ente.immagini).map((id) => {
        return { isLogo: false, id: id }
    })
    if (logo !== '') immagini.unshift({ isLogo: true, id: logo })
    return immagini
}

export const isMioProgetto = (id) => {
    if (getProfiloUtenteLoggato() !== profiloEnte.tipo) return false
    const cacheProgetto = getProgettoDaId(id)
    return cacheProgetto.idEnte === getIdUtenteLoggato()
}

export const getInfoProgettiEnte = () => {
    const idEnte = getIdUtenteLoggato()
    return {
        progettiAttivi: getListaProgettiAttiviEnte(idEnte),
        progettiCompletati: getListaProgettiCompletatiEnte(idEnte),
        progettiInAttesa: getListaProgettiInAttesaEnte(idEnte),
        progettiRifiutati: getListaProgettiRifiutatiEnte(idEnte),
    }
}

export const getListaImpattiEnte = () => {
    const idEnte = getIdEnte()
    const progetti = getListaProgettiAttiviEnte(idEnte)
    var impatti = []
    progetti.forEach((progetto) => {
        var impattiProgetto = [...getImpattoProgettoDaId(progetto.id)]
        impatti = impatti.concat(impattiProgetto)
    })
    const progettiCompletati = getListaProgettiCompletatiEnte(idEnte)
    progettiCompletati.forEach((progetto) => {
        var impattiProgetto = [...getImpattoProgettoDaId(progetto.id)]
        impatti = impatti.concat(impattiProgetto)
    })
    return impatti
}

export const getDatiCardEnte = (id) => {
    const imprese = getListaImpreseSostenitriciEnte(id)
    const progetti = getListaProgettiEnte(id)
    return {
        imprese: imprese.length || 0,
        progetto: progetti.length === 0 ? '' : { nome: getProgettoDaId(progetti[0].id).nome, totale: getTotaleRaccoltoProgetto(progetti[0].id) },
    }
}

export const getListaMailEnti = () => {
    return window.listaEnti.map((ente) => {
        return { nome: ente.nome, mail: ente.email, id: ente.id }
    })
}
