import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import MDTypography from 'components/MDTypography'
import { Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import MDBox from 'components/MDBox'

export function InputText(props) {
    const [vediPassword, setVediPassword] = useState(false)

    let standardInput = () => {
        let inputProps = props.model.details?.inputProps || { sx: {} }
        if (props.model.details?.maiuscolo) {
            inputProps.sx = { textTransform: 'uppercase !important' }
        }
        if (props.model.details?.testoBianco) {
            inputProps.sx = { color: 'white !important' }
        }
        //inputProps.sx.height = "28.13px !important"
        return (
            <TextField
                id={props.model.details?.id || ''}
                fullWidth
                inputProps={inputProps}
                color={props.model.details?.color || 'primary'}
                variant={props.model.details?.variant || 'outlined'}
                value={props.value}
                error={props.errore !== ''}
                type={props.model.details?.type}
                classes={props.model.details?.classi}
                helperText={props.errore}
                placeholder={props.model.details?.placeHolder || ''}
                sx={props.model.details?.sx}
                InputLabelProps={props.model.details?.InputLabelProps}
                onChange={(e, valore) => {
                    let daTornare = e.target.value
                    if (props.model.details?.type === 'number') {
                        var nuovo = e.target.value
                        daTornare = parseFloat(nuovo)
                    }
                    if (props.model.details?.maiuscolo) {
                        daTornare = e.target.value.toUpperCase()
                    }

                    props.azione(props.model.id, daTornare)
                }}
                label={props.model.label}
                /* style={{ width: 300 }} */
            />
        )
    }

    let inputPassword = () => {
        return (
            <FormControl variant='outlined' error={props.errore !== ''} fullWidth>
                <InputLabel htmlFor={props.model.id}>{props.model.label}</InputLabel>
                <OutlinedInput
                    id={props.model.details?.id || ''}
                    fullWidth
                    color={props.model.details?.color || 'primary'}
                    type={vediPassword ? 'text' : 'password'}
                    value={props.value}
                    error={props.errore !== ''}
                    classes={props.model.details?.classi}
                    onChange={(e, valore) => {
                        props.azione(props.model.id, e.target.value)
                    }}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton aria-label='toggle password visibility' onClick={() => setVediPassword(!vediPassword)} edge='end'>
                                {vediPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label={props.model.label}
                />
                <FormHelperText>{props.errore}</FormHelperText>
            </FormControl>
        )
    }

    return props.model.details?.type === 'password' ? inputPassword() : standardInput()
}

export function OutputText(props) {
    const [vediPassword, setVediPassword] = useState(false)
    const [vediTutto, setVediTutto] = useState(false)

    const handleVediTutto = () => setVediTutto(!vediTutto)

    const vediLink = () => {
        var link = props.value
        const primeCinque = props.value.slice(0, 5)
        if (primeCinque !== 'https') link = `https://${props.value}`
        if (props.model.details?.isLink) window.open(link)
    }

    const cosaVedere = () => {
        if (props.model.details?.isLink) return props.value === '' ? '' : 'Vai alla pagina'
        const testo = props.value.toString() || ''
        const strshortened = testo.length <= 140 ? testo : `${testo.slice(0, 200)}...`

        const valore = (
            <>
                {vediTutto ? props.value : strshortened}{' '}
                {testo.length <= 140 ? null : (
                    <MDTypography sx={{ cursor: 'pointer' }} variant='string' color='info' onClick={handleVediTutto}>
                        {!vediTutto ? "Vedi di piu'" : 'Nascondi'}
                    </MDTypography>
                )}
            </>
        )

        if (props.skeleton) {
            return <Skeleton animation='wave' height='32.5px' />
        }
        if (props.model.details?.type !== 'password') {
            return valore
        }
        if (vediPassword) {
            return props.value
        }
        return '******'
    }

    const renderOcchio = () => {
        if (props.model.details?.type !== 'password') {
            return ''
        }
        if (vediPassword) {
            return <VisibilityOff sx={{ cursor: 'pointer' }} onClick={() => setVediPassword(false)} />
        }
        if (!vediPassword) {
            return <Visibility sx={{ cursor: 'pointer' }} onClick={() => setVediPassword(true)} />
        }
    }

    const colorLabel = props.model?.details?.textColor !== undefined ? props.model?.details?.textColor : props.model?.details?.colorLabel || 'text'
    const colorValue = props.model?.details?.textColor !== undefined ? props.model?.details?.textColor : 'dark'

    let siLabel = (
        <Grid container direction='column'>
            <MDTypography variant='subtitle1' fontWeight={props.model?.details?.weightLabel || 'bold'} color={colorLabel} /* align={props.model.details.allineaCentro ? 'center' : 'start'} */>
                {props.model.label}
            </MDTypography>
            <Grid item container direction='row' justifyContent={'space-between'} width='100%'>
                <Grid item width='100%' xs={true} lg={true}>
                    {/* <MDBox width='100%' className={props.model.details.allineaCentro ? 'centrato' : ''}> */}
                    <MDTypography onClick={vediLink} sx={{ mt: cosaVedere() === '' ? 0 : 2, cursor: props.model.details?.isLink ? 'pointer' : '' }} width='100%' variant='string' color={colorValue} lineHeight={'0.2 !important'} align={props.model?.details?.allineaCentro ? 'center' : 'start'}>
                        {cosaVedere()}
                    </MDTypography>
                    {/* </MDBox> */}
                </Grid>
                <Grid item xs='auto' lg='auto'>
                    {renderOcchio()}
                </Grid>
            </Grid>
        </Grid>
    )

    let noLabel = (
        <MDTypography variant='caption' fontWeight='medium' color={colorValue} lineHeight={'0.2 !important'}>
            {cosaVedere()}
        </MDTypography>
    )

    return props.model.details?.noLabel ? noLabel : siLabel
}
