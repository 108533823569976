import React from 'react'
import { connect } from 'react-redux'
import MDBox from 'components/MDBox'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { List } from '@mui/material'
import ElementoMostraInvitoNetwork from 'componenti/elementoMostraInvitoNetwork'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import { getIdNetworkImpresa } from 'getter/getterImpresa'
import { getListaInvitiNetwork } from 'getter/getterImpresa'
import ElementoVediNetwork from 'componenti/elementoVediNetwork'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'

export const paginaIlMioNetwork = (props) => {
    const creaNetwork = () => props.navigate('/paginaCreaNetwork')

    const renderInviti = () => {
        if (props.inviti.length === 0) return <MDTypography color='text'>Nessun invito ricevuto</MDTypography>
        return (
            <List>
                {props.inviti.map((invito, index) => {
                    return <ElementoMostraInvitoNetwork navigate={props.navigate} key={invito.id} id={invito.id} nome={invito.nome} divider={index !== props.inviti.length - 1} />
                })}
            </List>
        )
    }

    const renderNoNetwork = (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
                <MDBox bgColor='white' shadow='lg' p={2} className='centrato flex-verticale' sx={{ width: '100%', height: '100%' }}>
                    <MDTypography sx={{ mb: 3 }}>Non fai parte di nessun network</MDTypography>
                    <MDButton variant='contained' color='dark' size='small' onClick={creaNetwork}>
                        crea il tuo network
                    </MDButton>
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
                <MDBox bgColor='white' shadow='lg' p={2} className='flex-verticale' sx={{ width: '100%', height: '100%' }}>
                    <ElementoEtichettaForm testo='Inviti ricevuti' />
                    {renderInviti()}
                </MDBox>
            </Grid>
        </Grid>
    )

    const renderNetwork = <ElementoVediNetwork id={props.idNetwork} idUtente={props.idUtente} navigate={props.navigate} />
    return props.idNetwork === '' ? renderNoNetwork : renderNetwork
}

const mapStateToProps = (state) => ({
    idNetwork: getIdNetworkImpresa(),
    inviti: getListaInvitiNetwork(),
    idUtente: getIdUtenteLoggato(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaIlMioNetwork)
