import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

import RotatingCard from 'examples/Cards/RotatingCard'
import RotatingCardFront from 'examples/Cards/RotatingCard/RotatingCardFront'
import RotatingCardBack from 'examples/Cards/RotatingCard/RotatingCardBack'

import { Divider } from '@mui/material'
import logo from 'assets/immaginiEtiqua/logoEtiqua2.png'
import { getDatiCardEnte } from 'getter/getterEnte'
import { getUrlDaStorage } from 'getter/getterimmagini'

export default function elementoMostraEnte(props) {
    const [dati, setDati] = useState({ progetto: '' })
    const [url, setUrl] = useState('')
    const vediEnte = () => {
        props.navigate(`paginaVediEnte/${props.ente.id}`)
    }

    const renderRiga = (label, value) => {
        return (
            <MDBox mb={1} lineHeight={0}>
                <MDTypography variant='caption' color='white'>
                    {label} :
                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize' color='white'>
                        {value}
                    </MDTypography>
                </MDTypography>
            </MDBox>
        )
    }

    useEffect(async () => {
        const nuovidati = getDatiCardEnte(props.ente.id)
        setDati(nuovidati)
        if (props.ente.logo === undefined || props.ente.logo === '') setUrl('')
        if (props.ente.logo !== undefined && props.ente.logo !== '') setUrl(await getUrlDaStorage(`${props.ente.id}/logo/thumbNail.png`))
    }, [props.ente])

    const getIndirizzo = `${props.ente.sedeLegale?.via || ''} ${props.ente.sedeLegale?.numero || ''}, ${props.ente.sedeLegale?.comune || ''} (${props.ente.sedeLegale?.provincia || ''})`

    const descrizioneEnte = (
        <MDBox display='flex' sx={{ flexDirection: 'column', width: '100%', boxSizing: 'border-box' }}>
            {renderRiga('Raccolte attive', props.ente.totaleRaccolteFondiAttive)}
            {renderRiga('Imprese sostenitrici', props.ente.totaleImpreseSostenitrici)}
        </MDBox>
    )

    return (
        <MDBox bgColor='white' p={2} onClick={vediEnte} sx={{ cursor: 'pointer', width: '100%', height: '100%', /* border: '0.2px solid', */ color: '#d2d6da' }} shadow='lg' /* bgColor='info' */>
            <MDBox sx={{ display: 'grid', height: '100%', width: '100%', gridTemplateColumns: '100%', gridTemplateRows: '1fr max-content' }}>
                <MDBox className='flex-verticale'>
                {url === '' ? <MDBox height='40px' width='max-content' mb={1} /> : <MDBox component='img' height='40px' width='max-content' src={url} mb={1} />}
                    <MDTypography color='dark' variant='h4'>
                        {props.ente.nome}
                    </MDTypography>
                    <MDTypography variant='subtitle2' color='text'>
                        {getIndirizzo}
                    </MDTypography>
                </MDBox>
                <MDBox className='flex-verticale'>
                    <Divider />
                    <MDTypography color='dark'>{dati.progetto === '' ? 'Nessun progetto attivo' : `${dati.progetto.nome} : ${dati.progetto.totale} € raccolti`}</MDTypography>
                    <MDTypography color='dark'>Imprese sostenitrici : {dati.imprese}</MDTypography>
                </MDBox>
            </MDBox>
        </MDBox>
    )

    return (
        <RotatingCard>
            <RotatingCardFront image='https://bit.ly/3G5JXJZ' icon='touch_app' title={props.ente.nome} description={props.ente.descrizione} />
            <RotatingCardBack image='https://bit.ly/32ZoTGx' title='Dati ente' description={descrizioneEnte} action={{ type: 'internal', route: vediEnte, label: 'Vedi ente' }} />
        </RotatingCard>
    )

    return (
        <MDBox onClick={vediEnte} p={2} bgColor='white' shadow='md' color='info' borderRadius='lg' sx={{ cursor: 'pointer', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.ente.nome}
        </MDBox>
    )
}
