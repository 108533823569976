import React, { useEffect, useState } from 'react'
import colors from 'assets/theme/base/colors'
import FormOutput from 'formMaker/formOutput'
import MDBox from 'components/MDBox'
import { InputBoolean, OutputBoolean } from './fieldBoolean'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Divider } from '@mui/material'
import { InputText, OutputText } from './fieldText'
import PopUpFieldList from 'popUp/popUpFieldList'
import MDButton from 'components/MDButton'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import MDTypography from 'components/MDTypography'

const modelBoolean = {
    id: 'dividiBudget',
    tipo: 'fieldBoolean',
    associazione: 'dividiBudget',
    label: 'suddividi la raccolta fondi in piu traguardi',
    value: false,
}
const modelBudget = {
    id: 'totale',
    tipo: 'fieldText',
    associazione: 'totale',
    label: 'Budget totale:',
    value: '',
    details: { type: 'number' },
}

const formTraguardo = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome traguardo:',
            value: '',
            details: { type: 'text' },
        },
        {
            id: 'totale',
            tipo: 'fieldText',
            associazione: 'totale',
            label: 'Budget traguardo:',
            value: '',
            details: { type: 'number' },
        },
        {
            id: 'descrizione',
            tipo: 'fieldTextArea',
            associazione: 'descrizione',
            label: 'Descrizione budget:',
            value: '',
            details: { grid: { lg: { input: 4, output: 4 } }, type: 'number' },
        },
    ],
}

const erroreBudget = 'Il budget totale non corrisponde alla somma di budget dei traguardi'

export function InputTraguardiProgetto(props) {
    const [lista, setLista] = useState([])
    const [indexSelezionato, setIndexSelezionato] = useState(-1)
    const [popUpAperto, setPopUpAperto] = useState(false)

    const handlePopUpAperto = (scelta) => setPopUpAperto(scelta)

    const apriPopUp = (index) => {
        setIndexSelezionato(index)
        handlePopUpAperto(true)
    }
    const chiudiPopUp = () => {
        handlePopUpAperto(false)
        setIndexSelezionato(-1)
    }

    const eliminaTraguardo = (index) => {
        var nuovaLista = [...lista]
        nuovaLista.splice(index, 1)
        setLista(nuovaLista)
    }

    const eliminaElemento = (index) => APRI_POP_UP({ id: 'elimina', parametro: { testo: 'Sei sicuro di voler eliminare questo traguardo?', azione: () => eliminaTraguardo(index) } })

    const aggiornaElemento = (value) => {
        var nuovaLista = [...lista]
        if (indexSelezionato !== -1) nuovaLista[indexSelezionato] = value
        if (indexSelezionato === -1) nuovaLista.push(value)
        setLista(nuovaLista)
        chiudiPopUp()
    }

    const getColore = () => {
        return props.errore !== '' ? colors.error.main : colors.inputBorderColor
    }

    useEffect(() => {
        props.azione(props.model.id, Object.assign(props.value, { lista: lista }))
    }, [lista])

    useEffect(() => {
        setLista(props.value.lista)
    }, [])

    const handleBudget = (campo, valore) => {
        return props.azione(props.model.id, Object.assign(props.value, { totale: valore }))
    }
    const handleDividiBudget = (campo, valore) => {
        if (!valore) return props.azione(props.model.id, { totale: props.value.totale, dividiBudget: valore, lista: [] })
        return props.azione(props.model.id, { totale: props.value.totale, dividiBudget: valore, lista: [] })
    }

    const renderElementoLista = (elemento, index) => (
        <MDBox sx={{ mb: 1, border: 'solid 0.1px', color: colors.inputBorderColor }} p={2} borderRadius='lg' key={`traguardo-${index}`}>
            <FormOutput skeleton={false} form={formTraguardo} dati={elemento} />
            <MDBox className='contenitore-bottoni-destra'>
                <MDButton variant='text' color='error' onClick={() => eliminaElemento(index)}>
                    elimina
                </MDButton>
                <MDButton variant='contained' color='dark' onClick={() => apriPopUp(index)}>
                    modifica
                </MDButton>
            </MDBox>
        </MDBox>
    )
    return <InputText value={props.value.totale} model={modelBudget} errore={props.errore} azione={handleBudget} />
    return (
        <MDBox sx={{ border: 'solid 0.1px', color: getColore(), display: 'flex', flexDirection: 'column' }} p={2} borderRadius='lg'>
            <PopUpFieldList titolo='Inserisci dati' aperto={popUpAperto} form={formTraguardo} salva={aggiornaElemento} value={indexSelezionato === -1 ? {} : lista[indexSelezionato]} chiudi={chiudiPopUp} />
            <MDBox mb={1} sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <InputBoolean value={props.value.dividiBudget} model={modelBoolean} errore='' azione={handleDividiBudget} />
                {!props.value.dividiBudget ? null : (
                    <MDBox sx={{ cursor: 'pointer', height: '100%', display: 'flex', boxSizing: 'border-box' }} onClick={() => apriPopUp(-1)}>
                        <AddCircleOutlineIcon sx={{ height: '100%' }} />
                    </MDBox>
                )}
            </MDBox>
            <InputText value={props.value.totale} model={modelBudget} errore={props.errore} azione={handleBudget} />
            {!props.value.dividiBudget ? null : <Divider />}
            {!props.value.dividiBudget ? null : lista.map((elemento, index) => renderElementoLista(elemento, index))}
        </MDBox>
    )
}
export function OutputTraguardiProgetto(props) {
    const renderElementoLista = (elemento, index) => (
        <MDBox color='dark' sx={{ mb: 1, border: 'solid 0.1px' }} p={2} borderRadius='lg' key={`traguardo-${index}`}>
            <FormOutput skeleton={false} form={formTraguardo} dati={elemento} />
        </MDBox>
    )

    return (
        <MDBox sx={{ border: 'solid 0.1px', display: 'flex', flexDirection: 'column', color: colors.inputBorderColor }} p={2} borderRadius='lg'>
            <MDBox bgColor='white' mt={-4.3} px={1} sx={{ width: 'max-content' }}>
                <MDTypography variant='caption' color='text'>
                    {props.model.label}
                </MDTypography>
            </MDBox>
            <MDBox mb={1} sx={{ display: 'flex', flexDirection: 'row', boxSizing: 'border-box', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <OutputBoolean value={props.value.dividiBudget} model={modelBoolean} />
            </MDBox>
            <OutputText value={props.value.totale} model={modelBudget} />
            {!props.value.dividiBudget ? null : <Divider />}
            {!props.value.dividiBudget ? null : props.value.lista.map((elemento, index) => renderElementoLista(elemento, index))}
        </MDBox>
    )
}
