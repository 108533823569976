export const listaSdgs = [
    {
        title: 'Armut in allen ihren Formen und überall beenden',
        short: 'No Poverty',
        number: '1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_PRIMARY_ICON/GOAL_1_SVG/TheGlobalGoals_Icons_Color_Goal_1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_PRIMARY_ICON/GOAL_1_PNG/TheGlobalGoals_Icons_Color_Goal_1.png',
        color: '#E5243B',
    },
    {
        title: 'Extreme Armut beseitigen',
        short: '',
        number: '1.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_SVG/GOAL_1_TARGET_1.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_PNG/GOAL_1_TARGET_1.1.png',
        color: '#E5243B',
    },
    {
        title: 'Anteil der Menschen, die in Armut leben um mindestens 50% reduzieren.',
        short: '',
        number: '1.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_SVG/GOAL_1_TARGET_1.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_PNG/GOAL_1_TARGET_1.2.png',
        color: '#E5243B',
    },
    {
        title: 'Sozialschutzsystem implementieren',
        short: '',
        number: '1.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_SVG/GOAL_1_TARGET_1.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_PNG/GOAL_1_TARGET_1.3.png',
        color: '#E5243B',
    },
    {
        title: 'Gleiche Rechte auf Eigentum, Grundversorgung, Technologie und wirtschaftliche Ressourcen.',
        short: '',
        number: '1.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_SVG/GOAL_1_TARGET_1.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_PNG/GOAL_1_TARGET_1.4.png',
        color: '#E5243B',
    },
    {
        title: 'Stärkung der Widerstandsfähigkeit gegen Umwelt-, Wirtschafts- und Sozialkatastrophen.',
        short: '',
        number: '1.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_SVG/GOAL_1_TARGET_1.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_PNG/GOAL_1_TARGET_1.5.png',
        color: '#E5243B',
    },
    {
        title: 'Mobilisierung von Ressourcen zur Umsetzung von Maßnahmen zur Armutsbekämpfung.',
        short: '',
        number: '1.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_SVG/GOAL_1_TARGET_1.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_PNG/GOAL_1_TARGET_1.A.png',
        color: '#E5243B',
    },
    {
        title: 'Schaffung eines armutsorientierten und geschlechtersensiblen politischen Rahmens.',
        short: '',
        number: '1.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_SVG/GOAL_1_TARGET_1.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-1/GOAL_1_TARGETS/GOAL_1_TARGETS_PNG/GOAL_1_TARGET_1.B.png',
        color: '#E5243B',
    },
    {
        title: 'Den Hunger beenden, Ernährungssicherheit und eine bessere Ernährung erreichen und eine nachhaltige Landwirtschaft fördern',
        short: 'Zero Hunger',
        number: '2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_PRIMARY_ICON/GOAL_2_SVG/TheGlobalGoals_Icons_Color_Goal_2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_PRIMARY_ICON/GOAL_2_PNG/TheGlobalGoals_Icons_Color_Goal_2.png',
        color: '#DDA63A',
    },
    {
        title: 'Universeller Zugang zu sicheren und nahrhaften Lebensmitteln.',
        short: '',
        number: '2.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_SVG/GOAL_2_TARGET_2.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_PNG/GOAL_2_TARGET_2.1.png',
        color: '#DDA63A',
    },
    {
        title: 'All Formen der Unterernährung beseitigen.',
        short: '',
        number: '2.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_SVG/GOAL_2_TARGET_2.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_PNG/GOAL_2_TARGET_2.2.png',
        color: '#DDA63A',
    },
    {
        title: 'Verdoppelung der Produktivität und des Einkommens der kleinen Lebensmittelproduzenten.',
        short: '',
        number: '2.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_SVG/GOAL_2_TARGET_2.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_PNG/GOAL_2_TARGET_2.3.png',
        color: '#DDA63A',
    },
    {
        title: 'Nachhaltige Nahrungsmittelproduktion und widerstandsfähige landwirtschaftliche Praktiken.',
        short: '',
        number: '2.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_SVG/GOAL_2_TARGET_2.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_PNG/GOAL_2_TARGET_2.4.png',
        color: '#DDA63A',
    },
    {
        title: 'Erhaltung der genetischen Nahrungsvielfalt in der Nahrungsmittelproduktion.',
        short: '',
        number: '2.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_SVG/GOAL_2_TARGET_2.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_PNG/GOAL_2_TARGET_2.5.png',
        color: '#DDA63A',
    },
    {
        title: 'Investitionen in ländliche Infrastruktur, Agrarforschung, Technologie und Genbanken.',
        short: '',
        number: '2.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_SVG/GOAL_2_TARGET_2.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_PNG/GOAL_2_TARGET_2.A.png',
        color: '#DDA63A',
    },
    {
        title: 'Verhindern von Handelsbeschränkungen, Marktverzerrungen und Exportsubventionen.',
        short: '',
        number: '2.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_SVG/GOAL_2_TARGET_2.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_PNG/GOAL_2_TARGET_2.B.png',
        color: '#DDA63A',
    },
    {
        title: 'Sicherstellung stabiler Lebensmittelmärkte und zeitnahe Bereitstellung von Informationen.',
        short: '',
        number: '2.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_SVG/GOAL_2_TARGET_2.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-2/GOAL_2_TARGETS/GOAL_2_TARGETS_PNG/GOAL_2_TARGET_2.C.png',
        color: '#DDA63A',
    },
    {
        title: 'Ein gesundes Leben für alle Menschen jeden Alters gewährleisten und ihr Wohlergehen fördern',
        short: 'Good Health And Well-Being',
        number: '3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_PRIMARY_ICON/GOAL_3_SVG/TheGlobalGoals_Icons_Color_Goal_3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_PRIMARY_ICON/GOAL_3_PNG/TheGlobalGoals_Icons_Color_Goal_3.png',
        color: '#4C9F38',
    },
    {
        title: 'Reduzieren von Müttersterblichkeit.',
        short: '',
        number: '3.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.1.png',
        color: '#4C9F38',
    },
    {
        title: 'Beenden aller vermeidbaren Todesfälle unter 5 Jahren.',
        short: '',
        number: '3.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.2.png',
        color: '#4C9F38',
    },
    {
        title: 'Bekämpfung übertragbarer Krankheiten.',
        short: '',
        number: '3.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.3.png',
        color: '#4C9F38',
    },
    {
        title: 'Verringerung der Sterblichkeit durch nicht übertragbare Krankheiten und Förderung der psychischen Gesundheit.',
        short: '',
        number: '3.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.4.png',
        color: '#4C9F38',
    },
    {
        title: 'Verhindern und behandeln von Drogenmissbrauch.',
        short: '',
        number: '3.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.5.png',
        color: '#4C9F38',
    },
    {
        title: 'Reduzierung von Verkehrsunfällen und Todesfällen.',
        short: '',
        number: '3.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.6.png',
        color: '#4C9F38',
    },
    {
        title: 'Universeller Zugang zu sexueller und fortpflanzungsbezogener Betreuung, Familienplanung und Erziehung.',
        short: '',
        number: '3.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.7.png',
        color: '#4C9F38',
    },
    {
        title: 'Erreichen einer universelle Gesundheitsversorgung.',
        short: '',
        number: '3.8',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.8.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.8.png',
        color: '#4C9F38',
    },
    {
        title: 'Reduzieren von Krankheiten und Todesfällen durch gefährliche Chemikalien und Umweltverschmutzung.',
        short: '',
        number: '3.9',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.9.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.9.png',
        color: '#4C9F38',
    },
    {
        title: 'Umsetzung des WHO-Rahmenübereinkommens zur Eindämmung des Tabakkonsums.',
        short: '',
        number: '3.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.A.png',
        color: '#4C9F38',
    },
    {
        title: 'Unterstützung der Forschung, Entwicklung und des allgemeinen Zugangs zu erschwinglichen Impfstoffen und Medikamenten.',
        short: '',
        number: '3.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.B.png',
        color: '#4C9F38',
    },
    {
        title: 'Erhöhung der Gesundheitsfinanzierung und Unterstützung des Gesundheitspersonals in Entwicklungsländern.',
        short: '',
        number: '3.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_SVG/GOAL_3_TARGET_3.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-3/GOAL_3_TARGETS/GOAL_3_TARGETS_PNG/GOAL_3_TARGET_3.C.png',
        color: '#4C9F38',
    },
    {
        title: 'Inklusive, gleichberechtigte und hochwertige Bildung gewährleisten und Möglichkeiten lebenslangen Lernens für alle fördern',
        short: 'Quality education',
        number: '4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_PRIMARY_ICON/GOAL_4_SVG/TheGlobalGoals_Icons_Color_Goal_4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_PRIMARY_ICON/GOAL_4_PNG/TheGlobalGoals_Icons_Color_Goal_4.png',
        color: '#C5192D',
    },
    {
        title: 'Kostenlose Primar- und Sekundarschulbildung.',
        short: '',
        number: '4.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.1.png',
        color: '#C5192D',
    },
    {
        title: 'Gleicher Zugang zu qualitativ hochwertiger Vorschulerziehung.',
        short: '',
        number: '4.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.2.png',
        color: '#C5192D',
    },
    {
        title: 'Gleicher Zugang zu erschwinglicher technischer, beruflicher und höherer Bildung.',
        short: '',
        number: '4.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.3.png',
        color: '#C5192D',
    },
    {
        title: 'Steigerung von Anzahl der Personen mit relevanten Fähigkeiten für den finanziellen Erfolg.',
        short: '',
        number: '4.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.4.png',
        color: '#C5192D',
    },
    {
        title: 'Beseitigung jeglicher Diskriminierung in der Bildung.',
        short: '',
        number: '4.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.5.png',
        color: '#C5192D',
    },
    {
        title: 'Universelle Lese- und Rechenfertigkeiten.',
        short: '',
        number: '4.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.6.png',
        color: '#C5192D',
    },
    {
        title: 'Bildung für nachhaltige Entwicklung und globale Bürgerschaft.',
        short: '',
        number: '4.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.7.png',
        color: '#C5192D',
    },
    {
        title: 'Aufbau und Verbesserung von inklusiven und sicheren Schulen.',
        short: '',
        number: '4.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.A.png',
        color: '#C5192D',
    },
    {
        title: 'Ausbau der Hochschulstipendien für Entwicklungsländer.',
        short: '',
        number: '4.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.B.png',
        color: '#C5192D',
    },
    {
        title: 'Erhöhung des Angebots an qualifizierten Lehrkräften in Entwicklungsländern.',
        short: '',
        number: '4.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_SVG/GOAL_4_TARGET_4.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-4/GOAL_4_TARGETS/GOAL_4_TARGETS_PNG/GOAL_4_TARGET_4.C.png',
        color: '#C5192D',
    },
    {
        title: 'Geschlechtergleichstellung erreichen und alle Frauen und Mädchen zur Selbstbestimmung befähigen',
        short: 'Gender equality',
        number: '5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_PRIMARY_ICON/GOAL_5_SVG/TheGlobalGoals_Icons_Color_Goal_5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_PRIMARY_ICON/GOAL_5_PNG/TheGlobalGoals_Icons_Color_Goal_5.png',
        color: '#FF3A21',
    },
    {
        title: 'Ende der Diskriminierung von Frauen und Mädchen.',
        short: '',
        number: '5.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.1.png',
        color: '#FF3A21',
    },
    {
        title: 'Ende aller Gewalt gegen und Ausbeutung von Frauen und Mädchen.',
        short: '',
        number: '5.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.2.png',
        color: '#FF3A21',
    },
    {
        title: 'Beenden von Zwangsehen und Genitalverstümmelungen.',
        short: '',
        number: '5.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.3.png',
        color: '#FF3A21',
    },
    {
        title: 'Wertschätzung der unbezahlten Pflege und Förderung der gemeinsamen häuslichen Verantwortung.',
        short: '',
        number: '5.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.4.png',
        color: '#FF3A21',
    },
    {
        title: 'Sicherstellung der vollen Beteiligung an der Führung und Entscheidungsfindung.',
        short: '',
        number: '5.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.5.png',
        color: '#FF3A21',
    },
    {
        title: 'Universeller Zugang zu reproduktiver Gesundheit und Rechten.',
        short: '',
        number: '5.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.6.png',
        color: '#FF3A21',
    },
    {
        title: 'Gleiche Rechte auf wirtschaftliche Ressourcen, Eigentum und Finanzdienstleistungen.',
        short: '',
        number: '5.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.A.png',
        color: '#FF3A21',
    },
    {
        title: 'Förderung des Empowerment von Frauen durch Technologie.',
        short: '',
        number: '5.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.B.png',
        color: '#FF3A21',
    },
    {
        title: 'Verabschiedung einer verstärkten Politik und durchsetzbarer Rechtsvorschriften für die Gleichstellung der Geschlechter.',
        short: '',
        number: '5.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_SVG/GOAL_5_TARGET_5.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-5/GOAL_5_TARGETS/GOAL_5_TARGETS_PNG/GOAL_5_TARGET_5.C.png',
        color: '#FF3A21',
    },
    {
        title: 'Verfügbarkeit und nachhaltige Bewirtschaftung von Wasser und Sanitärversorgung für alle gewährleisten',
        short: 'Clean water and sanitation',
        number: '6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_PRIMARY_ICON/GOAL_6_SVG/TheGlobalGoals_Icons_Color_Goal_6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_PRIMARY_ICON/GOAL_6_PNG/TheGlobalGoals_Icons_Color_Goal_6.png',
        color: '#26BDE2',
    },
    {
        title: 'Sicheres und erschwingliches Trinkwasser.',
        short: '',
        number: '6.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_SVG/GOAL_6_TARGET_6.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_PNG/GOAL_6_TARGET_6.1.png',
        color: '#26BDE2',
    },
    {
        title: 'Beenden des offenen Stuhlgangs und Bereitstellung von sanitären Einrichtungen und Hygiene.',
        short: '',
        number: '6.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_SVG/GOAL_6_TARGET_6.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_PNG/GOAL_6_TARGET_6.2.png',
        color: '#26BDE2',
    },
    {
        title: 'Verbesserung der Wasserqualität, Abwasserbehandlung und sichere Wiederverwendung.',
        short: '',
        number: '6.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_SVG/GOAL_6_TARGET_6.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_PNG/GOAL_6_TARGET_6.3.png',
        color: '#26BDE2',
    },
    {
        title: 'Steigerung der Wassernutzungseffizienz und Sicherstellung der Frischwasserversorgung.',
        short: '',
        number: '6.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_SVG/GOAL_6_TARGET_6.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_PNG/GOAL_6_TARGET_6.4.png',
        color: '#26BDE2',
    },
    {
        title: 'Integriertes Wasserressourcenmanagement implementieren.',
        short: '',
        number: '6.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_SVG/GOAL_6_TARGET_6.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_PNG/GOAL_6_TARGET_6.5.png',
        color: '#26BDE2',
    },
    {
        title: 'Schutz und Wiederherstellung wasserbezogener Ökosysteme.',
        short: '',
        number: '6.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_SVG/GOAL_6_TARGET_6.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_PNG/GOAL_6_TARGET_6.6.png',
        color: '#26BDE2',
    },
    {
        title: 'Ausbau der Wasser- und Sanitärversorgung für Entwicklungsländer.',
        short: '',
        number: '6.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_SVG/GOAL_6_TARGET_6.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_PNG/GOAL_6_TARGET_6.A.png',
        color: '#26BDE2',
    },
    {
        title: 'Unterstützung des lokalen Engagements in der Wasser- und Abwasserwirtschaft.',
        short: '',
        number: '6.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_SVG/GOAL_6_TARGET_6.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-6/GOAL_6_TARGETS/GOAL_6_TARGETS_PNG/GOAL_6_TARGET_6.B.png',
        color: '#26BDE2',
    },
    {
        title: 'Zugang zu bezahlbarer, verlässlicher, nachhaltiger und moderner Energie für alle sichern',
        short: 'Affordable and clean energy',
        number: '7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_PRIMARY_ICON/GOAL_7_SVG/TheGlobalGoals_Icons_Color_Goal_7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_PRIMARY_ICON/GOAL_7_PNG/TheGlobalGoals_Icons_Color_Goal_7.png',
        color: '#FCC30B',
    },
    {
        title: 'Universeller Zugang zu moderner Energie.',
        short: '',
        number: '7.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_SVG/GOAL_7_TARGET_7.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_PNG/GOAL_7_TARGET_7.1.png',
        color: '#FCC30B',
    },
    {
        title: 'Erhöhung des weltweiten Anteils erneuerbarer Energien.',
        short: '',
        number: '7.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_SVG/GOAL_7_TARGET_7.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_PNG/GOAL_7_TARGET_7.2.png',
        color: '#FCC30B',
    },
    {
        title: 'Verdoppelung der Energieeffizienz.',
        short: '',
        number: '7.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_SVG/GOAL_7_TARGET_7.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_PNG/GOAL_7_TARGET_7.3.png',
        color: '#FCC30B',
    },
    {
        title: 'Förderung des Zugangs zu Forschung, Technologie und Investitionen in saubere Energie.',
        short: '',
        number: '7.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_SVG/GOAL_7_TARGET_7.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_PNG/GOAL_7_TARGET_7.A.png',
        color: '#FCC30B',
    },
    {
        title: 'Ausbau und Verbesserung der Energiedienstleistungen für Entwicklungsländer.',
        short: '',
        number: '7.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_SVG/GOAL_7_TARGET_7.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-7/GOAL_7_TARGETS/GOAL_7_TARGETS_PNG/GOAL_7_TARGET_7.B.png',
        color: '#FCC30B',
    },
    {
        title: 'Dauerhaftes, inklusives und nachhaltiges Wirtschaftswachstum, produktive Vollbeschäftigung und menschenwürdige Arbeit für alle fördern',
        short: 'Decent work and economic growth',
        number: '8',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_PRIMARY_ICON/GOAL_8_SVG/TheGlobalGoals_Icons_Color_Goal_8.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_PRIMARY_ICON/GOAL_8_PNG/TheGlobalGoals_Icons_Color_Goal_8.png',
        color: '#A21942',
    },
    {
        title: 'Nachhaltiges Wirtschaftswachstum.',
        short: '',
        number: '8.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.1.png',
        color: '#A21942',
    },
    {
        title: 'Diversifizierung, Innovation und Upgrade für wirtschaftliche Produktivität.',
        short: '',
        number: '8.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.2.png',
        color: '#A21942',
    },
    {
        title: 'Förderung von Maßnahmen zur Unterstützung der Schaffung von Arbeitsplätzen und wachsender Unternehmen.',
        short: '',
        number: '8.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.3.png',
        color: '#A21942',
    },
    {
        title: 'Verbesserung der Ressourceneffizienz bei Verbrauch und Produktion.',
        short: '',
        number: '8.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.4.png',
        color: '#A21942',
    },
    {
        title: 'Vollbeschäftigung und menschenwürdige Arbeit bei gleichem Lohn.',
        short: '',
        number: '8.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.5.png',
        color: '#A21942',
    },
    {
        title: 'Förderung der Jugendbeschäftigung, der allgemeinen und beruflichen Bildung.',
        short: '',
        number: '8.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.6.png',
        color: '#A21942',
    },
    {
        title: 'Ende des modernen Sklavenhandels und der Kinderarbeit.',
        short: '',
        number: '8.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.7.png',
        color: '#A21942',
    },
    {
        title: 'Schutz der Arbeitnehmerrechte und Förderung eines sicheren Arbeitsumfelds.',
        short: '',
        number: '8.8',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.8.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.8.png',
        color: '#A21942',
    },
    {
        title: 'Förderung eines nutzbringenden und nachhaltigen Tourismus.',
        short: '',
        number: '8.9',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.9.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.9.png',
        color: '#A21942',
    },
    {
        title: 'Universeller Zugang zu Banken, Versicherungen und Finanzdienstleistungen.',
        short: '',
        number: '8.10',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.10.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.10.png',
        color: '#A21942',
    },
    {
        title: 'Erhöhung der Hilfe für die Handelsunterstützung.',
        short: '',
        number: '8.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.A.png',
        color: '#A21942',
    },
    {
        title: 'Entwicklung einer globalen Jugendbeschäftigungsstrategie.',
        short: '',
        number: '8.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_SVG/GOAL_8_TARGET_8.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-8/GOAL_8_TARGETS/GOAL_8_TARGETS_PNG/GOAL_8_TARGET_8.B.png',
        color: '#A21942',
    },
    {
        title: 'Eine widerstandsfähige Infrastruktur aufbauen, inklusive und nachhaltige Industrialisierung fördern und Innovationen unterstützen',
        short: 'Industry, Innovation and Infrastructure',
        number: '9',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_PRIMARY_ICON/GOAL_9_SVG/TheGlobalGoals_Icons_Color_Goal_9.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_PRIMARY_ICON/GOAL_9_PNG/TheGlobalGoals_Icons_Color_Goal_9.png',
        color: '#FD6925',
    },
    {
        title: 'Entwicklung nachhaltiger, belastbarer und integrativer Infrastrukturen.',
        short: '',
        number: '9.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_SVG/GOAL_9_TARGET_9.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_PNG/GOAL_9_TARGET_9.1.png',
        color: '#FD6925',
    },
    {
        title: 'Förderung einer integrativen und nachhaltigen Industrialisierung.',
        short: '',
        number: '9.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_SVG/GOAL_9_TARGET_9.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_PNG/GOAL_9_TARGET_9.2.png',
        color: '#FD6925',
    },
    {
        title: 'Verbesserung des Zugangs zu Finanzdienstleistungen und -märkten.',
        short: '',
        number: '9.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_SVG/GOAL_9_TARGET_9.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_PNG/GOAL_9_TARGET_9.3.png',
        color: '#FD6925',
    },
    {
        title: 'Alle Branchen und Infrastrukturen auf Nachhaltigkeit ausrichten.',
        short: '',
        number: '9.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_SVG/GOAL_9_TARGET_9.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_PNG/GOAL_9_TARGET_9.4.png',
        color: '#FD6925',
    },
    {
        title: 'Verbesserung der Forschung und der industriellen Technologien',
        short: '',
        number: '9.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_SVG/GOAL_9_TARGET_9.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_PNG/GOAL_9_TARGET_9.5.png',
        color: '#FD6925',
    },
    {
        title: 'Förderung einer nachhaltigen Infrastrukturentwicklung für Entwicklungsländer.',
        short: '',
        number: '9.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_SVG/GOAL_9_TARGET_9.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_PNG/GOAL_9_TARGET_9.A.png',
        color: '#FD6925',
    },
    {
        title: 'Förderung der heimischen Technologieentwicklung und der industriellen Diversifizierung.',
        short: '',
        number: '9.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_SVG/GOAL_9_TARGET_9.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_PNG/GOAL_9_TARGET_9.B.png',
        color: '#FD6925',
    },
    {
        title: 'Universeller Zugang zur Informations- und Kommunikationstechnologie.',
        short: '',
        number: '9.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_SVG/GOAL_9_TARGET_9.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-9/GOAL_9_TARGETS/GOAL_9_TARGETS_PNG/GOAL_9_TARGET_9.C.png',
        color: '#FD6925',
    },
    {
        title: 'Ungleichheit in und zwischen Ländern verringern',
        short: 'Reduced Inequalities',
        number: '10',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_PRIMARY_ICON/GOAL_10_SVG/TheGlobalGoals_Icons_Color_Goal_10.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_PRIMARY_ICON/GOAL_10_PNG/TheGlobalGoals_Icons_Color_Goal_10.png',
        color: '#DD1367',
    },
    {
        title: 'Einkommensungleichheiten abbauen.',
        short: '',
        number: '10.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.1.png',
        color: '#DD1367',
    },
    {
        title: 'Förderung der allgemeinen sozialen, wirtschaftlichen und politischen Integration.',
        short: '',
        number: '10.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.2.png',
        color: '#DD1367',
    },
    {
        title: 'Gewährleistung der Chancengleichheit und Beendigung der Diskriminierung.',
        short: '',
        number: '10.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.3.png',
        color: '#DD1367',
    },
    {
        title: 'Steuer- und Sozialpolitik, die die Gleichstellung fördert.',
        short: '',
        number: '10.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.4.png',
        color: '#DD1367',
    },
    {
        title: 'Verbesserte Regulierung der globalen Finanzmärkte und -institutionen.',
        short: '',
        number: '10.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.5.png',
        color: '#DD1367',
    },
    {
        title: 'Verstärkte Vertretung der Entwicklungsländer in den Finanzinstitutionen',
        short: '',
        number: '10.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.6.png',
        color: '#DD1367',
    },
    {
        title: 'Verantwortungsvolle und gut geführte Migrationspolitik.',
        short: '',
        number: '10.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.7 .svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.7 .png',
        color: '#DD1367',
    },
    {
        title: 'Besondere und differenzierte Behandlung für Entwicklungsländer.',
        short: '',
        number: '10.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.A.png',
        color: '#DD1367',
    },
    {
        title: 'Förderung von Entwicklungshilfe und Investitionen in den am wenigsten entwickelten Ländern.',
        short: '',
        number: '10.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.B.png',
        color: '#DD1367',
    },
    {
        title: 'Reduzierung der Transaktionskosten für Überweisungen von Migranten.',
        short: '',
        number: '10.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_SVG/GOAL_10_TARGET_10.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-10/GOAL_10_TARGETS/GOAL_10_TARGETS_PNG/GOAL_10_TARGET_10.C.png',
        color: '#DD1367',
    },
    {
        title: 'Städte und Siedlungen inklusiv, sicher, widerstandsfähig und nachhaltig gestalten',
        short: 'Sustainable cities and communities',
        number: '11',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_PRIMARY_ICON/GOAL_11_SVG/TheGlobalGoals_Icons_Color_Goal_11.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_PRIMARY_ICON/GOAL_11_PNG/TheGlobalGoals_Icons_Color_Goal_11.png',
        color: '#FD9D24',
    },
    {
        title: 'Sicheres und erschwingliches Wohnen.',
        short: '',
        number: '11.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.1.png',
        color: '#FD9D24',
    },
    {
        title: 'Erschwingliche und nachhaltige Verkehrssysteme.',
        short: '',
        number: '11.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.2.png',
        color: '#FD9D24',
    },
    {
        title: 'Inklusive und nachhaltige Urbanisierung.',
        short: '',
        number: '11.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.3.png',
        color: '#FD9D24',
    },
    {
        title: 'Schutz des Kultur- und Naturerbes unserer Erde',
        short: '',
        number: '11.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.4.png',
        color: '#FD9D24',
    },
    {
        title: 'Verringerung der negativen Auswirkungen von Naturkatastrophen.',
        short: '',
        number: '11.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.5.png',
        color: '#FD9D24',
    },
    {
        title: 'Verringerung der Umweltauswirkungen von Städten.',
        short: '',
        number: '11.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.6.png',
        color: '#FD9D24',
    },
    {
        title: 'Zugang zu sicheren und integrativen Grünflächen und öffentlichen Plätzen bieten.',
        short: '',
        number: '11.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.7.png',
        color: '#FD9D24',
    },
    {
        title: 'Starke nationale und regionale Entwicklungsplanung.',
        short: '',
        number: '11.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.A.png',
        color: '#FD9D24',
    },
    {
        title: 'Umsetzung von Maßnahmen zur Eingliederung, Ressourceneffizienz und Katastrophenvorsorge.',
        short: '',
        number: '11.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.B.png',
        color: '#FD9D24',
    },
    {
        title: 'Unterstützung der am wenigsten entwickelten Länder beim nachhaltigen und belastbaren Bauen.',
        short: '',
        number: '11.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_SVG/GOAL_11_TARGET_11.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-11/GOAL_11_TARGETS/GOAL_11_TARGETS_PNG/GOAL_11_TARGET_11.C.png',
        color: '#FD9D24',
    },
    {
        title: 'Nachhaltige Konsum- und Produktionsmuster sicherstellen',
        short: 'Responsible consumption and production',
        short: '',
        number: '12',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_PRIMARY_ICON/GOAL_12_SVG/TheGlobalGoals_Icons_Color_Goal_12.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_PRIMARY_ICON/GOAL_12_PNG/TheGlobalGoals_Icons_Color_Goal_12.png',
        color: '#BF8B2E',
    },
    {
        title: 'Umsetzung des auf 10 Jahre angelegten Rahmens für nachhaltigen Konsum und nachhaltige Produktion.',
        short: '',
        number: '12.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.1.png',
        color: '#BF8B2E',
    },
    {
        title: 'Nachhaltige Bewirtschaftung und Nutzung der natürlichen Ressourcen.',
        short: '',
        number: '12.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.2.png',
        color: '#BF8B2E',
    },
    {
        title: 'Halbierung der weltweiten Pro-Kopf-Nahrungsmittelverschwendung.',
        short: '',
        number: '12.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.3.png',
        color: '#BF8B2E',
    },
    {
        title: 'Verantwortungsvoller Umgang mit Chemikalien und Abfällen.',
        short: '',
        number: '12.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.4.png',
        color: '#BF8B2E',
    },
    {
        title: 'Erhebliche Reduzierung des Abfallaufkommens.',
        short: '',
        number: '12.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.5.png',
        color: '#BF8B2E',
    },
    {
        title: 'Ermutigung von Unternehmen, nachhaltige Praktiken und Nachhaltigkeitsberichte einzuführen.',
        short: '',
        number: '12.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.6.png',
        color: '#BF8B2E',
    },
    {
        title: 'Förderung nachhaltiger öffentlicher Beschaffungspraktiken.',
        short: '',
        number: '12.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.7.png',
        color: '#BF8B2E',
    },
    {
        title: 'Förderung des universellen Verständnisses nachhaltiger Lebensstile.',
        short: '',
        number: '12.8',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.8.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.8.png',
        color: '#BF8B2E',
    },
    {
        title: 'Unterstützung der wissenschaftlichen und technologischen Kapazitäten der Entwicklungsländer für einen nachhaltigen Konsum und eine nachhaltige Produktion.',
        short: '',
        number: '12.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.A.png',
        color: '#BF8B2E',
    },
    {
        title: 'Entwicklung und Umsetzung von Instrumenten zur Überwachung des nachhaltigen Tourismus.',
        short: '',
        number: '12.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.B.png',
        color: '#BF8B2E',
    },
    {
        title: 'Entfernen von Marktverzerrungen, die den verschwenderischen Konsum fördern.',
        short: '',
        number: '12.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_SVG/GOAL_12_TARGET_12.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-12/GOAL_12_TARGETS/GOAL_12_TARGETS_PNG/GOAL_12_TARGET_12.C.png',
        color: '#BF8B2E',
    },
    {
        title: 'Umgehend Maßnahmen zur Bekämpfung des Klimawandels und seiner Auswirkungen ergreifen*',
        short: 'Climate Action',
        number: '13',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_PRIMARY_ICON/GOAL_13_SVG/TheGlobalGoals_Icons_Color_Goal_13.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_PRIMARY_ICON/GOAL_13_PNG/TheGlobalGoals_Icons_Color_Goal_13.png',
        color: '#3F7E44',
    },
    {
        title: 'Stärkung der Widerstandsfähigkeit und Anpassungsfähigkeit an klimabedingte Katastrophen.',
        short: '',
        number: '13.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_SVG/GOAL_13_TARGET_13.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_PNG/GOAL_13_TARGET_13.1.png',
        color: '#3F7E44',
    },
    {
        title: 'Integration von Klimaschutzmaßnahmen in Politik und Planung.',
        short: '',
        number: '13.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_SVG/GOAL_13_TARGET_13.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_PNG/GOAL_13_TARGET_13.2.png',
        color: '#3F7E44',
    },
    {
        title: 'Aufbau von Wissen und Kapazitäten zur Bewältigung des Klimawandels.',
        short: '',
        number: '13.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_SVG/GOAL_13_TARGET_13.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_PNG/GOAL_13_TARGET_13.3.png',
        color: '#3F7E44',
    },
    {
        title: 'Umsetzung der UN-Rahmenkonvention zum Klimawandel.',
        short: '',
        number: '13.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_SVG/GOAL_13_TARGET_13.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_PNG/GOAL_13_TARGET_13.A.png',
        color: '#3F7E44',
    },
    {
        title: 'Förderung von Mechanismen zur Erhöhung der Kapazitäten für die Klimaplanung und -bewirtschaftung.',
        short: '',
        number: '13.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_SVG/GOAL_13_TARGET_13.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-13/GOAL_13_TARGETS/GOAL_13_TARGETS_PNG/GOAL_13_TARGET_13.B.png',
        color: '#3F7E44',
    },
    {
        title: 'Ozeane, Meere und Meeresressourcen im Sinne nachhaltiger Entwicklung erhalten und nachhaltig nutzen',
        short: 'Life below water',
        number: '14',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_PRIMARY_ICON/GOAL_14_SVG/TheGlobalGoals_Icons_Color_Goal_14.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_PRIMARY_ICON/GOAL_14_PNG/TheGlobalGoals_Icons_Color_Goal_14.png',
        color: '#0A97D9',
    },
    {
        title: 'Reduzierung der Meeresverschmutzung.',
        short: '',
        number: '14.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.1.png',
        color: '#0A97D9',
    },
    {
        title: 'Schutz und Wiederherstellung von Ökosystemen.',
        short: '',
        number: '14.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.2.png',
        color: '#0A97D9',
    },
    {
        title: 'Reduzieren der Versauerung der Ozeane.',
        short: '',
        number: '14.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.3.png',
        color: '#0A97D9',
    },
    {
        title: 'Nachhaltige Fischerei.',
        short: '',
        number: '14.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.4.png',
        color: '#0A97D9',
    },
    {
        title: 'Schutz der Küsten- und Meeresgebiete.',
        short: '',
        number: '14.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.5.png',
        color: '#0A97D9',
    },
    {
        title: 'Ende der Subventionen, die zur Überfischung beitragen.',
        short: '',
        number: '14.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.6.png',
        color: '#0A97D9',
    },
    {
        title: 'Steigerung des wirtschaftlichen Nutzens durch nachhaltige Nutzung der Meeresressourcen.',
        short: '',
        number: '14.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.7.png',
        color: '#0A97D9',
    },
    {
        title: 'Steigerung des wirtschaftlichen Nutzens aus der nachhaltigen Nutzung der Meeresressourcen.',
        short: '',
        number: '14.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.7.png',
        color: '#0A97D9',
    },
    {
        title: 'Erhöhung der wissenschaftlichen Kenntnisse, Forschung und Technologie für die Gesundheit der Meere.',
        short: '',
        number: '14.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.A.png',
        color: '#0A97D9',
    },
    {
        title: 'Unterstützung von kleinen Fischerbetrieben.',
        short: '',
        number: '14.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.B.png',
        color: '#0A97D9',
    },
    {
        title: 'Umsetzung und Durchsetzung des internationalen Seerechts.',
        short: '',
        number: '14.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_SVG/GOAL_14_TARGET_14.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-14/GOAL_14_TARGETS/GOAL_14_TARGETS_PNG/GOAL_14_TARGET_14.C.png',
        color: '#0A97D9',
    },
    {
        title: 'Landökosysteme schützen, wiederherstellen und ihre nachhaltige Nutzung fördern, Wälder nachhaltig bewirtschaften, Wüstenbildung bekämpfen, Bodendegradation beenden und umkehren und dem Verlust der biologischen Vielfalt ein Ende setzen',
        short: 'Life On Land',
        number: '15',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_PRIMARY_ICON/GOAL_15_SVG/TheGlobalGoals_Icons_Color_Goal_15.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_PRIMARY_ICON/GOAL_15_PNG/TheGlobalGoals_Icons_Color_Goal_15.png',
        color: '#56C02B',
    },
    {
        title: 'Erhaltung und Wiederherstellung von Land- und Süßwasserökosystemen.',
        short: '',
        number: '15.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.1.png',
        color: '#56C02B',
    },
    {
        title: 'Ende der Abholzung und Wiederherstellung degradierter Wälder.',
        short: '',
        number: '15.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.2.png',
        color: '#56C02B',
    },
    {
        title: 'Die Wüstenbildung beenden und degradiertes Land wiederherstellen.',
        short: '',
        number: '15.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.3.png',
        color: '#56C02B',
    },
    {
        title: 'Sicherstellung der Erhaltung der Bergökosysteme.',
        short: '',
        number: '15.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.4.png',
        color: '#56C02B',
    },
    {
        title: 'Schutz der Biodiversität und der natürlichen Lebensräume.',
        short: '',
        number: '15.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.5.png',
        color: '#56C02B',
    },
    {
        title: 'Förderung des Zugangs zu genetischen Ressourcen und der gerechten Aufteilung der Vorteile.',
        short: '',
        number: '15.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.6.png',
        color: '#56C02B',
    },
    {
        title: 'Eliminierung von Wilderei und Handel mit geschützten Arten.',
        short: '',
        number: '15.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.7.png',
        color: '#56C02B',
    },
    {
        title: 'Verhindern von invasive gebietsfremde Arten auf dem Land und in Wasserökosystemen.',
        short: '',
        number: '15.8',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.8.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.8.png',
        color: '#56C02B',
    },
    {
        title: 'Integration von Ökosystem und Biodiversität in die staatliche Planung.',
        short: '',
        number: '15.9',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.9.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.9.png',
        color: '#56C02B',
    },
    {
        title: 'Erhöhung der finanziellen Mittel zur Erhaltung und nachhaltigen Nutzung von Ökosystemen und Biodiversität.',
        short: '',
        number: '15.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.A.png',
        color: '#56C02B',
    },
    {
        title: 'Nachhaltige Waldbewirtschaftung finanzieren und fördern.',
        short: '',
        number: '15.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.B.png',
        color: '#56C02B',
    },
    {
        title: 'Bekämpfung der weltweiten Wilderei und des Menschenhandels.',
        short: '',
        number: '15.C',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_SVG/GOAL_15_TARGET_15.C.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-15/GOAL_15_TARGETS/GOAL_15_TARGETS_PNG/GOAL_15_TARGET_15.C.png',
        color: '#56C02B',
    },
    {
        title: 'Friedliche und inklusive Gesellschaften für eine nachhaltige Entwicklung fördern, allen Menschen Zugang zur Justiz ermöglichen und leistungsfähige, rechenschaftspflichtige und inklusive Institutionen auf allen Ebenen aufbauen',
        short: 'Peace, Justice, And Strong Institutions',
        number: '16',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_PRIMARY_ICON/GOAL_16_SVG/TheGlobalGoals_Icons_Color_Goal_16.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_PRIMARY_ICON/GOAL_16_PNG/TheGlobalGoals_Icons_Color_Goal_16.png',
        color: '#00689D',
    },
    {
        title: 'Gewalt reduzieren',
        short: '',
        number: '16.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.1.png',
        color: '#00689D',
    },
    {
        title: 'Schutz von Kindern vor Missbrauch, Ausbeutung, Menschenhandel und Gewalt.',
        short: '',
        number: '16.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.2.png',
        color: '#00689D',
    },
    {
        title: 'Förderung der Rechtsstaatlichkeit und Gewährleistung eines gleichberechtigten Zugangs zum Recht.',
        short: '',
        number: '16.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.3.png',
        color: '#00689D',
    },
    {
        title: 'Bekämpfung der organisierten Kriminalität und der illegalen Finanz- und Waffenströme.',
        short: '',
        number: '16.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.4.png',
        color: '#00689D',
    },
    {
        title: 'Erhebliche Reduzierung von Korruption und Bestechung.',
        short: '',
        number: '16.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.5.png',
        color: '#00689D',
    },
    {
        title: 'Entwicklung effektiver, rechenschaftspflichtiger und transparenter Institutionen.',
        short: '',
        number: '16.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.6.png',
        color: '#00689D',
    },
    {
        title: 'Sicherstellung einer reaktionsschnellen, integrativen und repräsentativen Entscheidungsfindung.',
        short: '',
        number: '16.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.7.png',
        color: '#00689D',
    },
    {
        title: 'Stärkung der Beteiligung an der Global Governance.',
        short: '',
        number: '16.8',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.8.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.8.png',
        color: '#00689D',
    },
    {
        title: 'Bereitstellung einer universellen und rechtlichen Identität.',
        short: '',
        number: '16.9',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.9.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.9.png',
        color: '#00689D',
    },
    {
        title: 'Gewährleistung des Zugangs der Öffentlichkeit zu Informationen und Schutz der Grundfreiheiten.',
        short: '',
        number: '16.10',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.10.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.10.png',
        color: '#00689D',
    },
    {
        title: 'Stärkung der nationalen Institutionen zur Verhütung von Gewalt und zur Bekämpfung von Terrorismus und Kriminalität.',
        short: '',
        number: '16.A',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.A.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.A.png',
        color: '#00689D',
    },
    {
        title: 'Förderung und Durchsetzung nichtdiskriminierender Gesetze und Richtlinien.',
        short: '',
        number: '16.B',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_SVG/GOAL_16_TARGET_16.B.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-16/GOAL_16_TARGETS/GOAL_16_TARGETS_PNG/GOAL_16_TARGET_16.B.png',
        color: '#00689D',
    },
    {
        title: 'Stärkung der Umsetzungsmöglichkeiten und Revitalisierung der Globalen Partnerschaft für nachhaltige Entwicklung',
        short: 'Partnerships for the goals',
        number: '17',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_PRIMARY_ICON/GOAL_17_SVG/TheGlobalGoals_Icons_Color_Goal_17.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_PRIMARY_ICON/GOAL_17_PNG/TheGlobalGoals_Icons_Color_Goal_17.png',
        color: '#19486A',
    },
    {
        title: 'Mobilisierung von Ressourcen zur Verbesserung der inländischen Steuererhebung.',
        short: '',
        number: '17.1',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.1.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.1.png',
        color: '#19486A',
    },
    {
        title: 'Umsetzung aller Entwicklungshilfeverpflichtungen.',
        short: '',
        number: '17.2',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.2.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.2.png',
        color: '#19486A',
    },
    {
        title: 'Mobilisierung von Finanzmitteln für Entwicklungsländer.',
        short: '',
        number: '17.3',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.3.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.3.png',
        color: '#19486A',
    },
    {
        title: 'Unterstützung der Entwicklungsländer bei der Erreichung der Tragfähigkeit ihrer Schulden.',
        short: '',
        number: '17.4',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.4.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.4.png',
        color: '#19486A',
    },
    {
        title: 'Investitionen in den am wenigsten entwickelten Ländern.',
        short: '',
        number: '17.5',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.5.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.5.png',
        color: '#19486A',
    },
    {
        title: 'Wissensaustausch und Zusammenarbeit für den Zugang zu Wissenschaft, Technologie und Innovation.',
        short: '',
        number: '17.6',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.6.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.6.png',
        color: '#19486A',
    },
    {
        title: 'Förderung nachhaltiger Technologien in Entwicklungsländern.',
        short: '',
        number: '17.7',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.7.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.7.png',
        color: '#19486A',
    },
    {
        title: 'Stärkung der Wissenschafts-, Technologie- und Innovationskapazität der am wenigsten entwickelten Länder.',
        short: '',
        number: '17.8',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.8.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.8.png',
        color: '#19486A',
    },
    {
        title: 'Ausbau der SDG-Kapazitäten in Entwicklungsländern.',
        short: '',
        number: '17.9',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.9.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.9.png',
        color: '#19486A',
    },
    {
        title: 'Förderung eines universellen Handelssystems im Rahmen der WTO.',
        short: '',
        number: '17.10',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.10.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.10.png',
        color: '#19486A',
    },
    {
        title: 'Steigerung der Exporte der Entwicklungsländer.',
        short: '',
        number: '17.11',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.11.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.11.png',
        color: '#19486A',
    },
    {
        title: 'Abbau von Handelshemmnissen für die am wenigsten entwickelten Länder.',
        short: '',
        number: '17.12',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.12.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.12.png',
        color: '#19486A',
    },
    {
        title: 'Verbesserung der globalen makroökonomischen Stabilität.',
        short: '',
        number: '17.13',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.13.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.13.png',
        color: '#19486A',
    },
    {
        title: 'Verbesserung der Politikkohärenz für eine nachhaltige Entwicklung.',
        short: '',
        number: '17.14',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.14.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.14.png',
        color: '#19486A',
    },
    {
        title: 'Respektieren der nationalen Führung bei der Umsetzung der Politik für die Ziele der nachhaltigen Entwicklung.',
        short: '',
        number: '17.15',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.15.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.15.png',
        color: '#19486A',
    },
    {
        title: 'Stärkung der globalen Partnerschaft für nachhaltige Entwicklung',
        short: '',
        number: '17.16',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.16.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.16.png',
        color: '#19486A',
    },
    {
        title: 'Förderung effektiver Partnerschaften.',
        short: '',
        number: '17.17',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.17.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.17.png',
        color: '#19486A',
    },
    {
        title: 'Erhöhung der Verfügbarkeit zuverlässiger Daten.',
        short: '',
        number: '17.18',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.18.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.18.png',
        color: '#19486A',
    },
    {
        title: 'Fortschrittsmessungen weiterentwickeln.',
        short: '',
        number: '17.19',
        svg: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_SVG/GOAL_17_TARGET_17.19.svg',
        png: 'assets/img/the-global-goals-goals-and-targets/goal-17/GOAL_17_TARGETS/GOAL_17_TARGETS_PNG/GOAL_17_TARGET_17.19.png',
        color: '#19486A',
    },
]
