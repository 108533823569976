import React, { Component } from 'react'
import dizionarioFields from './../fields/dizionarioFields'
import { Grid } from '@mui/material'

export default function FormOutput(props) {
    let standard = 'test-form'
    if (props.isSubInput) {
        standard = 'test-form-no-padding'
    }
    const standardXs = 4
    const standardLg = 2
    const standardMd = 2


    return (
        <Grid component={props.isSubInput ? 'div' : 'form'} container spacing={3} maxWidth='100%' width='100%'>
            {props.form.parts.map((model, index) => {
                let valore = props.dati[model.id]
                if(valore === undefined) valore = model.value 
                let valoreXs = model.details?.grid?.xs?.input || standardXs
                let valoreLg = model.details?.grid?.lg?.input || standardLg
                let valoreMd = model.details?.grid?.md?.input || standardMd
                let valoreXl = model.details?.grid?.xl?.input || model.details?.grid?.lg?.input || standardLg
                if(dizionarioFields[model.tipo] === undefined) console.log('guardaaaa : ', model.tipo);
                return (
                    <Grid item xs={valoreXs * 3} md={valoreMd * 3} lg={valoreLg * 3} xl={valoreXl * 3} key={index} maxWidth='100%' width='100%'>
                        {dizionarioFields[model.tipo].output(model, valore, props.skeleton || false)}
                    </Grid>
                )
            })}
        </Grid>
    )
}
