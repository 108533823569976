import React, { useState, useEffect } from 'react'

export default function useInputFileImmagini(id, azione,tipo) {
    const [altezza, setAltezza] = useState(200)
    const [larghezza, setLarghezza] = useState(200)

    const getTipo = () => {
        if(tipo === undefined || tipo === "") return 'image/*'
        if(tipo === 'pdf') return 'application/pdf'
    }

    const renderInput = (
        <>
            <input type='file' accept={getTipo()} name={id} id={id} style={{ display: 'none' }} onChange={() => nuovoFile()} />
            <canvas id='canvassino' width={larghezza} height={altezza} style={{ display: 'none' }}></canvas>
        </>
    )

    const computeSize = (height, width, maxHeight, maxWidth) => {
        var defaultRatio = maxHeight / maxWidth
        var imageRatio = height / width
        if (defaultRatio <= imageRatio) {
            var conversionRatio = maxHeight / height
        } else {
            var conversionRatio = maxWidth / width
        }
        var result = {
            height: height * conversionRatio,
            width: width * conversionRatio,
        }
        return result
    }

    const caricaCanvas = (image) => {
        return new Promise((resolve, reject) => {
            let canvas = document.getElementById('canvassino')
            // Le dimensioni del'immagine variano in base all'input
            let misure = computeSize(image.height, image.width, altezza, larghezza)
            // Il canvas si dimensiona in base all'immagine

            canvas.width = misure.width
            canvas.height = misure.height
            let ctx = canvas.getContext('2d')
            // Disegniamo l'immagine
            ctx.drawImage(image, 0, 0, misure.width, misure.height)
            // Richiamiamo download per ottenere il link e rimpire il parametro href per il downlaod
            canvas.toBlob((blob) => {
                const dataUrl = canvas.toDataURL()
                let myBlob = blob
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                resolve({ blob, myBlob, dataUrl: dataUrl })
            })
        })
    }

    const ottieniImmagine = (dataUrl) => {
        return new Promise((resolve, reject) => {
            // Si crea un'immagine
            let image = new Image()
            // si risolve con l'immagine
            image.onload = () => {
                resolve(image)
            }
            // Il source dell'immagine e' uguale al link (base64/URL)
            image.src = dataUrl
        })
    }

    const leggiFile = (fileInput) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader()
            // Cosa deve fare il reader al moment del load, va definito prima per richiamarlo dopo
            reader.onload = (e) => {
                // ritorno il file base32
                resolve(e.target.result)
            }
            // Interpretiamo il file come data URL cioÃ¨ in base64
            reader.readAsDataURL(fileInput)
        })
    }

    const nuovoFile = async () => {
        let fileInput = document.getElementById(id).files[0]
        if (fileInput.type === "application/pdf") {
            return await azione("", fileInput)
        }
        const dataUrl = await leggiFile(fileInput)

        const image = await ottieniImmagine(dataUrl)

        const thumbNail = await caricaCanvas(image)

        document.getElementById(id).value = ''
        await azione(thumbNail, fileInput)
    }

    const carica = () => {
        document.getElementById(id).click()
    }
    return [renderInput, carica]
}
