/*

l'elemeto tramite il suo osservatore "userWatcher" comuncia allo store che le sue info sono cambiate

1) con elemento facciamo n azione
2) userWatcher si accorge di un cambiamento nell'elemento
3) userWatcher aggiorna lo Store 
4) lo store chiama il reducer
5) il reducer aggiorna lo state

*/
import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { lazyReducerEnhancer } from 'pwa-helpers/lazy-reducer-enhancer.js'
import { statoUtente } from './reducers/statoUtente'
import { url } from './reducers/url'
import { spinner } from './reducers/spinner'
import { reducerPopUp } from './reducers/reducerPopUp'
import { storico } from './reducers/storico'
import { reducerStatoModifica } from './reducers/reducerStatoModifica'
import { reducerProgetto } from './reducers/reducerProgetto'
import { reducerEnte } from './reducers/reducerEnte'
import { reducerImpresa } from './reducers/reducerImpresa'
import { reducerAggiornamentiProgetto } from './reducers/reducerAggiornamentiProgetto'

const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore((state) => state, devCompose(lazyReducerEnhancer(combineReducers), applyMiddleware(thunk)))

store.addReducers({
    statoUtente,
    url,
    reducerPopUp,
    storico,
    reducerStatoModifica,
    spinner,
    reducerProgetto,
    reducerEnte,
    reducerImpresa,
    reducerAggiornamentiProgetto
})
