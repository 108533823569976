import React from 'react'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

export default function elementoNavigazioneMieiProgettiEnte(props) {

    const handleClick = (valore) => props.handleSelezionato(valore)

    const getStile = (color, isSelezionato) => {
        const styleDefault = {
            py: 1,
            color: color,
            bgColor: 'white',
            borderRadius: 'md',
            className: 'centrato',
            sx: { border: 'solid 0.2px', cursor: 'pointer' },
        }
        const styleSelezionato = {
            py: 1,
            color: 'white',
            bgColor: color,
            borderRadius: 'md',
            className: 'centrato',
            sx: { cursor: 'pointer' },
        }
        return isSelezionato ? styleSelezionato : styleDefault
    }

    const renderTab = (testo, color) => {
        const isSelezionato = testo === props.selezionato
        const stile = getStile(color, isSelezionato)
        return (
            <Grid item xs={12} sm={3} md={3} lg={3}>
                <MDBox {...stile} onClick={() => handleClick(testo)}>
                    <MDTypography color={isSelezionato ? 'white' : color} fontWeight='medium'>
                        {testo}
                    </MDTypography>
                </MDBox>
            </Grid>
        )
    }

    return (
        <Grid container spacing={0.5} p={1} maxWidth={'1000px'} mx='auto' mb={2} justifyContent={'center'}>
            {renderTab('In attesa', 'warning')}
            {renderTab('Attivi', 'info')}
            {renderTab('Completati', 'success')}
            {renderTab('Rifiutati', 'error')}
        </Grid>
    )
}
