import { getAuth, updatePassword, updateEmail } from 'firebase/auth'
import { doc, updateDoc } from 'firebase/firestore'

export const MODIFICA_CREDENZIALI = async (isCambioMail, dato) => {
    const utente = getAuth().currentUser
    const oggettoDB = isCambioMail ? { ['email']: dato } : { ['password']: dato }

    const aggiornaPassword = async () => {
        try {
            return await updatePassword(utente, dato)
        } catch (error) {
            throw new Error(error)
        }
    }
    const aggiornaMail = async () => {
        try {
            return await updateEmail(utente, dato)
        } catch (error) {
            throw new Error(error)
        }
    }
    const aggiornaDb = async () => {
        try {
            return await updateDoc(doc(db, 'utenti', utente.uid, 'datiLogIn', 'dati'), oggettoDB)
        } catch (error) {
            throw new Error(error)
        }
    }

    const aggiornaAuth = async () => {
        if (isCambioMail) return await aggiornaMail()
        return await aggiornaPassword()
    }

    try {
        //await Promise.all([aggiornaAuth(), aggiornaDb()])
        await aggiornaAuth()
        return
    } catch (error) {
        throw new Error(error)
        return
    }
}
