import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import useCache from 'customHooks/useCache'
import ElementoListaProgetti from 'componenti/elementoListaProgetti'
import ElementoFiltriProgetti from 'componenti/elementoFiltriProgetti'
import MDBox from 'components/MDBox'
import { getListaProgettiFiltrata } from 'getter/getterProgetti'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import axios from 'axios'

export const paginaProgetti = (props) => {
    const { listaProgettiWindow } = useCache()
    const [filtri, setFiltri] = useState({ categoria: '', luogo: '', ordinaPer: 0 })
    const [listaFiltrata, setListafiltrata] = useState(listaProgettiWindow)

    const handleFilri = (nuoviFiltri) => setFiltri(Object.assign({}, nuoviFiltri))

    /*   useEffect(()=>{
        setTimeout(async ()=>{
            console.log('inizioooooo');
            const indirizzo = '6 Via San Giovanni Ceraso Salerno'
            const API_KEY = 'AIzaSyAweUmfy54SADWbqgf7h5SaDYvUGgBkUVA'
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${indirizzo}&key=${API_KEY}`)
            console.log('response : ', response);
        },5000)
    }, []) */

    useEffect(() => {
        setListafiltrata(getListaProgettiFiltrata(filtri))
    }, [filtri, listaProgettiWindow])

    const renderPagina = (
        <>
            <ElementoRiquadroPagine propsBox={{ shadow: 'lg', mb: 2 }}>
                <MDBox mx='auto' sx={{ maxWidth: '1000px', width: '100%' }}>
                    <ElementoFiltriProgetti lista={listaFiltrata} filtri={filtri} aggiornaFiltri={handleFilri} />
                </MDBox>
            </ElementoRiquadroPagine>
            <MDBox width='100%' maxWidth='1200px' mx='auto'>
                <ElementoListaProgetti {...props} lista={listaFiltrata} testoErrore='Nessun progetto presente in archivio' />
            </MDBox>
        </>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaProgetti)
