const initialState = false

export const spinner = (state = initialState, action) => {
    switch (action.type) {
        case 'MODIFICA_STATO_SPINNER':
            return action.payload
        default:
            return state
    }
}
