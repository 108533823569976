import React, { useState, useEffect } from 'react'

export default function useCache() {
    const [listaProgettiWindow, setListaProgettiWindow] = useState(window.listaProgetti || [])
    const [listaImpreseWindow, setListaImpreseWindow] = useState(window.listaImprese || [])
    const [listaEntiWindow, setListaEntiWindow] = useState(window.listaEnti || [])
    const [listaNetworkWindow, setListaNetworkWindow] = useState(window.listaNetwork || [])

    useEffect(() => {
        function cacheCambiata(e) {
            switch (e.detail) {
                case 'progetti':
                    return setListaProgettiWindow(window.listaProgetti)
                case 'imprese':
                    return setListaImpreseWindow(window.listaImprese)
                case 'enti':
                    return setListaEntiWindow(window.listaEnti)
                case 'network':
                    return setListaNetworkWindow(window.listaNetwork)
                default:
                    return
            }
        }

        window.addEventListener('cache-cambiata', cacheCambiata)
        return () => window.removeEventListener('cache-cambiata', cacheCambiata)
    }, [])
    return {
        listaProgettiWindow,
        listaImpreseWindow,
        listaEntiWindow,
        listaNetworkWindow,
    }
}
