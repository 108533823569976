import { beneficiari } from 'util'
import { categorieAttivitaProgetto } from 'util'

export const formProgetto = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'referente',
            tipo: 'fieldText',
            associazione: 'referente',
            label: 'Referente:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'breveDescrizione',
            tipo: 'fieldTextArea',
            associazione: 'breveDescrizione',
            label: 'Il progetto in un tweet:',
            value: '',
            details: {
                type: 'text',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'obiettivo',
            tipo: 'fieldTextArea',
            associazione: 'obiettivo',
            label: 'In cosa consiste il progetto?',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'aChiRivolto',
            tipo: 'fieldTextArea',
            associazione: 'aChiRivolto',
            label: 'A chi è rivolto?',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'scopo',
            tipo: 'fieldTextArea',
            associazione: 'scopo',
            label: 'Qual è lo scopo del progetto?',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'accessibilita',
            tipo: 'fieldTextArea',
            associazione: 'accessibilita',
            label: 'Quando il progetto sarà accessibile?',
            value: '',
            //details: { grid: { lg: { input: 4, output: 4 } } },
            validazione: 'testoRichiesto',
        },
        {
            id: 'dataFine',
            tipo: 'fieldData',
            associazione: 'dataFine',
            label: 'Data fine:',
            value: '',
            //details: { grid: { lg: { input: 4, output: 4 } } },
            validazione: 'testoRichiesto',
        },
        {
            id: 'traguardi',
            tipo: 'fieldTraguardiProgetto',
            associazione: 'traguardi',
            label: 'Traguardi:',
            value: { totale: '', dividiBudget: false, lista: [] },
            //details: { grid: { lg: { input: 4, output: 4 } } },
            validazione: 'validazioneTraguardi',
        },
        {
            id: 'categoriaAttivita',
            tipo: 'fieldSceltaMultipla',
            associazione: 'categoriaAttivita',
            label: 'Categorie di attività',
            value: [],
            details: {
                complex: false,
                scelte: categorieAttivitaProgetto,
            },
        },
        {
            id: 'beneficiari',
            tipo: 'fieldSceltaMultipla',
            associazione: 'beneficiari',
            label: 'Beneficiari:',
            value: [],
            details: {
                complex: false,
                scelte: beneficiari,
            },
        },
        /*  {
            id: 'isProgettoSingolo',
            tipo: 'fieldBoolean',
            associazione: 'isProgettoSingolo',
            label: 'Progetto singolo',
            value: true,
            details: {
                complex: false,
                scelte: beneficiari,
            },
        }, */
        /* {
            id: 'isProgettoContinuativo',
            tipo: 'fieldBoolean',
            associazione: 'isProgettoContinuativo',
            label: 'Progetto continuativo:',
            value: false,
            details: {
                complex: false,
                scelte: beneficiari,
            },
        }, */
    ],
}

export const formRiassuntoProgetto = {
    parts: [
        {
            id: 'obiettivo',
            tipo: 'fieldTextArea',
            associazione: 'obiettivo',
            label: 'In cosa consiste il progetto?',
            value: '',
            details: {
                type: 'text',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'aChiRivolto',
            tipo: 'fieldTextArea',
            associazione: 'aChiRivolto',
            label: 'A chi è rivolto?',
            value: '',
            details: {
                type: 'text',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'scopo',
            tipo: 'fieldTextArea',
            associazione: 'scopo',
            label: 'Qual è lo scopo del progetto?',
            value: '',
            details: {
                type: 'text',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },

            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'accessibilita',
            tipo: 'fieldTextArea',
            associazione: 'accessibilita',
            label: 'Quando il progetto sarà accessibile?',
            value: '',
            grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            validazione: 'testoRichiesto',
        },
    ],
}

const formContattiGenericiProgetto = {
    parts: [
        {
            id: 'referente',
            tipo: 'fieldText',
            associazione: 'referente',
            label: 'Referente:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
    ],
}

export const formContattiProgetto = (testoBianco) => {
    return {
        parts: [
            {
                id: 'referente',
                tipo: 'fieldText',
                associazione: 'referente',
                label: 'Referente:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'email',
                tipo: 'fieldText',
                associazione: 'email',
                label: 'Email contatto:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'sitoWeb',
                tipo: 'fieldText',
                associazione: 'sitoWeb',
                label: 'Url sito web:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'telefono',
                tipo: 'fieldText',
                associazione: 'telefono',
                label: 'Telefono :',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
        ],
    }
}

export const formLinkContattiProgetto = (testoBianco) => {
    return {
        parts: [
            {
                id: 'linkedin',
                tipo: 'fieldText',
                associazione: 'linkedin',
                label: 'linkedin:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },

            {
                id: 'facebook',
                tipo: 'fieldText',
                associazione: 'facebook',
                label: 'Facebook:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },

            {
                id: 'instagram',
                tipo: 'fieldText',
                associazione: 'instagram',
                label: 'Instagram:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },

            {
                id: 'youtube',
                tipo: 'fieldText',
                associazione: 'youtube',
                label: 'Youtube:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
            {
                id: 'twitter',
                tipo: 'fieldText',
                associazione: 'twitter',
                label: 'Twitter:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: true,
                },
            },
        ],
    }
}
