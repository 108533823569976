import React, { useEffect, useState } from 'react'
import { InputBoolean, OutputBoolean } from './fieldBoolean'
import { InputSceltaMultipla, OutputSceltaMultipla } from './fieldSceltaMultipla'
import MDBox from 'components/MDBox'
import colors from 'assets/theme/base/colors'

export function InputSceltaMultiplaCondizionale(props) {
    const [lista, setLista] = useState([])

    useEffect(() => {
        setLista(props.value.lista || [])
    }, [])
    useEffect(() => {
        props.azione(props.model.id, Object.assign(props.value, { lista: lista }))
    }, [lista])

    const handleScelta = (campo, valore) => {
        const newValue = { scelta: valore, lista: valore ? lista : [] }
        props.azione(props.model.id, newValue)
    }

    const handleLista = (campo, valore) => setLista(valore)

    const modelSceltaMultipla = {
        id: 'lista',
        tipo: 'fieldSceltaMultipla',
        associazione: 'lista',
        label: '',
        value: [],
        details: Object.assign(props.model.details, { noBorder: true }),
    }

    const modelBoolean = {
        id: 'scelta',
        tipo: 'fieldBoolean',
        associazione: 'scelta',
        label: props.model.label,
        value: false,
    }

    //console.log('valoreeeeeee ; ', props.value, lista);

    const renderBoolean = <InputBoolean value={props.value.scelta} errore='' model={modelBoolean} azione={handleScelta} />
    const renderScelte = <InputSceltaMultipla value={props.value.lista} errore='' model={modelSceltaMultipla} azione={handleLista} />

    return (
        <MDBox sx={{ border: 'solid 0.1px', color: colors.inputBorderColor }} p={2} borderRadius='lg' display='flex' flexDirection='column' width='100%'>
            {renderBoolean} {props.value.scelta ? renderScelte : null}
        </MDBox>
    )
}

export function OutputSceltaMultiplaCondizionale(props) {
    const modelSceltaMultipla = {
        id: 'lista',
        tipo: 'fieldSceltaMultipla',
        associazione: 'lista',
        label: '',
        value: [],
        details: props.model.details,
    }

    const modelBoolean = {
        id: 'scelta',
        tipo: 'fieldBoolean',
        associazione: 'scelta',
        label: props.model.label,
        value: false,
        details: props.model.details,
    }

    const renderBoolean = <OutputBoolean value={props.value.scelta} model={modelBoolean} />
    const renderScelte = <OutputSceltaMultipla value={props.value.lista} model={modelSceltaMultipla} />

    return (
        <MDBox display='flex' flexDirection='column' width='100%'>
            {renderBoolean} {props.value.scelta ? renderScelte : null}
        </MDBox>
    )
}
