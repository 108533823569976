import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import MDBox from 'components/MDBox'
import React, { useState, useEffect } from 'react'
import useForm from 'customHooks/useForm'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { MODIFICA_CREDENZIALI } from 'data/azioni/MODIFICA_CREDENZIALI'
import { formCambioCredenziali } from 'formModel/formCambioCredenziali'

export default function popUpCambiaCredenziali(props) {
    const [attributo, setAttributo] = useState('mail')
    const handleClose = () => props.chiudi()

    useEffect(() => {
        setAttributo(props.parametro)
    }, [props.aperto])
    useEffect(() => {
        azioniCredenziali.setForm(formCambioCredenziali(attributo === 'mail'))
    }, [attributo])

    const [statoCredenziali, azioniCredenziali, renderFormCredenziali] = useForm(formCambioCredenziali(false), undefined, false, true)

    const aggiornaCredenziali = async () => {
        MODIFICA_STATO_SPINNER(true)
        try {
            await azioniCredenziali.validaForm()
            await MODIFICA_CREDENZIALI(attributo === 'mail', statoCredenziali.statoForm.nuovo)
            MODIFICA_STATO_SPINNER(false)
            handleClose()
        } catch (error) {
            console.log('error : ', error);
            MODIFICA_STATO_SPINNER(false)
        }
    }

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-conferma'>
            <DialogTitle id='titolo-pop-up-conferma'>MODIFICA CREDENZIALI</DialogTitle>
            <DialogContent>
                <MDBox p={2}>{renderFormCredenziali}</MDBox>
            </DialogContent>
            <DialogActions>
                <ElementoBottoniForm vista={statoCredenziali.vista} annulla={handleClose} valida={aggiornaCredenziali} />
            </DialogActions>
        </Dialog>
    )
}
