import { doc, updateDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { ref, uploadBytes } from 'firebase/storage'

export const MODIFICA_STATO_PROGETTO = async (id, impatto) => {
    MODIFICA_STATO_SPINNER(true)
    var impattoDaSalvare = []

    const inserisciInStorage = async (path, file) => {
        return await uploadBytes(ref(storage, path), file)
    }

    var promesse = []

    impatto.forEach(async (datiImpatto) => {
        const pathBianco = `${id}/impatto/${datiImpatto.sdg}/bianco.svg`
        const pathColorato = `${id}/impatto/${datiImpatto.sdg}/colorato.svg`
        var daSalvare = Object.assign({}, datiImpatto)
        delete daSalvare.immagini
        delete daSalvare.isNuovo
        impattoDaSalvare.push(daSalvare)
        if (datiImpatto.isNuovo) {
            console.log('entro quaaa');
            promesse.push(await inserisciInStorage(pathBianco, datiImpatto.immagini.bianco.originale))
            promesse.push(await inserisciInStorage(pathColorato, datiImpatto.immagini.colorato.originale))
        }
    })

    await Promise.all(promesse)

    console.log('impatto : ', impattoDaSalvare)
    await updateDoc(doc(db, 'progetti', id), { impatto: impattoDaSalvare })
    MODIFICA_STATO_SPINNER(false)
    return
}
