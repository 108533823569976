import { store } from 'data/store'
import { formattaDataDaFirebase } from 'data/strumenti'
import { getImpresaDaId } from './getterImpresa'
import { getProgettoDaId } from './getterProgetti'

export const getListaRichiesteAcquisto = () => {
    const state = store.getState()
    var lista = state.reducerRichiesteAcquisti ?? []
    var daTornare = []
    daTornare =  lista.map((elemento) => {
        return {
            data: formattaDataDaFirebase(elemento.data, true),
            nomeImpresa: getImpresaDaId(elemento.idImpresa).nome,
            percentuale: elemento.percentuale,
            stato: elemento.stato,
            id: elemento.id,
            nomeProgetto: getProgettoDaId(elemento.idProgetto).nome,
            totale: elemento.totale,
        }
    })
    return daTornare
}
