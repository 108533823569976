import { doc, updateDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'

export const ELIMINA_LOGO_PROGETTO = async () => {
    MODIFICA_STATO_SPINNER(true)
    const idProgetto = getIdProgettoSelezionato()
    await updateDoc(doc(db, 'progetti', idProgetto), { logo: '' })
    MODIFICA_STATO_SPINNER(false)
    return
}
