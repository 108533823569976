import { getIdImpresa } from 'getter/getterImpresa'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { httpsCallable } from 'firebase/functions'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'

export const INVITA_IMPRESA_IN_NETWORK = async () => {
    MODIFICA_STATO_SPINNER(true)
    const idImpresa = getIdImpresa()
    const idNetwork = getUtenteLoggato().network

    const inviaInvitoInNetwork = httpsCallable(functions, 'inviaInvitoInNetwork')
    const result = await inviaInvitoInNetwork({ idImpresa: idImpresa, idNetwork: idNetwork })
    MODIFICA_STATO_SPINNER(false)
    return
}
