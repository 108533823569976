import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { doc, updateDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'

export const ELIMINA_LOGO_UTENTE = async () => {
    MODIFICA_STATO_SPINNER(true)
    const idUtente = getIdUtenteLoggato()
    await updateDoc(doc(db, 'utenti', idUtente), { logo: '' })
    MODIFICA_STATO_SPINNER(false)
    return
}
