import React from 'react'
import { connect } from 'react-redux'
import ElementoNavigazionePreLogin from './elementoNavigazionePreLogin'
import ElementoContenitorePreLogin from './elementoContenitorePreLogin'

export const elementoRenderPagina = (props) => {
    const renderLanding = (
        <>
            <ElementoNavigazionePreLogin />
            <ElementoContenitorePreLogin boxProps={props.boxProps}>{props.renderPagina}</ElementoContenitorePreLogin>
        </>
    )

    return props.isLanding ? renderLanding : props.renderPagina
}

elementoRenderPagina.defaultProps = {
    boxProps : {}
}

const mapStateToProps = (state) => ({
    isLanding: state.statoUtente.stato !== 4,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoRenderPagina)
