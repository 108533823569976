import React from 'react'
import MDButton from 'components/MDButton'

export default function elementoBottoniForm(props) {

    const renderBottoniInput = (
        <div className='contenitore-bottoni-destra' style={{justifyContent : props.allineaSinistra ? 'start' : 'end'}}>
            <MDButton variant='text' color='error' onClick={props.annulla}>
                annulla
            </MDButton>
            <MDButton variant='contained' color='success' disabled={props.disabled || false} onClick={props.valida}>
                salva
            </MDButton>
        </div>
    )

    const renderottoniOutput = (
        <div className='contenitore-bottoni-destra' style={{justifyContent : props.allineaSinistra ? 'start' : 'end'}}>
            <MDButton variant='contained' color='dark' onClick={props.vediInput} mb={-3}>
                modifica
            </MDButton>
        </div>
    )

    const renderBottoniPrimoStep = (
        <div className='contenitore-bottoni-destra' style={{justifyContent : props.allineaSinistra ? 'start' : 'end'}}>
            <MDButton variant='text' color='error' onClick={props.annulla}>
                annulla
            </MDButton>
            <MDButton variant='contained' color='dark' disabled={props.disabled || false} onClick={props.valida}>
                avanti
            </MDButton>
        </div>
    )

    const renderBottoniStepCentrale = (
        <div className='contenitore-bottoni-destra' style={{justifyContent : props.allineaSinistra ? 'start' : 'end'}}>
            <MDButton variant='text' color='error' onClick={props.annulla}>
                annulla
            </MDButton>
            <MDButton variant='contained' color='dark' onClick={props.indietro}>
                indietro
            </MDButton>
            <MDButton variant='contained' color='dark' disabled={props.disabled || false} onClick={props.valida}>
                avanti
            </MDButton>
        </div>
    )
    const renderBottoniStepFinale = (
        <div className='contenitore-bottoni-destra' style={{justifyContent : props.allineaSinistra ? 'start' : 'end'}}>
            <MDButton variant='text' color='error' onClick={props.annulla}>
                annulla
            </MDButton>
            <MDButton variant='contained' color='dark' onClick={props.indietro}>
                indietro
            </MDButton>
            <MDButton variant='contained' color='success' disabled={props.disabled || false} onClick={props.valida}>
                salva
            </MDButton>
        </div>
    )
    const renderBottoniStandard = props.vista === 'output' ? renderottoniOutput : renderBottoniInput

    const renderBottoni = () => {
        switch (props.scelta) {
            case 'standard':
                return renderBottoniStandard
            case 'primoStep':
                return renderBottoniPrimoStep
            case 'stepCentrale':
                return renderBottoniStepCentrale
            case 'ultimoStep':
                return renderBottoniStepFinale
            default:
                return null
        }
    }

    return renderBottoni()
}

elementoBottoniForm.defaultProps = {
    scelta : 'standard',
    allineaSinistra : false
}