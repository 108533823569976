import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import React from 'react'
import { connect } from 'react-redux'
import PaginaVediEnte from './paginaVediEnte'
import PaginaVediImpresa from './paginaVediImpresa'
import PaginaVediConsumatore from './paginaVediConsumatore'
import ElementoCaricamento from 'componenti/elementoCaricamento'
import { profiloSuperAmministratore, profiloConsumatore, profiloImpresa, profiloEnte } from 'util'

export const paginaMioProfilo = (props) => {
    switch (props.profilo) {
        case profiloEnte.tipo:
            return <PaginaVediEnte isMioProfilo={true} isLanding={false} {...props} />
        case profiloImpresa.tipo:
            return <PaginaVediImpresa isMioProfilo={true} isLanding={false} {...props} />
        case profiloConsumatore.tipo:
            return <PaginaVediConsumatore isMioProfilo={true} isLanding={false} {...props} />

        default:
            return 'ciao'
            return <ElementoCaricamento />
    }
}

function mapStateToProps(state) {
    return {
        profilo: getProfiloUtenteLoggato(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaMioProfilo)
