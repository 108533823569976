import React, { useEffect, useState } from 'react'
import useCache from 'customHooks/useCache'
import { Grid } from '@mui/material'
import { InputSelect } from 'fields/fieldSelect'
import { InputText } from 'fields/fieldText'
import { isImpresaB2b } from 'getter/getterImpresa'

const modelNome = {
    id: 'nome',
    tipo: 'fieldText',
    associazione: 'nome',
    label: 'Nome:',
    value: '',
    details: {},
}
const modelLuogo = {
    id: 'luogo',
    tipo: 'fieldSelect',
    associazione: 'luogo',
    label: 'Comune:',
    value: '',
    details: {
        complex: false,
        scelte: [],
        svuota: true,
    },
}
const modelOrdinaPer = {
    id: 'ordinaPer',
    tipo: 'fieldSelect',
    associazione: 'ordinaPer',
    label: 'Ordina per:',
    value: '',
    details: {
        complex: true,
        toShow: 'testo',
        toSave: 'scelta',
        scelte: [
            { scelta: 0, testo: 'Alfabetico(A-Z)' },
            { scelta: 1, testo: 'Alfabetico(Z-A)' },
            { scelta: 2, testo: 'Percentuale donazione' },
            { scelta: 3, testo: 'Vicinanza' },
        ],
    },
}

export default function elementoFiltriImpresa(props) {
    const { listaImpreseWindow } = useCache()
    const [formLuogo, setFormLuogo] = useState(modelLuogo)
    const { nome, luogo, ordinaPer } = props.filtri

    const handleChange = (campo, valore) => props.aggiornaFiltri(Object.assign(props.filtri, { [campo]: valore }))

    useEffect(() => {
        setFormLuogo(
            Object.assign(modelLuogo, {
                details: {
                    svuota: true,
                    scelte: listaImpreseWindow.reduce((acc, impresa) => {
                        const sede = isImpresaB2b(impresa.id) ? 'sedeLegale' : 'puntoVendita'
                        const comune = impresa[sede].comune
                        if (!acc.includes(comune)) acc.push(comune)
                        return acc
                    }, []),
                },
            }),
        )
    }, [listaImpreseWindow])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
                <InputText value={nome} model={modelNome} errore='' azione={handleChange} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <InputSelect value={luogo} model={formLuogo} errore='' azione={handleChange} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <InputSelect value={ordinaPer} model={modelOrdinaPer} errore='' azione={handleChange} />
            </Grid>
        </Grid>
    )
}
