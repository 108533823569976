import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { MODIFICA_STATO_PROGETTO } from 'data/azioni/MODIFICA_STATO_PROGETTO'
import ElementoEtichettaForm from './elementoEtichettaForm'
import { Icon } from '@mui/material'
import PopUpInserisciImpatto from 'popUp/popUpInserisciImpatto'
import ElementoMostraInserimentoImpatto from './elementoMostraInserimentoImpatto'
import { getProgettoDaId } from 'getter/getterProgetti'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'
import { isProgettoInAttesa } from 'getter/getterProgetti'

export default function elementoValidaProgetto(props) {
    const [lista, setLista] = useState([])
    const [popUpAperto, setPopUpAperto] = useState(false)
    const [selezionato, setSelezionato] = useState({})
    const [isInAttesa, setIsInAttesa] = useState(false)

    useEffect(() => {
        const idProgetto = getIdProgettoSelezionato()
        const attesa = isProgettoInAttesa(idProgetto)
        setIsInAttesa(attesa)
        if (attesa) return
        const progetto = getProgettoDaId(idProgetto)
        setLista(progetto.impatto)
        //console.log('progetto : ', progetto)
    }, [])

    const handleClose = () => setPopUpAperto(false)

    const apriPopUp = (index) => {
        setSelezionato({
            index: index,
            dati: index === -1 ? {} : lista[index],
        })
        setPopUpAperto(true)
    }

    const nuovoElemento = (dati) => {
        var nuovaLista = [...lista]
        if (selezionato.index === -1) {
            nuovaLista.push(dati)
        }
        if (selezionato.index !== -1) {
            nuovaLista[selezionato.index] = Object.assign(dati, {isNuovo : false})
        }
        setLista(nuovaLista)
    }

    const elimina = (index) => {
        var nuovaLista = [...lista]
        nuovaLista.splice(index, 1)
        setLista(nuovaLista)
    }

    const salva = async () => {
        //console.log('listaaa : ', lista)
        await MODIFICA_STATO_PROGETTO(props.idProgetto, lista)
        props.annulla()
    }

    const isNascondiImmagini = () => {
        if (selezionato.index === undefined) return true
        if (selezionato.index === -1) return false
        if (selezionato.dati === undefined) return true
        if (selezionato.dati.isNuovo === undefined) return true
        return !selezionato.dati.isNuovo
    }

    return (
        <MDBox mx='auto' maxWidth='1000px' width='100%' bgColor='white' p={2} shadow='lg'>
            <PopUpInserisciImpatto nascondiImmagini={isNascondiImmagini()} aperto={popUpAperto} selezionato={selezionato} handleClose={handleClose} onValidato={nuovoElemento} />
            <ElementoEtichettaForm
                testo='Impatto progetto'
                secondoElemento={
                    <MDButton variant='outlined' color='dark' onClick={() => apriPopUp(-1)}>
                        <Icon sx={{ mr: 1 }} color='dark'>
                            add
                        </Icon>
                        aggiungi
                    </MDButton>
                }
            />
            {lista.map((elemento, index) => {
                const nascondi = elemento.isNuovo === undefined ? true : !elemento.isNuovo
                return <ElementoMostraInserimentoImpatto nascondiImmagini={nascondi} key={`impatto-${index}`} index={index} dati={elemento} elimina={elimina} modifica={apriPopUp} />
            })}
            <MDBox className='contenitore-bottoni-destra'>
                <MDButton variant='contained' color='error' onClick={props.annulla}>
                    annulla
                </MDButton>
                <MDButton variant='contained' color='success' disabled={lista.length === 0} onClick={salva}>
                    salva
                </MDButton>
            </MDBox>
        </MDBox>
    )
}
