import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { doc, onSnapshot } from 'firebase/firestore'
import { LEGGI_IMPRESA } from 'data/azioni/LEGGI_IMPRESA'

export class watcherImpresa extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        store.subscribe(() => {
            this.update()
        })
        this.update()
    }

    update() {
        const listaUrl = ['paginaInserisciSpesa', 'paginaVediImpresa']
        if (listaUrl.includes(store.getState().url[3])) {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    apriConnessione(nuovoId) {
        store.dispatch({
            type: 'SVUOTA_REDUCER_IMPRESA',
        })
        this.funzioneConnessione = onSnapshot(doc(db, 'utenti', nuovoId), (documento) => {
            LEGGI_IMPRESA(documento.data())
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}

