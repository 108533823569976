import { profiloConsumatore } from 'util'
import { getProfiloUtenteLoggato, getUtenteLoggato } from './getterUtenteLoggato'
import { store } from 'data/store'
import { getProgettoDaId } from './getterProgetti'
import { formattaDataDaFirebase } from 'data/strumenti'

export const getDatiConsumatore = () => {
    if (getProfiloUtenteLoggato() === profiloConsumatore.tipo) return getUtenteLoggato()
    return undefined
}

export const getListaAcquistiResponsabiliConsumatore = () => {
    const state = store.getState()
    return []
    var lista = state.reducerRichiesteAcquisti ?? []
    const acquisti =  lista.map((transazione) => {
        return {
            color: 'success',
            icon: 'expand_less',
            name: `Donazione ${getProgettoDaId(transazione.idProgetto).nome}`,
            description: formattaDataDaFirebase(transazione.data, true),
            value: `€ ${(transazione.totale / 100) * transazione.percentuale}`,
            id: transazione.id,
        }
    })
    return acquisti
}

export const getUltimiSetteAcquistiConsumatore = () =>{
    return getListaAcquistiResponsabiliConsumatore().filter((transazione, index) => {
        return index < 7
    })
}

export const getListaProgettiSostenutiConsumatore = () => {
    const consumatore = getDatiConsumatore()
    if (consumatore.sostegni === undefined) return []
    return Object.keys(consumatore.sostegni).map((idProgetto) => {
        const sostegno = consumatore.sostegni[idProgetto]
        const totale = Object.keys(sostegno.imprese).reduce((acc, idImpresa) => {
            acc += sostegno.imprese[idImpresa]
            return acc
        }, 0)
        return { idProgetto: idProgetto, totale, totale }
    })
}
