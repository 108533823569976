import { store } from 'data/store'
import { getUtenteLoggato } from './getterUtenteLoggato'
import { getImpresaDaId, getListaTuttiProgettiSupportatiImpresa } from './getterImpresa'
import { getImpattoProgettoDaId } from './getterProgetti'

export const getnetworkDaId = (id) => {
    const index = window.listaNetwork.findIndex((elemento) => elemento.id === id)
    if (index === -1) return {}
    return window.listaNetwork[index]
}

export const getIdNetworkSelezionato = () => {
    const state = store.getState()
    if (state.url[3] === 'paginaIlMioNetwork') return getUtenteLoggato().network || ''
    return state.url[4]
}

export const getDatiNetwork = (id) => {
    const idNetwork = id === undefined ? getIdNetworkSelezionato() : id
    return getnetworkDaId(idNetwork)
}

export const getMembriNetwork = (id) => {
    const network = getDatiNetwork(id)
    if (network.membri === undefined) return []
    var membri = network.membri.map((idImpresa) => getImpresaDaId(idImpresa))
    const index = membri.findIndex((impresa) => impresa.id === network.capoFila)
    if (index === 0) return membri
    const capoFila = Object.assign({}, membri[index])
    membri.splice(index, 1)
    membri.unshift(capoFila)
    return membri
}

export const getRichiesteInAttesaNetwork = () => {
    const network = getDatiNetwork()
    if (network.inviti === undefined) return []
    if (Object.keys(network.inviti).length === 0) return []
    var lista = []
    Object.keys(network.inviti).forEach((idImpresa) => {
        if (network.inviti[idImpresa] === 'inAttesa') lista.push(getImpresaDaId(idImpresa))
    })
    return lista
}
export const getRichiesteRifiutateNetwork = () => {
    const network = getDatiNetwork()
    if (network.inviti === undefined) return []
    if (Object.keys(network.inviti).length === 0) return []
    var lista = []
    Object.keys(network.inviti).forEach((idImpresa) => {
        if (network.inviti[idImpresa] === 'rifiutato') lista.push(getImpresaDaId(idImpresa))
    })
    return lista
}

export const getInfoNetwork = (id) => {
    const membri = getMembriNetwork(id)
    var progettiSostenuti = []
    var totale = 0
    membri.forEach((impresa) => {
        const progetti = getListaTuttiProgettiSupportatiImpresa(impresa.id)
        progetti.forEach((progetto) => {
            totale += progetto.totale
            const index = progettiSostenuti.findIndex((el) => el.idProgetto === progetto.idProgetto)
            if (index === -1) progettiSostenuti.push(progetto)
            if (index !== -1) progettiSostenuti[index].totale += progetto.totale
        })
    })
    var impatti = []
    progettiSostenuti.forEach((progetto) => {
        var impattiProgetto = [...getImpattoProgettoDaId(progetto.idProgetto)]
        impattiProgetto.forEach((impatto) => {
            impatto.valore = Number(((impatto.valore * progetto.totale) / impatto.budget).toFixed(2))
        })
        impatti = impatti.concat(impattiProgetto)
    })
    console.log('progettiSostenuti : ', progettiSostenuti);
    return { impatti: impatti, totaleSostenuto: totale, totaleProgetti: progettiSostenuti.length, progetti : progettiSostenuti }
}

export const getDatiCardNetwork = (id) => {
    const info = getInfoNetwork(id)
    const membri = getMembriNetwork(id)
    return {
        membri: membri.length,
        raccolte: info.totaleProgetti,
        sostegno: info.totaleSostenuto,
    }
}

export const getListaFiltrataNetwork = (filtri) => {
    const { nome, ordinaPer } = filtri
    var listaFiltrata = window.listaNetwork.filter((impresa) => {
        const comparazioneNome = () => {
            if (nome === '') return true
            let nomeCacheLowerCase = impresa.nome.toLowerCase()
            let nomeDaCercareLowerCase = nome.toLowerCase()
            return nomeCacheLowerCase.indexOf(nomeDaCercareLowerCase) !== -1
        }
        return comparazioneNome()
    })

    return listaFiltrata.sort((a, b) => {
        const alfabetico = () => {
            var valore = 0
            const nomeA = a.nome.trim().toUpperCase()
            const nomeB = b.nome.trim().toUpperCase()
            if (nomeA.toUpperCase() > nomeB.toUpperCase()) valore = 1
            if (nomeA.toUpperCase() < nomeB.toUpperCase()) valore = -1
            return valore
        }
        const alfabeticoInverso = () => {
            var valore = 0
            const nomeA = a.nome.trim().toUpperCase()
            const nomeB = b.nome.trim().toUpperCase()
            if (nomeA.toUpperCase() > nomeB.toUpperCase()) valore = -1
            if (nomeA.toUpperCase() < nomeB.toUpperCase()) valore = 1
            return valore
        }
        const datiCardA =  getDatiCardNetwork(a.id)
        const datiCardB =  getDatiCardNetwork(b.id)
        if (ordinaPer === 0) return alfabetico()
        if (ordinaPer === 1) return alfabeticoInverso()
        if (ordinaPer === 2) return datiCardB.membri - datiCardA.membri
        if (ordinaPer === 3) return datiCardB.sostegno - datiCardA.sostegno
    })
}
