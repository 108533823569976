import React from 'react'
import { connect } from 'react-redux'
import { CHIUDI_POP_UP } from 'data/azioni/CHIUDI_POP_UP'
import PopUpElimina from 'popUp/popUpElimina'
import PopUpModificheNonSalvate from 'popUp/popUpModificheNonSalvate'
import PopUpRichiediConferma from 'popUp/popUpRichiediConferma'
import PopUpRicaricaCredito from 'popUp/popUpRicaricaCredito'
import PopUpPermessoPosizione from 'popUp/popUpPermessoPosizione'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import PopUpCambiaCredenziali from 'popUp/popUpCambiaCredenziali'
import PopUpEliminaAccount from 'popUp/popUpEliminaAccount'
import { profiloImpresa } from 'util'

export const elementoMostraPopUp = (props) => {
    const chiudi = () => CHIUDI_POP_UP()

    const popUpImpresa = (
        <>
            <PopUpRicaricaCredito aperto={props.popUpAperto === 'ricaricaCredito'} parametro={props.parametro} chiudi={chiudi} />
        </>
    )

    return (
        <React.Fragment>
            <PopUpElimina aperto={props.popUpAperto === 'elimina'} parametro={props.parametro} chiudi={chiudi} />
            <PopUpRichiediConferma aperto={props.popUpAperto === 'richiediConferma'} parametro={props.parametro} chiudi={chiudi} />
            <PopUpPermessoPosizione aperto={props.popUpAperto === 'permessoPosizione'} parametro={props.parametro} chiudi={chiudi} />
            <PopUpCambiaCredenziali aperto={props.popUpAperto === 'credenziali'} parametro={props.parametro} chiudi={chiudi} />
            <PopUpEliminaAccount aperto={props.popUpAperto === 'eliminaAccount'} parametro={props.parametro} chiudi={chiudi} />
            <PopUpModificheNonSalvate />
            {props.profilo === profiloImpresa.tipo ? popUpImpresa : null}
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        popUpAperto: state.reducerPopUp.id,
        parametro: state.reducerPopUp.parametro,
        profilo: getProfiloUtenteLoggato(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoMostraPopUp)
