import React from 'react'
import { InputText, OutputText } from './fieldText'
import { InputSelect, OutputSelect } from './fieldSelect'
import { InputAutocomplete, OutputAutoComplete } from './fieldAutocomplete'
import { InputTextArea, OutputTextArea } from './fieldTextArea'
import { InputData, OutputData } from './fieldData'
import { InputSceltaSingola, OutputSceltaSingola } from './fieldSceltaSingola'
import { InputListaSemplice, OutputListaSemplice } from './fieldListaSemplice'
import { InputSubInput, OutputSubInput } from './fieldSubInput'
import { InputSlider, OutputSlider } from './fieldSlider'
import { InputList, OutputList } from './fieldList'
import { InputSceltaMultipla, OutputSceltaMultipla } from './fieldSceltaMultipla'
import { InputBoolean, OutputBoolean } from './fieldBoolean'
import { InputTraguardiProgetto, OutputTraguardiProgetto } from './fieldTraguardiProgetto'
import { InputSceltaMultiplaCondizionale, OutputSceltaMultiplaCondizionale } from './fieldSceltaMultiplaCondizionale'
import { InputAccettaTerminiECondizioni, OutputAccettaTerminiECondizioni } from './fieldAccettaTerminiECondizioni'

export default {
    fieldText: {
        input: (model, value, azione, errore) => {
            return <InputText key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputText skeleton={skeleton || false} key={model.id} model={model} value={value} />
        },
    },
    fieldSelect: {
        input: (model, value, azione, errore) => {
            return <InputSelect key={model.id} model={model} value={value === undefined ? '' : value} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSelect skeleton={skeleton || false} key={model.id} model={model} value={value === undefined ? '' : value} />
        },
    },
    fieldAutocomplete: {
        input: (model, value, azione, errore) => {
            return <InputAutocomplete key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputAutoComplete skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldTextArea: {
        input: (model, value, azione, errore) => {
            return <InputTextArea key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputTextArea skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldData: {
        input: (model, value, azione, errore) => {
            return <InputData key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputData skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSceltaSingola: {
        input: (model, value, azione, errore) => {
            return <InputSceltaSingola key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSceltaSingola skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSubInput: {
        input: (model, value, azione, errore) => {
            return <InputSubInput key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSubInput skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldListaSemplice: {
        input: (model, value, azione, errore) => {
            return <InputListaSemplice key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputListaSemplice skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSlider: {
        input: (model, value, azione, errore) => {
            return <InputSlider key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSlider skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldList: {
        input: (model, value, azione, errore) => {
            return <InputList key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputList skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldSceltaMultipla: {
        input: (model, value, azione, errore) => {
            return <InputSceltaMultipla key={model.id} model={model} value={value || ''} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSceltaMultipla skeleton={skeleton || false} key={model.id} model={model} value={value || ''} />
        },
    },
    fieldBoolean: {
        input: (model, value, azione, errore) => {
            return <InputBoolean key={model.id} model={model} value={value === undefined ? false : value} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputBoolean skeleton={skeleton || false} key={model.id} model={model} value={value === undefined ? false : value} />
        },
    },
    fieldTraguardiProgetto: {
        input: (model, value, azione, errore) => {
            return <InputTraguardiProgetto key={model.id} model={model} value={value} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputTraguardiProgetto skeleton={skeleton || false} key={model.id} model={model} value={value} />
        },
    },
    fieldSceltaMultiplaCondizionale: {
        input: (model, value, azione, errore) => {
            return <InputSceltaMultiplaCondizionale key={model.id} model={model} value={value} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputSceltaMultiplaCondizionale skeleton={skeleton || false} key={model.id} model={model} value={value} />
        },
    },
    fieldAccettaTerminiECondizioni: {
        input: (model, value, azione, errore) => {
            return <InputAccettaTerminiECondizioni key={model.id} model={model} value={value === undefined ? false : value} azione={azione} errore={errore} />
        },
        output: (model, value, skeleton) => {
            return <OutputAccettaTerminiECondizioni skeleton={skeleton || false} key={model.id} model={model} value={value === undefined ? false : value} />
        },
    },
}
