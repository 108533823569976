import { scelteTipoBusiness } from 'util'
import { welfareAziendale } from 'util'
import { ambientale } from 'util'
import { certificazioni } from 'util'
import { social_activity } from 'util'
import { listaProvince } from 'util'
import { settoriImprese } from 'util'
import { formeGiuridicheImprese } from 'util'

const subInputPuntoVendita = (testoBianco) => {
    return {
        parts: [
            {
                id: 'email',
                tipo: 'fieldText',
                associazione: 'email',
                label: 'Email contatto:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'comune',
                tipo: 'fieldText',
                associazione: 'comune',
                label: 'Comune:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'provincia',
                tipo: 'fieldSelect',
                associazione: 'provincia',
                label: 'Provincia:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    complex: true,
                    scelte: listaProvince,
                    toShow: 'nome',
                    toSave: 'nome',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'via',
                tipo: 'fieldText',
                associazione: 'via',
                label: 'Indirizzo :',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'numero',
                tipo: 'fieldText',
                associazione: 'numero',
                label: 'N:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
        ],
    }
}
const subInputSedeLegale = (testoBianco) => {
    return {
        parts: [
            {
                id: 'email',
                tipo: 'fieldText',
                associazione: 'email',
                label: 'Email contatto:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'comune',
                tipo: 'fieldText',
                associazione: 'comune',
                label: 'Comune:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'provincia',
                tipo: 'fieldSelect',
                associazione: 'provincia',
                label: 'Provincia:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    complex: true,
                    scelte: listaProvince,
                    toShow: 'nome',
                    toSave: 'nome',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'via',
                tipo: 'fieldText',
                associazione: 'via',
                label: 'Indirizzo :',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
            {
                id: 'numero',
                tipo: 'fieldText',
                associazione: 'numero',
                label: 'N:',
                value: '',
                details: {
                    grid: testoBianco ? { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } } : {},
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
                validazione: 'testoRichiesto',
            },
        ],
    }
}

export const formInfoBaseImpresaB2b = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome impresa:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'formaGiuridica',
            tipo: 'fieldSelect',
            associazione: 'formaGiuridica',
            label: 'Forma giuridica:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: formeGiuridicheImprese,
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'societaBenefit',
            tipo: 'fieldBoolean',
            associazione: 'societaBenefit',
            label: 'Società di benefit?',
            value: false,
        },
        {
            id: 'partitaIva',
            tipo: 'fieldText',
            associazione: 'partitaIva',
            label: 'Partita iva:',
            value: '',
            details: {
                inputProps: { maxLength: 11 },
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'settoreAppartenenza',
            tipo: 'fieldSelect',
            associazione: 'settoreAppartenenza',
            label: 'Settore di appartenenza:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: settoriImprese,
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'tipoBusiness',
            tipo: 'fieldSelect',
            associazione: 'tipoBusiness',
            label: 'Tipo di Business:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: scelteTipoBusiness,
            },
            validazione: 'testoRichiesto',
        },{
            id: 'breveDescrizione',
            tipo: 'fieldTextArea',
            associazione: 'breveDescrizione',
            label: 'La tua impresa in un tweet:',
            value: '',
            details: {
                inputProps: { maxLength: 240 },
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },

        {
            id: 'sedeLegale',
            tipo: 'fieldSubInput',
            associazione: 'sedeLegale',
            label: 'Sede legale:',
            value: {},
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 } },
                subModel: subInputSedeLegale(),
            },
        },
        {
            id: 'accettazioneTerminiECondizioni',
            tipo: 'fieldAccettaTerminiECondizioni',
            associazione: 'accettazioneTerminiECondizioni',
            label: 'Accetto termini e condizioni:',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
                testoLink: 'scopri di piu',
                //link: 'terminiECondizioni.html',
            },
            validazione: 'controllaSeVero',
        }
    ],
}
export const formInfoBaseImpresa = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome impresa:',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'formaGiuridica',
            tipo: 'fieldSelect',
            associazione: 'formaGiuridica',
            label: 'Forma giuridica:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: formeGiuridicheImprese,
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'societaBenefit',
            tipo: 'fieldBoolean',
            associazione: 'societaBenefit',
            label: 'Società di benefit?',
            value: false,
        },
        {
            id: 'partitaIva',
            tipo: 'fieldText',
            associazione: 'partitaIva',
            label: 'Partita iva:',
            value: '',
            details: {
                inputProps: { maxLength: 11 },
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'settoreAppartenenza',
            tipo: 'fieldSelect',
            associazione: 'settoreAppartenenza',
            label: 'Settore di appartenenza:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: settoriImprese,
            },
            validazione: 'testoRichiesto',
        },
        
        {
            id: 'tipoBusiness',
            tipo: 'fieldSelect',
            associazione: 'tipoBusiness',
            label: 'Tipo di Business:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: scelteTipoBusiness,
            },
            validazione: 'testoRichiesto',
        },{
            id: 'breveDescrizione',
            tipo: 'fieldTextArea',
            associazione: 'breveDescrizione',
            label: 'La tua impresa in un tweet:',
            value: '',
            details: {
                inputProps: { maxLength: 240 },
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },

        {
            id: 'sedeLegale',
            tipo: 'fieldSubInput',
            associazione: 'sedeLegale',
            label: 'Sede legale:',
            value: {},
            details: {
                grid: { lg: { input: 4, output: 4 } },
                subModel: subInputSedeLegale(),
            },
        },
        {
            id: 'puntoVendita',
            tipo: 'fieldSubInput',
            associazione: 'puntoVendita',
            label: 'Punto vendita:',
            value: {},
            details: {
                grid: { lg: { input: 4, output: 4 } },
                subModel: subInputPuntoVendita(),
            },
        },
        {
            id: 'accettazioneTerminiECondizioni',
            tipo: 'fieldAccettaTerminiECondizioni',
            associazione: 'accettazioneTerminiECondizioni',
            label: 'Accetto termini e condizioni:',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
                testoLink: 'scopri di piu',
                //link: 'terminiECondizioni.html',
            },
            validazione: 'controllaSeVero',
        }
    ],
}
export const formInfoAggiuntiveImpresa = {
    parts: [
        {
            id: 'numeroDipendenti',
            tipo: 'fieldText',
            associazione: 'numeroDipendenti',
            label: 'N° dipendenti:',
            value: '',
            details: {
                type: 'number',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'descrizione',
            tipo: 'fieldTextArea',
            associazione: 'descrizione',
            label: 'Descrivi la tua impresa ai tuoi clienti e stakeholder :',
            value: '',
            details: {
                type: 'text',
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'mission',
            tipo: 'fieldTextArea',
            associazione: 'mission',
            label: 'Mission:',
            value: '',
            details: {
                placeHolder: 'Es. La nostra missione è trasformare le donazioni in un potente strumento per il cambiamento, promuovendo la responsabilità sociale delle imprese e creando sinergie con il Terzo Settore.',
                inputProps: { maxLength: 280 },
            },
            validazione: 'testoRichiesto',
        },

        /* {
            id: 'vision',
            tipo: 'fieldTextArea',
            associazione: 'vision',
            label: 'Vision:',
            value: '',
            validazione: 'testoRichiesto',
        }, */
    ],
}
export const formAttivitaImpresa = {
    parts: [
        {
            id: 'welfare',
            tipo: 'fieldSceltaMultiplaCondizionale',
            associazione: 'welfare',
            label: 'L’impresa fa attività di welfare aziendale ?',
            value: [{ scelta: true, lista: [] }],
            details: {
                colorLabel : 'primary',
                weightLabel : 'medium',
                complex: false,
                scelte: welfareAziendale,
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
            },
        },
        {
            id: 'attivitaResponsabilitaSociale',
            tipo: 'fieldSceltaMultiplaCondizionale',
            associazione: 'attivitaResponsabilitaSociale',
            label: 'L’impresa fa attività di Responsabilità Sociale ?',
            value: [{ scelta: true, lista: [] }],
            details: {
                colorLabel : 'primary',
                weightLabel : 'medium',
                complex: false,
                scelte: social_activity,
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
            },
        },
        {
            id: 'attivitaResponsabilitaAmbientale',
            tipo: 'fieldSceltaMultiplaCondizionale',
            associazione: 'attivitaResponsabilitaAmbientale',
            label: 'L’impresa fa attività di Sostenibilità ambientale ?',
            value: [{ scelta: true, lista: [] }],
            details: {
                colorLabel : 'primary',
                weightLabel : 'medium',
                complex: false,
                scelte: ambientale,
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
            },
        },
    ],
}
export const formDocumentiAllegatiImpresa = {
    parts: [
        {
            id: 'certificazioniQualitaSostenibilita',
            tipo: 'fieldSceltaMultiplaCondizionale',
            associazione: 'certificazioniQualitaSostenibilita',
            label: 'Certificazioni di qualità o sostenibilità',
            value: [{ scelta: true, lista: [] }],
            details: {
                colorLabel : 'primary',
                weightLabel : 'medium',
                complex: false,
                scelte: certificazioni,
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
            },
        },
        /* {
            id: 'codiceEtico',
            tipo: 'fieldBoolean',
            associazione: 'codiceEtico',
            label: 'L’impresa ha un Codice Etico ?',
            value: false,
        },
        {
            id: 'bilancioSociale',
            tipo: 'fieldBoolean',
            associazione: 'bilancioSociale',
            label: 'L’impresa ha un Bilancio Sociale ?',
            value: false,
        },
        {
            id: 'ratingESG',
            tipo: 'fieldBoolean',
            associazione: 'ratingESG',
            label: 'L’impresa ha il rating ESG ?',
            value: false,
        }, */
    ],
}
export const formRiassuntoImpresa = {
    parts: [
        {
            id: 'settoreAppartenenza',
            tipo: 'fieldSelect',
            associazione: 'settoreAppartenenza',
            label: 'Settore di appartenenza:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: settoriImprese,
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'descrizione',
            tipo: 'fieldTextArea',
            associazione: 'descrizione',
            label: 'Descrivi la tua impresa ai tuoi clienti e stakeholder :',
            value: '',
            details: {
                type: 'text',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'mission',
            tipo: 'fieldTextArea',
            associazione: 'mission',
            label: 'Mission:',
            value: '',
            details: {
                placeHolder: 'Es. La nostra missione è trasformare le donazioni in un potente strumento per il cambiamento, promuovendo la responsabilità sociale delle imprese e creando sinergie con il Terzo Settore.',
                inputProps: { maxLength: 280 },
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        /*{
            id: 'impegno',
            tipo: 'fieldTextArea',
            associazione: 'impegno',
            label: 'Come destinerai la % in donazione sulle vendite? :',
            value: '',
            details: {
                placeHolder: 'Es. su tutti i prodotti, su aperitivo, su articolo x...',
                inputProps: { maxLength: 50 },
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },*/
        
        {
            id: 'numeroDipendenti',
            tipo: 'fieldText',
            associazione: 'numeroDipendenti',
            label: 'N° dipendenti:',
            value: '',
            details: {
                type: 'number',
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
    ],
}

export const formContattiImpresa = (isB2b, testoBianco) => {
    return {
        parts: [
            {
                id: isB2b ? 'sedeLegale' : 'puntoVendita',
                tipo: 'fieldSubInput',
                associazione: isB2b ? 'sedeLegale' : 'puntoVendita',
                label: isB2b ? 'Sede legale:' : 'Punto vendita:',
                value: {},
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
                    subModel: isB2b ? subInputSedeLegale(true) : subInputPuntoVendita(true),
                    allineaCentro: testoBianco,
                },
            },
            {
                id: 'partitaIva',
                tipo: 'fieldText',
                associazione: 'partitaIva',
                label: 'Partita iva:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                    inputProps: { maxLength: 11 },
                },
                validazione: 'testoRichiesto',
            },
        ],
    }
}
export const formContattiiLinkmpresa = ( testoBianco) => {
    return {
        parts: [
          
            {
                id: 'sitoWeb',
                tipo: 'fieldText',
                associazione: 'sitoWeb',
                label: 'Url sito web:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
            },
            {
                id: 'telefono',
                tipo: 'fieldText',
                associazione: 'telefono',
                label: 'Telefono :',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
            },
            {
                id: 'linkedin',
                tipo: 'fieldText',
                associazione: 'linkedin',
                label: 'Linkedin:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
            },
            {
                id: 'facebook',
                tipo: 'fieldText',
                associazione: 'facebook',
                label: 'Facebook:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
            },
            {
                id: 'instagram',
                tipo: 'fieldText',
                associazione: 'instagram',
                label: 'Instagram:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
            },
            {
                id: 'youtube',
                tipo: 'fieldText',
                associazione: 'youtube',
                label: 'Youtube:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
            },
            {
                id: 'twitter',
                tipo: 'fieldText',
                associazione: 'twitter',
                label: 'Twitter:',
                value: '',
                details: {
                    grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
                    type: 'text',
                    isLink: true,
                    textColor: testoBianco ? 'white' : undefined,
                    allineaCentro: testoBianco,
                },
            },
        ],
    }
}
