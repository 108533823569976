import React, { useEffect, useState } from 'react'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import MDBox from 'components/MDBox'
import useCache from 'customHooks/useCache'
import ElementoListaNetwork from 'componenti/elementoListaNetwork'
import { getListaFiltrataNetwork } from 'getter/getterNetwork'
import ElementoFiltriNetwork from 'componenti/elementoFiltriNetwork'

export default function paginaNetwork(props) {
    const { listaNetworkWindow } = useCache()
    const [filtri, setFiltri] = useState({ nome: '', ordinaPer: 0 })
    const [listaFiltrata, setListaFiltrata] = useState(listaNetworkWindow)

    const handleFilri = (nuoviFiltri) => setFiltri(Object.assign({}, nuoviFiltri))

    useEffect(() => {
        setListaFiltrata(getListaFiltrataNetwork(filtri))
    }, [filtri, listaNetworkWindow])

    const renderPagina = (
        <MDBox display='flex' flexDirection='column' width='100%' boxSizing='border-box'>
            <MDBox bgColor='white' shadow='lg' norderRadius='lg' p={3} mb={3}>
                <MDBox mx='auto' maxWidth='1000px' width='100%'>
                    <ElementoFiltriNetwork filtri={filtri} aggiornaFiltri={handleFilri} />
                </MDBox>
            </MDBox>
            <MDBox width='100%' mx='auto' maxWidth='1200px'>
                <ElementoListaNetwork {...props} lista={listaFiltrata} testoErrore='Nessun network presente in archivio' />
            </MDBox>
        </MDBox>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}
