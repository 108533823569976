import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { doc, updateDoc } from 'firebase/firestore'

export const MODIFICA_DATI_UTENTE_LOGGATO = async (dati) => {
    const id = getIdUtenteLoggato()
    console.log('modificooooooo : ', dati)
    try {
        await updateDoc(doc(db, 'utenti', id), dati)
    } catch (error) {
        console.log('errore ; ', errore)
    }
    console.log('modificatoooooo')
    return
}
