import React, { useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Grid } from '@mui/material'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import MDButton from 'components/MDButton'
import ElementoFeedbackUtente from './elementoFeedbackUtente'

export default function elementoDonazione(props) {
    const [value, setValue] = useState(props.modalita.start || props.modalita.min)
    const [errore, setErrore] = useState('')
    const [finito, setFinito] = useState(false)
    const handleValue = (nuovo) => {
        var valore = nuovo
        if (props.modalita?.max !== undefined && nuovo > props.modalita.max) valore = props.modalita.max
        if (props.modalita?.min !== undefined && nuovo < props.modalita.min) valore = props.modalita.min
        setValue(Number(valore))
    }
    const aggiungi = () => handleValue(value + 0.5)
    const sottrai = () => handleValue(value - 0.5)

    const annulla = () => {
        setValue(1)
        props.handleScelta(null)
    }

    const erroreCifreDecimali = (valore) => {
        const numStr = valore.toString()
        const decimalIndex = numStr.indexOf('.')
        if (decimalIndex === -1) {
            return false
        }
        const decimalPlaces = numStr.length - decimalIndex - 1
        return decimalPlaces > 2
    }

    const salva = async () => {
        setErrore("")
        const errore = erroreCifreDecimali(value)
        if(errore) {
            setErrore('Non possono esserci piu di due cifre decimali')
            return
        }
        const test = 10000
        if(props.modalita.max < value) {
            setErrore('Crediti insufficienti per effettuare questa donazione')
            return
        }
        await props.modalita.azione(value)
        setFinito(true)
        //annulla()
    }

    const renderLista = props.modalita?.info?.map((elemento) => {
        return (
            <MDTypography color='white' key={elemento}>
                {elemento}
            </MDTypography>
        )
    })

    if (finito) return <ElementoFeedbackUtente testo={props.modalita.testoFine} testoBottone={'Ok'} azione={annulla} />

    return (
        <MDBox p={2} mx='auto' bgColor='primary' variant='gradient' borderRadius='lg' shadow='lg' className='flex-verticale' sx={{ maxWidth: '1000px', minHeight: '440px', justifyContent: 'space-around' }}>
            <MDBox color='primary' height='140px' sx={{ maxWidth: '320px', maxHeight: '140px' }} mx='auto'>
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <MDBox p={2} className='flex-verticale' bgColor='white' borderRadius='lg'>
                            <MDBox className='flex-orizzontale'>
                                <input type='number' className='input-donazione' min={`${props.modalita?.min || 1}`} max={`${props.modalita?.max || 10000000}`} value={value} onChange={(event) => handleValue(event.target.value)}></input>
                                <MDBox className='centrato' p={2} color='info' width='max-content' height='100%'>
                                    <MDTypography variant='h2' color='dark'>
                                        {props.modalita.simbolo}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                            <MDTypography variant='subtitle2' align='center' color='error'>
                                {errore}
                            </MDTypography>
                        </MDBox>
                    </Grid>
                    {props.modalita.tipo === 'percentuale' ? (
                        <Grid container item xs={3} spacing={1}>
                            <Grid item xs={12}>
                                <MDBox onClick={aggiungi} borderRadius='lg' height='100%' width='100%' sx={{ transform: 'rotate(90deg)', cursor: 'pointer' }} className='centrato' p={2}>
                                    <ArrowBackIosRoundedIcon color='white' />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12}>
                                <MDBox onClick={sottrai} borderRadius='lg' height='100%' width='100%' sx={{ transform: 'rotate(-90deg)', cursor: 'pointer' }} className='centrato' p={2}>
                                    <ArrowBackIosRoundedIcon color='white' />
                                </MDBox>
                            </Grid>
                        </Grid>
                    ) : null}
                </Grid>
            </MDBox>
            {renderLista}
            <MDBox className='centrato'>
                {/* <MDBox sx={{ cursor: 'pointer' }} p={2} mr={2} bgColor='white' borderRadius='lg' onClick={annulla}> */}
                <MDButton sx={{ mr: 2 }} color='white' variant='contained' onClick={annulla}>
                    annulla
                </MDButton>
                {/* <MDTypography color='primary'>Annulla</MDTypography> */}
                {/* </MDBox> */}
                {/*  <MDBox sx={{ cursor: 'pointer' }} p={2} bgColor='white' borderRadius='lg' onClick={() => salva()}> */}
                <MDButton color='white' variant='contained' onClick={() => salva()}>
                    Sostieni il progetto
                </MDButton>
                {/* <MDTypography color='primary'>Inizia a sostenere il progetto</MDTypography> */}
                {/* </MDBox> */}
            </MDBox>
        </MDBox>
    )
}
