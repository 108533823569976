import React from 'react'
import { connect } from 'react-redux'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export const elementoSpinner = (props) => {
    return (
        <React.Fragment>
            <Backdrop open={props.aperto} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }} />
            {props.aperto ? <CircularProgress color='dark' size='4rem' sx={{ zIndex: (theme) => theme.zIndex.modal + 1, position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%)' }} /> : ''}
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        aperto: state.spinner || false,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoSpinner)
