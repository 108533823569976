import React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import ElementoRiquadroPagine from './elementoRiquadroPagine'
import { Divider } from '@mui/material'

export default function elementoFeedbackUtente(props) {
    return (
        <ElementoRiquadroPagine propsBox={{ mx: 'auto', maxWidth: '330px', borderRadius: 'lg', shadow: 'lg', mt: props.mt, bgColor: 'success' }}>
            <MDBox m='auto' className='centrato' sx={{ width: '150px', height: '150px' }}>
                <CheckCircleOutlineRoundedIcon color='white' sx={{ height: '100%', width: '100%' }} />
            </MDBox>
            <Divider light />
            <MDTypography color='white' align='center' sx={{ mb: 2 }}>
                {props.testo}
            </MDTypography>
            <MDBox className='centrato' m='auto' p={1} color='white' sx={{ height: '55px', borderRadius: '27.5px', border: 'solid', cursor: 'pointer' }} onClick={props.azione}>
                <MDTypography color='white' align='center' variant='subtitle1'>
                    {props.testoBottone}
                </MDTypography>
            </MDBox>
        </ElementoRiquadroPagine>
    )
}

elementoFeedbackUtente.defaultProps = {
    testo: '',
    testoBottone: '',
    mt : { xs: 7, md: 20 },
    azione: () => {},
}
