import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import ElementoMostraImpresa from 'componenti/elementoMostraImpresa'
import { getDistanzaImpresa } from 'getter/getterImpresa'

export default function elementoListaImprese(props) {
    const [permesso, setPermesso] = useState(window.permessoPosizione)
    const [posizione, setPosizione] = useState({})

    useEffect(() => {
        if (permesso) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    setPosizione(position)
                },
                function (error) {
                    setPosizione({})
                },
            )
        }
    }, [permesso])

    useEffect(() => {
        const handleWindowValueChange = () => {
            setPermesso(window.permessoPosizione)
        }

        window.addEventListener('permessoPosizione', handleWindowValueChange)

        return () => {
            window.removeEventListener('permessoPosizione', handleWindowValueChange)
        }
    }, [])

    const getDistanza = (impresa) => {
        const coords = posizione.coords
        if (!permesso || coords === undefined) {
            return ''
        }
        const distanza = Number(getDistanzaImpresa(impresa.location.lat, impresa.location.lng, posizione.coords.latitude, posizione.coords.longitude)).toFixed(2)
        return `${distanza} km da te`
    }

    const renderLista = (
        <Grid container maxWidth='1200px' rowGap={{xs : 2, sm : 0}} spacing={{xs : 0, sm : 2}}>
            {props.lista.map((impresa) => {
                return (
                    <Grid item xs={12} sm={6} lg={4} xxl={4} key={impresa.id}>
                        <ElementoMostraImpresa impresa={impresa} {...props} distanza={getDistanza(impresa)} />
                    </Grid>
                )
            })}
        </Grid>
    )

    const renderErrore = (
        <MDBox width='100%' bgColor='white' p={2} shadow='lg' borderRadius='lg'>
            <MDTypography color='text'>{props.testoErrore}</MDTypography>
        </MDBox>
    )

    return props.lista.length === 0 ? renderErrore : renderLista
}

elementoListaImprese.defaultProps = {
    lista: [],
    testoErrore: '',
}
