import React, { useEffect, useState } from 'react'
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import bgImage from 'assets/images/bg-etiqua-login.jpg'
import { InputText } from 'fields/fieldText'
import { Card } from '@mui/material'
import { listaProfiliApp, profiloConsumatore, profiloImpresa, profiloEnte } from 'util'
import useStepperOrizzontale from 'customHooks/useStepperOrizzontale'
import { InputSceltaSingola } from 'fields/fieldSceltaSingola'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import { formInfoBaseImpresaB2b, formInfoBaseImpresa } from 'formModel/formImpresaResponsabile'
import { formConsumatoreResponsabile } from 'formModel/formConsumatoreResponsabile'
import useForm from 'customHooks/useForm'
import { formCredenziali } from 'formModel/formCredenziali'
import { httpsCallable } from 'firebase/functions'
import { newId } from 'data/strumenti'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import ElementoNavigazionePreLogin from 'componenti/elementoNavigazionePreLogin'
import { formInfoBaseEnte } from 'formModel/formEnteNonProfit'
import { sendEmailVerification } from 'firebase/auth'

export default function paginaRegistrati(props) {
    const modelText = {
        id: 'immagine',
        tipo: 'fieldSText',
        associazione: 'immagine',
        label: 'Poi mettiamo le immagini:',
        value: '',
    }
    const modelSceltaProfilo = {
        id: 'profilo',
        tipo: 'fieldSceltaSingola',
        associazione: 'profilo',
        details: {
            scelte: listaProfiliApp,
            complex: true,
            toShow: 'label',
            toSave: 'tipo',
        },
        label: 'Tipo profilo:',
        label: '',
        value: '',
    }

    const abbandonaPagina = () => props.navigate('/login')
    const [profilo, setProfilo] = useState(profiloConsumatore.tipo)
    const [immagine, setImmagine] = useState('Vai avanti')
    const [isB2b, setIsB2b] = useState(false)
    const [dati, setDati] = useState({})
    const stepsImmagini = ['Tipo', 'Immagine', 'Dati', 'Credenziali']
    const stepsNoImmagini = ['Tipo', 'Dati', 'Credenziali']
    const [formDati, setFormDati] = useState({ parts: [] })
    const { renderStepper, step, avanti, steps, indietro, setSteps, isUltimoStep } = useStepperOrizzontale(stepsNoImmagini)
    const [statoDati, azioniDati, renderFormDati] = useForm(formDati, dati, false, true)
    const [statoCredenziali, azioniCredenziali, renderFormCredenziali] = useForm(formCredenziali, undefined, false, true)

    const handleProfilo = (campo, valore) => setProfilo(valore)
    const handleImmagine = (campo, valore) => setImmagine(valore)

    const validaCredenziali = async () => {
        await azioniCredenziali.validaForm()

        const utente = Object.assign(statoDati.statoForm, { profilo: profilo })
        const id = newId()

        const registrati = httpsCallable(functions, 'registrati')
        MODIFICA_STATO_SPINNER(true)
        const result = await registrati({ id: id, credenziali: statoCredenziali.statoForm, datiUtente: utente })
        console.log('result : ', result)
        const auth = getAuth()
        await signInWithEmailAndPassword(auth, statoCredenziali.statoForm.email, statoCredenziali.statoForm.password)
        auth.languageCode = 'it'
        await sendEmailVerification(auth.currentUser)
        MODIFICA_STATO_SPINNER(false)
        return

        //azione login
    }
    const validaDati = async () => {
        await azioniDati.validaForm()
        avanti()
    }

    useEffect(() => {
        setDati(statoDati.statoForm)
        if (isB2b && profilo === profiloImpresa.tipo) setFormDati(formInfoBaseImpresaB2b)
        if (!isB2b && profilo === profiloImpresa.tipo) setFormDati(formInfoBaseImpresa)
    }, [isB2b])

    useEffect(() => {
        setIsB2b(statoDati.statoForm.tipoBusiness === 'B2B (vendita ad altre imprese)')
    }, [statoDati])

    useEffect(() => {
        //if (profilo === profiloConsumatore.tipo) setSteps(stepsNoImmagini)
        //if (profilo !== profiloConsumatore.tipo && steps.length === 3) setSteps(stepsImmagini)
        if (profilo === profiloConsumatore.tipo) setFormDati(formConsumatoreResponsabile)
        if (profilo === profiloImpresa.tipo) setFormDati(formInfoBaseImpresa)
        if (profilo === profiloEnte.tipo) setFormDati(formInfoBaseEnte)
    }, [profilo])

    useEffect(() => {
        azioniDati.setForm(formDati)
    }, [formDati])

    const renderBottoni = () => {
        const isVediImmagine = steps.length === 4
        switch (step) {
            case 0:
                return <ElementoBottoniForm scelta='primoStep' valida={avanti} annulla={abbandonaPagina} />
            case 1:
                return <ElementoBottoniForm scelta='stepCentrale' valida={isVediImmagine ? avanti : validaDati} indietro={indietro} annulla={abbandonaPagina} />
            case 2:
                return <ElementoBottoniForm scelta={isUltimoStep() ? 'ultimoStep' : 'stepCentrale'} valida={isVediImmagine ? validaDati : validaCredenziali} indietro={indietro} annulla={abbandonaPagina} />
            case 3:
                return <ElementoBottoniForm scelta='ultimoStep' valida={validaCredenziali} indietro={indietro} annulla={abbandonaPagina} />
            default:
                break
        }
    }

    const renderSceltaProfilo = <InputSceltaSingola value={profilo} model={modelSceltaProfilo} errore={''} azione={handleProfilo} />
    const renderSceltaImmagine = <InputText value={immagine} model={modelText} errore={''} azione={handleImmagine} />

    const renderInserisciCredenziali = (
        <MDBox className='flex-verticale'>
            <MDTypography align='center' color='dark' variant='h5'>
                credenziali utente
            </MDTypography>
            <MDBox pt={2}>{renderFormCredenziali}</MDBox>
        </MDBox>
    )

    const renderStep = () => {
        switch (step) {
            case 0:
                return renderSceltaProfilo
            case 1:
                return steps.length === 4 ? renderSceltaImmagine : renderFormDati
            case 2:
                return steps.length === 4 ? renderFormDati : renderInserisciCredenziali
            case 3:
                return renderInserisciCredenziali

            default:
                return null
        }
    }

    return (
        <>
            <ElementoNavigazionePreLogin transparent={true} />
            <BasicLayout image={bgImage}>
                <Card sx={{ p: 2 }}>
                    <MDBox variant='gradient' bgColor='info' borderRadius='lg' coloredShadow='info' mx={2} mt={-3} p={2} mb={1} textAlign='center'>
                        <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
                            Registrati
                        </MDTypography>
                        <MDTypography variant='caption' fontWeight='medium' color='white' mt={1}>
                            Compila tutti i campi per registrarti su Etiqua
                        </MDTypography>
                    </MDBox>
                    <MDBox sx={{ mt: 2 }}>{renderStepper}</MDBox>
                    <MDBox sx={{ mt: 1, mb: 1, pl: 2, pr: 2 }}>{renderStep()}</MDBox>
                    <MDBox>{renderBottoni()}</MDBox>
                </Card>
            </BasicLayout>
        </>
    )
}
