import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './firebaseConfig'
import './App.css'
import { userWatcher } from './data/watchers/userWatcher'
import { watcherCacheProgetti } from 'data/watchers/watcherCacheProgetti'
import { watcherCacheEnti } from 'data/watchers/watcherCacheEnti'
import { watcherCacheImprese } from 'data/watchers/watcherCacheImprese'
import { watcherProgetto } from 'data/watchers/watcherProgetto'
import { watcherEnte } from 'data/watchers/watcherEnte'
import { watchercacheNetwork } from 'data/watchers/watcherCacheNetwork'
import { watcherImpresa } from 'data/watchers/watcherImpresa'
import { inizializzatore } from 'inizializzatori/inizializzatore'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import themeRTL from 'assets/theme/theme-rtl'
import ElementoSpinner from './componenti/elementoSpinner'
import { getUtenteLoggato } from './getter/getterUtenteLoggato'
import ElementoCaricamento from 'componenti/elementoCaricamento'
/* import RoutingLanding from 'landing/routingLanding' */
import ElementoMostraPopUp from 'componenti/elementoMostraPopUp'
import LayoutApp from './layoutApp'
import Routing from 'routing'
import { useLocation } from 'react-router-dom'
import { store } from 'data/store'
import ElmentoUtenteNonVerificato from 'componenti/elmentoUtenteNonVerificato'

export const App = (props) => {
    const [inizializzatoreApp, setInizializzatoreApp] = useState(null)
    const { pathname } = useLocation()

    useEffect(
        (e) => {
            store.dispatch({
                type: 'CAMBIA_URL',
                payload: window.location.href.split('/'),
            })
            document.documentElement.scrollTop = 0
            document.scrollingElement.scrollTop = 0
        },
        [pathname],
    )

    useEffect(() => {
        setInizializzatoreApp(new inizializzatore())
        new userWatcher()
        new watcherCacheProgetti()
        new watcherCacheEnti()
        new watcherCacheImprese()
        new watcherProgetto()
        new watcherEnte()
        new watcherImpresa()
        new watchercacheNetwork()
    }, [])

    useEffect(() => {
        if (props.logout) inizializzatoreApp.logOut()
    }, [props.logout])

    useEffect(() => {
        if (inizializzatoreApp === null) return
        inizializzatoreApp.aggiorna(props.utente)
    }, [props.utente])


    switch (props.stato) {
        case 1:
            return <ElementoCaricamento />
        case 2:
            return (
                <ThemeProvider theme={themeRTL}>
                    <CssBaseline />
                    <ElementoMostraPopUp />
                    <ElementoSpinner />
                    <Routing />
                </ThemeProvider>
            )

        case 3:
            return <ElementoCaricamento />
        case 4:
            return <LayoutApp />
        case 5:
            return (
                <ThemeProvider theme={themeRTL}>
                    <CssBaseline />
                    <ElmentoUtenteNonVerificato />
                    <ElementoSpinner />
                </ThemeProvider>
            )
        default:
            return <ElementoCaricamento />
    }
}

function mapStateToProps(state) {
    return {
        stato: state.statoUtente.stato,
        utente: getUtenteLoggato(),
        logout: state.storico[0] === 'LOG_OUT',
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(App)
