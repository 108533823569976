import React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Divider, Grid } from '@mui/material'

export default function elementoEtichettaForm(props) {
    const style = {
        width: '1005',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
    }

    return (
        <MDBox display='flex' flexDirection='column' boxSizing='border-box' pl={0} pr={1} /* pb={3} */ sx={{ width: '100%', justifyContent: 'space-between' }}>
            <Grid container>
                <Grid item sx={12} lg='auto'>
                    <MDBox sx={{display : 'flex', alignItems : 'center',  height : '100%'}}>
                        <MDTypography align={props.align} color={props.colorTesto} fontWeight='bold' variant={props.variant}>
                            {props.testo}
                        </MDTypography>
                    </MDBox>
                </Grid>
                <Grid item sx={12} lg={true}>
                    <MDBox ml={2} className='centrato' sx={{ justifyContent: 'start' }}>
                        {props.secondoElemento}
                    </MDBox>
                </Grid>
            </Grid>

            <MDBox sx={style} mt={1}>
                {props.elementoSotto}
            </MDBox>
            {/* <Divider light={props.lightDivider} /> */}
        </MDBox>
    )
}

elementoEtichettaForm.defaultProps = {
    testo: '',
    secondoElemento: null,
    colorTesto: 'dark',
    lightDivider: false,
    elementoSotto: null,
    align: 'start',
    variant: 'h3',
}
