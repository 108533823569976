import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Divider } from '@mui/material'
import { getDatiCardNetwork } from 'getter/getterNetwork'

export default function elementoMostraNetwork(props) {
    const [dati, setDati] = useState({ membri: 0, raccolte: 0, sostegno: 0 })

    const vediNetwork = () => props.navigate(`/paginaVediNetwork/${props.network.id}`)

    useEffect(()=>{
        const nuoviDati = getDatiCardNetwork(props.network.id)
        setDati(nuoviDati)
    },[props.network])

    return (
        <MDBox bgColor='white' p={2} onClick={vediNetwork} sx={{ cursor: 'pointer', width: '100%', height: '100%', /* border: '0.2px solid', */ color: '#d2d6da' }} shadow='lg' /* bgColor='info' */>
            <MDBox sx={{ display: 'grid', height: '100%', width: '100%', gridTemplateColumns: '100%', gridTemplateRows: '1fr max-content' }}>
                <MDBox className='flex-verticale'>
                    <MDTypography color='dark' variant='h4'>
                        {props.network.nome}
                    </MDTypography>
                    <MDTypography variant='subtitle2' color='text'>
                        {props.network.areaGeografica}
                    </MDTypography>
                </MDBox>
                <MDBox className='flex-verticale'>
                    <Divider />
                    <MDTypography color='dark'>Membri : {dati.membri}</MDTypography>
                    <MDTypography color='dark'>Raccolte sostenute : {dati.raccolte}</MDTypography>
                    <MDTypography color='dark'>Sostegno totale : {dati.sostegno} €</MDTypography>
                </MDBox>
            </MDBox>
        </MDBox>
    )
}
