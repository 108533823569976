import { doc, setDoc } from 'firebase/firestore'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'

export const CREA_PROGETTO = async (dati, id) => {
    dati.idEnte = getIdUtenteLoggato()
    dati.comune = getUtenteLoggato().sedeLegale.comune
    MODIFICA_STATO_SPINNER(true)
    await setDoc(doc(db, 'progetti', id), dati)
    MODIFICA_STATO_SPINNER(false)
    return
}
