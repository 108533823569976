import React, { useEffect, useState } from 'react'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import ElementoStatistica from 'componenti/elementoStatistica'
import { getCreditiImpresa } from 'getter/getterImpresa'
import { getTotaleDonazioniLiberaliImpresa } from 'getter/getterImpresa'
import { getTotaleSostegniImpresa } from 'getter/getterImpresa'
import PercentRoundedIcon from '@mui/icons-material/PercentRounded'
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded'
import EuroRoundedIcon from '@mui/icons-material/EuroRounded'

import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Transaction from 'layouts/pages/account/billing/components/Transaction'
import MDButton from 'components/MDButton'
import { getUltimeCinqueTransazioniImpresa } from 'getter/getterImpresa'
import { getProgettoDaId } from 'getter/getterProgetti'
import VerticalBarChart from 'examples/Charts/BarCharts/VerticalBarChart'
import { getListaTuttiProgettiSupportatiImpresa } from 'getter/getterImpresa'
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded'
import { getUrlDaStorage } from 'getter/getterimmagini'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import ElementoVisualizzaQrCode from 'componenti/elementoVisualizzaQrCode'
import { getTransazioniImpresa } from 'getter/getterImpresa'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { isImpresaB2b } from 'getter/getterImpresa'

export const paginaPortafoglioImpresa = (props) => {
    const [datiGrafico, setDatiGrafico] = useState({ labels: [], datasets: [{ label: '', data: [] }] })
    const [url, setUrl] = useState('')
    const [qrCodeAperto, setQrCodeAperto] = useState(false)
    const [transazioniAperte, setTransazioniAperte] = useState(false)
    const [listaTransazioni, setListaTransazioni] = useState([])

    const handleQrCodeAperto = () => setQrCodeAperto(!qrCodeAperto)
    const handleTransazioniAperte = () => setTransazioniAperte(!transazioniAperte)
    const ricarica = () => APRI_POP_UP({ id: 'ricaricaCredito', parametro: '' })

    useEffect(() => {
        if (transazioniAperte) {
            setListaTransazioni(getTransazioniImpresa())
        }
        if (!transazioniAperte) {
            setListaTransazioni(getUltimeCinqueTransazioniImpresa())
        }
    }, [props.transazioni, transazioniAperte])

    useEffect(async () => {
        const path = `${props.id}/qrCode/immagine.png`
        setUrl(await getUrlDaStorage(path))
    }, [])

    useEffect(() => {
        var listaNomi = []
        var listaValori = []
        props.progetti.forEach((progetto) => {
            const nome = getProgettoDaId(progetto.idProgetto).nome
            listaNomi.push(nome)
            listaValori.push(progetto.totale)
        })
        setDatiGrafico({ labels: listaNomi, datasets: [{ label: 'Donato ', data: listaValori, color: 'info' }] })
    }, [props.progetti])

    const renderTransazioni = () => {
        return (
            <Card sx={{ height: '100%' }}>
                <MDBox display='flex' justifyContent='space-between' alignItems='center' pt={3} px={2}>
                    <MDTypography variant='h6' fontWeight='medium' textTransform='capitalize'>
                        Le tue transazioni
                    </MDTypography>
                    <MDBox display='flex' alignItems='flex-start'>
                        <MDButton variant='outlined' color='info' onClick={handleTransazioniAperte}>
                            {transazioniAperte ? 'Nascondi' : 'vedi tutte'}
                        </MDButton>
                    </MDBox>
                </MDBox>
                <MDBox pt={3} pb={2} px={2}>
                    <MDBox mb={2}>
                        <MDTypography variant='caption' color='text' fontWeight='bold' textTransform='uppercase'>
                            Ultime 5 transazioni
                        </MDTypography>
                    </MDBox>
                    <MDBox component='ul' display='flex' flexDirection='column' p={0} m={0} sx={{ listStyle: 'none' }}>
                        {listaTransazioni.map((transazione) => {
                            return <Transaction key={transazione.id} {...transazione} />
                        })}
                    </MDBox>
                </MDBox>
            </Card>
        )
    }

    return (
        <>
            <Grid container spacing={3} sx={{ justifyContent: 'space-between', mt: 2, mb: 3 }}>
                <Grid item xs={12} md={3}>
                    <ElementoStatistica icona={<EuroRoundedIcon />} testoAzione='Ricarica' azione={ricarica} label='crediti' valore={Number(props.crediti).toFixed(2)} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ElementoStatistica icona={<PercentRoundedIcon />} label='Donazioni percentuali' valore={props.donazionePercentuali} />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ElementoStatistica icona={<VolunteerActivismRoundedIcon />} label='Donazioni liberali' valore={props.donazioniLiberali} />
                </Grid>
                {props.isB2b ? null : (
                    <Grid item xs={12} md={3}>
                        <ElementoVisualizzaQrCode aperto={qrCodeAperto} url={url} handleQrCodeAperto={handleQrCodeAperto} />
                        <ElementoStatistica icona={<QrCode2RoundedIcon />} testoAzione={'Apri'} azioneBox={true} azione={handleQrCodeAperto} label='Qr code' valore={<MDBox component='img' alt='qr' src={url} sx={{ height: '46.8px', width: '46.8px' }} />} />
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <VerticalBarChart title='I tuoi progetti sostenuti' description='Donazioni ai progetti che hai sostenuto.' chart={datiGrafico} />
                </Grid>
                {/* <Grid item xs={12} md={0}>
                    {renderTransazioni()}
                </Grid> */}
            </Grid>
        </>
    )
}

function mapStateToProps(state) {
    return {
        crediti: getCreditiImpresa(),
        donazioniLiberali: getTotaleDonazioniLiberaliImpresa(),
        donazionePercentuali: getTotaleSostegniImpresa(),
        transazioni: getTransazioniImpresa(),
        progetti: getListaTuttiProgettiSupportatiImpresa(),
        id: getIdUtenteLoggato(),
        isB2b: isImpresaB2b(getIdUtenteLoggato()),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaPortafoglioImpresa)
