import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getUrlDaStorage } from 'getter/getterimmagini'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MDAvatar from 'components/MDAvatar'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import MDTypography from 'components/MDTypography'
import { getLogoUtenteLoggato } from 'getter/getterUtenteLoggato'

export const elementoLogoProfilo = (props) => {
    const [url, setUrl] = useState('')

    useEffect(async () => {
        if (props.idLogo === '' && url === '') return
        if (props.idLogo === '' && url !== '') {
            setUrl('')
            return
        }
        const path = `${props.id}/logo/thumbNail.png`
        const nuovo = await getUrlDaStorage(path)
        setUrl(nuovo)
    }, [props.idLogo, props.id])

    if (url === '') {
        const primaLettera = props.dati.nome[0] ?? ''
        return (
            <MDAvatar bgColor='light' size='sm'>
                <MDTypography fontWeight='bold' color='info' align='center'>
                    {primaLettera.toUpperCase()}
                </MDTypography>
            </MDAvatar>
        )
    }

    return <MDAvatar src={url} alt='fotoProfilo' size='sm' />
}

const mapStateToProps = (state) => ({
    idLogo: getLogoUtenteLoggato(),
    id: getIdUtenteLoggato(),
    dati: getUtenteLoggato(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoLogoProfilo)
