import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

export default function popUpPermessoPosizione(props) {
    const handleClose = () => props.chiudi()

    const accetta = () => {
        navigator.geolocation.getCurrentPosition(
            async function (position) {
                window.permessoPosizione = true
                window.dispatchEvent(new CustomEvent('permessoPosizione'))
                handleClose()
            },
            function (error) {
                window.permessoPosizione = false
                window.dispatchEvent(new CustomEvent('permessoPosizione'))
            },
        )
    }

    return (
        <Dialog open={props.aperto} onClose={() => handleClose()} scroll={'paper'} aria-labelledby='titolo-pop-up-conferma'>
            <DialogTitle id='titolo-pop-up-conferma'>PERMESSO POSIZIONE</DialogTitle>
            <DialogContent>
                <MDTypography>Concedi il permesso di accedere alla tua posizione per aiutarti a trovare piu in fretta il negozio piu vicino a te</MDTypography>
            </DialogContent>
            <DialogActions>
                <MDButton color='dark' size='small' variant='contained' onClick={accetta}>
                    accetta
                </MDButton>
                <MDButton color='dark' size='small' variant='contained' onClick={handleClose}>
                    rifiuta
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
