/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import Icon from '@mui/material/Icon'

// Material Kit 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

function RotatingCardFront({ color, image, icon, title, description }) {
    return (
        <MDBox
            display='flex'
            justifyContent='center'
            alignContent='center'
            borderRadius='lg'
            coloredShadow={color}
            width='100%'
            position='relative'
            zIndex={2}
            sx={{
                backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85), rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85))}, url(${image})`,
                backgroundSize: 'cover',
                backfaceVisibility: 'hidden',
            }}>
            <MDBox py={12} px={3} textAlign='center' lineHeight={1}>
                {icon && (
                    <MDTypography variant='h2' color='white' my={2}>
                        {typeof icon === 'string' ? <Icon>{icon}</Icon> : icon}
                    </MDTypography>
                )}
                <MDTypography variant='h3' color='white' gutterBottom>
                    {title}
                </MDTypography>
                <MDTypography variant='body2' color='white' opacity={0.8}>
                    {description}
                </MDTypography>
            </MDBox>
        </MDBox>
    )
}

// Setting default props for the RotatingCardFront
RotatingCardFront.defaultProps = {
    color: 'info',
    icon: '',
}

// Typechecking props for the RotatingCardFront
RotatingCardFront.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
    image: PropTypes.string.isRequired,
    icon: PropTypes.node,
    title: PropTypes.node.isRequired,
    description: PropTypes.node.isRequired,
}

export default RotatingCardFront
