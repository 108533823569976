import { httpsCallable } from 'firebase/functions'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'
import { getProgettoDaId } from 'getter/getterProgetti'
import { Timestamp } from 'firebase/firestore'

export const SOSTIENI_PROGETTO = async (totale) => {
    MODIFICA_STATO_SPINNER(true)
    const idProgetto = getIdProgettoSelezionato()
    const data = getProgettoDaId(idProgetto).dataFine
    const oggetto = {
        idImpresa: getIdUtenteLoggato(),
        idProgetto: idProgetto,
        percentuale: totale,
        dataFine: (new Timestamp(data.seconds, data.nanoseconds)).toDate(),
    }
    const sostieniProgetto = httpsCallable(functions, 'sostieniProgetto')
    await sostieniProgetto(oggetto)
    MODIFICA_STATO_SPINNER(false)
    return
}
