import { store } from 'data/store'
import { profiloConsumatore } from 'util'
import { profiloSuperAmministratore } from 'util'
import { profiloImpresa } from 'util'

export const getIdUtenteLoggato = () => {
    return store.getState().statoUtente?.id || ''
}

export const getUtenteLoggato = () => {
    let utente = store.getState().statoUtente.utente || {}
    if (Object.keys(utente).length === 0) {
        return {}
    }
    return utente
}

export const getProfiloUtenteLoggato = () => {
    return getUtenteLoggato().profilo || ''
}

export const getLogoUtenteLoggato = () => {
    return getUtenteLoggato().logo || ''
}
export const getListaImmaginiUtenteLoggato = () => {
    const immagini = getUtenteLoggato().immagini
    if (immagini === undefined) return []
    return Object.keys(immagini)
}

export const isImpresaLoggata = () => {
    return getProfiloUtenteLoggato() === profiloImpresa.tipo
}
export const isEtiquaLoggata = () => {
    return getProfiloUtenteLoggato() === profiloSuperAmministratore.tipo
}

export const isConsumatoreLoggato = () => {
    const loggato = getProfiloUtenteLoggato()
    if(loggato === '' || loggato === profiloConsumatore.tipo){
        return true
    }
    return false
}