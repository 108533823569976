import React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Icon } from '@mui/material'
import colors from 'assets/theme/base/colors'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { SMETTI_DI_SEGUIRE_PROGETTO } from 'data/azioni/SMETTI_DI_SEGUIRE_PROGETTO'
import MDButton from 'components/MDButton'

export default function elementoModalitaDonazione(props) {
    const cliccato = () => props.handleClick(props.modalita)

    const smettiDiSeguire = async () => await SMETTI_DI_SEGUIRE_PROGETTO()

    const nonSostenere = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: 'Sei sicuro di voler smettere di seguire questo progetto?', azione : smettiDiSeguire } })

    var propsBox = {
        shadow: 'lg',
        borderRadius: 'lg',
        className: 'centrato flex-verticale',
        p: 2,
        minHeight: '330px',
        sx: { justifyContent: 'space-around', display : 'flex' },
        variant : 'gradient',
        height : 'max-content',
    }
    if (props.modalita.tipologia === 'standard') {
        propsBox.onClick = cliccato
        propsBox.bgColor = 'primary'
        propsBox.sx.cursor = 'pointer'
    }
    if (props.modalita.tipologia === 'attualmenteSostenuto') {
        propsBox.bgColor = 'success'
    }
    if (props.modalita.tipologia === 'altroProgettoSostenuto') {
        propsBox.sx.backgroundColor = colors.grey[300]
    }
    if (props.modalita.tipologia === 'noCrediti') {
        propsBox.sx.backgroundColor = colors.grey[500]
    }

    const renderBottone = (testo, azione) => {
        return <MDButton sx={{mr : 1}} color='white' variant={'contained'} onClick={azione}>{testo}</MDButton>
        return (
            <MDBox onClick={azione} bgColor='white' p={2} className='centrato' sx={{ height: '50px', borderRadius: '25px', cursor: 'pointer' }}>
                <MDTypography fontWeight='bold' color='dark'>
                    {testo}
                </MDTypography>
            </MDBox>
        )
    }

    const renderBottoni = () => {
        if (props.modalita.tipologia !== 'attualmenteSostenuto') return null
        return (
            <MDBox className='centrato' mt={2}>
                {renderBottone('modifica', cliccato)}
                {renderBottone('Interrompi sostegno', nonSostenere)}
            </MDBox>
        )
    }

    return (
        <MDBox {...propsBox} >
            <MDBox p={2} className='centrato' height='135px' width='135px' /* sx={{ backgroundColor: colors.grey[400] }} */>
                {props.modalita.tipologia === 'standard' ? (
                    <Icon sx={{ width: '100%', height: '100%' }} color='white'>
                        {props.modalita.icona}
                    </Icon>
                ) : (
                    props.modalita.icona
                )}
            </MDBox>
            <MDBox>
                <MDTypography variant='h3' color='white' fontWeight='medioum'>
                    {props.modalita.titolo}
                </MDTypography>
                <MDTypography color={props.modalita.tipologia === 'altroProgettoSostenuto' ? 'error' : 'white'}>{props.modalita.testo}</MDTypography>
                {renderBottoni()}
            </MDBox>
        </MDBox>
    )
}
