import React from 'react'
import { formCreaNetwork } from 'formModel/formCreaNetwork'
import useForm from 'customHooks/useForm'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import { NUOVO_NETWORK } from 'data/azioni/NUOVO_NETWORK'

export default function paginaCreaNetwork(props) {
    const [stato, azioni, renderForm] = useForm(formCreaNetwork, undefined, false, true)

    const abbandonaPagina = () => window.history.back()

    const valida = async () => {
        await azioni.validaForm()
        console.log('validatooo')
        await NUOVO_NETWORK(stato.statoForm)
        props.navigate('/paginaIlMioNetwork')
    }

    return (
        <ElementoRiquadroPagine>
            <ElementoEtichettaForm testo='Crea il tuo network' />
            {renderForm}
            <ElementoBottoniForm vista={stato.vista} annulla={abbandonaPagina} valida={valida} />
        </ElementoRiquadroPagine>
    )
}
