import { store } from './../store'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'

export class userWatcher {
    constructor() {
        this.utente = {}
        this.leggi = false
        const auth = getAuth()
        window.addEventListener('leggiUtente', (e) => {
            this.dispatch(3, e.detail, null)
            this.apriConnessioneDatiUtente(e.detail)
        })
        onAuthStateChanged(auth, (user) => {
            switch (user) {
                case undefined:
                    this.dispatch(1, null, null)
                    break
                case null:
                    this.chiudiConnessioneDatiUtente()
                    this.dispatch(2, null, null)
                    break
                default:
                    this.dispatch(3, user.uid, null)
                    if (!user.emailVerified) {
                        this.dispatch(5, user.uid, null)
                        break
                    }
                    this.apriConnessioneDatiUtente(user.uid)
                    break
            }
        })
    }

    apriConnessioneDatiUtente(uid) {
        console.log('uidddddd : ', uid)
        this.funzioneGuardaUtente = onSnapshot(doc(db, 'utenti', uid), (user) => {
            this.dispatch(4, uid, user.data())
        })
    }

    chiudiConnessioneDatiUtente() {
        if (this.funzioneGuardaUtente !== undefined) {
            this.funzioneGuardaUtente()
        }
    }

    dispatch(stato, id, utente) {
        store.dispatch({
            type: 'CAMBIA_STATO',
            payload: { stato, id, utente },
        })
    }
}
