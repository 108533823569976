import { ref, deleteObject } from "firebase/storage";
import { getIdUtenteLoggato } from "getter/getterUtenteLoggato";
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { doc, updateDoc } from 'firebase/firestore'

export const ELIMINA_DOCUMENTO_IMPRESA = async (dato) => {
    MODIFICA_STATO_SPINNER(true)
    const id = getIdUtenteLoggato()
    const path = `${id}/documenti/${dato}.pdf`
    
    const eliminaInStorage = async () => {
        return await deleteObject(ref(storage, path))
    }
    
    const aggiornaDb = async () => {
        return await updateDoc(doc(db, 'utenti', id), { [dato]: false })
    }
    
    await eliminaInStorage()
    await aggiornaDb()
    MODIFICA_STATO_SPINNER(false)
    return
}