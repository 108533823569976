import React, { useEffect, useState } from 'react'
import useForm from 'customHooks/useForm'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import ElementoBottoniForm from './elementoBottoniForm'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Grid } from '@mui/material'

export default function elementoContatti(props) {
    const [stato, azioni, renderForm] = useForm(props.form, props.dati, false, false)
    const [statoLink, azioniLink, renderFormLink] = useForm(props.formLink, props.dati, false, false)
    const [aperto, setAperto] = useState()
    const [apertoLink, setApertoLink] = useState()

    useEffect(() => {
        azioni.setForm(props.form)
    }, [props.form])
    /*  useEffect(() => {
        azioniLink.setForm(props.formLink)
    }, [props.formLink]) */

    const handleClose = (reset) => {
        azioni.vediOutput()
        azioni.reset()
        azioniLink.vediOutput()
        azioniLink.reset()
        setAperto(false)
        setApertoLink(false)
    }

    const apri = () => {
        azioni.vediInput()
        setAperto(true)
    }
    const apriLink = () => {
        azioniLink.vediInput()
        setApertoLink(true)
    }

    const salva = async () => {
        try {
            await azioni.validaForm()
            await props.aggiorna(stato.statoForm)
            azioni.vediOutput()
            setAperto(false)
        } catch (error) {
            return
        }
    }
    const salvaLink = async () => {
        console.log('entroooo ');
        try {
            await azioniLink.validaForm()
            await props.aggiorna(statoLink.statoForm)
            azioniLink.vediOutput()
            setApertoLink(false)
        } catch (error) {
            return
        }
    }

    const popUp = (
        <Dialog open={aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-modifica-contatti'>
            <DialogTitle id='titolo-pop-up-modifica-contatti'>MODIFICA-CONTATTI</DialogTitle>
            <DialogContent>
                <MDBox p={2}>{renderForm}</MDBox>
            </DialogContent>
            <DialogActions>
                <ElementoBottoniForm vista={stato.vista} annulla={handleClose} valida={salva} vediInput={azioni.vediInput} />
            </DialogActions>
        </Dialog>
    )
    const popUpLink = (
        <Dialog open={apertoLink} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-modifica-contatti-link'>
            <DialogTitle id='titolo-pop-up-modifica-contatti-link'>MODIFICA-LINK CONTATTI</DialogTitle>
            <DialogContent>
                <MDBox p={2}>{renderFormLink}</MDBox>
            </DialogContent>
            <DialogActions>
                <ElementoBottoniForm vista={statoLink.vista} annulla={handleClose} valida={salvaLink} vediInput={azioniLink.vediInput} />
            </DialogActions>
        </Dialog>
    )

    return (
        <Grid container spacing={2}>
            {popUp}
            {popUpLink}
            <Grid item xs={12} md={6} lg={6}>
                <MDBox width='100%' shadow='lg' bgColor='dark' variant='gradient' p={3} height='100%'>
                    <MDBox className='flex-verticale' ml={{ sm: 0, md: 'auto' }} mr={'auto'} width='100%' maxWidth='350px'>
                        <MDTypography align='center' variant='h5' color='white'>
                            CONTATTI
                        </MDTypography>
                        <MDBox className='flex-verticale' pt={2} >
                            {renderForm}
                        </MDBox>
                        {props.vediBottone ? (
                            <MDBox pt={2} className='centrato' justifyContent='end' alignItems='end'>
                                <MDButton variant='contained' color='white' onClick={apri}>
                                    modifica
                                </MDButton>
                            </MDBox>
                        ) : null}
                    </MDBox>
                </MDBox>

              
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <MDBox width='100%' shadow='lg' bgColor='dark' p={3} height='100%' variant='gradient'>
                    <MDBox className='flex-verticale' mr='auto' ml={{ sm: 0, md: 'auto' }} width='100%' maxWidth='350px'>
                        <MDTypography align='center' variant='h5' color='white'>
                            LINK CONTATTI
                        </MDTypography>
                        <MDBox className='flex-verticale' pt={2}>{renderFormLink}</MDBox>
                        {props.vediBottone ? (
                            <MDBox pt={2} className='centrato' justifyContent='start' alignItems='end'>
                                <MDButton variant='contained' color='white' onClick={apriLink}>
                                    modifica
                                </MDButton>
                            </MDBox>
                        ) : null}
                    </MDBox>
                </MDBox>
            </Grid>
        </Grid>
    )
}

elementoContatti.defaultProps = {
    vediBottone: false,
    dati: {},
    form: { parts: [] },
    formLink: { parts: [] },
    aggiorna: () => {},
}
