import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'

export default function popUpEliminaAccount(props) {
    const handleClose = () => props.chiudi()

    return (
        <Dialog open={props.aperto} onClose={() => handleClose()} scroll={'paper'} aria-labelledby='titolo-pop-up-elimina-account'>
            <DialogTitle id='titolo-pop-up-elimina-account'>ELIMINA ACCOUNT</DialogTitle>
            <DialogContent>
                <MDBox className='flex-verticale'>
                    <MDTypography>Per eliminare l'account inviare una mail al team di etiqua al seguente indirizzo.</MDTypography>
                    <MDTypography color='info' fontWeight='medium'>
                        info@etiqua.net
                    </MDTypography>
                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDButton color='dark' size='small' variant='contained' onClick={() => handleClose()}>
                    ok
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
