import { doc, updateDoc, deleteField  } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'
import { ref, deleteObject } from 'firebase/storage'

export const ELIMINA_IMMAGINE_PROGETTO = async (idImmagine) => {
    MODIFICA_STATO_SPINNER(true)
    const idProgetto = getIdProgettoSelezionato()
    const pathThumbNail = `${idProgetto}/immagini/${idImmagine}/thumbNail.png`
    const pathOriginale = `${idProgetto}/immagini/${idImmagine}/originale.png`
    await deleteObject(ref(storage, pathThumbNail))
    await deleteObject(ref(storage, pathOriginale))
    await updateDoc(doc(db, 'progetti', idProgetto), { [`immagini.${idImmagine}`]: deleteField () })
    MODIFICA_STATO_SPINNER(false)
    return
}