import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import React, { useState, useEffect } from 'react'

const API_KEY = 'AIzaSyAeVTPAfE9nuQgojjuojj7KbMCiIAkuszU'

const naviga = (marker) => window.open(`https://www.google.com/maps/dir/?api=1&destination=${marker.location.lat},${marker.location.lng}`)

const MapComponent = withScriptjs(
    withGoogleMap((props) => {
        //return <iframe width='600' height='450' style='border:0' loading='lazy' allowFullScreen referrerPolicy='no-referrer-when-downgrade' src='https://www.google.com/maps/embed/v1/place?key=AIzaSyAeVTPAfE9nuQgojjuojj7KbMCiIAkuszU&q=Space+Needle,Seattle+WA'></iframe>

        return (
            <GoogleMap defaultZoom={5} zoom={props.selectedMarker !== null ? 15 : 9.5} defaultCenter={props.selectedMarker !== null ? props.selectedMarker.location : { lat: 45.1061, lng: 7.7426 }}>
                {props.markers.map((marker, index) => {
                    return <Marker key={index} position={marker.location} onClick={() => props.handleSetSelectedMarker(marker)} />
                })}
                {props.selectedMarker && (
                    <InfoWindow
                        position={{ lat: props.selectedMarker.location.lat, lng: props.selectedMarker.location.lng }}
                        onCloseClick={() => {
                            props.handleSetSelectedMarker(null)
                        }}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                            <h2 style={{ marginBottom: '5px' }}>{props.selectedMarker.nome}</h2>
                            <p style={{ marginBottom: '5px' }}>{props.selectedMarker.indirizzo}</p>
                            <div style={{ cursor: 'pointer' }} onClick={() => naviga(props.selectedMarker)}>
                                Apri su maps
                            </div>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        )
    }),
)

const elementoMap = (props) => {
    const container = <div id='test' style={{ height: '400px' }} />
    const mapElement = <div style={{ height: `100%` }} />
    const loadingElement = <div style={{ height: `100%` }} />
    const url = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`

    const html = `<iframe width='600' height='450' style='border:0' loading='lazy' allowFullScreen referrerPolicy='no-referrer-when-downgrade' src='https://www.google.com/maps/embed/v1/place?key=AIzaSyAeVTPAfE9nuQgojjuojj7KbMCiIAkuszU&q=Space+Needle,Seattle+WA'></iframe>`

    //return <div dangerouslySetInnerHTML={{ __html: html }} />

    return <MapComponent selectedMarker={props.selezionato} handleSetSelectedMarker={props.hanndleSelezionato} mapElement={mapElement} googleMapURL={url} loadingElement={loadingElement} containerElement={container} markers={props.lista} />
}

export default elementoMap
