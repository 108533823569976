import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

export default function popUpRichiediConferma(props) {
    const handleClose = () => {
        props.chiudi()
    }
    const elimina = async () => {
        await props.parametro.azione()
        if (props.parametro.noAlert) {
            handleClose()
            return
        }
        handleClose()
        //APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Operazione completata' } })
    }
    return (
        <Dialog open={props.aperto} onClose={() => handleClose()} scroll={'paper'} aria-labelledby='titolo-pop-up-conferma'>
            <DialogTitle id='titolo-pop-up-conferma'>CONFERMA RICHIESTA</DialogTitle>
            <DialogContent>
                <MDTypography>{props.parametro.testo}</MDTypography>
            </DialogContent>
            <DialogActions>
                <MDButton color='dark' size='small' variant='contained' onClick={() => elimina()}>
                    si
                </MDButton>
                <MDButton color='dark' size='small' variant='contained' onClick={() => handleClose()}>
                    no
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
