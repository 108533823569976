import React from 'react'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import useForm from 'customHooks/useForm'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import { formProgetto } from 'formModel/formProgetto'
import { CREA_PROGETTO } from 'data/azioni/CREA_PROGETTO'
import { newId } from 'data/strumenti'

export default function paginaNuovoProgetto(props) {
    const [stato, azioni, renderForm] = useForm(formProgetto, undefined, false, true)

    const abbandonaPagina = () => window.history.back()

    const valida = async () => {
        await azioni.validaForm()
        await CREA_PROGETTO(stato.statoForm, newId())
        props.navigate('/paginaMieiProgetti')
    }

    return (
        <ElementoRiquadroPagine>
            <ElementoEtichettaForm testo='Nuova raccolta fondi' />
            <p>
              <a href="https://www.etiqua.net/linee-guida" target="_blank" rel="noopener noreferrer">
                 Linee guida per la compilazione
              </a>
             </p>
             <br />
             
            {renderForm}
            <ElementoBottoniForm vista={stato.vista} annulla={abbandonaPagina} valida={valida} />

        </ElementoRiquadroPagine>
    )
}
