import React, { useEffect, useState } from 'react'
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import bgImage from 'assets/images/bg-etiqua-login.jpg'
import { Link } from 'react-router-dom'
import { InputText } from 'fields/fieldText'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { Card } from '@mui/material'
import ElementoNavigazionePreLogin from 'componenti/elementoNavigazionePreLogin'

export default function paginaLogin(props) {
    const [credenziali, setCredenziali] = useState({ mail: '', password: '' })
    const [errore, setErrore] = useState('')

    const formEmail = {
        id: 'mail',
        tipo: 'fieldText',
        associazione: 'mail',
        label: 'Email',
        value: '',
        details: {
            id: 'mail',
            type: 'email',
        },
    }
    const formPassword = {
        id: 'password',
        tipo: 'fieldText',
        associazione: 'password',
        label: 'Password',
        value: '',
        details: {
            id: 'password',
            type: 'password',
        },
    }

    const login = () => {
        setErrore('')
        const auth = getAuth()

        signInWithEmailAndPassword(auth, credenziali.mail, credenziali.password)
            .then((userCredential) => {})
            .catch((error) => {
                setErrore('Credenziali errate')
            })
    }

    const premutoInvio = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            document.getElementById('login').click()
        }
    }

    useEffect((e) => {
        setTimeout(() => {
            document.getElementById('password').addEventListener('keypress', (e) => {
                premutoInvio(e)
            })

            document.getElementById('mail').addEventListener('keypress', (e) => {
                premutoInvio(e)
            })
        }, 100)
    }, [])

    const aggiornaCredenziali = (campo, valore) => {
        let nuoveCredenziali = Object.keys(credenziali).reduce((acc, chiave) => {
            let daInserire = chiave === campo ? valore : credenziali[chiave]
            acc[chiave] = daInserire
            return acc
        }, {})
        setCredenziali(nuoveCredenziali)
    }

    return (
        <>
            <ElementoNavigazionePreLogin transparent={true} />
            <BasicLayout image={bgImage}>
                <Card sx={{ p: 2 }}>
                    <MDBox variant='gradient' bgColor='info' borderRadius='lg' coloredShadow='info' mx={2} mt={-3} p={2} mb={1} textAlign='center'>
                        <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
                            Accedi
                        </MDTypography>
                        <MDTypography variant='subtitle2' fontWeight='medium' color='white' mt={1}>
                            Inserisci mail e password per accedere
                        </MDTypography>
                    </MDBox>

                    <MDBox component='form' role='form' sx={{ mt: 2 }}>
                        <MDBox mb={2} variant='h6'>
                            <InputText model={formEmail} value={credenziali.mail} errore='' azione={(a, b) => aggiornaCredenziali(a, b)} />
                        </MDBox>
                        <MDBox mb={2}>
                            <InputText model={formPassword} value={credenziali.password} errore='' azione={(a, b) => aggiornaCredenziali(a, b)} />
                        </MDBox>
                        <MDTypography variant='h6' color='error' fontWeight='bold'>
                            {errore}
                        </MDTypography>
                        <MDBox display='flex' alignItems='center' ml={-1}></MDBox>
                        <MDBox mt={4} mb={1}>
                            <MDButton id='login' color='dark' size='large' fullWidth onClick={() => login()}>
                                Accedi
                            </MDButton>
                            <MDButton sx={{ mt: 0.5 }} color='error' size='large' fullWidth variant='text' onClick={() => props.navigate('/')}>
                                annulla
                            </MDButton>
                        </MDBox>
                        <MDBox mt={3} textAlign='center'>
                            <MDTypography color='text' variant='h6'>
                                Non hai un account?{' '}
                                <MDTypography component={Link} to='/registrati' color='info' fontWeight='medium' variant='h6'>
                                    Registrati
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                        <MDBox mt={3} textAlign='center'>
                            <MDTypography color='text' variant='h6'>
                                Hai dimenticato la password?{' '}
                                <MDTypography component={Link} to='/passwordDimenticata' color='info' fontWeight='medium' variant='h6'>
                                    Clicca qui
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Card>
            </BasicLayout>
        </>
    )
}
