import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import useCache from 'customHooks/useCache'
import { getInfoProgettiEnte } from 'getter/getterEnte'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import ElementoListaProgetti from 'componenti/elementoListaProgetti'
import ElementoNavigazioneMieiProgettiEnte from 'componenti/elementoNavigazioneMieiProgettiEnte'
import MDBox from 'components/MDBox'

export const paginaMieiProgetti = (props) => {
    const [progettiInAttesa, setProgettiInAttesa] = useState([])
    const [progettiAttivi, setProgettiAttivi] = useState([])
    const [progettiCompletati, setProgettiCompletati] = useState([])
    const [progettiRifiutati, setProgettiRifiutati] = useState([])
    const [selezionato, setSelezionato] = useState('In attesa')
    const [listaDaVedere, setListaDaVedere] = useState([])
    const [messaggioErrore, setMessaggioErrore] = useState('')
    const { listaProgettiWindow } = useCache()

    const handleSelezionato = (valore) => setSelezionato(valore)

    useEffect(() => {
        var lista = []
        var errore = ''
        switch (selezionato) {
            case 'In attesa':
                lista = [...progettiInAttesa]
                errore = 'Nessun progetto in attesa di conferma'
                break
            case 'Attivi':
                lista = [...progettiAttivi]
                errore = 'Nessun progetto attivo'
                break
            case 'Completati':
                lista = [...progettiCompletati]
                errore = 'Nessun progetto completato'
                break
            case 'Rifiutati':
                lista = [...progettiRifiutati]
                errore = 'Nessun progetto rifiutato'
                break

            default:
                break
        }
        setListaDaVedere(lista)
        setMessaggioErrore(errore)
    }, [selezionato, progettiInAttesa, progettiAttivi, progettiCompletati, progettiRifiutati])

    useEffect(() => {
        const dati = getInfoProgettiEnte()
        setProgettiAttivi(dati.progettiAttivi)
        setProgettiCompletati(dati.progettiCompletati)
        setProgettiInAttesa(dati.progettiInAttesa)
        setProgettiRifiutati(dati.progettiRifiutati)
    }, [props.dati, listaProgettiWindow])

    return (
        <>
            <ElementoNavigazioneMieiProgettiEnte selezionato={selezionato} handleSelezionato={handleSelezionato} />
            <MDBox mx='auto' width='100%' maxWidth='1000px'>
                <ElementoListaProgetti {...props} lista={listaDaVedere} noOmbra={true} testoErrore={messaggioErrore} />
            </MDBox>
        </>
    )

    return (
        <ElementoRiquadroPagine>
            <ElementoEtichettaForm testo='I miei progetti in attesa di conferma' />
            <ElementoListaProgetti {...props} lista={progettiInAttesa} noOmbra={true} testoErrore='Nessun progetto in attesa di conferma' />

            <ElementoEtichettaForm testo='I miei progetti attivi' />
            <ElementoListaProgetti {...props} lista={progettiAttivi} noOmbra={true} testoErrore='Nessun progetto attivo' />

            <ElementoEtichettaForm testo='I miei progetti completati' />
            <ElementoListaProgetti {...props} lista={progettiCompletati} noOmbra={true} testoErrore='Nessun progetto completato' />

            <ElementoEtichettaForm testo='I miei progetti rifiutati' />
            <ElementoListaProgetti {...props} lista={progettiRifiutati} noOmbra={true} testoErrore='Nessun progetto rifiutato' />
        </ElementoRiquadroPagine>
    )
}

const mapStateToProps = (state) => ({
    dati: getUtenteLoggato(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaMieiProgetti)
