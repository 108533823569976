import React, { useState } from 'react'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import MDBox from 'components/MDBox'
import { InputSelect } from 'fields/fieldSelect'
import { InputTextArea } from 'fields/fieldTextArea'
import { Grid, Icon } from '@mui/material'
import useInputFileImmagini from 'customHooks/useInputFileImmagini'
import ElementoGalleria from 'componenti/elementoGalleria'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import MDButton from 'components/MDButton'
import { connect } from 'react-redux'
import { newId } from 'data/strumenti'
import { NUOVO_AGGIORNAMENTO_PROGETTO } from 'data/azioni/NUOVO_AGGIORNAMENTO_PROGETTO'

const modelScelta = {
    id: 'scelta',
    tipo: 'fieldSelect',
    associazione: 'scelta',
    label: 'Invialo a:',
    value: '',
    details: {
        complex: false,
        scelte: ['Tutti', 'Imprese', 'Consumatori'],
    },
}
const modelTesto = {
    id: 'testo',
    tipo: 'fieldTextArea',
    associazione: 'testo',
    label: 'Aggiorna i tuoi sostenitori:',
    value: '',
    details: {},
}

export function paginaNuovoAggiornamento(props) {
    const [scelta, setScelta] = useState('Tutti')
    const [testo, settesto] = useState('')
    const [immagini, setImmagini] = useState([])

    const nuovaImmagine = (thumbNail, originale) => {
        /* const index =  */
        const nuovo = {
            originale: originale,
            thumbNail: thumbNail,
            id: newId(),
        }
        var nuovaLista = [...immagini]
        nuovaLista.push(nuovo)
        setImmagini(nuovaLista)
    }

    const eliminaImmagine = (index) => {
        var nuovaLista = [...immagini]
        nuovaLista.splice(index, 1)
        setImmagini(nuovaLista)
    }

    const [renderInputImmagine, caricaImmagine] = useInputFileImmagini('input-immagini', nuovaImmagine)

    const handleScelta = (campo, value) => setScelta(value)
    const handleTesto = (campo, value) => settesto(value)
    const annulla = () => props.navigate(`paginaVediProgetto/${props.idProgetto}`)
    const salva = async () => {
        await NUOVO_AGGIORNAMENTO_PROGETTO(scelta, testo, immagini)
        annulla()
    }

    const renderValori = (
        <Grid container spacing={2} sx={{ justifyContent: 'center' }} mb={2}>
            <Grid item xs={12} md={4}>
                <InputSelect value={scelta} model={modelScelta} errore='' azione={handleScelta} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputTextArea value={testo} model={modelTesto} errore='' azione={handleTesto} />
            </Grid>
        </Grid>
    )

    const etichetta = (testo, bottone) => {
        return (
            <MDBox m='auto' sx={{ width: '100%', maxWidth: '1000px', boxSizing: 'border-box', display: 'flex' }}>
                <ElementoEtichettaForm testo={testo} secondoElemento={bottone || null} />
            </MDBox>
        )
    }

    const bottoniSalvaAnnulla = () => {
        return (
            <Grid container spacing={2} sx={{ justifyContent: 'end', width: 'max-content' }}>
                <Grid item xs={12} md={6}>
                    <MDButton variant='contained' color='error' onClick={annulla}>
                        annulla
                    </MDButton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MDButton variant='contained' color='success' onClick={salva}>
                        salva
                    </MDButton>
                </Grid>
            </Grid>
        )
    }

    const bottoneNuovaImmagine = (
        <MDButton variant='outlined' color='dark' onClick={caricaImmagine}>
            <Icon sx={{ mr: 1 }}>add</Icon>
            aggiungi
        </MDButton>
    )

    return (
        <ElementoRiquadroPagine>
            {renderInputImmagine}
            {etichetta('Rilascia un aggiornamento', bottoniSalvaAnnulla())}
            {renderValori}
            {etichetta('Aggiungi delle immagini', bottoneNuovaImmagine)}
            <ElementoGalleria
                testoErrore='Nessuna immagine aggiunta'
                immagini={immagini.map((immagine, index) => {
                    console.log('immagine : ', immagine)
                    return {
                        src: immagine.thumbNail.dataUrl,
                        thumbNail: immagine.thumbNail.dataUrl,
                        alt: immagine.originale.name,
                        icona: <DeleteRoundedIcon color='error' onClick={() => eliminaImmagine(index)} />,
                    }
                })}
            />
        </ElementoRiquadroPagine>
    )
}

const mapStateToProps = (state) => ({
    idProgetto: state.url[4],
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaNuovoAggiornamento)
