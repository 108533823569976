import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import useCache from 'customHooks/useCache'
import ElementoListaEnti from 'componenti/elementoListaEnti'
import ElementoFiltriEnti from 'componenti/elementoFiltriEnti'
import { getListaEntiFiltrata } from 'getter/getterEnte'
import MDBox from 'components/MDBox'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'

export const paginaEnti = (props) => {
    const { listaEntiWindow } = useCache()
    const [filtri, setFiltri] = useState({ categoria: '', luogo: '', ordinaPer: 0 })
    const [listaFiltrata, setListafiltrata] = useState(listaEntiWindow)

    const handleFilri = (nuoviFiltri) => setFiltri(Object.assign({}, nuoviFiltri))

    useEffect(() => {
        setListafiltrata(getListaEntiFiltrata(filtri))
    }, [filtri, listaEntiWindow])

    const renderPagina = (
        <MDBox display='flex' flexDirection='column' width='100%' boxSizing='border-box'>
            <MDBox bgColor='white' shadow='lg' norderRadius='lg' p={3} mb={3}>
                <MDBox mx='auto' maxWidth='1000px' width='100%'>
                    <ElementoFiltriEnti filtri={filtri} aggiornaFiltri={handleFilri} />
                </MDBox>
            </MDBox>
            <MDBox width='100%' mx='auto' maxWidth='1200px' >
                <ElementoListaEnti {...props} lista={listaFiltrata} testoErrore='Nessun ente presente in archivio' />
            </MDBox>
        </MDBox>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

paginaEnti.defaultProps = {
    isLanding: true,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaEnti)
