const initialState = ''

export const url = (state = initialState, action) => {
    let newState = ''

    switch (action.type) {
        case 'CAMBIA_URL':
            newState = action.payload
            return newState
            break

        default:
            return state
            break
    }
}
