export const formCambioCredenziali = (isCambioMail) => {
    const vecchiaPassword = {
        id: 'vecchiaPassword',
        tipo: 'fieldText',
        associazione: 'vecchiaPassword',
        label: 'Password attuale :',
        value: '',
        details: {
            type: 'text',
        },
        validazione: 'passwordAttuale',
    }
    const nuovaPassword = {
        id: 'nuovo',
        tipo: 'fieldText',
        associazione: 'nuovo',
        label: 'Nuova password:',
        value: '',
        details: {
            type: 'text',
        },
        validazione: 'lunghezzaMinima',
    }
    const nuovaMail = {
        id: 'nuovo',
        tipo: 'fieldText',
        associazione: 'nuovo',
        label: 'Nuova mail :',
        value: '',
        details: {
            type: 'text',
        },
        validazione: 'emailGiaPresente',
    }

    return {
        parts: [vecchiaPassword, isCambioMail ? nuovaMail : nuovaPassword],
    }
}
