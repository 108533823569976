import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { OutputText } from './fieldText'

export function InputBoolean(props) {
    return <FormControlLabel control={<Checkbox checked={typeof props.value !== "boolean" ? false : props.value} onChange={(e, valore) => props.azione(props.model.id, valore)} />} label={props.model.label} />
}
export function OutputBoolean(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }
    return <OutputText skeleton={props.skeleton || false} model={model} value={props.value ? "SI" : "NO"} />
}
