import React, { useState, useEffect } from 'react'
import useCache from 'customHooks/useCache'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import ElementoListaProgetti from 'componenti/elementoListaProgetti'
import { getListaProgettiCompletati } from 'getter/getterProgetti'

export default function paginaListaProgettiCompletatiEtiqua(props) {
    const [progettiCompletati, setProgettiCompletati] = useState([])
    const { listaProgettiWindow } = useCache()
    useEffect(() => {
        setProgettiCompletati(getListaProgettiCompletati())
    }, [listaProgettiWindow])

    return (
        <>
            <ElementoEtichettaForm testo='Progetti completati' />
            <ElementoListaProgetti {...props} lista={progettiCompletati} noOmbra={true} testoErrore='Nessun progetto attivo' />
        </>
    )
}
