import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { InputText } from 'fields/fieldText'
import { httpsCallable } from 'firebase/functions'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'

const modelValore = {
    id: 'importo',
    tipo: 'fieldText',
    associazione: 'importo',
    label: 'Importo :',
    value: '',
    details: {
        type: 'number',
    },
}

export default function popUpRicaricaCredito(props) {
    const [valore, setValore] = useState(100)
    const [errore, setErrore] = useState('')

    const erroreCifreDecimali = (valore) => {
        const numStr = valore.toString()
        const decimalIndex = numStr.indexOf('.')
        if (decimalIndex === -1) {
            return false
        }
        const decimalPlaces = numStr.length - decimalIndex - 1
        return decimalPlaces > 2
    }

    const chiamaStripe = async () => {
        const ricaricaCrediti = httpsCallable(functions, 'ricaricaCrediti')
        const generaQrCode = httpsCallable(functions, 'generaQrCode')
        MODIFICA_STATO_SPINNER(true)
        //const url = `https://www.etiqua.net/etiqua-app/`
        const url = window.location.href
        const result = await ricaricaCrediti({ totale: valore, successUrl: url, defaultUrl: url })
        //const result = await generaQrCode()
        console.log('result : ', result)
        //MODIFICA_STATO_SPINNER(false)

        stripe.redirectToCheckout({
            sessionId: result.data,
        })
    }

    const ricarica = async () => {
        setErrore('')
        if (valore <= 0) {
            setErrore('Importo non valido')
            return
        }
        if (erroreCifreDecimali(valore)) {
            setErrore('Non possono esserci piu di due cifre decimali')
            return
        }
        await chiamaStripe()
    }

    const handleValore = (campo, value) => setValore(value)

    const handleClose = () => props.chiudi()

    return (
        <Dialog open={props.aperto} onClose={() => handleClose()} scroll={'paper'} aria-labelledby='titolo-pop-up-ricarica-credito'>
            <DialogTitle id='titolo-pop-up-ricarica-credito'>CONFERMA RICHIESTA</DialogTitle>
            <DialogContent>
                <MDTypography sx={{ mb: 2 }}>Inserisci l'importo che vuoi ricaricare</MDTypography>
                <InputText value={valore} model={modelValore} errore={errore} azione={handleValore} />
            </DialogContent>
            <DialogActions>
                <MDButton color='error' size='small' variant='contained' onClick={handleClose}>
                    annulla
                </MDButton>
                <MDButton color='success' size='small' variant='contained' onClick={ricarica}>
                    ricarica
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
