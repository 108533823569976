import { getProgettoSelezionato } from 'getter/getterProgetti'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import MDBox from 'components/MDBox'
import ElementoEtichettaForm from './elementoEtichettaForm'
import MDTypography from 'components/MDTypography'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'
import { getStatoProgetto } from 'getter/getterProgetti'
import MDButton from 'components/MDButton'
import { Grid } from '@mui/material'
import { AGGIORNA_PROGETTO } from 'data/azioni/AGGIORNA_PROGETTO'

export const elementoOpzioniProgetto = (props) => {
    const [stato, setStato] = useState('')
    const [colore, setColore] = useState('info')
    const [bottoni, setBottoni] = useState(null)

    const modifica = async (dati) => {
        await AGGIORNA_PROGETTO(props.idProgetto, dati)
    }

    const accettaProgetto = () => modifica({ stato: 'accettato' })
    const rifiutaProgetto = () => modifica({ stato: 'rifiutato' })
    const mettiInAttesaProgetto = () => modifica({ stato: 'inAttesa' })
    const validaProgetto = () => modifica({ isValidata: true })
    const CancellaValidazioneProgetto = () => modifica({ isValidata: false })

    useEffect(() => {
        const nuovo = getStatoProgetto()
        setStato(nuovo)
    }, [props.progetto, props.idProgetto])

    const bottoneAccetta = (
        <Grid item>
            <MDButton variant='contained' color='success' onClick={accettaProgetto}>
                accetta
            </MDButton>
        </Grid>
    )
    const bottoneRifiuta = (
        <Grid item>
            <MDButton variant='contained' color='error' onClick={rifiutaProgetto}>
                rifiuta
            </MDButton>
        </Grid>
    )
    const bottoneMettiInAttesa = (
        <Grid item>
            <MDButton variant='contained' color='warning' onClick={mettiInAttesaProgetto}>
                metti in attesa
            </MDButton>
        </Grid>
    )
    const bottoneValida = (
        <Grid item>
            <MDButton variant='contained' color='success' onClick={validaProgetto}>
                valida
            </MDButton>
        </Grid>
    )
    const bottoneCancellavalidazione = (
        <Grid item>
            <MDButton variant='contained' color='error' onClick={CancellaValidazioneProgetto}>
                cancella validazione
            </MDButton>
        </Grid>
    )

    const renderBottoniAttivo = <>{bottoneMettiInAttesa}</>
    const renderBottoniValidato = <>{bottoneCancellavalidazione}</>
    const renderBottoniCompletato = (
        <>
            {bottoneValida}
            {bottoneMettiInAttesa}
        </>
    )
    const renderBottoniRifiutato = <>{bottoneMettiInAttesa}</>
    const renderBottoniInAttesa = (
        <>
            {bottoneRifiuta}
            {bottoneAccetta}
        </>
    )

    useEffect(() => {
        switch (stato) {
            case 'Attivo':
                setColore('info')
                setBottoni(renderBottoniAttivo)
                break
            case 'Validato':
                setColore('success')
                setBottoni(renderBottoniValidato)
                break
            case 'Completata':
                setColore('success')
                setBottoni(renderBottoniCompletato)
                break
            case 'Rifiutato':
                setColore('error')
                setBottoni(renderBottoniRifiutato)
                break
            case 'In attesa':
                setColore('warning')
                setBottoni(renderBottoniInAttesa)
                break
            case 'Scaduto':
                setColore('error')
                setBottoni(null)
                break

            default:
                break
        }
    }, [stato])

    return (
        <MDBox bgColor='white' p={2} mx='auto' width='100%' maxWidth='1000px' shadow='lg'>
            <ElementoEtichettaForm testo={props.progetto.nome} />
            <MDBox className='flex-orizzontale' mb={2}>
                <MDTypography sx={{ mr: 1 }}>Stato : </MDTypography>
                <MDTypography color={colore} fontWeigth='medium'>
                    {stato}
                </MDTypography>
            </MDBox>
            <Grid container direction='row' spacing={2}>
                {bottoni}
            </Grid>
        </MDBox>
    )
}

const mapStateToProps = (state) => ({
    progetto: getProgettoSelezionato(),
    idProgetto: getIdProgettoSelezionato(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoOpzioniProgetto)
