const initialState = []

export const reducerRichiesteAcquisti = (state = initialState, action) => {
    switch (action.type) {
        case 'LEGGI_RICHIESTE_ACQUISTO':
            var lista = [... action.payload]
            lista.sort((a, b) => {
                let dataA = a.data.seconds
                let dataB = b.data.seconds
                return dataB - dataA
            })
            return lista
        case 'LOG_OUT':
            return []
        default:
            return state
    }
}
