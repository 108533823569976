import React, { useState } from 'react'
import VediForm from 'formMaker/vediForm'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
/* import PopUpFieldList from 'popUp/popUpFieldList' */
import FormOutput from 'formMaker/formOutput'
import MDTypography from 'components/MDTypography'
import DataTable from './../examples/Tables/DataTable'
import DefaultCell from './../layouts/ecommerce/products/product-page/components/DefaultCell'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import RemoveIcon from '@mui/icons-material/Remove'
import colors from 'assets/theme/base/colors'
//import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
//import { APRI_POP_UP_FIELD_LIST } from 'data/azioni/POP_UP_FIELD_LIST'
import { Skeleton } from '@mui/material'

export function InputList(props) {
    const [focus, setFocus] = useState(false)
    const getColore = () => {
        if (focus) {
            return colors.primary.main
        }
        return props.errore !== '' ? colors.error.main : colors.inputBorderColor
    }

    const popUpChiuso = (dati, indice) => {
        let nuovaLista = [...props.value]
        if (indice === -1) {
            nuovaLista.push(dati)
            props.azione(props.model.id, nuovaLista)
            return
        }
        nuovaLista[indice] = dati
        props.azione(props.model.id, nuovaLista)
        return
    }

    let modifica = (indice, valore) => {
        if (indice === -1) {
            APRI_POP_UP_FIELD_LIST({ index: indice, model: props.model.details.subModel, dati: {}, chiuso: (dati, aggiungi) => popUpChiuso(dati, aggiungi), titolo: 'Aggiungi elemento' })
            return
        }
        let nuovoStato = props.model.details.subModel.parts.reduce((acc, model) => {
            acc[model.id] = valore[model.id]

            return acc
        }, {})
        APRI_POP_UP_FIELD_LIST({ index: indice, model: props.model.details.subModel, dati: nuovoStato, chiuso: (dati, aggiungi) => popUpChiuso(dati, aggiungi), titolo: 'Aggiungi elemento' })
    }

    let elimina = (indice) => {
        let nuovaLista = [...props.value]
        nuovaLista.splice(indice, 1)
        props.azione(props.model.id, nuovaLista)
    }

    let fab = (indice, valore) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={6} lg={6}>
                    <Fab color='primary' size='small' aria-label='modifica' onClick={() => modifica(indice, valore)}>
                        <EditIcon />
                    </Fab>
                </Grid>
                <Grid item xs={6} lg={6}>
                    <Fab color='primary' size='small' aria-label='cancella' onClick={() => elimina(indice)}>
                        <RemoveIcon />
                    </Fab>
                </Grid>
            </Grid>
        )
    }

    let colonneTabella = props.model.details.subModel.parts.reduce(
        (acc, model) => {
            acc.push({ Header: model.label, accessor: model.label })
            return acc
        },
        [{ Header: 'azioni', accessor: 'azioni' }],
    )

    let righeTabella = props.value.map((valore, indiceValori) => {
        return colonneTabella.reduce((acc, colonna) => {
            if (colonna.Header === 'azioni') {
                acc[colonna.Header] = <DefaultCell>{fab(indiceValori, valore)}</DefaultCell>
            }
            if (colonna.Header !== 'azioni') {
                let indiceModel = props.model.details.subModel.parts.findIndex((el) => {
                    return el.label === colonna.Header
                })
                let model = props.model.details.subModel.parts[indiceModel]
                let valoreDaVrdere = { [model.id]: valore[model.id] }
                model.details.noLabel = true
                acc[colonna.Header] = (
                    <DefaultCell>
                        <FormOutput form={{ parts: [model] }} dati={valoreDaVrdere} isSubInput={true} />
                    </DefaultCell>
                )
            }
            return acc
        }, {})
    })

    return (
        <MDBox sx={{ border: 'solid 0.1px', color: getColore() }} p={2} borderRadius='lg' onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
            <MDBox mt={-5.3} bgColor='white' p={0.5} width={'max-content'}>
                <MDTypography gutterBottom variant='caption' sx={{ color: getColore() }}>
                    {props.model.label}
                </MDTypography>
            </MDBox>
            {/* <PopUpFieldList index={index} model={props.model.details.subModel} aperto={aperto} dati={dati} chiuso={(dati, aggiungi) => popUpChiuso(dati, aggiungi)} titolo={'Aggiungi elemento'} /> */}

            <DataTable table={{ columns: colonneTabella, rows: righeTabella }} entriesPerPage={false} showTotalEntries={false} isSorted={false} noEndBorder={false} />
            <div className='contenitore-bottoni-destra'>
                <MDButton color='primary' onClick={() => modifica(-1)}>
                    aggiungi
                </MDButton>
            </div>
            <MDTypography variant='caption' color='error'>
                {props.errore}
            </MDTypography>
        </MDBox>
    )
}

export function OutputList(props) {
    let colonneTabella = props.model.details.subModel.parts.reduce((acc, model) => {
        acc.push({ Header: model.label, accessor: model.label, width: '20%' })
        return acc
    }, [])

    let righeTabella = props.value.map((valore) => {
        return colonneTabella.reduce((acc, colonna) => {
            if (colonna.Header !== 'azioni') {
                let indiceModel = props.model.details.subModel.parts.findIndex((el) => {
                    return el.label === colonna.Header
                })
                let model = props.model.details.subModel.parts[indiceModel]
                let valoreDaVrdere = { [model.id]: valore[model.id] }
                model.details.noLabel = true
                acc[colonna.Header] = (
                    <DefaultCell>
                        <FormOutput form={{ parts: [model] }} dati={valoreDaVrdere} isSubInput={true} />
                    </DefaultCell>
                )
            }
            return acc
        }, {})
    })

    const mostraDati = () => {
        if (props.skeleton) {
            return <Skeleton animation='wave' width='100%' height='200px' />
        }
        return <DataTable table={{ columns: colonneTabella, rows: righeTabella }} entriesPerPage={false} showTotalEntries={false} isSorted={false} noEndBorder={false} />
    }

    return (
        <MDBox width='100%' maxWidth='100%'>
            <MDTypography variant='caption' color='text'>
                {props.model.label}
            </MDTypography>

            <MDBox maxWidth='100%' width='100%' p={1}>
                {mostraDati()}
            </MDBox>
        </MDBox>
    )
}
