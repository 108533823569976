import React from 'react'
import useInputFileImmagini from 'customHooks/useInputFileImmagini'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'
import { Grid, Icon } from '@mui/material'
import MDButton from 'components/MDButton'
import ElementoGalleria from 'componenti/elementoGalleria'
import ElementoEtichettaForm from './elementoEtichettaForm'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function elementoPaginaModifcaImmagini(props) {
    const indietro = () => window.history.back()

    const [renderInputLogo, caricaLogo] = useInputFileImmagini('input-logo', props.nuovoLogo)
    const [renderInputImmagine, caricaImmagine] = useInputFileImmagini('input-immagine', props.nuovaImmagine)
    const renderNoImmagine = (
        <>
            <MDBox className='centrato' p={5} bgColor='grey-500' borderRadius='lg' sx={{ width: '100%', height: '300px' }}>
                <MDBox className='centrato' sx={{ display: 'flex', width: '100%', height: '100%', cursor: 'pointer' }} onClick={caricaLogo}>
                    <AddCircleOutlineRoundedIcon sx={{ width: '80%', height: '80%' }} color='dark' />
                </MDBox>
            </MDBox>
        </>
    )

    const renderImmagineLogo = () => {
        if (props.logo === '') return renderNoImmagine
        return <MDBox component='img' src={props.logo} alt='Logo' shadow='lg' borderRadius='lg' width='100%' />
    }

    const renderBottoniLogo = () => {
        if (props.logo === '') return null
        return (
            <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                    <MDButton size='small' variant='text' color='dark' onClick={caricaLogo}>
                        <Icon sx={{ mr: 1 }}> edit </Icon>
                        modifica
                    </MDButton>
                </Grid>
                <Grid item xs={6}>
                    <MDButton size='small' variant='text' color='error' onClick={props.eliminaLogo}>
                        <Icon sx={{ mr: 1 }}> delete </Icon>
                        elimina
                    </MDButton>
                </Grid>
            </Grid>
        )
    }

    const renderLogo = (
        <MDBox className='flex-verticale'>
            <MDBox className='flex-verticale centrato' m='auto' sx={{ width: '100%', maxWidth: '500px' }}>
                <MDTypography sx={{ mb: 2 }} fontWeight='bold'>
                    <ElementoEtichettaForm testo='Logo' />
                </MDTypography>
                {renderImmagineLogo()}
                {renderBottoniLogo()}
            </MDBox>
        </MDBox>
    )

    const renderGalleria = (
        <>
            <MDBox className='flex-verticale' my={2}>
                <ElementoEtichettaForm testo='Immagini aggiuntive' />
                <MDBox className='flex-orizzontale'>
                    <MDTypography color='dark' className='centrato' sx={{ maxWidth: 'max-content' }}>
                        {props.testo}
                    </MDTypography>
                    <MDButton sx={{ ml: 2 }} color='dark' variant='contained' onClick={caricaImmagine}>
                        <Icon sx={{ mr: 1 }}>upload</Icon>
                        aggiungi
                    </MDButton>
                </MDBox>
            </MDBox>
            <ElementoGalleria immagini={props.immagini} />
        </>
    )

    return (
        <>
            <MDBox className='centrato' sx={{ cursor: 'pointer' }} width='50px' height='50px' variant='gradient' color='white' bgColor='primary' onClick={indietro}>
                <ArrowBackIcon color='white' sx={{ width: '100%', height: '100%' }} />
            </MDBox>
            {renderInputLogo}
            {renderInputImmagine}
            {renderLogo}
            {renderGalleria}
        </>
    )
}

elementoPaginaModifcaImmagini.defaultProps = {
    logo: '',
    immagini: [],
    id: '',
    testo: 'Mostra ai consumatori delle immagini rappresentino la tua impresa',
    nuovoLogo: () => {},
    nuovaImmagine: () => {},
    eliminaLogo: () => {},
}
