import React, { useState, useEffect } from 'react'
import useCache from 'customHooks/useCache'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import ElementoListaProgetti from 'componenti/elementoListaProgetti'
import { getListaProgettiInAttesa } from 'getter/getterProgetti'
import { InputSelect } from 'fields/fieldSelect'
import MDBox from 'components/MDBox'
import { getListaTuttiProgetti } from 'getter/getterProgetti'

const model = {
    id: 'scelta',
    tipo: 'fieldSelect',
    associazione: 'scelta',
    label: 'Stato:',
    value: '',
    details: {
        complex: false,
        scelte: ['attivi', 'completati', 'rifiutati', 'inAttesa', 'scaduti'],
        svuota: false,
    },
}

export default function paginaListaProgettiEtiqua(props) {
    const [selezionato, setSelezionato] = useState('inAttesa')
    const [progettiAttivi, setProgettiAttivi] = useState([])
    const [progettiCompletati, setProgettiCompletati] = useState([])
    const [progettiRifiutati, setProgettiRifiutati] = useState([])
    const [progettiInAttesa, setProgettiInAttesa] = useState([])
    const [progettiScaduti, setProgettiScaduti] = useState([])
    const [progetti, setProgetti] = useState([])
    const { listaProgettiWindow } = useCache()
    const handleSelezionato = (campo, valore) => setSelezionato(valore)

    useEffect(() => {
        var lista = []
        console.log('selezionato : ', selezionato);
        switch (selezionato) {
            case 'attivi':
                lista = [...progettiAttivi]
                break
            case 'completati':
                lista = [...progettiCompletati]
                break
            case 'rifiutati':
                lista = [...progettiRifiutati]
                break
            case 'inAttesa':
                console.log('ci vadoooo');
                lista = progettiInAttesa
                break
            case 'scaduti':
                lista = [...progettiScaduti]
                break
            default:
                break
        }
        setProgetti(lista)
    }, [selezionato, progettiAttivi, progettiCompletati, progettiRifiutati, progettiInAttesa, progettiScaduti])

    useEffect(() => {
        const { attivi, completati, rifiutati, inAttesa, scaduti } = getListaTuttiProgetti()
        setProgettiAttivi(attivi)
        setProgettiCompletati(completati)
        setProgettiRifiutati(rifiutati)
        setProgettiInAttesa(inAttesa)
        setProgettiScaduti(scaduti)
    }, [listaProgettiWindow])

    return (
        <>
            <MDBox bgColor='white' width='100%' shadow='lg' p={2} mb={2}>
                <MDBox width='100%' maxWidth='300px' mx='auto'>
                    <InputSelect value={selezionato} model={model} errore='' azione={handleSelezionato} />
                </MDBox>
            </MDBox>
            <MDBox width='100%' maxWidth='1000px' mx='auto'>
                <ElementoListaProgetti {...props} lista={progetti} noOmbra={true} testoErrore='Nessun progetto' />
            </MDBox>
        </>
    )
}
