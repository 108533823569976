import { httpsCallable } from 'firebase/functions'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'

export const SMETTI_DI_SEGUIRE_PROGETTO = async () => {
    MODIFICA_STATO_SPINNER(true)
    const oggetto = {
        idImpresa: getIdUtenteLoggato(),
        idProgetto: getIdProgettoSelezionato(),
    }
    const smettiDiSostenere = httpsCallable(functions, 'smettiDiSostenere')
    await smettiDiSostenere(oggetto)
    MODIFICA_STATO_SPINNER(false)
    return
}
