import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { OutputText } from './fieldText'

export function InputAutocomplete(props) {
    //console.log('propsssss ;' , props);
    let getValoreDaVedere = (opzioni) => {
        if (props.model.details.complex) {
            return opzioni[props.model.details.toShow]
        }
        return opzioni
    }

    let getValoreDaRitornare = (valore) => {
        if (props.model.details.toSave !== undefined && props.model.details.toSave !== '') {
            return valore[props.model.details.toSave]
        }
        return valore
    }

    let getValoreSelezionato = () => {
        let index = props.model.details.scelte.findIndex((el) => {
            return getValoreDaRitornare(el) === props.value
        })
        if (index === -1) {
            return props.model.details.complex ? { [props.model.details.toShow]: '' } : ''
        }
        return props.model.details.scelte[index]
    }

    const getProps = () => {
        if (props.model.details?.testoBianco) {
            return { color: 'white !important' }
        }
        return {}
    }

    const renderTextField = (params) => {
        params.inputProps.sx = getProps()
        return (
            <TextField
                {...params}
                color={props.model.details?.color || 'primary'}
                sx={props.model.details?.sx}
                InputLabelProps={props.model.details?.InputLabelProps}
                label={props.model.label}
                error={props.errore !== ''}
                helperText={props.errore}
                onChange={(e, valore) => {
                    props.azione(props.model.id, getValoreDaRitornare(e.target.value), false)
                }}
            />
        )
    }

    return (
        <Autocomplete
            id={props.model.id}
            options={props.model.details.scelte}
            noOptionsText={'Nessuna opzione'}
            value={getValoreSelezionato()}
            getOptionLabel={(opzioni) => getValoreDaVedere(opzioni)}
            onChange={(e, valore) => {
                props.azione(props.model.id, getValoreDaRitornare(valore), true)
            }}
            renderInput={(params) => renderTextField(params)}
        />
    )
}
export function OutputAutoComplete(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    return <OutputText skeleton={props.skeleton || false} model={model} value={props.value} />
}
