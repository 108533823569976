import React, { useEffect } from 'react'
import ElementoMap from 'componenti/elementoMap'
import MDBox from 'components/MDBox'
import { List, Divider } from '@mui/material'
import { useState } from 'react'
import ElementoMostraImpresaMappa from './elementoMostraImpresaMappa'

export default function elementoImpreseMappa(props) {
    const [lista, setLista] = useState([])
    const [selezionato, setSelezionato] = useState(null)

    const hanndleSelezionato = (elemento) => props.navigate(`paginaVediImpresa/${elemento.id}`)

    const vaiPagamento = (elemento) => props.navigate(`paginaInserisciSpesa/${elemento.id}`)

    const vediInMappa = (elemento) => setSelezionato(elemento)

    useEffect(() => {
        const nuovaLista = props.lista.map((elemento) => {
            const puntoVendita = elemento.puntoVendita
            const sedeLegale = elemento.sedeLegale
            const sede = puntoVendita === undefined ? sedeLegale : puntoVendita
            const indirizzo = `${sede?.via || ''} ${sede?.numero || ''} ${sede?.comune || ''} (${sede?.provincia || ''})`
            return Object.assign(elemento, { indirizzo: indirizzo })
        })
        setLista(nuovaLista)
    }, [props.lista])

    return (
        <MDBox bgColor='white' width='100%' shadow='lg' borderRadius='lg' p={2} mb={2} className='contenitore-mappa-imprese'>
            <MDBox borderRadius='lg' sx={{ overflow: 'hidden', height: '400px' }}>
                <ElementoMap lista={lista} selezionato={selezionato} hanndleSelezionato={vediInMappa} />
            </MDBox>
            <MDBox mt={{ xs: 3, md: 3, lg: 0 }}>
                <List>
                    {lista.map((impresa, index) => {
                        return (
                            <MDBox key={impresa.id}>
                                <ElementoMostraImpresaMappa vediBottoni={true} impresa={impresa} hanndleSelezionato={hanndleSelezionato} vediInMappa={vediInMappa} vaiPagamento={vaiPagamento} />
                                {index === lista.length + 1 ? null : <Divider />}
                            </MDBox>
                        )
                    })}
                </List>
            </MDBox>
        </MDBox>
    )
}
