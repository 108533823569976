import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

//inizio firebase config
const firebaseConfig = {"apiKey":"AIzaSyB7IUm2gw8gCuX3ur9-umVwc5k_8wwXAJI","authDomain":"etiqua-cc349.firebaseapp.com","projectId":"etiqua-cc349","storageBucket":"etiqua-cc349.appspot.com","messagingSenderId":"233309397755","appId":"1:233309397755:web:e97b5d10a4fe3a96a7065e","measurementId":"G-0SCYTKY4JS"}
//fine firebase config

const app = initializeApp(firebaseConfig)
window.db = getFirestore(app)
window.storage = getStorage(app)
window.analytics = getAnalytics(app)
window.functions = getFunctions(app)
//window.appUtilizzata = 'Osteo'
window.appUtilizzata = 'Physio'
