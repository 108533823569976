import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useForm from 'customHooks/useForm'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'

export function popUpFieldList(props) {
    const handleClose = () => props.chiudi()
    const [stato, azioni, renderForm] = useForm(props.form, props.value, false, true)

    const salva = async (e) => {
        await azioni.validaForm()
        return props.salva(stato.statoForm)
    }

    return (
        <Dialog open={props.aperto} onClose={handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle id='titolo-pop-up-field-list'>{props.titolo}</DialogTitle>
            <DialogContent dividers={true}>{renderForm}</DialogContent>
            <DialogActions>
                <ElementoBottoniForm vista={stato.vista} annulla={handleClose} valida={salva} />
            </DialogActions>
        </Dialog>
    )
}

export default popUpFieldList
