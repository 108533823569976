import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ElementoMostraLogoInLista from './elementoMostraLogoInLista'
import MDButton from 'components/MDButton'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import MDBox from 'components/MDBox'
import ReadMoreRoundedIcon from '@mui/icons-material/ReadMoreRounded'
import FmdGoodRoundedIcon from '@mui/icons-material/FmdGoodRounded'
import ShoppingCartCheckoutRoundedIcon from '@mui/icons-material/ShoppingCartCheckoutRounded'
import { isImpresaB2b } from 'getter/getterImpresa'
import { isConsumatoreLoggato } from 'getter/getterUtenteLoggato'

export default function elementoMostraImpresaMappa(props) {
    const getIndirizzo = () => {
        if (props.vediBottoni) return null
        if (props.impresa.indirizzo === undefined || props.impresa.indirizzo === '') {
            const puntoVendita = props.impresa.puntoVendita
            const sedeLegale = props.impresa.sedeLegale
            const sede = puntoVendita === undefined ? sedeLegale : puntoVendita
            const indirizzo = `${sede?.via || ''} ${sede?.numero || ''}, ${sede?.comune || ''} (${sede?.provincia || ''})`
            return indirizzo
        }
        return props.impresa.indirizzo
    }

    const handleSelezionato = () => props.hanndleSelezionato(props.impresa)

    const selezionato = () => {
        if (props.vediBottoni) return
        handleSelezionato()
    }

    const vediInMappa = () => props.vediInMappa(props.impresa)
    const vaiPagamento = () => props.vaiPagamento(props.impresa)

    const renderBottoni = () => {
        if (!props.vediBottoni) return null
        return (
            <MDBox className='contenitore-bottoni-destra' sx={{ marginTop: '0px !important', paddingTop: '0px !important' }}>
                {/* {isImpresaB2b(props.impresa.id) || !isConsumatoreLoggato() ? null : (
                    <MDButton color='dark' variant='contained' size='small' iconOnly onClick={vaiPagamento}>
                        <ShoppingCartCheckoutRoundedIcon sx={{ height: '20px', width: '20px' }} />
                    </MDButton>
                )}
 */}
                <MDButton color='dark' variant='contained' size='small' iconOnly onClick={vediInMappa}>
                    <FmdGoodRoundedIcon sx={{ height: '20px', width: '20px' }} />
                </MDButton>
                <MDButton color='dark' variant='contained' size='small' iconOnly onClick={handleSelezionato}>
                    <ReadMoreRoundedIcon sx={{ height: '20px', width: '20px' }} />
                </MDButton>
            </MDBox>
        )
    }

    return (
        <ListItem sx={{ cursor: props.vediBottoni ? '' : 'pointer', width: '100%' }} onClick={selezionato}>
            <MDBox className='flex-orizzontale centrato' sx={{justifyContent : 'flex-start', textAlign : 'left'}} mb={1}>
                <ListItemIcon>
                    <ElementoMostraLogoInLista dati={props.impresa} id={props.impresa.id} />
                </ListItemIcon>
                <ListItemText primary={props.impresa.nome} secondary={getIndirizzo()} />
                {props.vediBottoni ? null : (
                    <ListItemIcon>
                        <ArrowForwardIosRoundedIcon color='dark' />
                    </ListItemIcon>
                )}
            </MDBox>
            {renderBottoni()}
        </ListItem>
    )
}

elementoMostraImpresaMappa.defaultProps = {
    vediBottoni: false,
}
