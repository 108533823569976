import { LEGGI_RICHIESTE_CONTATTO } from 'data/azioni/LEGGI_RICHIESTE_CONTATTO'
import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { collection, query, orderBy, limit, onSnapshot, where } from 'firebase/firestore'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { profiloImpresa } from 'util'

export class watcherRichiesteContatto extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    async apriConnessione() {
        var q = query(collection(db, 'richiesteContatto'))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            let lista = []
            querySnapshot.forEach((doc) => {
                const elemento = Object.assign({}, doc.data(), { id: doc.id })
                lista.push(elemento)
            })
            LEGGI_RICHIESTE_CONTATTO(lista)
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
