import React, { useState, useEffect, useCallback } from 'react'
import { AGGIORNA_STATO_MODIFICA } from 'data/azioni/AGGIORNA_STATO_MODIFICA'
import isEqual from 'lodash-es/isEqual'
import valida from 'fields/validazione'
import PopUpModificheNonSalvateForm from 'popUp/popUpModificheNonSalvateForm'
import FormInput from 'formMaker/formInput'
import FormOutput from 'formMaker/formOutput'

export default function useForm(datiForm, dati, skeleton, noOutput, noStatoModifica, isSubInput) {
    const [statoForm, setStatoForm] = useState({})
    const [statoIniziale, setStatoIniziale] = useState({})
    const [errori, setErrori] = useState([])
    const [vista, setVista] = useState('output')
    const [popUpAperto, setPopUpAperto] = useState(false)
    const [form, setForm] = useState({ parts: [] })

    const getValoreBandiere = (model) => {
        if (dati[model.id].id === undefined) {
            return { id: [], descrizione: '' }
        }
        if (typeof dati[model.id].id === 'string') {
            if (dati[model.id].id === '') {
                return { id: [], descrizione: '' }
            } else {
                return { id: [dati[model.id].id], descrizione: dati[model.id].descrizione || '' }
            }
        }
        return { id: dati[model.id].id, descrizione: dati[model.id].descrizione || '' }
    }

    const getValore = (model) => {
        
        if (model.tipo === 'fieldSelect') {
            if(dati === undefined) return statoForm[model.id] || model.value
            if(statoForm[model.id] === undefined && dati[model.id] === undefined) return model.value
            if(vista === 'output') return dati[model.id]
            return statoForm[model.id] || dati[model.id] || model.value
        }
        if (dati === undefined) {
            return model.value
        }
        if (dati[model.id] === undefined) {
            return model.value
        }
        if (model.tipo === 'fieldSceltaBandierina') {
            return getValoreBandiere(model)
        }
        if (model.tipo === 'fieldData') {
            if (dati[model.id] === undefined) {
                return model.value
            }
            if (dati[model.id].seconds === undefined) {
                return dati[model.id]
            }
            return dati[model.id].toDate()
        }
        if(dati[model.id] !== undefined) return dati[model.id]
        return  model.value || ''
    }

    const inizializza = () => {
        let stato = {}
        let errori = []
        form.parts.forEach((model) => {
            stato[model.id] = getValore(model)
            errori.push('')
        })
        const datiCopiati = Object.assign({}, dati)
        stato = Object.assign(datiCopiati || {}, stato)
        setStatoForm(stato)
        setStatoIniziale(Object.assign({}, stato))
        setErrori(errori)
    }

    useEffect(() => {
        inizializza()
        setForm(datiForm)
        if (noOutput) vediInput()
    }, [])

    useEffect(() => {
        inizializza()
    }, [dati])
    useEffect(() => {
        inizializza()
    }, [form])

    useEffect(() => {
        if (noStatoModifica === undefined || !noStatoModifica) AGGIORNA_STATO_MODIFICA(!isPristine())
    }, [statoForm])

    const isPristine = () => {
        return isEqual(statoIniziale, statoForm)
    }

    const reset = () => {
        inizializza()
    }

    const cambiaVista = (scelta) => {
        setVista(scelta)
    }

    const vediInput = () => {
        cambiaVista('input')
    }

    const vediOutput = () => {
        cambiaVista('output')
    }

    const annullaControlla = () => {
        if (isPristine()) {
            reset()
            vediOutput()
        }
        if (!isPristine()) {
            setPopUpAperto(true)
        }
    }

    const chiudiPopUp = useCallback(
        (cambiaVista) => {
            setPopUpAperto(false)
            if (cambiaVista) {
                reset()
                vediOutput()
            }
        },
        [vista],
    )

    const aggiornaStatoForm = useCallback(
        (proprieta, valore) => {
            let nuovoStato = Object.assign({}, statoForm)
            nuovoStato[proprieta] = valore
            setStatoForm(nuovoStato)
        },
        [statoForm],
    )

    const validaForm = async () => {
        let { errore, listaErrori } = await valida(statoForm, form)
        setErrori(listaErrori)
        if (errore) {
            throw new Error()
        }
        AGGIORNA_STATO_MODIFICA(false)
        return
        /*   if (!errore) {
            await azioni.valida()
            AGGIORNA_STATO_MODIFICA(false)
            reset()
            if (noOutput === undefined || !noOutput) vediOutput()
        }  */
    }

    const renderInput = (
        <React.Fragment>
            <FormInput isSubInput={isSubInput || false} form={form} dati={statoForm} azione={aggiornaStatoForm} errori={errori} />
            <PopUpModificheNonSalvateForm aperto={popUpAperto} chiudi={chiudiPopUp} />
        </React.Fragment>
    )

    const renderOutput = <FormOutput skeleton={skeleton || false} form={form} dati={statoForm} />
    const renderForm = vista === 'output' ? renderOutput : renderInput

    const azioniDaEsportare = { isPristine, reset, vediInput, vediOutput, annullaControlla, validaForm, setForm, inizializza }
    const statoDaEsportare = { statoForm, vista }

    return [statoDaEsportare, azioniDaEsportare, renderForm]
}
