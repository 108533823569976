import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getDatiImpresaDonazione } from 'getter/getterImpresa'
import { Divider, Grid } from '@mui/material'
import MDButton from 'components/MDButton'
import { InputText } from 'fields/fieldText'
import useCache from 'customHooks/useCache'
import ElementoAutenticazioneInserimentoSpesa from 'componenti/elementoAutenticazioneInserimentoSpesa'
import { getDatiImpresa } from 'getter/getterImpresa'
import brand from 'assets/logoEtiqua/ORIZZONTALE POSITIVO payoff.svg'
import { NUOVA_RICHIESTA_ACQUISTO } from 'data/azioni/NUOVA_RICHIESTA_ACQUISTO'
import ElementoFeedbackUtente from 'componenti/elementoFeedbackUtente'
import { isImpresaB2b } from 'getter/getterImpresa'

const model = {
    id: 'importo',
    tipo: 'fieldText',
    associazione: 'importo',
    label: 'Inserisci importo speso:',
    value: null,
    details: { type: 'number', inputProps: { min: 0, max: 10000000 } },
}

export const paginaInserisciSpesa = (props) => {
    const [valore, setValore] = useState(null)
    const [dati, setDati] = useState({})
    const { listaProgettiWindow, listaImpreseWindow } = useCache()
    const [errore, setErrore] = useState('')
    const [finito, setFinito] = useState(false)
    const handleValue = (campo, value) => setValore(value)
    const cambiaPagina = () => props.navigate('paginaRichiesteAcquisto')

    const erroreCifreDecimali = (valore) => {
        const numStr = valore.toString()
        const decimalIndex = numStr.indexOf('.')
        if (decimalIndex === -1) {
            return false
        }
        const decimalPlaces = numStr.length - decimalIndex - 1
        return decimalPlaces > 2
    }

    const conferma = async () => {
        setErrore('')
        if (valore <= 0) {
            setErrore('Importo non valido')
            return
        }
        if (erroreCifreDecimali(valore)) {
            setErrore('Non possono esserci piu di due cifre decimali')
            return
        }
        await NUOVA_RICHIESTA_ACQUISTO(valore, dati.percentuale, dati.idProgetto)
        setFinito(true)
    }

    useEffect(() => {
        setDati(props.dati)
    }, [props.dati])
    useEffect(() => {
        const nuovi = getDatiImpresaDonazione()
        setDati(nuovi)
    }, [listaProgettiWindow, listaImpreseWindow])

    const renderParteSuperiore = (
        <MDBox mb={3} className='flex-verticale centrato' mx='auto' sx={{ maxWidth: '1000px' }} p={2}>
            <MDTypography variant='h5' fontWeight='bold' color='dark'>
                {dati.nomeImpresa}
            </MDTypography>
            <MDTypography variant='body2' color='text'>
                {dati.indirizzoImpresa}
            </MDTypography>
            <MDTypography sx={{ mt: 2 }}>
                il {dati.percentuale}% dell’importo speso andrà a finanziare il progetto {dati.nomeProgetto}
            </MDTypography>
        </MDBox>
    )

    const renderInput = (
        <MDBox className='centrato'>
            <InputText value={valore} azione={handleValue} model={model} errore={errore} />
            <MDTypography variant='h2' color='dark' fontWeight='bold' sx={{ ml: 1 }}>
                €
            </MDTypography>
        </MDBox>
    )

    const renderBottone = (valore) => {
        return (
            <Grid item xs={4}>
                <MDBox color='dark' shadow='lg' coloredShadow='dark' className='bottotne-donazione-consumatore centrato'>
                    <MDTypography color='dark' fontWeight='bold' variant='h3'>
                        {valore}
                    </MDTypography>
                </MDBox>
            </Grid>
        )
    }

    const renderTastiera = (
        <MDBox mt={3} className='centrato' sx={{ maxWidth: '1000px' }} mx='auto'>
            <Grid container spacing={2}>
                {renderBottone(1)}
                {renderBottone(2)}
                {renderBottone(3)}
                {renderBottone(4)}
                {renderBottone(5)}
                {renderBottone(6)}
                {renderBottone(7)}
                {renderBottone(8)}
                {renderBottone(9)}
                {renderBottone(',')}
                {renderBottone(0)}
                {renderBottone('Canc')}
            </Grid>
        </MDBox>
    )

    const renderParteInferiore = (
        <MDBox className='centrato flex-verticale' sx={{ maxWidth: '1000px' }} mx='auto' mt={3}>
            <MDTypography>conferma l’importo e attendi la risposta dell’esercente</MDTypography>
            <MDButton variant='contained' color='primary' sx={{ mt: 2 }} onClick={conferma}>
                conferma{' '}
            </MDButton>
        </MDBox>
    )

    const renderInserimentoSpesa = (
        <>
            {renderParteSuperiore}
            {renderInput}
            {renderParteInferiore}
        </>
    )

    const cosaVedere = () => {
        if (props.isB2b) {
            return (
                <MDTypography color='text' align='center'>
                    L'impresa non puo accettare pagamenti dai consumatori
                </MDTypography>
            )
        }
        if (props.dati?.idProgetto === undefined) {
            return (
                <MDTypography color='text' align='center'>
                    L'impresa attualmente non sostiene nessun progetto
                </MDTypography>
            )
        }
        if (props.isSenzaCrediti) {
            return (
                <MDTypography color='text' align='center'>
                    Le donazioni per questa impresa sono momentaneamente sospese
                </MDTypography>
            )
        }
        if (props.isLoggato) return renderInserimentoSpesa
        return <ElementoAutenticazioneInserimentoSpesa />
    }

    if (finito) return <ElementoFeedbackUtente testo="Richiesta di acquisto inviata. Attendi la conferma da parte dell'impresa" testoBottone={'vedi stato'} azione={cambiaPagina} />

    return (
        <ElementoRiquadroPagine propsBox={{ mx: 'auto', maxWidth: '330px', borderRadius: 'lg', shadow: 'lg', mt: { xs: 7, md: 20 } }}>
            <MDBox component='img' src={brand} alt='Brand' height='auto' width='100%' />
            <Divider />
            {cosaVedere()}
        </ElementoRiquadroPagine>
    )
}

function mapStateToProps(state) {
    const impresa = getDatiImpresa()
    return {
        dati: getDatiImpresaDonazione(),
        isLoggato: state.statoUtente.stato === 4,
        isSenzaCrediti: impresa.crediti === undefined || impresa.crediti === 0,
        isB2b : isImpresaB2b(state.url[4])
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaInserisciSpesa)
