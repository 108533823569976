import { ref, uploadBytes } from 'firebase/storage'
import { doc, updateDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { newId } from 'data/strumenti'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'

export const NUOVA_IMMAGINE_PROGETTO = async (thumbnail, immagine) => {
    console.log('immagineeee')
    MODIFICA_STATO_SPINNER(true)
    const idProgetto = getIdProgettoSelezionato()
    const idImmagine = newId()

    const caricaInStorage = async (file, path) => {
        return await uploadBytes(ref(storage, path), file)
    }

    const aggiornaDb = async () => {
        return await updateDoc(doc(db, 'progetti', idProgetto), { [`immagini.${idImmagine}`]: new Date() })
    }

    const pathThumbNail = `${idProgetto}/immagini/${idImmagine}/thumbNail.png`
    const pathOriginale = `${idProgetto}/immagini/${idImmagine}/originale.png`

    await Promise.all([caricaInStorage(thumbnail, pathThumbNail), caricaInStorage(immagine, pathOriginale), aggiornaDb()])
    MODIFICA_STATO_SPINNER(false)
    return
}
