import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { doc, onSnapshot, query, collection } from 'firebase/firestore'
import { LEGGI_PROGETTO } from 'data/azioni/LEGGI_PROGETTO'
import { LEGGI_AGGIORNAMENTI_PROGETTO } from 'data/azioni/LEGGI_AGGIORNAMENTI_PROGETTO'

export class watcherProgetto extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        store.subscribe(() => {
            this.update()
        })
        this.update()
    }

    update() {
        const state = store.getState()
        const parteUrl = state.url[3]
        if (parteUrl === 'paginaVediProgetto' || parteUrl === 'paginaSostieniProgetto' || parteUrl === 'paginaModificaImmaginiProgetto') {
            this.scegliAzioni(this.idDaGuardare, store.getState().url[4])
        }
    }

    apriConnessione(nuovoId) {
        store.dispatch({
            type: 'SVUOTA_REDUCER_PROGETTO',
        })
        this.funzioneConnessione = onSnapshot(doc(db, 'progetti', nuovoId), (documento) => {
            LEGGI_PROGETTO(documento.data())
        })
        const q = query(collection(db, 'progetti', nuovoId, 'aggiornamenti'))
        this.funzioneConnessioneAggiornamenti = onSnapshot(q, (querySnapshot) => {
            var aggiornamenti = []
            querySnapshot.forEach((aggiornamento) => {
                aggiornamenti.push(Object.assign({},aggiornamento.data(), {id : aggiornamento.id}))
            })
            LEGGI_AGGIORNAMENTI_PROGETTO(aggiornamenti)
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
            this.funzioneConnessioneAggiornamenti()
        }
    }
}
