/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from 'react'

// react-router components
import { useLocation } from 'react-router-dom'

// @mui material components
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Material Dashboard 2 PRO React examples
import Sidenav from 'examples/Sidenav'
import Configurator from 'examples/Configurator'

// Material Dashboard 2 PRO React themes
import theme from 'assets/theme'
import themeRTL from 'assets/theme/theme-rtl'

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from 'assets/theme-dark'
import themeDarkRTL from 'assets/theme-dark/theme-rtl'

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl'
import createCache from '@emotion/cache'

// Material Dashboard 2 PRO React routes

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from 'context'

// Images
import brandWhite from 'assets/logoEtiqua/ORIZZONTALE NEGATIVO payoff.svg'
import brandWhiteClosed from 'assets/logoEtiqua/NEGATIVO.svg'

import BaseLayout from './examples/LayoutContainers/DashboardLayout'
import DefaultNavbar from './examples/Navbars/DashboardNavbar'
import Routing from './routing'

import { connect } from 'react-redux'
import ElementoMostraPopUp from './componenti/elementoMostraPopUp'
import { getRotte } from 'getter/getRotte'

import ElementoSpinner from './componenti/elementoSpinner'

export function LayoutApp(props) {
    const [controller, dispatch] = useMaterialUIController()
    const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller
    const [onMouseEnter, setOnMouseEnter] = useState(false)
    const [rtlCache, setRtlCache] = useState(null)
    const { pathname } = useLocation()

    // Cache for the rtl
    useMemo(() => {
        const cacheRtl = createCache({
            key: 'rtl',
            stylisPlugins: [rtlPlugin],
        })

        setRtlCache(cacheRtl)
    }, [])

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false)
            setOnMouseEnter(true)
        }
    }

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true)
            setOnMouseEnter(false)
        }
    }

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator)

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute('dir', direction)
    }, [direction])

    // Setting page scroll to 0 when changing the route

    const configsButton = (
        <MDBox display='flex' justifyContent='center' alignItems='center' width='3.25rem' height='3.25rem' bgColor='white' shadow='sm' borderRadius='50%' position='fixed' right='2rem' bottom='2rem' zIndex={99} color='dark' sx={{ cursor: 'pointer' }} onClick={handleConfiguratorOpen}>
            <Icon fontSize='small' color='inherit'>
                settings
            </Icon>
        </MDBox>
    )

    //const brandDaVedere = (transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === 'dashboard' && (
                <>
                    <Sidenav color={sidenavColor} brand={brandWhite} brandClosed={brandWhiteClosed} brandName='' routes={props.rotte} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} />
                    <Configurator />
                </>
            )}
            {layout === 'vr' && <Configurator />}
            <BaseLayout stickyNavbar>
                <div className='toolbar-post-login'>
                    <DefaultNavbar></DefaultNavbar>
                </div>
                <Routing />
                <ElementoSpinner />
                <ElementoMostraPopUp />
            </BaseLayout>
        </ThemeProvider>
    )
}

function stateChanged(state, ownProps) {
    return {
        rotte: getRotte(),
    }
}

const mapDispatchToProps = {}

export default connect(stateChanged, mapDispatchToProps)(LayoutApp)
