import React, { useState, useEffect } from 'react'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

export default function useStepperOrizzontale(stepsDaVedere) {
    const [steps, setSteps] = useState(stepsDaVedere)
    const [step, setStep] = useState(0)

    const avanti = () => {
        setStep(step + 1)
    }
    const indietro = () => {
        setStep(step - 1)
    }

    const isUltimoStep = () => {
        return step === steps.length - 1
    }

    const reset = () => {
        setStep(0)
    }

  /*   useEffect(() => {
        setSteps(stepsDaVedere)
    }, [stepsDaVedere]) */

    const renderStepper = (
        <Stepper activeStep={step} alternativeLabel>
            {steps.map((label, index) => (
                <Step key={label} completed={index < step}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    )

    return { renderStepper, step, steps, avanti, indietro, setSteps, setStep, isUltimoStep, reset }
}
