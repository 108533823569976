import { baseWatcher } from './baseWatcher'
import { collection, onSnapshot } from 'firebase/firestore'
import { profiloImpresa } from 'util'

export class watcherCacheImprese extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    apriConnessione() {
        this.funzioneConnessione = onSnapshot(collection(db, profiloImpresa.cache), (documenti) => {
            var imprese = []
            documenti.forEach((documento) => {
                imprese.push(documento.data())
            })
            window.listaImprese = imprese
            window.dispatchEvent(new CustomEvent('cache-cambiata', { detail: 'imprese' }))
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
