import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import DefaultBackgroundCard from 'examples/Cards/BackgroundCards/DefaultBackgroundCard'
import RotatingCard from 'examples/Cards/RotatingCard'
import RotatingCardFront from 'examples/Cards/RotatingCard/RotatingCardFront'
import RotatingCardBack from 'examples/Cards/RotatingCard/RotatingCardBack'
import MDProgress from 'components/MDProgress'
import MDTypography from 'components/MDTypography'
import { Divider, Grid } from '@mui/material'
import logo from 'assets/logoEtiqua/ORIZZONTALE POSITIVO.svg'
import { getEnteDaId } from 'getter/getterEnte'
import { getDatiCardProgetto } from 'getter/getterProgetti'
import { getUrlDaStorage } from 'getter/getterimmagini'

export default function elementoMostraProgetto(props) {
    const [dati, setDati] = useState({ impatti: [] })
    const [url, setUrl] = useState('')
    if (props.progetto === undefined) return null
    const seleziona = () => {
        props.navigate(`paginaVediProgetto/${props.progetto.id}`)
    }

    const progress = () => {
        return (
            <MDBox mt={1} bgColor='white' borderRadius='md' sx={{ justifyContent: 'center', display: 'flex', width: '100%', boxSizig: 'border-box', flexDirection: 'column' }} p={2}>
                <MDProgress value={(props.progetto.traguardi.totale / 100) * props.progetto.totaleRaccolto} color='info' variant='gradient' label />
                <MDBox mt={1} color='dark' display='flex' sx={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}>
                    <MDTypography color='info' fontWeight='bold'>
                        Raccolti {props.progetto.totaleRaccolto}
                    </MDTypography>

                    <MDTypography color='dark'>/{props.progetto.traguardi.totale} budget</MDTypography>
                </MDBox>
            </MDBox>
        )
    }

    useEffect(async () => {
        const nuoviDati = getDatiCardProgetto(props.progetto.id)
        //console.log('datiiiii : ', nuoviDati)
        setDati(nuoviDati)
        if (props.progetto.logo === undefined || props.progetto.logo === '') setUrl('')
        if (props.progetto.logo !== undefined && props.progetto.logo !== '') setUrl(await getUrlDaStorage(`${props.progetto.id}/logo/originale.png`))
    }, [props.progetto])

    const immagine = (
        <MDBox
            className='centrato immagine-card-progetti'
            sx={{
                height: '12.5rem',
                backgroundImage: `url(${url}`,
            }}></MDBox>
    )

    return (
        <MDBox bgColor='white' shadow='lg' onClick={seleziona} sx={{ cursor: 'pointer', width: '100%', height: '100%', minHeight: '300px', color: '#d2d6da' }}>
            {url === '' ? <MDBox bgColor='primary' variant='gradient' className='immagine-progetto' /> : immagine}
            <MDBox p={2}>
                <MDTypography color='dark' variant='h4'>
                    {dati.nome}
                </MDTypography>
                <MDTypography variant='body1' color='info'>
                    {dati.ente}
                </MDTypography>
                <Divider />
                {/* <MDTypography sx={{ mb: 1 }} variant='body1' color='text'>
                Lorem ipsum dolor sit amet consectetur. Sit quisque aenean egestas nec enim sagittis ac lorem. Amet egestas pellentesque tincidunt diam elementum ac.
            </MDTypography> */}
                <MDProgress value={dati.percentuale} color='info' variant='gradient' label />

                <MDBox mt={1} color='dark' display='flex'>
                    <MDTypography color='dark'>€ {dati.budget} budget</MDTypography>
                </MDBox>
                <MDTypography color='info' fontWeight='bold'>
                    Raccolti € {dati.totale}
                </MDTypography>
                <MDTypography>{dati.scadenza} giorni alla fine</MDTypography>
                <Grid container spacing={1} direction={'column'} mt={1}>
                    {dati.impatti.map((impatto, index) => {
                        return (
                            <Grid key={`impatto-${index}-${impatto.numero}`} item>
                                <MDBox className='flex-orizzontale' p={1} sx={{ color: impatto.colore, border: 'solid 0.5px', width: '100%', height: 'max-content', mr: 1 }}>
                                    <Grid container direction='row'>
                                        <Grid item xs='auto' md='auto' lg='auto'>
                                            <MDBox pr={1} className='centrato' sx={{ borderRight: 'solid 0.5px', color: impatto.colore, width: 'max-content !important', height: '100%' }}>
                                                <MDTypography variant='body2' sx={{ color: impatto.colore }} fontWeight='medium'>
                                                    {impatto.numero}
                                                </MDTypography>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={true} md={true} lg={true}>
                                            <MDBox className='centrato' sx={{ textAlign: 'left', justifyContent: 'flex-start' }}>
                                                <MDTypography variant='body2' sx={{ color: impatto.colore, ml: 1 }} fontWeight='medium'>
                                                    {impatto.testo}
                                                </MDTypography>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Grid>
                        )
                    })}
                </Grid>
            </MDBox>
        </MDBox>
    )

    return (
        <DefaultBackgroundCard
            image='https://bit.ly/3zv9PMF'
            label=''
            title={props.progetto.nome}
            description={progress()}
            action={{
                type: 'internal',
                route: seleziona,
                label: 'Vedi progetto',
            }}
        />
    )

    return (
        <RotatingCard>
            <RotatingCardFront image='https://bit.ly/3G5JXJZ' icon='touch_app' title={props.progetto.nome} description={props.progetto.descrizione} />
            <RotatingCardBack image='https://bit.ly/32ZoTGx' title='Scopri di piu' description='You will save a lot of time going from prototyping to full-functional code because all elements are implemented.' action={{ type: 'internal', route: `paginaVediProgetto/${props.progetto.id}`, label: 'vedi progetto' }} />
        </RotatingCard>
    )

    return (
        <MDBox onClick={seleziona} p={2} bgColor='white' shadow='md' color='info' borderRadius='lg' sx={{ cursor: 'pointer', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.progetto.nome}
        </MDBox>
    )
}
