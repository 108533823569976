import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import MDButton from 'components/MDButton'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import colors from 'assets/theme/base/colors'
import MDBox from 'components/MDBox'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import Slide from '@mui/material/Slide'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />
})

export default function popUpElimina(props) {
    const handleClose = () => {
        props.chiudi()
    }
    const elimina = async () => {
        await props.parametro.azione()
        if (props.parametro.noAlert) {
            handleClose()
            return
        }
        handleClose()
        //APRI_POP_UP({ id: 'alert', parametro: { severity: 'success', testo: 'Operazione completata' } })
    }

    const renderBottoni = () => {
        if (props.parametro.azione === undefined)
            return (
                <MDButton color='white' variant='contained' onClick={() => handleClose()}>
                    ok
                </MDButton>
            )
        return (
            <>
                <MDButton color='white' variant='text' onClick={() => elimina()}>
                    si
                </MDButton>
                <MDButton color='white' variant='contained' onClick={() => handleClose()}>
                    no
                </MDButton>
            </>
        )
    }
    return (
        <Dialog PaperProps={{ sx: { backgroundColor: colors.error.main } }} TransitionComponent={Transition} open={props.aperto} onClose={() => handleClose()} scroll={'paper'} aria-labelledby='titolo-pop-up-field-list'>
            <DialogTitle sx={{ color: colors.white.main }} id='titolo-pop-up-field-list'>
                ATTENZIONE
            </DialogTitle>
            <DialogContent sx={{ color: colors.white.main }}>
                <MDBox sx={{ margin: 'auto' }} width='200px' height='200px' p={2}>
                    <ErrorOutlineRoundedIcon sx={{ height: '100%', width: '100%' }} color='white' />
                </MDBox>
                {props.parametro?.testo || 'ciao'}
            </DialogContent>
            <DialogActions>{renderBottoni()}</DialogActions>
        </Dialog>
    )
}
