import * as validator from './validator'

const isErrore = (array) => {
    let errore = false
    array.forEach((parte, index) => {
        if (typeof parte !== 'string') {
            let test = isErrore(parte)
            if (test) {
                errore = true
            }
        } else {
            if (parte !== '') {
                errore = true
            }
        }
    })
    return errore
}

const getPromesseValidazioni = async (oggetto, partiModel) => {
    let prova = partiModel.map((model, index) => {
        if (model.tipo === 'fieldSubInput') {
            return getPromesseValidazioni(oggetto[model.id], model.details.subModel.parts)
        }
        let valore = oggetto !== undefined ? oggetto[model.id] || model.value : model.value
        let validazione = model.validazione || ''
        if (validazione !== '') {
            return getValidazione(validazione, valore, model)
        }
        if (validazione === '') {
            return fintaValidazione()
        }
    })
    let result = await Promise.all(prova)
    return result
}

const fintaValidazione = () => {
    return new Promise((resolve, reject) => {
        resolve('')
    })
}

const getValidazione = async (validazione, valore, model) => {
    /*   console.log('guarda : ', model.id, ' = ', valore) */
    try {
        await validator[validazione](valore, model)
        return ''
    } catch (error) {
        return error
    }
}

const valida = async (statoForm, form) => {
    let promesseValidazione = await getPromesseValidazioni(statoForm, form.parts)
    let errore = isErrore(promesseValidazione)
    return {
        errore: errore,
        listaErrori: promesseValidazione,
    }
}

export default valida
