import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import { getUrlDaStorage } from 'getter/getterimmagini'

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { NUOVO_LOGO_UTENTE } from 'data/azioni/NUOVO_LOGO_UTENTE'
import { getLogoUtenteLoggato, getListaImmaginiUtenteLoggato, getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { ELIMINA_LOGO_UTENTE } from 'data/azioni/ELIMINA_LOGO_UTENTE'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { NUOVA_IMMAGINE_UTENTE } from 'data/azioni/NUOVA_IMMAGINE_UTENTE'
import { ELIMINA_IMMAGINE_UTENTE } from 'data/azioni/ELIMINA_IMMAGINE_UTENTE'
import ElementoPaginaModifcaImmagini from 'componenti/elementoPaginaModifcaImmagini'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { profiloImpresa } from 'util'
import { profiloEnte } from 'util'

export const paginaModificaImmagini = (props) => {
    const nuovoLogo = (thumbNail, originale) => NUOVO_LOGO_UTENTE(thumbNail.blob, originale)
    const nuovaImmagine = (thumbNail, originale) => NUOVA_IMMAGINE_UTENTE(thumbNail.blob, originale)
    const eliminaLogo = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: 'Sei sicuro di voler elimanre il logo?', azione: () => ELIMINA_LOGO_UTENTE() } })
    const eliminaImmagine = (id) => APRI_POP_UP({ id: 'elimina', parametro: { testo: 'Sei sicuro di voler elimanre questa immagine?', azione: () => ELIMINA_IMMAGINE_UTENTE(id) } })

    const [immagini, setImmagini] = useState([])
    const [logo, setLogo] = useState('')

    const getUrlImmagine = async (immagine) => {
        const pathThumbNail = `${props.id}/immagini/${immagine}/thumbNail.png`
        const pathOriginale = `${props.id}/immagini/${immagine}/originale.png`
        const url = await Promise.all([getUrlDaStorage(pathThumbNail), getUrlDaStorage(pathOriginale)])
        return { thumbNail: url[0], originale: url[1], id: immagine }
    }

    useEffect(async () => {
        if (props.listaImmagini.length === 0) {
            setImmagini([])
            return
        }

        const promesse = props.listaImmagini.map(async (immagine) => {
            return await getUrlImmagine(immagine)
        })

        const risultati = await Promise.all(promesse)
        const lista = risultati.map((result) => {
            return {
                src: result.originale,
                thumbnail: result.thumbNail,
                alt: result.id,
                icona: <DeleteRoundedIcon color='error' onClick={() => eliminaImmagine(result.id)} />,
            }
        })

        const nuoveImmagini = getListaImmaginiUtenteLoggato()
        if (nuoveImmagini.length !== 0) setImmagini(lista)
    }, [props.listaImmagini])

    useEffect(async () => {
        if (props.idLogo === '') {
            setLogo('')
            return
        }
        const path = `${props.id}/logo/thumbNail.png`
        setLogo(await getUrlDaStorage(path))
    }, [props.idLogo])

    const getTesto = () => {
        if (props.profilo === profiloImpresa.tipo) return 'Mostra ai consumatori delle immagini rappresentino la tua impresa'
        if (props.profilo === profiloEnte.tipo) return 'Mostra ai consumatori delle immagini rappresentino la tua non profit'
    }

    return (
        <ElementoRiquadroPagine propsBox={{ shadow: 'lg' }}>
            <ElementoPaginaModifcaImmagini testo={getTesto()} logo={logo} immagini={immagini} nuovoLogo={nuovoLogo} nuovaImmagine={nuovaImmagine} eliminaLogo={eliminaLogo} />
        </ElementoRiquadroPagine>
    )
}

const mapStateToProps = (state) => ({
    idLogo: getLogoUtenteLoggato(),
    listaImmagini: getListaImmaginiUtenteLoggato(),
    id: getIdUtenteLoggato(),
    profilo: getProfiloUtenteLoggato(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaModificaImmagini)
