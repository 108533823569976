export const formConsumatoreResponsabile = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome :',
            value: '',
            details: {
                type: 'text',
                grid : {lg : {input : 4}}
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'cognome',
            tipo: 'fieldText',
            associazione: 'cognome',
            label: 'Cognome :',
            value: '',
            details: {
                type: 'text',
                grid : {lg : {input : 4}}
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'accettazioneTerminiECondizioni',
            tipo: 'fieldAccettaTerminiECondizioni',
            associazione: 'accettazioneTerminiECondizioni',
            label: 'Accetto termini e condizioni:',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
                testoLink: 'scopri di piu',
                //link: 'terminiECondizioni.html',
            },
            validazione: 'controllaSeVero',
        }
        
    ],
}
export const formConsumatoreResponsabileNoCOndizioni = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome :',
            value: '',
            details: {
                type: 'text',
                grid : {lg : {input : 4}}
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'cognome',
            tipo: 'fieldText',
            associazione: 'cognome',
            label: 'Cognome :',
            value: '',
            details: {
                type: 'text',
                grid : {lg : {input : 4}}
            },
            validazione: 'testoRichiesto',
        },
    ],
}
