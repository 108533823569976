import React from 'react'
import MDBox from 'components/MDBox'

export default function elementoRiquadroPagine(props) {
    return (
        <MDBox bgColor='white' width='100%' display='flex' flexDirection='column' p={2} {... props.propsBox}>
            {props.children}{' '}
        </MDBox>
    )
}
