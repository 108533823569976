import React from 'react'
import { OutputText } from 'fields/fieldText'
import { OutputTextArea } from 'fields/fieldTextArea'
import { OutputSelect } from 'fields/fieldSelect'
import { listaSdgs } from 'data/sdgs'
import MDBox from 'components/MDBox'
import { Divider, Grid } from '@mui/material'
import MDButton from 'components/MDButton'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import MDTypography from 'components/MDTypography'

const modelSdg = {
    id: 'sdg',
    tipo: 'fieldSelect',
    associazione: 'sdg',
    label: 'sdg:',
    value: '',
    details: {
        scelte: listaSdgs,
        complex: 'true',
        toShow: 'number',
        toSave: 'number',
    },
}

const modelValore = {
    id: 'valore',
    tipo: 'fieldText',
    associazione: 'valore',
    label: 'valore:',
    value: '',
    details: {
        type: 'number',
    },
}

const modelDescrizione = {
    id: 'descrizione',
    tipo: 'fieldTextArea',
    associazione: 'descrizione',
    label: 'Descrizione :',
    value: '',
    details: {
        type: 'text',
    },
    validazione: 'testoRichiesto',
}
const modelTitolo = {
    id: 'titolo',
    tipo: 'fieldText',
    associazione: 'titolo',
    label: 'Titolo :',
    value: '',
    details: {
        type: 'text',
    },
    validazione: 'testoRichiesto',
}
const modelTestoCard = {
    id: 'testo card',
    tipo: 'fieldText',
    associazione: 'testo card',
    label: 'Testo card :',
    value: '',
    details: {
        type: 'text',
    },
    validazione: 'testoRichiesto',
}

export default function elementoMostraInserimentoImpatto(props) {
    const renderImmagine = (immagine, sfondo) => {
        return (
            <>
                <MDBox borderRadius='lg' bgColor={sfondo ? 'info' : 'white'} height='max-content' width='max-content'>
                    <MDBox component='img' src={immagine} />
                </MDBox>
            </>
        )
    }

    const elimina = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: 'Sei sicuro di voler elimnare l elemento?', azione: () => props.elimina(props.index) } })

    return (
        <MDBox width='100%'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <OutputSelect value={props.dati.sdg} model={modelSdg} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutputText value={props.dati.valore} model={modelValore} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutputText value={props.dati.titolo} model={modelTitolo} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutputText value={props.dati.testoCard} model={modelTestoCard} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <OutputTextArea value={props.dati.descrizione} model={modelDescrizione} />
                </Grid>
                {props.nascondiImmagini ? null : (
                    <>
                        <Grid item xs={12} md={6}>
                            <MDTypography>immagine bianca</MDTypography>
                            {renderImmagine(props.dati.immagini.bianco.thumbanail, true)}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDTypography>immagine colorata</MDTypography>
                            {renderImmagine(props.dati.immagini.colorato.thumbanail, false)}
                        </Grid>
                    </>
                )}
            </Grid>
            <MDBox className='contenitore-bottoni-destra'>
                <MDButton variant='contained' size='small' color='error' onClick={elimina}>
                    elimina
                </MDButton>
                <MDButton variant='contained' size='small' color='dark' onClick={() => props.modifica(props.index)}>
                    modifica
                </MDButton>
            </MDBox>
            <Divider />
        </MDBox>
    )
}

elementoMostraInserimentoImpatto.defaultProps = {
    nascondiImmagini : false
}
