const initialState = {
    id: '',
    parametro: '',
}

export const reducerPopUp = (state = initialState, action) => {
    switch (action.type) {
        case 'APRI_POP_UP':
            return action.payload
        case 'CHIUDI_POP_UP':
            return { id: '', parametro: '' }

        default:
            return state
    }
}
