import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'

const formContattiLoggato = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome :',
            value: '',
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'oggetto',
            tipo: 'fieldSelect',
            associazione: 'oggetto',
            label: 'Oggetto:',
            value: '',
            details: {
                type: 'text',
                complex: false,
                scelte: ["Accesso e password", "Elimina account", "Il mio profilo", "Il mio progetto", "Altro"],
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 }, xxl: { input: 4, output: 4 } },
            },
            validazione: 'testoRichiesto',
        },
        {
            id: 'testo',
            tipo: 'fieldTextArea',
            associazione: 'testo',
            label: 'Testo :',
            value: '',
            validazione: 'testoRichiesto',
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
            },
        },
    ],
}

const formNonLoggato = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome :',
            value: '',
            validazione: 'testoRichiesto',
        },
        {
            id: 'cognome',
            tipo: 'fieldText',
            associazione: 'cognome',
            label: 'Cognome :',
            value: '',
            validazione: 'testoRichiesto',
        },
        {
            id: 'email',
            tipo: 'fieldText',
            associazione: 'email',
            label: 'Email :',
            value: '',
            detils: { type: 'email' },
            validazione: 'testoRichiesto',
        },
        {
            id: 'cellulare',
            tipo: 'fieldText',
            associazione: 'cellulare',
            label: 'Cellulare :',
            value: '',
        },
        {
            id: 'testo',
            tipo: 'fieldTextArea',
            associazione: 'testo',
            label: 'Testo :',
            value: '',
            validazione: 'testoRichiesto',
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
            },
        },
    ],
}
const formNonLoggatoAccettaCondizioni = {
    parts: [
        {
            id: 'nome',
            tipo: 'fieldText',
            associazione: 'nome',
            label: 'Nome :',
            value: '',
            validazione: 'testoRichiesto',
        },
        {
            id: 'cognome',
            tipo: 'fieldText',
            associazione: 'cognome',
            label: 'Cognome :',
            value: '',
            validazione: 'testoRichiesto',
        },
        {
            id: 'email',
            tipo: 'fieldText',
            associazione: 'email',
            label: 'Email :',
            value: '',
            detils: { type: 'email' },
            validazione: 'testoRichiesto',
        },
        {
            id: 'cellulare',
            tipo: 'fieldText',
            associazione: 'cellulare',
            label: 'Cellulare :',
            value: '',
        },
        {
            id: 'testo',
            tipo: 'fieldTextArea',
            associazione: 'testo',
            label: 'Testo :',
            value: '',
            validazione: 'testoRichiesto',
            details: {
                grid: { lg: { input: 4, output: 4 }, md: { input: 4, output: 4 }, xl: { input: 4, output: 4 } },
            },
        },
        {
            id: 'accettazioneTerminiECondizioni',
            tipo: 'fieldAccettaTerminiECondizioni',
            associazione: 'accettazioneTerminiECondizioni',
            label: 'Accetto termini e condizioni:',
            value: '',
            details: {
                testoLink: 'scopri di piu',
                //link: 'terminiECondizioni.html',
            },
            validazione: 'controllaSeVero',
        },
    ],
}

export const formContattiEtiqua = () => {
    if (getIdUtenteLoggato() !== '') return formContattiLoggato
    return formNonLoggatoAccettaCondizioni
}

export const formVediRichiestaContatto = (profilo) => {
    if(profilo === '') return formNonLoggato
    return formContattiLoggato
}