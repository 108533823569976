import { store } from 'data/store'

export const MODIFICA_STATO_SPINNER = (valore) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'MODIFICA_STATO_SPINNER',
            payload: valore,
        })
        resolve()
    })
}
