import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { ref, uploadBytes } from 'firebase/storage'
import { doc, updateDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'

export const AGGIORNA_DOCUMENTI_IMPRESA = async (file, dato) => {
    MODIFICA_STATO_SPINNER(true)
    const id = getIdUtenteLoggato()
    const path = `${id}/documenti/${dato}.pdf`
    
    const caricaInStorage = async () => {
        return await uploadBytes(ref(storage, path), file)
    }
    
    const aggiornaDb = async () => {
        return await updateDoc(doc(db, 'utenti', id), { [dato]: true })
    }
    
    await caricaInStorage()
    await aggiornaDb()
    MODIFICA_STATO_SPINNER(false)
    return
}
