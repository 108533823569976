import { getIdImpresa } from 'getter/getterImpresa'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { collection, addDoc } from 'firebase/firestore'

export const NUOVA_RICHIESTA_ACQUISTO = async (totale, percentuale, idProgetto) => {
    MODIFICA_STATO_SPINNER(true)

    const oggetto = {
        idImpresa: getIdImpresa(),
        idConsumatore: getIdUtenteLoggato(),
        idProgetto: idProgetto,
        totale: totale,
        percentuale: percentuale,
        stato: 'inAttesa',
        data: new Date(),
    }

    await addDoc(collection(db, 'richiesteAcquisti'), oggetto)
    MODIFICA_STATO_SPINNER(false)
    return
}
