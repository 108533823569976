const initialState = {}

export const reducerImpresa = (state = initialState, action) => {
    switch (action.type) {
        case 'LEGGI_IMPRESA':
            return action.payload
        default:
            return state
    }
}
