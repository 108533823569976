import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { listaSdgs } from 'data/sdgs'
import { InputText } from 'fields/fieldText'
import { InputTextArea } from 'fields/fieldTextArea'
import useInputFileImmagini from 'customHooks/useInputFileImmagini'
import { InputSelect } from 'fields/fieldSelect'
import { Grid, Icon } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'

const modelSdg = {
    id: 'sdg',
    tipo: 'fieldSelect',
    associazione: 'sdg',
    label: 'sdg:',
    value: '',
    details: {
        scelte: listaSdgs,
        complex: 'true',
        toShow: 'number',
        toSave: 'number',
    },
}

const modelValore = {
    id: 'valore',
    tipo: 'fieldText',
    associazione: 'valore',
    label: 'valore:',
    value: '',
    details: {
        type: 'number',
    },
}
const modelTestoCard = {
    id: 'testo card',
    tipo: 'fieldText',
    associazione: 'testo card',
    label: 'Testo card :',
    value: '',
    details: {
        type: 'text',
    },
    validazione: 'testoRichiesto',
}

const modelDescrizione = {
    id: 'descrizione',
    tipo: 'fieldTextArea',
    associazione: 'descrizione',
    label: 'Descrizione :',
    value: '',
    details: {
        type: 'text',
    },
    validazione: 'testoRichiesto',
}
const modelTitolo = {
    id: 'titolo',
    tipo: 'fieldText',
    associazione: 'titolo',
    label: 'Titolo :',
    value: '',
    details: {
        type: 'text',
    },
    validazione: 'testoRichiesto',
}

export default function popUpInserisciImpatto(props) {
    const [sdg, setSdg] = useState('')
    const [valore, setValore] = useState('')
    const [testoCard, setTestoCard] = useState('')
    const [descrizione, setDescrizione] = useState('')
    const [titolo, setTitolo] = useState('')
    const [erroreSdg, setErroreSdg] = useState('')
    const [erroreValore, setErroreValore] = useState('')
    const [erroreTestoCard, setErroreTestoCard] = useState('')
    const [erroreDescrizione, setErroreDescrizione] = useState('')
    const [erroreTitolo, setErroreTitolo] = useState('')
    const [immagineBianca, setImmagineBianca] = useState({})
    const [immagineColorata, setImmagineColorata] = useState({})
    const [erroreImmagineBianca, setErroreImmagineBianca] = useState('')
    const [erroreImmagineColorata, setErroreImmagineColorata] = useState('')

    const handleSdg = (campo, valore) => setSdg(valore)
    const handleValore = (campo, valore) => setValore(valore)
    const handleTestoCard = (campo, valore) => setTestoCard(valore)
    const handleDescrizione = (campo, valore) => setDescrizione(valore)
    const handleTitolo = (campo, valore) => setTitolo(valore)
    const handleImmagineBianca = (thumbanail, originale) => setImmagineBianca({ thumbanail: thumbanail.dataUrl, originale: originale })
    const handleImmagineColorata = (thumbanail, originale) => setImmagineColorata({ thumbanail: thumbanail.dataUrl, originale: originale })

    const [renderInputBianco, caricaImmagineBianca] = useInputFileImmagini('input-bianco', handleImmagineBianca)
    const [renderInputColorato, caricaImmagineColorata] = useInputFileImmagini('input-colorato', handleImmagineColorata)

    useEffect(() => {
        if (props.aperto && props.selezionato.index !== -1) {
            setSdg(props.selezionato.dati.sdg)
            setValore(props.selezionato.dati.valore)
            setDescrizione(props.selezionato.dati.descrizione)
            setTestoCard(props.selezionato.dati.testoCard)
            setTitolo(props.selezionato.dati.titolo)
            if (!props.nascondiImmagini) {
                setImmagineBianca(props.selezionato.dati.immagini.bianco)
                setImmagineColorata(props.selezionato.dati.immagini.colorato)
            }
        }
        if (props.aperto && props.selezionato.index === -1) {
            setSdg('')
            setValore('')
            setDescrizione('')
            setTestoCard('')
            setTitolo('')
            if (!props.nascondiImmagini) {
                setImmagineBianca({})
                setImmagineColorata({})
            }
        }
    }, [props.aperto])

    const reset = () => {
        setSdg('')
        setValore('')
        setTestoCard('')
        setDescrizione('')
        setTitolo('')
        setImmagineBianca({})
        setImmagineColorata({})
    }

    const renderImmagine = (immagine, azione, errore, sfondo) => {
        if (Object.keys(immagine).length === 0) {
            return (
                <>
                    <MDBox className='centrato' width='25px' height='25px' onClick={() => azione()} color='dark' sx={{ border: 'solid 0.2px', cursor: 'pointer' }}>
                        <Icon>add</Icon>
                    </MDBox>
                    <MDTypography sx={{ mt: 1 }} color='error' variant='caption'>
                        {errore}
                    </MDTypography>
                </>
            )
        }
        return (
            <>
                <MDBox borderRadius='lg' bgColor={sfondo ? 'info' : 'white'} height='max-content' width='max-content'>
                    <MDBox component='img' src={immagine.thumbanail} />
                </MDBox>
                <MDBox className='centrato' width='25px' height='25px' onClick={() => azione()} color='dark' sx={{ border: 'solid 0.2px', cursor: 'pointer' }}>
                    <Icon>edit</Icon>
                </MDBox>
            </>
        )
    }

    const salva = () => {
        setErroreSdg('')
        setErroreValore('')
        setErroreDescrizione('')
        setErroreTitolo('')
        setErroreImmagineBianca('')
        setErroreImmagineColorata('')
        var errore = false
        if (sdg === '') {
            setErroreSdg('campo obbligatorio')
            errore = true
        }
        if (valore === '') {
            setErroreValore('campo obbligatorio')
            errore = true
        }
        if (testoCard === '') {
            setErroreTestoCard('campo obbligatorio')
            errore = true
        }
        if (descrizione === '') {
            setErroreDescrizione('campo obbligatorio')
            errore = true
        }
        if (titolo === '') {
            setErroreDescrizione('campo obbligatorio')
            errore = true
        }
        if (!props.nascondiImmagini && Object.keys(immagineBianca).length === 0) {
            setErroreImmagineBianca('immagine obbligatoria')
            errore = true
        }
        if (!props.nascondiImmagini && Object.keys(immagineColorata).length === 0) {
            setErroreImmagineColorata('immagine obbligatoria')
            errore = true
        }
        if (errore) return

        var immagini = []
        if (!props.nascondiImmagini) {
            immagini = { bianco: { thumbanail: immagineBianca.thumbanail, originale: immagineBianca.originale }, colorato: { thumbanail: immagineColorata.thumbanail, originale: immagineColorata.originale } }
        }
        console.log('arrivo quaaa')
        props.onValidato({isNuovo : true, sdg: sdg, valore: valore, descrizione: descrizione, titolo: titolo, immagini: immagini, testoCard: testoCard })
        props.handleClose()
        reset()
    }

    return (
        <Dialog open={props.aperto} onClose={props.handleClose} scroll={'paper'} aria-labelledby='titolo-pop-up-impatto'>
            <DialogTitle id='titolo-pop-up-impatto'>{props.titolo}</DialogTitle>
            <DialogContent dividers={true}>
                <MDBox width='100%'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <InputSelect value={sdg} model={modelSdg} errore={erroreSdg} azione={handleSdg} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText value={valore} model={modelValore} errore={erroreValore} azione={handleValore} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText value={titolo} model={modelTitolo} errore={erroreTitolo} azione={handleTitolo} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText value={testoCard} model={modelTestoCard} errore={erroreTestoCard} azione={handleTestoCard} />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <InputTextArea value={descrizione} model={modelDescrizione} errore={erroreDescrizione} azione={handleDescrizione} />
                        </Grid>
                        {props.nascondiImmagini ? null : (
                            <>
                                <Grid item xs={12} md={6}>
                                    <MDTypography>Inserisci immagine bianca</MDTypography>
                                    {renderInputBianco}
                                    {renderImmagine(immagineBianca, caricaImmagineBianca, erroreImmagineBianca, true)}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MDTypography>Inserisci immagine colorata</MDTypography>
                                    {renderInputColorato}
                                    {renderImmagine(immagineColorata, caricaImmagineColorata, erroreImmagineColorata, false)}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </MDBox>
            </DialogContent>
            <DialogActions>
                <MDButton variant='contained' size='small' color='error' onClick={props.handleClose}>
                    annulla
                </MDButton>
                <MDButton variant='contained' size='small' color='dark' onClick={salva}>
                    aggiungi
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}

popUpInserisciImpatto.defaultProps = {
    nascondiImmagini: false,
}
