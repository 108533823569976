import React from 'react'
import { Grid } from '@mui/material'
import { InputSelect } from 'fields/fieldSelect'
import { InputText } from 'fields/fieldText'

const modelNome = {
    id: 'nome',
    tipo: 'fieldText',
    associazione: 'nome',
    label: 'Nome:',
    value: '',
    details: {},
}

const modelOrdinaPer = {
    id: 'ordinaPer',
    tipo: 'fieldSelect',
    associazione: 'ordinaPer',
    label: 'Ordina per:',
    value: '',
    details: {
        complex: true,
        toShow: 'testo',
        toSave: 'scelta',
        scelte: [
            { scelta: 0, testo: 'Alfabetico(A-Z)' },
            { scelta: 1, testo: 'Alfabetico(Z-A)' },
            { scelta: 2, testo: 'Membri' },
            { scelta: 3, testo: 'Sostegno' },
        ],
    },
}

export default function elementoFiltriNetwork(props) {
    const { nome, ordinaPer } = props.filtri
    const handleChange = (campo, valore) => props.aggiornaFiltri(Object.assign(props.filtri, { [campo]: valore }))
    return (
        <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12} lg={4}>
                <InputText value={nome} model={modelNome} errore='' azione={handleChange} />
            </Grid>
            <Grid item xs={12} lg={4}>
                <InputSelect value={ordinaPer} model={modelOrdinaPer} errore='' azione={handleChange} />
            </Grid>
        </Grid>
    )
}
