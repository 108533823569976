import React, { useEffect, useState } from 'react'
import { InputText } from 'fields/fieldText'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import { Divider } from '@mui/material'
import useForm from 'customHooks/useForm'
import { formConsumatoreResponsabile } from 'formModel/formConsumatoreResponsabile'
import MDButton from 'components/MDButton'
import { getAuth, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth'
import useStepperOrizzontale from 'customHooks/useStepperOrizzontale'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import { profiloConsumatore } from 'util'
import { newId } from 'data/strumenti'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { formCredenziali } from 'formModel/formCredenziali'
import { httpsCallable } from 'firebase/functions'

const formEmail = {
    id: 'mail',
    tipo: 'fieldText',
    associazione: 'mail',
    label: 'Email',
    value: '',
    details: {
        id: 'mail',
        type: 'email',
    },
}
const formPassword = {
    id: 'password',
    tipo: 'fieldText',
    associazione: 'password',
    label: 'Password',
    value: '',
    details: {
        id: 'password',
        type: 'password',
    },
}

export default function elementoAutenticazioneInserimentoSpesa() {
    const [stato, setStato] = useState(0)
    const [credenziali, setCredenziali] = useState({ mail: '', password: '' })
    const [errore, setErrore] = useState('')
    const [statoDati, azioniDati, renderFormDati] = useForm(formConsumatoreResponsabile, undefined, false, true)
    const [statoCredenziali, azioniCredenziali, renderFormCredenziali] = useForm(formCredenziali, undefined, false, true)
    const { renderStepper, step, avanti, steps, indietro, setSteps, isUltimoStep } = useStepperOrizzontale(['Inserisci dati', 'Inserisci credenziali'])

    const handleCredenziali = (campo, valore) => setCredenziali(Object.assign({}, credenziali, { [campo]: valore }))
    const annulla = () => setStato(0)

    const validaDati = async () => {
        await azioniDati.validaForm()
        avanti()
    }

    const validaCredenziali = async () => {
        await azioniCredenziali.validaForm()
        const utente = Object.assign(statoDati.statoForm, { profilo: profiloConsumatore.tipo })
        const id = newId()
        const registrati = httpsCallable(functions, 'registrati')
        MODIFICA_STATO_SPINNER(true)
        const result = await registrati({ id: id, credenziali: statoCredenziali.statoForm, datiUtente: utente })

        const auth = getAuth()
        await signInWithEmailAndPassword(auth, statoCredenziali.statoForm.email, statoCredenziali.statoForm.password)
        auth.languageCode = 'it'
        await sendEmailVerification(auth.currentUser)
        MODIFICA_STATO_SPINNER(false)
        return

        MODIFICA_STATO_SPINNER(false)
    }

    const login = async () => {
        setErrore('')
        const auth = getAuth()
        try {
            await signInWithEmailAndPassword(auth, credenziali.mail, credenziali.password)
        } catch (error) {
            setErrore('Credenziali errate')
        }
    }

    const renderPrimoStep = (
        <>
            {renderFormDati}
            <ElementoBottoniForm scelta='primoStep' valida={validaDati} annulla={annulla} />
        </>
    )
    const renderSecondoStep = (
        <>
            {renderFormCredenziali}
            <ElementoBottoniForm scelta='ultimoStep' valida={validaCredenziali} indietro={indietro} annulla={annulla} />
        </>
    )

    const renderRegistrati = (
        <MDBox className='flex-verticale'>
            {renderStepper}
            <MDBox sx={{ mt: 1, mb: 1 }}>{step === 0 ? renderPrimoStep : renderSecondoStep}</MDBox>
        </MDBox>
    )

    const renderLogin = (
        <MDBox component='form' role='form'>
            <MDBox sx={{ mt: 2 }}>
                <InputText model={formEmail} value={credenziali.mail} errore='' azione={handleCredenziali} />
            </MDBox>
            <MDBox sx={{ mt: 2 }}>
                <InputText model={formPassword} value={credenziali.password} errore={errore} azione={handleCredenziali} />
            </MDBox>
            <MDButton sx={{ mt: 2 }} id='login' color='dark' size='large' fullWidth onClick={login}>
                Accedi
            </MDButton>
            <MDButton sx={{ mt: 2 }} id='login' color='dark' size='large' fullWidth onClick={() => setStato(1)}>
                Registrati
            </MDButton>
        </MDBox>
    )

    return (
        <MDBox className='flex-verticale'>
            <MDTypography color='text' align='center'>
                Per poter supportare il progetto devi essere loggato o iscriverti
            </MDTypography>
            <Divider />
            {stato === 0 ? renderLogin : renderRegistrati}
        </MDBox>
    )
}

//http://192.168.1.11:3000/paginaInserisciSpesa/AebecxWVMGDP4eoo803N
