import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import { getUrlDaStorage } from 'getter/getterimmagini'

export default function elementoVediImpatto(props) {
    const [url, setUrl] = useState('')

    useEffect(async () => {
        const pathColorato = `${props.dati.idProgetto}/impatto/${props.dati.sdg}/colorato.svg`
        const pathBianco = `${props.dati.idProgetto}/impatto/${props.dati.sdg}/bianco.svg`
        const path = props.dati.isTerminato ? pathBianco : pathColorato
        setUrl(await getUrlDaStorage(path))
    }, [props.dati])

    if (!props.dati.isTerminato) {
        return (
            <div className='contenitore-impatto' style={{ border: `solid ${props.dati.colore}`, position: 'relative', maxWidth: '471px', backgroundColor: 'white', minHeight: '250px', padding: '10px 10px' }}>
                <div style={{ position: 'absolute', top: 10, left: 10, color: props.dati.colore, textColor: props.dati.colore }}>{props.dati.sdg}</div>
                <div className='centrato'>
                    <img src={url} width='120px' height='80px' />
                </div>
                <div className='centrato' style={{ color: props.dati.colore, fontWeight: 500, fontSize: '50px', lineHeight: '50px' }}>{props.dati.valore}</div>
                <div className='centrato' style={{ color: props.dati.colore, fontWeight: 500, fontSize: '20px', lineHeight: '20px', textTransform: 'uppercase' }}>{props.dati.titolo}</div>
                <div className=''>
                    <div style={{ color: props.dati.colore, fontWeight: 300, fontSize: '17.44px', lineHeight: '17.44px', textAlign: 'start' }}>{props.dati.descrizione}</div>
                </div>
            </div>
        )
    }

    return (
        <div className='contenitore-impatto' style={{ position: 'relative', maxWidth: '471px', backgroundColor: props.dati.colore, minHeight: '250px', padding: '10px 10px' }}>
            <div style={{ position: 'absolute', top: 10, left: 10, color: 'white', textColor: 'white' }}>{props.dati.sdg}</div>
            <div className='centrato'>
                <img src={url} width='100px' height='50px' />
            </div>

            <div className='centrato' style={{ color: 'white', fontWeight: 700, fontSize: '50px', lineHeight: '50px' }}>{props.dati.valore}</div>
            <div className='centrato' style={{ color: 'white', fontWeight: 500, fontSize: '20px', lineHeight: '20px', textTransform: 'uppercase' }}>{props.dati.titolo}</div>

            <div className=''>
                <div style={{ color: 'white', fontWeight: 300, fontSize: '17.44px', lineHeight: '17.44px', textAlign: 'start' }}>{props.dati.descrizione}</div>
            </div>
        </div>
    )
}
