import React from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDBox from 'components/MDBox'

export function InputAccettaTerminiECondizioni(props) {
    const leggiCondizioni = () => window.open('https://www.etiqua.net/termini-e-condizioni-duso/')
    const leggiPrivacy = () => window.open('https://www.etiqua.net/privacy-policy/')

    return (
        <Grid container spacing={0.3}>
            <Grid item container xs={12} lg={12} spacing={2}>
                <Grid item xs={12} lg={12}>
                    <MDBox className='flex-orizzontale'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={props.value} onChange={(e, valore) => props.azione(props.model.id, valore)} />} /* label={props.model.label} */ label={''} />
                        </FormGroup>
                        <MDBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <MDTypography color='dark' variant='body2' fontWeight='medium' sx={{ justifyContent: 'center', marginLeft: '-1rem' }}>
                                Accetto{' '}
                                <MDTypography color='info' fontWeight='medium' variant='medium' sx={{ cursor: 'pointer' }} onClick={leggiPrivacy}>
                                    Privacy{' '}
                                </MDTypography>
                                e{' '}
                                <MDTypography color='info' fontWeight='medium' variant='medium' sx={{ cursor: 'pointer' }} onClick={leggiCondizioni}>
                                    Termini e condizioni
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                        {/* <MDTypography variant='body2' fontWeight='medium'>
                            Ho letto e accetto
                        </MDTypography> */}
                    </MDBox>
                </Grid>
                {/*  <Grid item xs={4} lg={4}>
                    <MDButton color={'primary'} variant='text' onClick={leggiCondizioni}>
                        {props.model.details.testoLink}
                    </MDButton>
                </Grid> */}
            </Grid>
            <Grid item xs={12} lg={12}>
                <MDTypography color={'error'} variant='caption'>
                    {props.errore}
                </MDTypography>
            </Grid>
        </Grid>
    )
}
export function OutputAccettaTerminiECondizioni(props) {
    return <div>fieldAccettaTerminiECondizioni</div>
}
