export class baseWatcher {
    devoFareQualcosa(idDaGuardare, nuovoId) {
        return idDaGuardare !== nuovoId
    }

    devoChiudereLaVecchiaConnessione(idDaGuardare) {
        return idDaGuardare !== ''
    }
    devoAprireLaNuovaConnessione(nuovoId) {
        return nuovoId !== ''
    }

    connetti(nuovoId) {
        this.apriConnessione(nuovoId)
    }

    scegliAzioni(idDaGuardare, nuovoId) {
        this.idDaGuardare = nuovoId
        if (this.devoFareQualcosa(idDaGuardare, nuovoId)) {
            if (this.devoChiudereLaVecchiaConnessione(idDaGuardare)) {
                this.chiudiConnessione()
            }
            if (this.devoAprireLaNuovaConnessione(nuovoId)) {
                this.connetti(nuovoId)
            }
        }
    }
    update() {
        this.scegliAzioni(this.idDaGuardare, nuovoId)
    }

    logOut() {
        return new Promise((resolve, reject) => {
            this.chiudiConnessione()
            resolve()
        })
    }
}
