import React, { useState } from 'react'
import { InputText, OutputText } from './fieldText'
import Slider from '@mui/material/Slider'
import MDTypography from 'components/MDTypography'
import Grid from '@mui/material/Grid'

export function InputSlider(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: '',
        value: props.value,
        details: {
            type: 'number',
            inputProps: { max: props.model.details.max, min: props.model.details.min },
        },
    }

    function valoreCambiato(valore) {
        if (valore < props.model.details.min) {
            return
        }
        if (valore > props.model.details.max) {
            return
        }
        props.azione(props.model.id, valore)
    }

    let renderSlider = <Slider onChange={(e, valore) => valoreCambiato(valore)} value={props.value} aria-labelledby={`${props.model.id}-label`} min={props.model.details.min} max={props.model.details.max} valueLabelDisplay='auto' />

    let sliderPiuInput = (
        <Grid item container xs={12} spacing={1}>
            <Grid item xs={10} lg={10}>
                {renderSlider}
            </Grid>
            <Grid item xs={2} lg={2}>
                <InputText model={model} value={props.value} azione={(a, b) => valoreCambiato(b)} errore={''} />
            </Grid>
        </Grid>
    )

    let soloSlider = (
        <Grid item xs={12}>
            {renderSlider}
        </Grid>
    )

    return (
        <Grid container spacing={0} >
            <Grid item xs={12}>
                <MDTypography id={`${props.model.id}-label`} gutterBottom>
                    {props.model.label}
                </MDTypography>
                {props.model.details.vediInput ? sliderPiuInput : soloSlider}
            </Grid>
        </Grid>
    )
}
export function OutputSlider(props) {
    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    return <OutputText skeleton={props.skeleton || false} model={model} value={props.value} />
}
