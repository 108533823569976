/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// react-router-dom components
import { Link } from 'react-router-dom'

// @mui material components
import Collapse from '@mui/material/Collapse'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

function DefaultNavbarDropdown({ name, selected, icon, children, collapseStatus, light, href, route, collapse, backgroundColor, ...rest }) {
    const linkComponent = {
        component: 'a',
        href,
        target: '_blank',
        rel: 'noreferrer',
    }

    const routeComponent = {
        component: Link,
        to: route,
    }

    return (
        <>
            <MDBox {...rest} mx={0} p={1} display='flex' alignItems='baseline' color={light ? 'white' : 'white'} opacity={1} sx={{ cursor: 'pointer', userSelect: 'none' }} {...(route && routeComponent)} {...(href && linkComponent)}>
                {icon && (
                    <MDTypography variant='body2' lineHeight={1} color='inherit' sx={{ alignSelf: 'center', '& *': { verticalAlign: 'middle' } }}>
                        {icon}
                    </MDTypography>
                )}
                <MDTypography variant='subtitle2' textTransform='capitalize' /* "capitalize" */ color='white' fontWeight='bold' fontSize='1rem' /*  color={light ? 'white' : backgroundColor === 'info' ? 'white' : 'dark'} */ sx={{ fontWeight: selected ? 'bold' : '100%', textDecoration: selected ? 'underline' : 'none', ml: 1, mr: 0.25 }}>
                    {name}
                </MDTypography>
                <MDTypography variant='body2' fontWeight='bold' color={light ? 'white' : 'dark'} ml='auto'>
                    <Icon sx={{ fontWeight: 'normal', verticalAlign: 'middle' }}>{collapse && 'keyboard_arrow_down'}</Icon>
                </MDTypography>
            </MDBox>
            {children && (
                <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    )
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
    icon: false,
    children: false,
    collapseStatus: false,
    light: false,
    href: '',
    route: '',
}

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.node,
    children: PropTypes.node,
    collapseStatus: PropTypes.bool,
    light: PropTypes.bool,
    href: PropTypes.string,
    route: PropTypes.string,
    collapse: PropTypes.bool.isRequired,
}

export default DefaultNavbarDropdown
