import { LOG_OUT } from 'data/azioni/LOG_OUT'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'

import { profiloSuperAmministratore, profiloConsumatore, profiloImpresa, profiloEnte } from 'util'

//inizio pagine
import PaginaVediEnte from 'pagine/paginaVediEnte'
import PaginaMioProfilo from 'pagine/paginaMioProfilo'
import PaginaNuovoProgetto from 'pagine/paginaNuovoProgetto'
import PaginaProgetti from 'pagine/paginaProgetti'
import PaginaImprese from 'pagine/paginaImprese'
import PaginaEnti from 'pagine/paginaEnti'
import PaginaVediProgetto from 'pagine/paginaVediProgetto'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import PaginaLogin from 'landing/pagine/paginaLogin'
import PaginaPasswordDimenticata from 'landing/pagine/paginaPasswordDimenticata'
import PaginaRegistrati from 'landing/pagine/paginaRegistrati'
import PaginaSostieniProgetto from 'pagine/paginaSostieniProgetto'
import PaginaInserisciSpesa from 'pagine/paginaInserisciSpesa'
import PaginaVediImpresa from 'pagine/paginaVediImpresa'
import PaginaPortafoglioImpresa from 'pagine/paginaPortafoglioImpresa'
import PaginaRichiesteAcquisto from 'pagine/paginaRichiesteAcquisto'
import PaginaModificaImmagini from 'pagine/paginaModificaImmagini'
import PaginaModificaImmaginiProgetto from 'pagine/paginaModificaImmaginiProgetto'
import PaginaMieiProgetti from 'pagine/paginaMieiProgetti'
import PaginaNuovoAggiornamento from 'pagine/paginaNuovoAggiornamento'
import PaginaListaProgettiEtiqua from 'pagine/paginaListaProgettiEtiqua'
import PaginaVediAggiornamento from 'pagine/paginaVediAggiornamento'
import PaginaListaProgettiCompletatiEtiqua from 'pagine/paginaListaProgettiCompletatiEtiqua'
import PaginaIlMioNetwork from 'pagine/paginaIlMioNetwork'
import PaginaCreaNetwork from 'pagine/paginaCreaNetwork'
import PaginaVediNetwork from 'pagine/paginaVediNetwork'
import PaginaNetwork from 'pagine/paginaNetwork'
import PaginaListaEmailetiqua from 'pagine/paginaListaEmailetiqua'
import PaginaContatti from 'pagine/paginaContatti'
import PaginaRichiesteContatto from 'pagine/paginaRichiesteContatto'
//fine pagine

const getPaginaPrincipale = (isLanding, profilo) => {
    if(isLanding) return 'paginaProgetti'
    switch (profilo) {
        case profiloSuperAmministratore.tipo:
            
            return 'paginaListaProgettiEtiqua';
        case profiloConsumatore.tipo:
            
            return 'paginaImprese';
        case profiloImpresa.tipo:
            
            return 'paginaRichiesteAcquisto';
        case profiloEnte.tipo:
            
            return 'paginaMioProfilo';
    
        default:
            return ''
    }
   
}

export function Routing(props) {

    const navigate = useNavigate()

    useEffect(() => {
        if(props.logOut) navigate('/')
    }, [props.logOut])

    const renderEnte = (
        <>
            <Route exact path={'/PaginaMioProfilo'} element={<PaginaMioProfilo navigate={useNavigate()} />} />
            <Route exact path={'/paginaCreaProgetto'} element={<PaginaNuovoProgetto navigate={useNavigate()} />} />
            <Route exact path={'/paginaMieiProgetti'} element={<PaginaMieiProgetti navigate={useNavigate()} />} />
            <Route exact path={'/paginaModificaImmaginiProgetto/:id'} element={<PaginaModificaImmaginiProgetto navigate={useNavigate()} />} />
            <Route exact path={'/paginaNuovoAggiornamento/:id'} element={<PaginaNuovoAggiornamento navigate={useNavigate()} />} />
        </>
    )
    const renderImpresa = (
        <>
            <Route exact path={'/PaginaMioProfilo'} element={<PaginaMioProfilo navigate={useNavigate()} />} />
            <Route exact path={'/paginaSostieniProgetto/:id'} element={<PaginaSostieniProgetto navigate={useNavigate()} />} />
            <Route exact path={'/paginaPortafoglioImpresa'} element={<PaginaPortafoglioImpresa navigate={useNavigate()} />} />
            <Route exact path={'/paginaRichiesteAcquisto'} element={<PaginaRichiesteAcquisto navigate={useNavigate()} />} />
            <Route exact path={'/paginaIlMioNetwork'} element={<PaginaIlMioNetwork navigate={useNavigate()} />} />
            <Route exact path={'/paginaCreaNetwork'} element={<PaginaCreaNetwork navigate={useNavigate()} />} />
        </>
    )
    const renderConsumatore = (
        <>
            <Route exact path={'/PaginaMioProfilo'} element={<PaginaMioProfilo navigate={useNavigate()} />} />
            <Route exact path={'/paginaInserisciSpesa/:id'} element={<PaginaInserisciSpesa navigate={useNavigate()} />} />
            <Route exact path={'/paginaRichiesteAcquisto'} element={<PaginaRichiesteAcquisto navigate={useNavigate()} />} />
        </>
    )
    const renderLanding = (
        <>
            <Route exact path={'/registrati'} element={<PaginaRegistrati navigate={useNavigate()} />} />
            <Route exact path={'/passwordDimenticata'} element={<PaginaPasswordDimenticata navigate={useNavigate()} />} />
            <Route exact path={'/login'} element={<PaginaLogin navigate={useNavigate()} />} />
            <Route exact path={'/paginaInserisciSpesa/:id'} element={<PaginaInserisciSpesa navigate={useNavigate()} />} />
        </>
    )
    const renderEtiqua = (
        <>
            <Route exact path={'/paginaListaProgettiEtiqua'} element={<PaginaListaProgettiEtiqua navigate={useNavigate()} />} />
            <Route exact path={'/paginaListaProgettiCompletatiEtiqua'} element={<PaginaListaProgettiCompletatiEtiqua navigate={useNavigate()} />} />
            <Route exact path={'/paginaListaEmailetiqua'} element={<PaginaListaEmailetiqua navigate={useNavigate()} />} />
            <Route exact path={'/paginaRichiesteContatto'} element={<PaginaRichiesteContatto navigate={useNavigate()} />} />
        </>
    )

    const renderPostLogin = (
        <>
            <Route exact path={'/paginaModificaImmagini'} element={<PaginaModificaImmagini navigate={useNavigate()} />} />
        </>
    )

    return (
        <Routes>
            <Route exact path={'/paginaContatti'} element={<PaginaContatti navigate={useNavigate()} />} />
            <Route exact path={'/paginaProgetti'} element={<PaginaProgetti navigate={useNavigate()} />} />
            <Route exact path={'/paginaImprese'} element={<PaginaImprese navigate={useNavigate()} />} />
            <Route exact path={'/paginaEnti'} element={<PaginaEnti navigate={useNavigate()} />} />
            <Route exact path={'/paginaNetwork'} element={<PaginaNetwork navigate={useNavigate()} />} />
            <Route exact path={'/paginaVediEnte/:id'} element={<PaginaVediEnte navigate={useNavigate()} />} />
            <Route exact path={'/paginaVediProgetto/:id'} element={<PaginaVediProgetto isLanding={false} navigate={useNavigate()} />} />
            <Route exact path={'/paginaVediImpresa/:id'} element={<PaginaVediImpresa navigate={useNavigate()} isMioProfilo={false} />} />
            <Route exact path={'/paginaVediNetwork/:id'} element={<PaginaVediNetwork navigate={useNavigate()} isMioProfilo={false} />} />
            <Route exact path={'/paginaVediProgetto/:id/paginaVediAggiornamento/:id'} element={<PaginaVediAggiornamento navigate={useNavigate()} isMioProfilo={false} />} />
            {props.profilo === '' ? renderLanding : renderPostLogin}
            {props.profilo === profiloSuperAmministratore.tipo ? renderEtiqua : null}
            {props.profilo === profiloConsumatore.tipo ? renderConsumatore : null}
            {props.profilo === profiloImpresa.tipo ? renderImpresa : null}
            {props.profilo === profiloEnte.tipo ? renderEnte : null}
            <Route path='*' element={<Navigate to={getPaginaPrincipale(props.profilo === '', props.profilo)} />} />
        </Routes>
    )
}

function mapStateToProps(state) {
    return {
        profilo: getProfiloUtenteLoggato(),
        logOut: state.storico[0] === 'LOG_OUT',
    }
}

export default connect(mapStateToProps, null)(Routing)
