import React from 'react'
import MDDatePicker from 'components/MDDatePicker'
import { OutputText } from './fieldText'
import { formattaDataDaFirebase } from 'data/strumenti'
import { convertiDataInStringa } from 'data/strumenti'
import { Timestamp } from 'firebase/firestore'
import { TextField } from '@mui/material'

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { getOggettoData } from 'data/strumenti'
import { it } from 'date-fns/locale'

export function InputData(props) {
    const aggiornaValore = (valore) => {
        //const data = valore.$d
        //console.log("vedi dataaaaaa : ", valore);
        let daTornare = props.model.details?.tornaStringa ? convertiDataInStringa(valore) : valore
        /* if (props.model.details?.tornaDataFirebase) {
                    daTornare = Timestamp.fromDate(e[0])
                } */
        props.azione(props.model.id, daTornare)
    }

    let options = {
        label: props.model.label,
        closeOnSelect: false,
        inputFormat: 'dd/MM/yyyy',
        value: getOggettoData(props.value),
        //views: [ 'year', 'month', 'day'],
        views: [ 'day', 'month', 'year', 'day'],
        onChange: (valore) => aggiornaValore(valore),
    }

    let input = {
        color: props.model.details?.color || 'primary',
        variant: props.model.details?.variant || 'outlined',
        error: props.errore !== '',
        helperText: props.errore,
        fullWidth: true,
        label: props.model.label,
        //value: typeof props.value === 'string' ? props.value : convertiDataInStringa(props.value, props.model.details?.noCalendario || false),
    }

    if (props.model.details?.vediOra) {
        options.views = ['year', 'month', 'day', 'hours', 'minutes']
        options.inputFormat = 'dd/MM/yyyy, H:i'
        options.showToolbar = true
    }
    if (props.model.details?.noCalendario) {
        options.views = ['hours', 'minutes']
        options.inputFormat = 'HH:mm'
        //options.noCalendar = props.model.details.noCalendario
        //options.dateFormat = 'H:i'
    }
    if (props.model.details?.maxDate !== undefined) {
        options.maxDate = props.model.details.maxDate
    }
    if (props.model.details?.minDate !== undefined) {
        options.minDate = props.model.details.minDate
    }

    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    }


    if (props.model.details?.vediOra && props.model.details?.noCalendario) {
        return (
            <LocalizationProvider locale={it} localeText={{ cancelButtonLabel: 'annulla', okButtonLabel: 'salva', clearButtonLabel: 'svuota' }} dateAdapter={AdapterDateFns}>
                <MobileTimePicker {... options} renderInput={(params) => <TextField {...Object.assign(params, input)} />} />
            </LocalizationProvider>
        )
    }

    return (
        <LocalizationProvider locale={it} localeText={{ cancelButtonLabel: 'annulla', okButtonLabel: 'salva', clearButtonLabel: 'svuota' }} dateAdapter={AdapterDateFns}>
            <MobileDatePicker {...options} renderInput={(params) => <TextField {...Object.assign(params, input)} />} />
        </LocalizationProvider>
    )

    return (
        <MDDatePicker
            id={props.model.id}
            options={options}
            input={input}
            value={props.value}
            onChange={(e, valore) => {
                let daTornare = props.model.details?.tornaStringa ? convertiDataInStringa(e[0]) : e[0]
                /* if (props.model.details?.tornaDataFirebase) {
                    daTornare = Timestamp.fromDate(e[0])
                } */
                props.azione(props.model.id, daTornare)
            }}
        />
    )
}

export function OutputData(props) {
    let mostraData = () => {
        if (typeof props.value === 'string') {
            return props.value
        }
        if (props.value.seconds !== undefined) {
            return formattaDataDaFirebase(props.value, props.model.details?.vediOra || false, props.model.details?.noCalendario || false)
        }
        return convertiDataInStringa(props.value, props.model.details?.vediOra || false, props.model.details?.noCalendario || false)
    }

    let model = {
        id: props.model.id,
        tipo: 'fieldText',
        associazione: props.model.associazione,
        label: props.model.label,
        value: props.model.value || '',
        details: props.model.details || {},
    }

    return <OutputText skeleton={props.skeleton || false} model={model} value={mostraData()} />
}
