//Puo tenere conto di dati dentro il database

import { store } from 'data/store'
import { getAuth, signInWithEmailAndPassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'

/* import { store } from '../mathilda/data/store' */

/**
 *
 * @param {String} dato [e' il valore che ci arriva dal form in cui inseriamo
 * il tipo di validazione: testo richiesto]
 */
export const testoRichiesto = (dato) => {
    const discriminante = dato !== '' && dato !== undefined

    return new Promise((resolve, reject) => {
        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Inserisci il dato')
        }
    })
}

const checkTotaleTraguardi = (dato) => {
    return new Promise((resolve, reject) => {
        const totaleTraguardi = dato.lista.reduce((acc, el) => {
            acc += el.totale
            return acc
        }, 0)
        if (dato.totale) resolve('tutto ok')
        reject('Il budget totale non corrisponde alla somma di budget dei traguardi')
    })
}

export const validazioneTraguardi = (dato) => {
    return new Promise((resolve, reject) => {
        if (dato.totale !== undefined && dato.totale !== null && dato.totale !== '' && dato.totale > 0) resolve('')
        reject('Il dato deve essere maggiore di 0')
        testoRichiesto(dato.totale)
    })
}
export const validazioneTraguardi2 = (dato) => {
    return new Promise((resolve, reject) => {
        testoRichiesto(dato.totale)
            .then(() => {
                //return checkTotaleTraguardi(dato)
                resolve('tutto ok')
            })
            /* .then(() => {
                resolve('tutto ok')
            }) */
            .catch((err) => {
                reject(err)
            })
    })
}

export const formattazioneCf = (dato) => {
    return new Promise((resolve, reject) => {
        if (dato.length === 0) {
            reject('Campo obbligatorio')
        }
        if (dato.length !== 16) {
            reject('Lunghezza cf errata')
        }
        const lettere = [0, 1, 2, 3, 4, 5, 8, 11, 15]
        const numeri = [6, 7, 9, 10, 12, 13, 14]
        const checkLettere = lettere.map((posizione) => {
            const lettera = dato[posizione]
            return /^[a-zA-Z]+$/.test(lettera)
        })
        if (checkLettere.includes(false)) {
            reject('Formattazione cf errata')
        }
        const checkNumeri = numeri.map((posizione) => {
            const numero = dato[posizione]
            return !isNaN(numero)
        })
        if (checkNumeri.includes(false)) {
            reject('Formattazione cf errata')
        }
        resolve('tutto ok')
    })
}

export const numeroMaggioreDi0 = (dato) => {
    const discriminante = typeof dato === 'number' && dato > 0

    return new Promise((resolve, reject) => {
        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Il dato deve essere maggiore di 0')
        }
    })
}
export const testoRichiestoSelect = (dato, model) => {
    return new Promise((resolve, reject) => {
        let scelte = model.details.scelte

        if (model.details.complex) {
            scelte = model.details.scelte.map((scelta) => {
                return scelta[model.details.toSave]
            })
        }
        const discriminante = scelte.includes(dato)

        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Inserisci il dato')
        }
    })
}
export const listaMaggioreDi0 = (dato, model) => {
    return new Promise((resolve, reject) => {
        const discriminante = dato.length > 0

        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Inserisci almeno un dato')
        }
    })
}



export const testoRichiestoSceltaMultipla = (dato, model) => {
    return new Promise((resolve, reject) => {
        const discriminante = dato.length !== 0

        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Inserisci il dato')
        }
    })
}

/**
 *
 * @param {Boolean} dato [e' il valore che ci arriva dal form
 * il tipo di validazione: valore == true]
 */
export const controllaSeVero = (dato) => {
    const discriminante = dato

    return new Promise((resolve, reject) => {
        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Il servizio viene erogato solo previa accettazione dei temrmini e delle condizioni ')
        }
    })
}

export const lunghezzaMinima = (dato) => {
    return new Promise((resolve, reject) => {
        if (dato.length >= 6) {
            resolve()
        } else {
            reject('La password deve contenere almeno 6 caratteri')
        }
    })
}

const formattazioneMail = (elem) => {
    return new Promise((resolve, reject) => {
        let re = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        if (!re.test(elem)) {
            reject('Formattazione indirizzo e-mail errata')
        } else {
            resolve()
        }
    })
}

const controllaMailDatabase = (elem) => {
    return new Promise((resolve, reject) => {
        signInWithEmailAndPassword(getAuth(), elem, 'falsa')
            .then(() => {
                reject('mail gia utilizzata')
            })
            .catch((err) => {
                if (err.code === 'auth/user-not-found') {
                    resolve()
                } else {
                    reject('mail gia utilizzata')
                }
            })
    })
}

export const email = (dato) => {
    return new Promise((resolve, reject) => {
        testoRichiesto(dato)
            .then(() => {
                return formattazioneMail(dato)
            })
            .then(() => {
                resolve('tutto ok')
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const emailGiaPresente = (dato) => {
    return new Promise((resolve, reject) => {
        testoRichiesto(dato)
            .then(() => {
                return formattazioneMail(dato)
            })
            .then(() => {
                return controllaMailDatabase(dato)
            })
            .then(() => {
                resolve('tutto ok')
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export const selezionaData = (dato) => {
    const discriminante = dato !== 'NaN/NaN/NaN'
    return new Promise((resolve, reject) => {
        if (discriminante) {
            resolve('tutto ok')
        } else {
            reject('Seleziona una data')
        }
    })
}



export const passwordAttuale = (dato) => {
    return new Promise((resolve, reject) => {
        const user = getAuth().currentUser
        const cred = EmailAuthProvider.credential(user.email, dato)
        reauthenticateWithCredential(user, cred)
            .then(() => resolve())
            .catch((err) => reject('Password errata'))
    })
}
