import React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { formattaDataDaFirebase } from 'data/strumenti'
import useForm from 'customHooks/useForm'
import { formVediRichiestaContatto } from 'formModel/formContattiEtiqua'
import MDButton from 'components/MDButton'
import { MODIFICA_STATO_RICHIESTA_CONTATTO } from 'data/azioni/MODIFICA_STATO_RICHIESTA_CONTATTO'

export default function elementoMostraRichiestaContatto(props) {
    const [stato, azioni, renderForm] = useForm(formVediRichiestaContatto(props.dati.profilo), props.dati, false, false)

    const cambiaStato = ()=> MODIFICA_STATO_RICHIESTA_CONTATTO(props.dati.id, !props.dati.letto)

    return (
        <MDBox className='flex-verticale' bgColor='white' p={2} shadow='lg'>
            <MDTypography variant='h4'>{formattaDataDaFirebase(props.dati.data)}</MDTypography>
            <MDTypography color='info' fontWeight='medium'>
                {props.dati.nome}
            </MDTypography>
            {props.dati.mailAccesso === undefined ? null : (
                <MDTypography color='text' variant='caption'>
                    {props.dati.mailAccesso}
                </MDTypography>
            )}
            <MDBox pt={2}>{renderForm}</MDBox>
            <MDBox sx={{ display: 'flex', width: '100%', alignItems: 'end', justifyContent: 'end', height: '100%' }}>
                <MDButton onClick={cambiaStato}>{!props.dati.letto ? 'segna come letto' : 'segna come non letto'}</MDButton>
            </MDBox>
        </MDBox>
    )
}
