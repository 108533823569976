import { getInformazioniProgettoAttualmenteSostenuto, getListaProgettiSostenutiNoAttuale, getIdImpresa, getListaImpattiImpresa, isImpresaB2b, getImmaginiImpresa, getDatiImpresa, canInvitaImpresaInNetwork, isInvitoinCorso } from 'getter/getterImpresa'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import useCache from 'customHooks/useCache'
import useForm from 'customHooks/useForm'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import MDBox from 'components/MDBox'
import { Grid } from '@mui/material'
import { getUrlDaStorage } from 'getter/getterimmagini'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { INVITA_IMPRESA_IN_NETWORK } from 'data/azioni/INVITA_IMPRESA_IN_NETWORK'
import MDTypography from 'components/MDTypography'
import { Divider, Icon } from '@mui/material'
import ElementoImmagineProfilo from 'componenti/elementoImmagineProfilo'
import ElementoCarosello from 'componenti/elementoCarosello'
import ElementoMostraProgetto from 'componenti/elementoMostraProgetto'
import { getProgettoDaId } from 'getter/getterProgetti'
import ElementoVediListaImpatti from 'componenti/elementoVediListaImpatti'
import MDButton from 'components/MDButton'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { MODIFICA_DATI_UTENTE_LOGGATO } from 'data/azioni/MODIFICA_DATI_UTENTE_LOGGATO'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import { formRiassuntoImpresa } from 'formModel/formImpresaResponsabile'
import ElementoContatti from 'componenti/elementoContatti'
import { formContattiImpresa } from 'formModel/formImpresaResponsabile'
import { formAttivitaImpresa } from 'formModel/formImpresaResponsabile'
import ElementoDocumentiImpresa from 'componenti/elementoDocumentiImpresa'
import { formContattiiLinkmpresa } from 'formModel/formImpresaResponsabile'

export const paginaVediImpresa = (props) => {
    const [statoDati, azioniDati, renderFormDati] = useForm(formRiassuntoImpresa, props.dati, false, false)
    const [statoAttivita, azioniAttivita, renderFormAttivita] = useForm(formAttivitaImpresa, props.dati, false, false)
    const [renderBottoneInvita, setRenderBottoneInvita] = useState(null)
    const { listaNetworkWindow } = useCache()
    const [listaImmagini, setListaImmagini] = useState([])
    const [formContatti, setFormContatti] = useState({ parts: [] })
    const [logo, setLogo] = useState('')

    useEffect(()=>{
        azioniDati.inizializza()
    },[props.dati])

    useEffect(() => {
        setFormContatti(formContattiImpresa(props.isB2b, true))
    }, [props.isB2b])

    const modificaImmagini = () => props.navigate('paginaModificaImmagini')
    const invita = async () => await INVITA_IMPRESA_IN_NETWORK()
    const apriPopUp = () => APRI_POP_UP({ id: 'richiediConferma', parametro: { testo: 'Sei sicuro di voler invitare questa impresa nel tuo network?', azione: invita } })
    const modificaCredenziali = (tipo) => APRI_POP_UP({ id: 'credenziali', parametro: tipo })

    const aggiorna = async () => {
        try {
            await azioniDati.validaForm()
            await MODIFICA_DATI_UTENTE_LOGGATO(statoDati.statoForm)
            azioniDati.vediOutput()
        } catch (error) {
            return
        }
    }
    const aggiornaAttivita = async () => {
        try {
            await azioniAttivita.validaForm()
            await MODIFICA_DATI_UTENTE_LOGGATO(statoAttivita.statoForm)
            azioniAttivita.vediOutput()
        } catch (error) {
            return
        }
    }

    const aggiornaContatti = async (stato) => {
        await MODIFICA_DATI_UTENTE_LOGGATO(stato)
        return
    }

    useEffect(() => {
        const canInvita = canInvitaImpresaInNetwork()
        const isInvitoInAttesa = isInvitoinCorso()
        if (isInvitoInAttesa) setRenderBottoneInvita(<MDTypography>Invito al network inviato ...</MDTypography>)
        if (canInvita)
            setRenderBottoneInvita(
                <MDButton variant='outlined' color='dark' onClick={apriPopUp}>
                    invita in network
                </MDButton>,
            )
        if (!canInvita && !isInvitoInAttesa) setRenderBottoneInvita(null)
    }, [listaNetworkWindow, props.dati, props.utenteLoggato])

    useEffect(async () => {
        const getUrlImmagine = async (immagine) => {
            const pathThumbNail = !immagine.isLogo ? `${props.id}/immagini/${immagine.id}/thumbNail.png` : `${props.id}/logo/thumbNail.png`
            const pathOriginale = !immagine.isLogo ? `${props.id}/immagini/${immagine.id}/originale.png` : `${props.id}/logo/originale.png`

            const url = await Promise.all([getUrlDaStorage(pathThumbNail), getUrlDaStorage(pathOriginale)])
            return { thumbNail: url[0], originale: url[1], id: immagine.id, isLogo: immagine.isLogo }
        }

        if (props.listaImmagini.length === 0) return

        const promesse = props.listaImmagini.map(async (immagine) => {
            return await getUrlImmagine(immagine)
        })

        const risultati = await Promise.all(promesse)
        var lista = []
        risultati.forEach((result) => {
            const immagine = {
                originale: result.originale,
                thumbnail: result.thumbNail,
                id: result.id,
            }
            if (result.isLogo) setLogo(immagine)
            if (!result.isLogo) lista.push(immagine)
            return
        })

        setListaImmagini(lista)
    }, [props.listaImmagini])

    const renderIndirizzo = () => {
        const sede = props.isB2b ? 'sedeLegale' : 'puntoVendita'
        const indirizzo = `${props.dati[sede]?.via || ''} ${props.dati[sede]?.numero || ''} ${props.dati[sede]?.comune || ''} ${props.dati[sede]?.provincia || ''}`
        return (
            <MDBox className='flex-verticale'>
                <MDTypography variant='body2'>{indirizzo}</MDTypography>
            </MDBox>
        )
    }

    const renderBottoneImmagini = () => {
        if (!props.isMioProfilo) return null
        return (
            <Grid container spacing={0.05} direction='column'>
                <Grid item>
                    <MDButton fullWidth sx={{ mt: 1 }} variant='outlined' color='dark' onClick={modificaImmagini}>
                        <Icon sx={{ mr: 1 }}>edit</Icon>
                        immagini
                    </MDButton>
                </Grid>
                <Grid item>
                    <MDButton fullWidth sx={{ mt: 1 }} variant='outlined' color='dark' onClick={() => modificaCredenziali('mail')}>
                        <Icon sx={{ mr: 1 }}>edit</Icon>
                        mail
                    </MDButton>
                </Grid>
                <Grid item>
                    <MDButton fullWidth sx={{ mt: 1 }} variant='outlined' color='dark' onClick={() => modificaCredenziali('password')}>
                        <Icon sx={{ mr: 1 }}>edit</Icon>
                        password
                    </MDButton>
                </Grid>
            </Grid>
        )
    }

    const immagini = (
        <MDBox mt={3}>
            <ElementoImmagineProfilo immagini={listaImmagini} />
        </MDBox>
    )

    const renderImmagineNome = (
        <MDBox bgColor='white' width='100%' className='flex-verticale' p={2} maxWidth='1000px' mx='auto'>
            <Grid container spacing={5}>
                <Grid item xs={12} lg='auto' sx={{ boxSizing: 'border=box', alignItems: 'end', display: 'flex' }}>
                    <MDBox className='flex-verticale centrato'>
                        {logo === '' ? (
                            <MDBox className='centrato' height='130px' width='130px' bgColor='light' variant='gradient' sx={{ borderRadius: '50%' }}>
                                <MDTypography variant='h1' fontWeight='bold' color='white' align='center'>
                                    {(props.dati?.nome === undefined ? '' : props.dati.nome[0]).toUpperCase()}
                                </MDTypography>
                            </MDBox>
                        ) : (
                            <MDBox component='img' src={logo.originale} alt='logo' height='auto' width='130px'></MDBox>
                        )}
                        {renderBottoneImmagini()}
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={true} sx={{ boxSizing: 'border=box', alignItems: 'end', display: 'flex' }}>
                    <MDBox className='flex-verticale'>
                        <ElementoEtichettaForm testo={props.dati.nome} secondoElemento={renderBottoneInvita} elementoSotto={renderIndirizzo()} />
                        <MDTypography sx={{ whiteSpace: 'pre-line', mt: 2 }}>{props.dati.breveDescrizione}</MDTypography>
                    </MDBox>
                </Grid>
            </Grid>

            {/* <Divider /> */}
            {immagini}
            <MDBox pt={2}>
                {renderFormDati}
                {props.isMioProfilo ? <ElementoBottoniForm vista={statoDati.vista} annulla={azioniDati.annullaControlla} valida={aggiorna} vediInput={azioniDati.vediInput} /> : null}
            </MDBox>
        </MDBox>
    )

    const renderProgetti = () => {
        if (props.isB2b) {
            return (
                <MDBox className='flex-verticale' mx='auto' sx={{ maxWidth: '330px' }}>
                    <MDBox mb={2}>
                        <ElementoEtichettaForm testo='Progetti che abbiamo sostenenuto' />
                    </MDBox>
                    <ElementoCarosello
                        borderRadius={false}
                        testo='Nessun progetto sostenuto'
                        lista={props.progettiSostenuti.map((progetto) => {
                            return { id: progetto.idProgetto, render: <ElementoMostraProgetto progetto={getProgettoDaId(progetto.idProgetto)} navigate={props.navigate} /> }
                        })}
                    />
                </MDBox>
            )
        }
        return (
            <div className='gridDueDue' spacing={2} style={{ justifyContent: 'center', maxWidth: '800px' }}>
                <div className='etichettaUno' item xs={12} md={5}>
                    <ElementoEtichettaForm testo='che stiamo sostenendo' variant='h5' />
                </div>
                <div className='etichettaDue' item xs={12} md={5}>
                    <ElementoEtichettaForm testo='che abbiamo sostenenuto' variant='h5' />
                </div>
                <div className='elementoUno' item xs={12} md={5}>
                    <ElementoCarosello testo='Nessun progetto attualmente sostenuto' lista={props.progettoSostenuto.idProgetto === undefined ? [] : [{ id: props.progettoSostenuto.idProgetto, render: <ElementoMostraProgetto progetto={getProgettoDaId(props.progettoSostenuto.idProgetto)} navigate={props.navigate} /> }]} />
                </div>
                <div className='elementoDue' item xs={12} md={5}>
                    <ElementoCarosello
                        borderRadius={false}
                        testo='Nessun progetto sostenuto'
                        lista={props.progettiSostenuti.map((progetto) => {
                            return { id: progetto.idProgetto, render: <ElementoMostraProgetto progetto={getProgettoDaId(progetto.idProgetto)} navigate={props.navigate} /> }
                        })}
                    />
                </div>
            </div>
        )
    }

    const renderImpatti = () => {
        if (props.listaImpatti.length === 0) return null
        return (
            <MDBox p={2} bgColor='white'>
                <MDBox mb={4} mx='auto' sx={{ width: '100%', maxWidth: '1000px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <MDTypography color='dark' fontWeight='bold' variant='h3' sx={{ mb: 2 }}>
                        Il nostro impatto
                    </MDTypography>
                    <ElementoVediListaImpatti lista={props.listaImpatti} />
                </MDBox>
            </MDBox>
        )
    }

    const renderAttivitaDocumenti = (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
                <MDBox width='100%' shadow='lg' bgColor='white' p={2} height='100%'>
                    <MDBox className='flex-verticale' ml={{ sm: 0, md: 'auto' }} mr='auto' width='100%' maxWidth='350px' >
                        <MDTypography align='center' variant='h5' color='text'>
                            ATTIVITA'
                        </MDTypography>
                        <MDBox className='flex-verticale' pt={2}>
                            {renderFormAttivita}
                            {props.isMioProfilo ? <ElementoBottoniForm vista={statoAttivita.vista} annulla={azioniAttivita.annullaControlla} valida={aggiornaAttivita} vediInput={azioniAttivita.vediInput} /> : null}
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <MDBox width='100%' shadow='lg' bgColor='white' p={2} height='100%'>
                    <MDBox className='flex-verticale' mr='auto' ml={{ sm: 0, md: 'auto' }} width='100%' maxWidth='350px'>
                        <MDTypography align='center' variant='h5' color='text'>
                            DOCUMENTI
                        </MDTypography>
                        <MDBox className='flex-verticale' pt={2}>
                            <ElementoDocumentiImpresa id={props.id} isMioProfilo={props.isMioProfilo} dati={props.dati} />
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Grid>
        </Grid>
    )

    const renderPagina = (
        <>
            <MDBox bgColor='white' width='100%'>
                {renderImmagineNome}
            </MDBox>
            <MDBox className='flex-verticale' p={2}>
                <MDTypography sx={{ mb: 3 }} align='center' variant='h3' color='text'>
                    PROGETTI
                </MDTypography>
                {renderProgetti()}
            </MDBox>
            <MDBox bgColor='white' width='100%' mb={2}>
                {renderImpatti()}
            </MDBox>
            <MDBox width='100%' mb={2}>
                {renderAttivitaDocumenti}
            </MDBox>
            <ElementoContatti vediBottone={props.isMioProfilo} formLink={formContattiiLinkmpresa(true)} form={formContatti} dati={props.dati} aggiorna={aggiornaContatti} />
        </>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

const mapStateToProps = (state) => ({
    dati: getDatiImpresa(),
    progettoSostenuto: getInformazioniProgettoAttualmenteSostenuto(),
    progettiSostenuti: getListaProgettiSostenutiNoAttuale(),
    listaImmagini: getImmaginiImpresa(),
    id: getIdImpresa(),
    listaImpatti: getListaImpattiImpresa(),
    utenteLoggato: getUtenteLoggato(),
    isB2b: isImpresaB2b(getIdImpresa()),
    isMioProfilo: getIdImpresa() === getIdUtenteLoggato(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediImpresa)
