import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import useCache from 'customHooks/useCache'
import MDBox from 'components/MDBox'
import { getListaImpreseFiltrata } from 'getter/getterImpresa'
import ElementoFiltriImpresa from 'componenti/elementoFiltriImpresa'
import ElementoListaImprese from 'componenti/elementoListaImprese'
import ElementoImpreseMappa from 'componenti/elementoImpreseMappa'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import MDTypography from 'components/MDTypography'
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import { InputBoolean } from 'fields/fieldBoolean'
import { Grid } from '@mui/material'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { profiloConsumatore } from 'util'

const modelBoolean = {
    id: 'b2b',
    tipo: 'fieldBoolean',
    associazione: 'b2b',
    label: 'Imprese B2B',
    value: false,
    details: {},
}
const modelB2c = {
    id: 'b2c',
    tipo: 'fieldBoolean',
    associazione: 'b2c',
    label: 'Negozi',
    value: false,
    details: {},
}

export const paginaImprese = (props) => {
    const { listaImpreseWindow } = useCache()
    const [vediMappa, setVediMappa] = useState(false)
    const [filtri, setFiltri] = useState({ nome: '', luogo: '', ordinaPer: 0, b2b: false, b2c: true })
    const [listaFiltrata, setListafiltrata] = useState(listaImpreseWindow)
    const handleFilri = (nuoviFiltri) => setFiltri(Object.assign({}, nuoviFiltri))
    const handleVediMappa = () => setVediMappa(!vediMappa)
    const handleB2b = (campo, valore) => setFiltri(Object.assign({}, filtri, { b2b: valore, b2c: !valore }))
    const handleB2c = (campo, valore) => setFiltri(Object.assign({}, filtri, { b2c: valore, b2b: !valore }))

    /*  useEffect(() => {
        if (!window.permessoPosizione) {
            APRI_POP_UP({ id: 'permessoPosizione', parametro: '' })
        }
    }, []) */
    useEffect(() => {
        if (getProfiloUtenteLoggato() === profiloConsumatore.tipo) {
            setFiltri({ nome: '', luogo: '', ordinaPer: 3, b2b: false, b2c: true })
        }
    }, [])

    useEffect(async () => {
        setListafiltrata(await getListaImpreseFiltrata(filtri))
    }, [filtri, listaImpreseWindow])

    const renderMappa = <ElementoImpreseMappa lista={listaFiltrata} navigate={props.navigate} />

    const renderLista = (
        <MDBox width='100%' maxWidth='1200px' mx='auto'>
            <ElementoListaImprese lista={listaFiltrata} testoErrore='Nessuna impresa in archivio' {...props} />
        </MDBox>
    )

    const renderPagina = (
        <MDBox display='flex' flexDirection='column' width='100%' boxSizing='border-box'>
            <MDBox bgColor='white' shadow='lg' norderRadius='lg' p={3} mb={3}>
                <MDBox mx='auto' maxWidth='1000px' width='100%'>
                    <ElementoFiltriImpresa filtri={filtri} aggiornaFiltri={handleFilri} />
                    <MDBox mt={2} display='flex' flexDirection='row' sx={{ width: '100%', boxSizing: 'border-box', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <Grid container spacing={2} direction='row'>
                            <Grid item xs={12} lg={4} justifyContent={'end'} alignItems={'end'}>
                                <MDBox className='centrato'>
                                    <InputBoolean value={filtri.b2c} model={modelB2c} errore='' azione={handleB2c} />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} lg={4} justifyContent={'end'} alignItems={'end'}>
                                <MDBox className='centrato'>
                                    <InputBoolean value={filtri.b2b} model={modelBoolean} errore='' azione={handleB2b} />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MDBox className='centrato' display='flex' flexDirection='row' sx={{ width: 'max-content' }} mx='auto' onClick={handleVediMappa}>
                                    {vediMappa ? <FormatListBulletedRoundedIcon color='dark' sx={{ height: '30px', width: '30px', mr: 1 }} /> : <LocationOnRoundedIcon color='dark' sx={{ height: '30px', width: '30px', mr: 1 }} />}
                                    <MDTypography variant='body1' fontWeight='bold' color='dark'>
                                        {vediMappa ? 'Visualizza lista' : 'Visualizza mappa'}
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </MDBox>
            {vediMappa ? renderMappa : renderLista}
        </MDBox>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

paginaImprese.defaultProps = {
    isLanding: true,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaImprese)
