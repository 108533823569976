import FormInput from 'formMaker/formInput'
import FormOutput from 'formMaker/formOutput'
import React from 'react'
import colors from 'assets/theme/base/colors'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

export function InputSubInput(props) {
    let stato = {}
    props.model.details.subModel.parts.forEach((model) => {
        stato[model.id] = props.value[model.id] || model.value
    })

    const getColore = () => {
        return props.errore !== '' ? colors.error.main : colors.inputBorderColor
    }
    const getColoreLabel = () => {
        return props.errore !== '' ? colors.error.main : colors.text.main
    }

    function aggiorna(campo, valore) {
        let nuovoStato = Object.assign(stato)
        nuovoStato[campo] = valore
        props.azione(props.model.id, nuovoStato)
    }

    return (
        <MDBox sx={{ border: 'solid 0.1px', color: getColore() }} p={2} borderRadius='lg'>
            <MDBox mt={-3.8} bgColor='white' pl={0.5} pr={0.5} width={'max-content'} pt={0} pb={0}>
                <MDTypography gutterBottom variant='caption' sx={{ color: getColoreLabel() }}>
                    {props.model.label}
                </MDTypography>
            </MDBox>
            <MDBox className='flex-verticale' pt={2}>
                <FormInput form={props.model.details.subModel} dati={stato} azione={(a, b) => aggiorna(a, b)} errori={props.errore} isSubInput={true} />
            </MDBox>
        </MDBox>
    )
}

export function OutputSubInput(props) {
    let stato = {}
    props.model.details.subModel.parts.forEach((model) => {
        stato[model.id] = props.value[model.id] || model.value
    })
    return <FormOutput skeleton={props.skeleton || false} form={props.model.details.subModel} dati={stato} isSubInput={true} />
}
