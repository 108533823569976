import React, { useEffect, useState } from 'react'
import useCache from 'customHooks/useCache'
import { getnetworkDaId } from 'getter/getterNetwork'
import MDBox from 'components/MDBox'
import ElementoEtichettaForm from './elementoEtichettaForm'
import MDButton from 'components/MDButton'
import { Grid, List, Divider } from '@mui/material'
import MDTypography from 'components/MDTypography'
import { getMembriNetwork } from 'getter/getterNetwork'
import { getRichiesteInAttesaNetwork } from 'getter/getterNetwork'
import { getRichiesteRifiutateNetwork } from 'getter/getterNetwork'
import { getInfoNetwork } from 'getter/getterNetwork'
import ElementoVediListaImpatti from 'componenti/elementoVediListaImpatti'
import ElementoMostraImpresaInNetwork from './elementoMostraImpresaInNetwork'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { httpsCallable } from 'firebase/functions'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { connect } from 'react-redux'
import { getProgettoDaId } from 'getter/getterProgetti'
import ElementoCarosello from './elementoCarosello'
import ElementoMostraProgetto from './elementoMostraProgetto'

export function elementoVediNetwork(props) {
    const [network, setNetwork] = useState({})
    const [membri, setMembri] = useState([])
    const [membriInAttesa, setMembriInattesa] = useState([])
    const [membriRifiutati, setMembriRifiutati] = useState([])
    const [impatti, setImpatti] = useState([])
    const [progetti, setProgetti] = useState([])
    const [isCapofila, setIsCapofila] = useState(false)
    const [isMembro, setIsMembro] = useState(false)
    const [totaleProgettiSostenuti, setTotaleProgettiSostenuti] = useState(0)
    const [totaleSostegno, setTotaleSostegno] = useState(0)
    const { listaNetworkWindow } = useCache

    const eliminaNetwork = async () => {
        MODIFICA_STATO_SPINNER(true)
        const eliminaNetwork = httpsCallable(functions, 'eliminaNetwork')
        await eliminaNetwork({ idImpresa: getIdUtenteLoggato(), idNetwork: props.id })
        MODIFICA_STATO_SPINNER(false)
    }
    const abbandonaNetwork = async () => {
        MODIFICA_STATO_SPINNER(true)
        const eliminaImpresaDaNetwork = httpsCallable(functions, 'eliminaImpresaDaNetwork')
        await eliminaImpresaDaNetwork({ idImpresa: getIdUtenteLoggato(), idNetwork: props.id })
        MODIFICA_STATO_SPINNER(false)
    }

    const capoFilaLasciaNetwork = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: `Prima di abbandonare il network devi nominare un nuovo capo fila. ` } })
    const lasciaNetwork = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: `Sei sicuro di voler abbandonare il network? `, azione: abbandonaNetwork } })
    const elimina = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: `Sei sicuro di voler eliminare il network? `, azione: eliminaNetwork } })

    useEffect(() => {
        setNetwork(getnetworkDaId(props.id))
    }, [props.network, listaNetworkWindow])

    useEffect(() => {
        setIsCapofila(network.capoFila === props.idUtente)
        setIsMembro(network.membri === undefined ? false : network.membri.includes(props.idUtente))
    }, [network, props.idUtente])

    useEffect(() => {
        const info = getInfoNetwork()
        setMembri(getMembriNetwork())
        setMembriInattesa(getRichiesteInAttesaNetwork())
        setMembriRifiutati(getRichiesteRifiutateNetwork())
        setImpatti(info.impatti)
        setTotaleProgettiSostenuti(info.totaleProgetti)
        setTotaleSostegno(info.totaleSostenuto)
        setProgetti(info.progetti)
    }, [network])

    const renderBottone = () => {
        if (!isMembro) return ''
        if (isCapofila) {
            return (
                <Grid container spacing={3}>
                    <Grid item sx={12} lg='auto'>
                        <MDButton variant='outlined' color='dark' onClick={capoFilaLasciaNetwork}>
                            lascia il network
                        </MDButton>
                    </Grid>
                    <Grid item sx={12} lg='auto'>
                        <MDButton variant='outlined' color='error' onClick={elimina}>
                            elimina il network
                        </MDButton>
                    </Grid>
                </Grid>
            )
        }
        return (
            <MDButton variant='outlined' color='dark' onClick={lasciaNetwork}>
                lascia il network
            </MDButton>
        )
    }

    const renderNome = (
        <MDBox bgColor='white' shadow='lg' p={2} mb={2}>
            <ElementoEtichettaForm testo={network.nome} secondoElemento={renderBottone()} />
        </MDBox>
    )

    const renderDecrizioneArea = (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <MDBox bgColor='white' shadow='lg' p={2} className='flex-verticale'>
                    <ElementoEtichettaForm testo={'Dove siamo'} />
                    <MDTypography color='text'>{network.areaGeografica}</MDTypography>
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6}>
                <MDBox bgColor='white' shadow='lg' p={2} className='centrato' sx={{ textAlign: 'left' }}>
                    <MDTypography color='text'>{network.descrizione}</MDTypography>
                </MDBox>
            </Grid>
        </Grid>
    )

    const renderInfoAttivita = (label, valore) => {
        return (
            <MDBox className='flex-orizzontale' mt={2}>
                <MDTypography variant='body2' color='info' fontWeight='medium' sx={{ mr: 0.5 }}>
                    {label}
                </MDTypography>
                <MDTypography variant='body2' color='dark' fontWeight='medium'>
                    {valore}
                </MDTypography>
            </MDBox>
        )
    }

    const renderInfo = (
        <MDBox shadow='lg' mt={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MDBox bgColor='secondary' className='flex-vertcale' px={2} py={4}>
                        <MDBox className='centrato' mx='auto' sx={{ maxWidth: '1000px' }}>
                            <MDTypography align='center' variant='h3' fontWeight='medium' color='info'>
                                I NOSTRI NUMERI
                            </MDTypography>
                        </MDBox>

                        <Grid container spacing={2} sx={{ maxWidth: '1000px' }} mx='auto' mt={3} justifyContent={'center'}>
                            <Grid item xs={12} lg={4}>
                                {renderInfoAttivita('Membri:', network.membri?.length || 0)}
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                {renderInfoAttivita('Raccolte sostenute:', totaleProgettiSostenuti)}
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                {renderInfoAttivita('Totale sostegno:', totaleSostegno)}
                            </Grid>
                        </Grid>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    )

    const renderListaImprese = (lista, errore, vediBottoni) => {
        if (lista.length === 0) return <MDTypography color='text'>{errore}</MDTypography>
        return (
            <List>
                {lista.map((impresa, index) => {
                    return (
                        <MDBox key={impresa.id}>
                            <ElementoMostraImpresaInNetwork vediBottoni={vediBottoni} idNetwork={props.id} capofila={network.capoFila} isCapofila={isCapofila} impresa={impresa} navigate={props.navigate} />
                            {index === lista.length + 1 ? null : <Divider />}
                        </MDBox>
                    )
                })}
            </List>
        )
    }

    const renderBoxImprese = (testo, lista, errore, vediBottoni) => {
        return (
            <MDBox className='flex-verticale' shadow='lg' height='100%' bgColor='white' p={2}>
                <ElementoEtichettaForm testo={testo} />
                {renderListaImprese(lista, errore, vediBottoni)}
            </MDBox>
        )
    }

    const renderImprese = () => {
        if (!isCapofila) {
            return (
                <MDBox bgColor='white' shadow='lg' p={2} mt={2}>
                    <MDBox mx='auto' className='flex-verticale' sx={{ maxWidth: '1000px' }}>
                        <ElementoEtichettaForm testo='Membri attivi' />
                        {renderListaImprese(membri, 'Nessun membro presente')}
                    </MDBox>
                </MDBox>
            )
        }
        return (
            <MDBox py={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                        {renderBoxImprese('Membri attivi', membri, 'Nessun membro presente', true)}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {renderBoxImprese('Inviti in attesa', membriInAttesa, 'Nessuna richiesta in attesa', false)}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {renderBoxImprese('Inviti rifiutati', membriRifiutati, 'Nessun invito rifiutato', false)}
                    </Grid>
                </Grid>
            </MDBox>
        )
        return (
            <MDBox bgColor='white' shadow='lg' p={2} mt={2}>
                <div className='gridTreDue' style={{ justifyContent: 'center' }}>
                    <div className='etichettaUno'>
                        <ElementoEtichettaForm testo='Membri attivi' />
                    </div>
                    <div className='etichettaDue'>
                        <ElementoEtichettaForm testo='Inviti in attesa' />
                    </div>
                    <div className='etichettaTre'>
                        <ElementoEtichettaForm testo='Inviti rifiutati' />
                    </div>
                    <div className='elementoUno'>{renderListaImprese(membri, 'Nessun membro presente')}</div>
                    <div className='elementoDue'>{renderListaImprese(membriInAttesa, 'Nessuna richiesta in attesa')}</div>
                    <div className='elementoTre'>{renderListaImprese(membriRifiutati, 'Nessun invito rifiutato')}</div>
                </div>
            </MDBox>
        )
    }

    const renderImpatti = () => {
        if (impatti.length === 0) return null
        return (
            <MDBox bgColor='white' shadow='lg' p={2} mt={2}>
                <MDBox mb={4} mx='auto' sx={{ width: '100%', maxWidth: '1000px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <MDTypography color='dark' fontWeight='bold' variant='h3' sx={{ mb: 2 }}>
                        Il nostro impatto
                    </MDTypography>
                    <ElementoVediListaImpatti lista={impatti} />
                </MDBox>
            </MDBox>
        )
    }

    const renderProgetti = () => {
        return (
            <MDBox className='flex-verticale centrato' p={2}>
                <MDTypography variant='h4' color='text'>
                    PROGETTI
                </MDTypography>
                <MDBox width='100%' maxWidth='350px' pt={2}>
                    <ElementoCarosello
                        borderRadius={false}
                        testo='Nessun progetto sostenuto'
                        lista={progetti.map((progetto) => {
                            return { id: progetto.idProgetto, render: <ElementoMostraProgetto progetto={getProgettoDaId(progetto.idProgetto)} navigate={props.navigate} /> }
                        })}
                    />
                </MDBox>
            </MDBox>
        )
    }

    return (
        <>
            {renderNome}
            {renderDecrizioneArea}
            {renderInfo}
            {renderImprese()}
            {renderProgetti()}
            {renderImpatti()}
        </>
    )
}
const mapStateToProps = (state, ownProps) => ({
    network: getnetworkDaId(ownProps.idNetwork),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoVediNetwork)
