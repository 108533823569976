import React, { useEffect, useState } from 'react'
import MDBox from 'components/MDBox'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import MDAvatar from 'components/MDAvatar'
import { Divider, Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'

import RotatingCard from 'examples/Cards/RotatingCard'
import RotatingCardFront from 'examples/Cards/RotatingCard/RotatingCardFront'
import RotatingCardBack from 'examples/Cards/RotatingCard/RotatingCardBack'
import logo from 'assets/immaginiEtiqua/logoEtiqua2.png'
import { getDatiCardImpresa } from 'getter/getterImpresa'
import { getUrlDaStorage } from 'getter/getterimmagini'

export default function elementoMostraImpresa(props) {
    const [dati, setDati] = useState({ distanza: '', network: '' })
    const [url, setUrl] = useState('')
    const vediImpresa = () => props.navigate(`paginaVediImpresa/${props.impresa.id}`)

    useEffect(async () => {
        const nuovidati = getDatiCardImpresa(props.impresa.id)
        setDati(nuovidati)
        if (props.impresa.logo === undefined || props.impresa.logo === '') setUrl('')
        if (props.impresa.logo !== undefined && props.impresa.logo !== '') setUrl(await getUrlDaStorage(`${props.impresa.id}/logo/thumbNail.png`))
    }, [props.impresa])

    const renderRiga = (label, value) => {
        return (
            <MDBox mb={1} lineHeight={0}>
                <MDTypography variant='caption' color='white'>
                    {label} :
                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize' color='white'>
                        {value}
                    </MDTypography>
                </MDTypography>
            </MDBox>
        )
    }

    const getIndirizzo = () => {
        const sede = dati.isB2b ? 'sedeLegale' : 'puntoVendita'
        return `${props.impresa[sede]?.via || ''} ${props.impresa[sede]?.numero || ''}, ${props.impresa[sede]?.comune || ''} (${props.impresa[sede]?.provincia || ''})`
    }

    const descrizioneImpresa = (
        <MDBox display='flex' sx={{ flexDirection: 'column', width: '100%', boxSizing: 'border-box' }}>
            {renderRiga('Indirizzo', `${props.impresa.puntoVendita?.via || ''} ${props.impresa.puntoVendita?.numero || ''} ${props.impresa.puntoVendita?.comune || ''} ${props.impresa.puntoVendita?.provincia || ''}`)}
            {renderRiga('Progetti sostenuti attualmente', props.impresa.totaleProgettiSostenuti)}
            {renderRiga('Totale sostegno', props.impresa.totaleSostegno)}
        </MDBox>
    )

    return (
        <MDBox bgColor='white' p={2} onClick={vediImpresa} sx={{ cursor: 'pointer', width: '100%', height: '100%', /* border: '0.2px solid', */ color: '#d2d6da' }} shadow='lg' /* bgColor='info' */>
            <MDBox sx={{ display: 'grid', height: '100%', width: '100%', gridTemplateColumns: '100%', gridTemplateRows: '1fr max-content' }}>
                <MDBox className='flex-verticale'>
                    {url === '' ? <MDBox height='40px' width='max-content' mb={1} /> : <MDBox component='img' height='40px' width='max-content' src={url} mb={1} />}
                    <MDTypography color='dark' variant='h4'>
                        {props.impresa.nome}
                    </MDTypography>
                    <MDTypography variant='subtitle2' color='text'>
                        {getIndirizzo()}
                    </MDTypography>
                    <MDTypography variant='subtitle2' color='info'>
                        {dati.network}
                    </MDTypography>
                </MDBox>
                <MDBox className='flex-verticale'>
                    <Divider />
                    <MDTypography color='dark'>{props.distanza}</MDTypography>
                    {dati.isB2b ? null : <MDTypography color='dark'>{dati.percentuale === '' ? 'Nessun progetto sostenuto' : `${dati.percentuale} % a ${dati.nomeProgetto}`} </MDTypography>}
                    <MDTypography> Sostegno totale : {parseFloat(dati.sostegno).toFixed(2)} € </MDTypography>
                </MDBox>
            </MDBox>
        </MDBox>
    )

    return (
        <RotatingCard>
            <RotatingCardFront image='https://bit.ly/3G5JXJZ' icon='touch_app' title={props.impresa.nome} description={props.impresa.descrizione} />
            <RotatingCardBack image='https://bit.ly/32ZoTGx' title='Dati impresa' description={descrizioneImpresa} action={{ type: 'internal', route: vediImpresa, label: 'Vedi impresa' }} />
        </RotatingCard>
    )

    return (
        <MDBox onClick={vediImpresa} p={2} bgColor='white' shadow='md' color='info' borderRadius='lg' sx={{ cursor: 'pointer', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container>
                <Grid container item xs={12} md={7}>
                    <Grid item xs={12} md={4}>
                        <MDBox display='flex' flexDirection='column' justifyContent='center' boxSizing='border-box' sx={{ height: '100%' }}>
                            <MDAvatar size='lg'>
                                <AccountCircleRoundedIcon color='dark' sx={{ width: '100%', height: '100%' }} />
                            </MDAvatar>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MDBox display='flex' flexDirection='column' justifyContent='center' boxSizing='border-box' sx={{ height: '100%' }}>
                            <MDTypography color='info' variant='h5' fontWeight='bold'>
                                {props.impresa.nome}
                            </MDTypography>
                            <MDTypography color='text' variant='caption'>
                                {props.impresa.puntoVendita?.via || ''} {props.impresa.puntoVendita?.numero || ''} {props.impresa.puntoVendita?.comune || ''} ({props.impresa.puntoVendita?.provincia || ''})
                            </MDTypography>
                            <MDTypography color='text' variant='caption'>
                                {props.impresa.descrizione}
                            </MDTypography>
                        </MDBox>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item xs={12} md={5}>
                    <MDBox display='flex' flexDirection='column' justifyContent='center' boxSizing='border-box' sx={{ height: '100%', borderLeft: 'solid 0.3px', pl: 1 }}>
                        {renderRiga('Progetti sostenuti attualmente', props.impresa.totaleProgettiSostenuti)}
                        {renderRiga('Totale sostegno', props.impresa.totaleSostegno)}
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    )
}
