import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import { getAggiornamentoSelezionato } from 'getter/getterProgetti'
import { getIdAggiornamentoSelezionato } from 'getter/getterProgetti'
import ElementoGalleria from 'componenti/elementoGalleria'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import { formattaDataDaFirebase } from 'data/strumenti'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { getUrlDaStorage } from 'getter/getterimmagini'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const paginaVediAggiornamento = (props) => {
    const [listaImmagini, setListaImmagini] = useState([])

    const indietro = () => window.history.back()

    const getUrlImmagine = async (immagine) => {
        const pathThumbNail = `${props.idProgetto}/aggiornamenti/${props.id}/immagini/${immagine}/thumbNail.png`
        const pathOriginale = `${props.idProgetto}/aggiornamenti/${props.id}/immagini/${immagine}/originale.png`
        const url = await Promise.all([getUrlDaStorage(pathThumbNail), getUrlDaStorage(pathOriginale)])
        return { thumbNail: url[0], originale: url[1], id: immagine }
    }

    useEffect(async () => {
        if (props.aggiornamento?.immagini === undefined) {
            setListaImmagini([])
            return
        }
        if (props.aggiornamento.immagini.length === 0) {
            setListaImmagini([])
            return
        }
        const promesse = props.aggiornamento.immagini.map(async (immagine) => {
            return await getUrlImmagine(immagine)
        })

        const risultati = await Promise.all(promesse)
        const lista = risultati.map((result) => {
            return {
                src: result.originale,
                thumbnail: result.thumbNail,
                alt: result.id,
                icona: null,
            }
        })
        setListaImmagini(lista)
    }, [props.aggiornamento])

    const renderPagina = (
        <ElementoRiquadroPagine>
            <MDBox className='flex-verticale' maxWidth='1000px' width='100%' mx='auto'>
                <MDBox className='centrato' sx={{cursor : 'pointer'}} width='50px' height='50px' variant='gradient' color='white' bgColor='primary' onClick={indietro}>
                   <ArrowBackIcon color='white' sx={{width : '100%', height : '100%'}} />
                </MDBox>
                <ElementoEtichettaForm testo={formattaDataDaFirebase(props.aggiornamento.data)} />
                <MDTypography sx={{ mb: 2 }} variant='body2'>
                    {props.aggiornamento.testo}
                </MDTypography>
                <ElementoGalleria immagini={listaImmagini} testoErrore='' />
            </MDBox>
        </ElementoRiquadroPagine>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

const mapStateToProps = (state) => ({
    aggiornamento: getAggiornamentoSelezionato(),
    id: getIdAggiornamentoSelezionato(),
    idProgetto: getIdProgettoSelezionato(),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediAggiornamento)
