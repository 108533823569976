import React from 'react'
import image1 from 'assets/images/products/product-details-1.jpg'
import MDBox from 'components/MDBox'
import { useState } from 'react'
import ImgsViewer from 'react-images-viewer'

import ElementoCarosello from './elementoCarosello'
import MDTypography from 'components/MDTypography'
import MDAvatar from 'components/MDAvatar'

export default function elementoImmagineProfilo(props) {
    //props.link

    const [currentImage, setCurrentImage] = useState(image1)
    const [imgsViewer, setImgsViewer] = useState(false)
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0)

    const handleSetCurrentImage = (index) => {
        setImgsViewerCurrent(index)
    }

    const openImgsViewer = (index) => {
        setImgsViewerCurrent(index)
        setImgsViewer(true)
    }
    const closeImgsViewer = () => setImgsViewer(false)
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1)
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1)

    if (props.immagini.length === 0) {
        const primaLettera = props.nome[0] ?? ''
        return null
        return (
            <MDBox className='centrato' bgColor='white' borderRadius='lg' width='100%' onClick={openImgsViewer}>
                <MDAvatar size='xxl' bgColor='light'>
                    <MDTypography variant='h1' fontWeight='bold' color='white' align='center'>
                        {primaLettera.toUpperCase()}
                    </MDTypography>
                </MDAvatar>
            </MDBox>
        )
    }

    return (
        <MDBox className='centrato'>
            <ImgsViewer
                imgs={props.immagini.map((immagine) => {
                    return { src: immagine.originale }
                })}
                isOpen={imgsViewer}
                onClose={closeImgsViewer}
                currImg={imgsViewerCurrent}
                onClickPrev={imgsViewerPrev}
                onClickNext={imgsViewerNext}
                backdropCloseable
            />

            <ElementoCarosello
                maxContent={true}
                lista={props.immagini.map((immagine, index) => {
                    return {
                        id: immagine.id,
                        render: (
                            <MDBox
                                className='centrato immagine-card-progetti'
                                /*  component='img' */
                                sx={{
                                    border : 'solid transparent',
                                    boxSizing : 'border-box',
                                    height: {xs : '200px', sm : '350px', md : '500px', lg : '700px', xl : '700px', xxl : '700px'},
                                    width: '100%',
                                    backgroundImage: `url(${immagine.originale}`,
                                }}
                                /* src={immagine.originale}
                                    alt={immagine.id}
                                    width='auto'
                                    maxWidth='100%' */
                                onClick={() => openImgsViewer(index)}
                            />
                        ),
                    }
                })}
                testo='Nessun immagine presente'
            />

            {/* <Swiper
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                spaceBetween={0}
                slidesPerView={1}
                loop>
                <SwiperSlide>
                    <MDBox component='img' sx={{ cursor: 'pointer' }} src={currentImage} alt='Product Image' shadow='lg' borderRadius='lg' width='100%' onClick={openImgsViewer} />
                </SwiperSlide>
                <SwiperSlide>
                    <MDBox component='img' sx={{ cursor: 'pointer' }} src={currentImage} alt='Product Image' shadow='lg' borderRadius='lg' width='100%' onClick={openImgsViewer} />
                </SwiperSlide>
            </Swiper> */}
        </MDBox>
    )
}

elementoImmagineProfilo.defaultProps = {
    immagini: [],
    nome: '',
}
