import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Divider } from '@mui/material'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { httpsCallable } from 'firebase/functions'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'

export default function elementoMostraInvitoNetwork(props) {
    const vediNetwork = () => props.navigate(`paginaVediNetwork/${props.id}`)

    const accettaInvito = async () => {
        MODIFICA_STATO_SPINNER(true)
        const accettaInvitoInNetwork = httpsCallable(functions, 'accettaInvitoInNetwork')
        await accettaInvitoInNetwork({ idImpresa: getIdUtenteLoggato(), idNetwork: props.id })
        MODIFICA_STATO_SPINNER(false)
    }
    const rifiutaInvito = async () => {
        MODIFICA_STATO_SPINNER(true)
        const rifiutaInvitoInNetwork = httpsCallable(functions, 'rifiutaInvitoInNetwork')
        await rifiutaInvitoInNetwork({ idImpresa: getIdUtenteLoggato(), idNetwork: props.id })
        MODIFICA_STATO_SPINNER(false)
    }

    const accetta = () => APRI_POP_UP({ id: 'richiediConferma', parametro: { testo: 'Sei sicuro di voler entrare a far parte di questo network ?', azione: accettaInvito } })
    const rifiuta = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: `Sei sicuro di voler rifiutare l'invito di questo network?`, azione: rifiutaInvito } })

    return (
        <>
            <ListItem sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} onClick={() => props.hanndleSelezionato(props.impresa)}>
                <ListItemText primary={props.nome} />
                <MDBox className='contenitore-bottoni-destra' sx={{ marginTop: '0px !important', paddingTop: '0px !important' }}>
                    <MDButton color='dark' variant='contained' size='small' onClick={vediNetwork}>
                        vedi network
                    </MDButton>
                    <MDButton color='error' variant='contained' size='small' onClick={rifiuta}>
                        rifiuta
                    </MDButton>
                    <MDButton color='success' variant='contained' size='small' onClick={accetta}>
                        accetta
                    </MDButton>
                </MDBox>
            </ListItem>
            {props.divider ? <Divider /> : null}
        </>
    )
}

elementoMostraInvitoNetwork.defaulProps = {
    nome: '',
    id: '',
    divider: true,
}
