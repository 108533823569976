import { doc, setDoc, Timestamp } from 'firebase/firestore'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'
import { ref, uploadBytes } from 'firebase/storage'
import { newId } from 'data/strumenti'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'

export const NUOVO_AGGIORNAMENTO_PROGETTO = async (scelta, testo, immagini) => {
    MODIFICA_STATO_SPINNER(true)
    const idProgetto = getIdProgettoSelezionato()
    const idAggiornamento = newId()

    const caricaInStorage = async (file, path) => {
        return await uploadBytes(ref(storage, path), file)
    }

    var listaIdImmagini = []

    if (immagini.length !== 0) {
        const promesse = immagini.map(async (immagine) => {
            const pathThumbnail = `${idProgetto}/aggiornamenti/${idAggiornamento}/immagini/${immagine.id}/thumbNail.png`
            const pathOriginale = `${idProgetto}/aggiornamenti/${idAggiornamento}/immagini/${immagine.id}/originale.png`
            listaIdImmagini.push(immagine.id)
            return await Promise.all([caricaInStorage(immagine.thumbNail.blob, pathThumbnail), caricaInStorage(immagine.originale, pathOriginale)])
        })

        await Promise.all(promesse)
    }

    const aggiornamento = {
        visibilita: scelta,
        testo: testo,
        immagini: listaIdImmagini,
        data: Timestamp.fromDate(new Date()),
    }

    await setDoc(doc(db, 'progetti', idProgetto, 'aggiornamenti', idAggiornamento), aggiornamento)
    MODIFICA_STATO_SPINNER(false)
    return
}
