import { store } from '../store'

export const AGGIORNA_STATO_MODIFICA = (parametro) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'AGGIORNA_STATO_MODIFICA',
            payload: parametro,
        })
        resolve()
    })
}
