import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ElementoRiquadroPagine from 'componenti/elementoRiquadroPagine'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import { getProgettoSelezionato } from 'getter/getterProgetti'
import { Chip, Divider, Grid } from '@mui/material'
import useCache from 'customHooks/useCache'
import ElementoImmagineProfilo from 'componenti/elementoImmagineProfilo'
import ElementoCarosello from 'componenti/elementoCarosello'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ElementoProgressCircolare from 'componenti/elementoProgressCircolare'
import ElementoMostraSdgs from 'componenti/elementoMostraSdgs'
import useForm from 'customHooks/useForm'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import { formattaDataDaFirebase } from 'data/strumenti'
import { getImpreseSostenitriciProgetto } from 'getter/getterProgetti'
import ElementoMostraImpresa from 'componenti/elementoMostraImpresa'
import { getImpresaDaId } from 'getter/getterImpresa'
import { getTotaleRaccoltoProgetto } from 'getter/getterProgetti'
import { isImpresaLoggata } from 'getter/getterUtenteLoggato'
import { isMioProgetto } from 'getter/getterEnte'
import { Icon } from '@mui/material'
import MDButton from 'components/MDButton'
import { getTutteImmaginiProgetto } from 'getter/getterProgetti'
import { getUrlDaStorage } from 'getter/getterimmagini'
import ElementoMostraAggiornamento from 'componenti/elementoMostraAggiornamento'
import { getAggiornamentiProgetto } from 'getter/getterProgetti'
import { isEtiquaLoggata } from 'getter/getterUtenteLoggato'
import ElementoNavigaziioneProgettoEtiqua from 'componenti/elementoNavigaziioneProgettoEtiqua'
import { formProgetto, formRiassuntoProgetto } from 'formModel/formProgetto'
import ElementoValidaProgetto from 'componenti/elementoValidaProgetto'
import { getDatiImpattoProgettoSelezionato } from 'getter/getterProgetti'
import ElementoVediListaImpatti from 'componenti/elementoVediListaImpatti'
import ElementoMostraImpresaMappa from 'componenti/elementoMostraImpresaMappa'
import { List } from '@mui/material'
import MDProgress from 'components/MDProgress'
import { isDonazioniAutorizzateProgetto } from 'getter/getterProgetti'
import { getEnteDaId } from 'getter/getterEnte'
import ElementoOpzioniProgetto from 'componenti/elementoOpzioniProgetto'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import { AGGIORNA_PROGETTO } from 'data/azioni/AGGIORNA_PROGETTO'
import { formContattiProgetto, formLinkContattiProgetto } from 'formModel/formProgetto'
import ElementoContatti from 'componenti/elementoContatti'

export const paginaVediProgetto = (props) => {
    const [statoCompleto, azioniCompleto, renderFormCompleto] = useForm(formProgetto, props.progetto, false, false)
    const [statoRiassunto, azioniRiassunto, renderFormRiassunto] = useForm(formRiassuntoProgetto, props.progetto, false, false)
    const { listaImpreseWindow } = useCache()
    const [listaImprese, setListaImprese] = useState([])
    const [listaImmagini, setListaImmagini] = useState([])
    const [vista, setVista] = useState('home')
    const [logo, setLogo] = useState('')
    const [formContatti, setFormContatti] = useState({ parts: [] })
    const handleVista = (valore) => setVista(valore)

    useEffect(() => {
        setFormContatti(formContattiProgetto(true))
    }, [])

    const aggiorna = async () => {
        try {
            await azioniRiassunto.validaForm()
            await AGGIORNA_PROGETTO(props.idProgetto, statoRiassunto.statoForm)
            azioniRiassunto.vediOutput()
        } catch (error) {
            return
        }
    }
    const aggiornaCompleto = async () => {
        try {
            await azioniCompleto.validaForm()
            await AGGIORNA_PROGETTO(props.idProgetto, statoCompleto.statoForm)
            azioniCompleto.vediOutput()
        } catch (error) {
            return
        }
    }
    const aggiornaContatti = async (stato) => {
        await AGGIORNA_PROGETTO(props.idProgetto, stato)
        return
    }

    const vediImpresa = (impresa) => props.navigate(`paginaVediImpresa/${impresa.id}`)

    const getUrlImmagine = async (immagine) => {
        const pathThumbNail = !immagine.isLogo ? `${props.idProgetto}/immagini/${immagine.id}/thumbNail.png` : `${props.idProgetto}/logo/thumbNail.png`
        const pathOriginale = !immagine.isLogo ? `${props.idProgetto}/immagini/${immagine.id}/originale.png` : `${props.idProgetto}/logo/originale.png`

        const url = await Promise.all([getUrlDaStorage(pathThumbNail), getUrlDaStorage(pathOriginale)])
        return { thumbNail: url[0], originale: url[1], id: immagine.id, isLogo: false /* immagine.isLogo */ }
    }

    useEffect(async () => {
        if (props.listaImmagini.length === 0) return

        const promesse = props.listaImmagini.map(async (immagine) => {
            return await getUrlImmagine(immagine)
        })

        const risultati = await Promise.all(promesse)
        var lista = []
        risultati.forEach((result) => {
            const immagine = {
                originale: result.originale,
                thumbnail: result.thumbNail,
                id: result.id,
            }
            if (result.isLogo) setLogo(immagine)
            if (!result.isLogo) lista.push(immagine)
            return
        })

        setListaImmagini(lista)
    }, [props.listaImmagini])

    useEffect(() => {
        const nuovaLista = props.listaImprese.map((impresa) => {
            const datiImpresa = getImpresaDaId(impresa.idImpresa)
            return Object.assign({}, datiImpresa, impresa)
        })
        /* const nuovaLista = props.listaImprese.map((impresa) => {
            return { id: impresa.idImpresa, render: <ElementoMostraImpresa impresa={getImpresaDaId(impresa.idImpresa)} navigate={props.navigate} /> }
        }) */
        setListaImprese(nuovaLista)
    }, [listaImpreseWindow, props.listaImprese])

    const sostieni = () => props.navigate(`paginaSostieniProgetto/${props.idProgetto}`)
    const nuovoAggiornamento = () => props.navigate(`paginaNuovoAggiornamento/${props.idProgetto}`)
    const modificaImmagini = () => props.navigate(`paginaModificaImmaginiProgetto/${props.idProgetto}`)

    const getPercentuale = () => {
        const percentuale = Number(((props.totaleRaccolto * 100) / (props.progetto?.traguardi?.totale || 0)).toFixed(2))
        return percentuale > 100 ? 100 : percentuale
    }

    const immagini = (
        <MDBox mt={3}>
            <ElementoImmagineProfilo immagini={listaImmagini} />
        </MDBox>
    )

    const bottoneSostieni = () => {
        if (props.isMioProgetto) {
            return (
                <MDBox className='flex-verticale' sx={{ padding: '0px !important', margin: '0px !important', alignItems: 'end' }}>
                    <MDBox className='flex-verticale' width='max-content'>
                        <MDBox className='centrato' px={{ xs: 1, md: 4, lg: 7 }} pt={1} pb={1} borderRadius='lg' bgColor='info' color='white' onClick={nuovoAggiornamento} sx={{ cursor: 'pointer' }}>
                            <MDTypography color='white' fontWeight='bold' align='center'>
                                rilascia aggiornamento
                            </MDTypography>
                        </MDBox>
                        <MDButton sx={{ mt: 1 }} variant='outlined' color='dark' onClick={modificaImmagini}>
                            <Icon sx={{ mr: 1 }}>edit</Icon>
                            immagini
                        </MDButton>
                    </MDBox>
                </MDBox>
            )
        }
        if (!props.isImpresa) return null
        if (!props.possoDonare) return null
        return (
            <MDBox px={7} pt={1} pb={1} borderRadius='lg' bgColor='info' color='white' onClick={sostieni} sx={{ cursor: 'pointer' }}>
                <MDTypography color='white' fontWeight='bold'>
                    Sostieni
                </MDTypography>
            </MDBox>
        )
    }

    const renderEnte = <MDTypography color='info'>{getEnteDaId(props.progetto.idEnte).nome}</MDTypography>

    const renderTags = (
        <Grid container spacing={1} direction={'row'}>
            {props.progetto.categoriaAttivita?.map((elemento) => {
                return (
                    <Grid item xs='auto' key={`chip-${elemento}`}>
                        <Chip label={elemento} />
                    </Grid>
                )
            })}
        </Grid>
    )

    const renderStatoProgetto = (
        <MDBox className='flex-verticale' sx={{ justifyContent: 'end' }}>
            {/* <ElementoProgressCircolare value={Number(((props.totaleRaccolto * 100) / (props.progetto?.traguardi?.totale || 0)).toFixed(2))} color='info' /> */}
            <MDBox sx={{ width: '100%' }} mb={2}>
                <MDProgress value={getPercentuale()} color='info' variant='gradient' label />
            </MDBox>

            <MDTypography color='text'>Budget : € {props.progetto?.traguardi?.totale || 0}</MDTypography>
            <MDTypography color='info' fontWeight='bold'>
                Raccolti : € {props.totaleRaccolto}
            </MDTypography>

            <MDBox display='flex' flexDirection='row'>
                <MDTypography color='text'>Data scadenza :</MDTypography>
                <MDTypography color='info' fontWeight='bold' sx={{ ml: 0.8 }}>
                    {props.progetto.dataFine === undefined ? null : formattaDataDaFirebase(props.progetto.dataFine)}
                </MDTypography>
            </MDBox>
        </MDBox>
    )

    const renderAggiornamenti = (
        <MDBox className='flex-verticale'>
            <MDTypography color='text'>Aggiornamenti</MDTypography>
            <MDBox mt={1} sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <ElementoCarosello
                    testo='Nessun aggiornamento'
                    lista={props.aggiornamenti.map((aggiornamento) => {
                        return { id: aggiornamento.id, render: <ElementoMostraAggiornamento idProgetto={props.idProgetto} navigate={props.navigate} aggiornamento={aggiornamento} /> }
                    })}
                />
            </MDBox>
        </MDBox>
    )

    const renderParteUno = (
        <MDBox bgColor='white' width='100%' className='flex-verticale' maxWidth='1000px' mx='auto' px={1} sx={{ overflow: 'hidden' }}>
            <ElementoEtichettaForm testo={props.progetto.nome} elementoSotto={renderEnte} secondoElemento={bottoneSostieni()} />

            {/* <Divider /> */}
            <MDTypography variant='subtitle2' color='dark' sx={{ whiteSpace: 'pre-line', mt: 2 }}>
                {props.progetto.breveDescrizione}
            </MDTypography>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6} lg={6}>
                    {renderStatoProgetto}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    {renderAggiornamenti}
                </Grid>
            </Grid>

            {immagini}
            <MDBox mt={1}>{renderTags}</MDBox>
            <MDBox pt={2}>
                {renderFormRiassunto}
                {props.isMioProgetto ? <ElementoBottoniForm vista={statoRiassunto.vista} annulla={azioniRiassunto.annullaControlla} valida={aggiorna} vediInput={azioniRiassunto.vediInput} /> : null}
            </MDBox>
        </MDBox>
    )

    const renderParteDue = (
        <MDBox width='100%' maxWidth='500px' mx='auto'>
            <ElementoEtichettaForm testo='Aggiornamenti' />
            {renderAggiornamenti}
        </MDBox>
    )

    const renderParteTre = () => {
        if (props.datiImpatto.length === 0) return null
        return (
            <MDBox bgColor='white' mb={2} width='100%' p={2}>
                <MDBox mx='auto' sx={{ width: '100%', maxWidth: '1000px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <MDTypography color='dark' fontWeight='bold' variant='h3' sx={{ mb: 2 }}>
                        IMPATTO
                    </MDTypography>
                    <ElementoVediListaImpatti lista={props.datiImpatto} />
                </MDBox>
            </MDBox>
        )
    }

    const renderImprese = () => {
        if (listaImprese.length === 0) {
            return (
                <MDBox mx='auto' maxWidth='1000px' width='100%'>
                    <ElementoEtichettaForm testo='Imprese sostenitrici' />
                    <MDTypography color='text'>Nessuna Impresa sostenitrice</MDTypography>
                </MDBox>
            )
        }
        return (
            <MDBox mx='auto' maxWidth='1000px' width='100%'>
                <ElementoEtichettaForm testo='Imprese sostenitrici' />
                <List sx={{ width: '100%', mt: 2 }}>
                    {listaImprese.map((impresa, index) => {
                        return (
                            <MDBox key={impresa.id} width='100%'>
                                <ElementoMostraImpresaMappa impresa={impresa} hanndleSelezionato={vediImpresa} />
                                {index === listaImprese.length + 1 ? null : <Divider />}
                            </MDBox>
                        )
                    })}
                </List>
            </MDBox>
        )
    }

    const renderHome = (
        <>
            <MDBox bgColor='white' mb={2} width='100%' p={2}>
                {renderParteUno}
            </MDBox>

            {/* <MDBox bgColor='white' mb={0} width='100%' p={2}>
                {renderParteDue}
            </MDBox> */}

            {renderParteTre()}

            <MDBox bgColor='white' mb={3} width='100%' p={2}>
                {renderImprese()}
            </MDBox>

            <ElementoContatti vediBottone={props.isMioProgetto} formLink={formLinkContattiProgetto(true)} form={formContatti} dati={props.progetto} aggiorna={aggiornaContatti} />
        </>
    )

    const renderDettagli = (
        <MDBox bgColor='white' widtj='100%' shadow='lg' p={2}>
            <MDBox mx='auto' maxWidth='1000px'>
                {renderFormCompleto}
                <ElementoBottoniForm vista={statoCompleto.vista} annulla={azioniCompleto.annullaControlla} valida={aggiornaCompleto} vediInput={azioniCompleto.vediInput} />
            </MDBox>
        </MDBox>
    )

    const renderVista = () => {
        switch (vista) {
            case 'home':
                return renderHome
            case 'dettagli':
                return renderDettagli
            case 'impatto':
                return <ElementoValidaProgetto idProgetto={props.idProgetto} annulla={() => handleVista('home')} />
            case 'opzioni':
                return <ElementoOpzioniProgetto />

            default:
                return null
        }
    }

    const renderPagina = (
        <>
            {props.isEtiqua ? <ElementoNavigaziioneProgettoEtiqua selezionato={vista} handleVista={handleVista} /> : null}
            {renderVista()}
        </>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

const mapStateToProps = (state) => ({
    progetto: getProgettoSelezionato(),
    idProgetto: state.url[4],
    listaImprese: getImpreseSostenitriciProgetto(state.url[4]),
    totaleRaccolto: getTotaleRaccoltoProgetto(state.url[4]),
    isImpresa: isImpresaLoggata(),
    isMioProgetto: isMioProgetto(state.url[4]),
    listaImmagini: getTutteImmaginiProgetto(),
    aggiornamenti: getAggiornamentiProgetto(),
    isEtiqua: isEtiquaLoggata(),
    datiImpatto: getDatiImpattoProgettoSelezionato(),
    possoDonare: isDonazioniAutorizzateProgetto(state.url[4]),
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediProgetto)
