import React from 'react'
import ImgsViewer from 'react-images-viewer'
import SwiperCore, { Autoplay, Navigation, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import MDBox from 'components/MDBox'
import colors from 'assets/theme/base/colors'
import MDTypography from 'components/MDTypography'

export default function elementoCarosello(props) {
    SwiperCore.use([Autoplay, Navigation])
    var propsBox = { borderRadius: 'lg' }
    if (!props.borderRadius) {
        propsBox = { shadow: 'lg' }
    }

    const renderSlider = (
        <MDBox {... propsBox} sx={{/*  overflow: 'hidden', */ height: props.maxContent ? 'max-content' : '100%', width: '100%', display: 'flex', boxSizing: 'border-box' }}>
            {props.lista.length === 1 ? (
                props.lista[0].render
            ) : (
                <Swiper
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    spaceBetween={2}
                    slidesPerView={1}
                    loop>
                    {props.lista.map((elemento) => {
                        return <SwiperSlide key={`slide-${elemento.id}`}>{elemento.render}</SwiperSlide>
                    })}
                </Swiper>
            )}
        </MDBox>
    )

    const renderErrore = (
        <MDBox p={1} width='100%' height='100%' minHeight='200px' sx={{ textAlign: 'center', backgroundColor: colors.grey[300], display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MDTypography variant='body1' fontWeight='bold' color='dark'>
                {props.testo}
            </MDTypography>
        </MDBox>
    )
    return props.lista.length === 0 ? renderErrore : renderSlider
}

elementoCarosello.defaultProps = {
    lista: [],
    testo: 'Ti sei dimenticato il testo',
    maxContent: false,
    borderRadius: true,
}
