import { store } from 'data/store'
import PercentRoundedIcon from '@mui/icons-material/PercentRounded'
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded'
import { getCreditiImpresa, getImpresaDaId, getProgettoAttualmenteSostenutoImpresa } from './getterImpresa'
import { NUOVA_DONAZIONE_LIBERALE } from 'data/azioni/NUOVA_DONAZIONE_LIBERALE'
import { SOSTIENI_PROGETTO } from 'data/azioni/SOSTIENI_PROGETTO'
import MDTypography from 'components/MDTypography'
import { getColoreSdg } from './getterSdg'
import { getIdUtenteLoggato, getProfiloUtenteLoggato } from './getterUtenteLoggato'
import { profiloConsumatore } from 'util'
import { profiloEnte } from 'util'
import { profiloImpresa } from 'util'
import { getEnteDaId } from './getterEnte'
import { isImpresaB2b } from './getterImpresa'

export const getProgettoSelezionato = () => {
    return store.getState().reducerProgetto
}

export const getIdProgettoSelezionato = () => {
    const state = store.getState()
    return state.url[4]
}

export const getListaProgettiFiltrata = (filtri) => {
    const { categoria, luogo, ordinaPer } = filtri

    var listaFiltrata = window.listaProgetti.filter((progetto) => {
        const comparazioneCategoria = () => {
            if (categoria === '') return true
            return progetto.categoriaAttivita.includes(categoria)
        }
        const comparazioneLuogo = () => {
            if (luogo === '') return true
            const comuneEnte = getEnteDaId(progetto.idEnte).sedeLegale.comune
            return comuneEnte === luogo
        }
        const test = isProgettoAttivo(progetto.id) || isRaccoltaCompletata(progetto.id)
        return comparazioneCategoria() && comparazioneLuogo() && test
    })

    //if (ordinaPer === '') return listaFiltrata
    //metodo ordinamento

    return listaFiltrata.sort((a, b) => {
        var parametro = ''
        if (ordinaPer === 0) parametro = getGiorniInizioProgetto(a.id) - getGiorniInizioProgetto(b.id)
        if (ordinaPer === 1) parametro = getPercentualeSostenutaProgetto(b.id) - getPercentualeSostenutaProgetto(a.id)
        if (ordinaPer === 2) parametro = getGiorniScadenzaProgetto(a.id) - getGiorniScadenzaProgetto(b.id)
        return parametro
    })
}

export const getListaModalitaDonazione = () => {
    const crediti = getCreditiImpresa()
    const b2b = isImpresaB2b(getIdUtenteLoggato())
    const donazioneLiberale = {
        tipologia: 'standard',
        tipo: 'liberale',
        spiegazione: 'con una donazioneliberale',
        titolo: 'Donazione liberale',
        testo: 'Scegli liberamente quanto vuoi investire nel progetto e accedi subito alle previsioni di impatto che la tua donazione avrà sulla comunità!',
        icona: <VolunteerActivismRoundedIcon color='white' sx={{ width: '100%', height: '100%' }} />,
        simbolo: '€',
        start: 200,
        min: 1,
        max: getCreditiImpresa(),
        azione: NUOVA_DONAZIONE_LIBERALE,
        info: [`Crediti disponibili : ${crediti}`],
        testoFine : 'Grazie per aver effettuato una donazione a questo progetto'
    }
    const donazioneLiberaleNoCrediti = {
        tipologia: 'noCrediti',
        tipo: 'liberale',
        spiegazione: 'con una donazioneliberale',
        titolo: 'Donazione liberale',
        testo: 'Effettua una ricarica per poter fare una donazione',
        icona: <VolunteerActivismRoundedIcon color='white' sx={{ width: '100%', height: '100%' }} />,
        simbolo: '€',
        start: 200,
        min: 1,
        max: getCreditiImpresa(),
        azione: NUOVA_DONAZIONE_LIBERALE,
    }
    const donazionePercentuale = {
        tipologia: 'standard',
        tipo: 'percentuale',
        spiegazione: 'con una donazione % sulla vendita dei tuoi prodotti e servizi',
        titolo: 'Donazioni % sulle vendite',
        testo: "Associa al progetto una % sulle vendite! La donazione partirà solo dopo aver effettuato una vendita a un cliente affiliato a ETIQUA. Trasforma ogni vendita in un'opportunità per fare del bene!",
        icona: <PercentRoundedIcon color='white' sx={{ width: '100%', height: '100%' }} />,
        simbolo: '%',
        info: ['non è possibile inserire un valore inferiore allo 0.5%', 'sarà possibile modificare o eliminare il valore in qualsiasi momento.'],
        min: 0.5,
        max: 100,
        azione: SOSTIENI_PROGETTO,
        testoFine : 'Grazie per aver deciso di sostenere attivamente questo progetto'
    }

    const altroProgettoSostenuto = {
        tipologia: 'altroProgettoSostenuto',
        tipo: 'percentuale',
        spiegazione: 'con una donazione % sulla vendita dei tuoi prodotti e servizi',
        titolo: 'Donazioni % sulle vendite',
        testo: '* Stai attualmente sostenendo un altro progetto, quindi non puoi fare altrettanto con questo',
        icona: <PercentRoundedIcon color='white' sx={{ width: '100%', height: '100%' }} />,
    }

    const liberale = crediti === 0 ? donazioneLiberaleNoCrediti : donazioneLiberale
    const progettoAttualmenteSostenuto = getProgettoAttualmenteSostenutoImpresa()
    if (b2b) return [liberale]
    if (Object.keys(progettoAttualmenteSostenuto).length === 0) return [liberale, donazionePercentuale]
    const progettoSostenuto = {
        tipologia: 'attualmenteSostenuto',
        tipo: 'percentuale',
        spiegazione: 'con una donazione % sulla vendita dei tuoi prodotti e servizi',
        titolo: 'Donazioni % sulle vendite',
        testo: 'Stai attualmente sostenendo questo progetto',
        icona: (
            <MDTypography color='white' variant='h1' fontSize='70px'>
                {progettoAttualmenteSostenuto.percentuale}%
            </MDTypography>
        ),
        simbolo: '%',
        info: ['non è possibile inserire un valore inferiore allo 0.5%', 'sarà possibile modificare o eliminare il valore in qualsiasi momento.'],
        min: 0.5,
        max: 100,
        azione: SOSTIENI_PROGETTO,
        testoFine : 'Percentuale di donazione al progetto modificata'
    }
    if (b2b) return [liberale]
    if (progettoAttualmenteSostenuto.id === getIdProgettoSelezionato()) return [liberale, progettoSostenuto]
    return [liberale, altroProgettoSostenuto]
}

export const getProgettoDaId = (id) => {
    const index = window.listaProgetti.findIndex((progetto) => progetto.id === id)
    if (index === -1) return ''
    return window.listaProgetti[index]
}

const getTotaleDonazioniLiberaliImpresaSuProgetto = (idImpresa, id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.donazioniLiberali === undefined) return 0
    if (progetto.donazioniLiberali[idImpresa] === undefined) return 0
    return progetto.donazioniLiberali[idImpresa]
}
const getTotaleDonazioniPercentualiImpresaSuProgetto = (idImpresa, id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.sostegni === undefined) return 0
    if (progetto.sostegni[idImpresa] === undefined) return 0
    return progetto.sostegni[idImpresa].totale ?? 0
}

export const getImpreseDonazioniLiberaliProgetto = (id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.donazioniLiberali === undefined) return []
    return Object.keys(progetto.donazioniLiberali).map((chiave) => {
        return { idImpresa: chiave, totale: getTotaleDonazioniLiberaliImpresaSuProgetto(chiave, id) }
    })
}
export const getImpreseDonazioniPercentualiProgetto = (id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.sostegni === undefined) return []
    var imprese = []
    Object.keys(progetto.sostegni).map((chiave) => {
        const sostegno = progetto.sostegni[chiave]
        if (chiave !== 'totaleConsumatori') imprese.push({ idImpresa: chiave, percentuale: sostegno.percentuale, attivo: sostegno.attivo, totale: getTotaleDonazioniPercentualiImpresaSuProgetto(chiave, id) })
    })
    return imprese
}

export const getImpreseSostenitriciProgetto = (id) => {
    var listaId = []
    var imprese = []
    imprese = getImpreseDonazioniPercentualiProgetto(id).map((impresa) => {
        listaId.push(impresa.idImpresa)
        const donazioniLiberali = getTotaleDonazioniLiberaliImpresaSuProgetto(impresa.idImpresa, id)
        return {
            idImpresa: impresa.idImpresa,
            percentuale: impresa.percentuale,
            attivo: impresa.attivo,
            totaleDonazioniLiberali: donazioniLiberali,
            totaleDonazioniPercentuali: impresa.totale,
            totale: impresa.totale + donazioniLiberali,
        }
    })
    getImpreseDonazioniLiberaliProgetto(id).forEach((impresa) => {
        if (!listaId.includes(impresa.idImpresa)) {
            imprese.push({
                idImpresa: impresa.idImpresa,
                percentuale: 0,
                attivo: false,
                totaleDonazioniLiberali: impresa.totale,
                totaleDonazioniPercentuali: 0,
                totale: impresa.totale,
            })
        }
    })
    imprese = imprese.sort((a, b) => {
        const prima = getImpresaDaId(a.idImpresa)
        const seconda = getImpresaDaId(b.idImpresa)
        if (prima.nome < seconda.nome) {
            return -1
        }
        if (prima.nome > seconda.nome) {
            return 1
        }
        return 0
    })
    return imprese
}

export const getTotaleRaccoltoProgetto = (id) => {
    const cacheProgetto = getProgettoDaId(id)

    const getTotaleDonazioniLiberaliProgetto = () => {
        if (cacheProgetto.donazioniLiberali === undefined) return 0
        return Object.keys(cacheProgetto.donazioniLiberali).reduce((acc, idImpresa) => {
            acc += cacheProgetto.donazioniLiberali[idImpresa]
            return acc
        }, 0)
    }

    const getTotaleDonazioniPercentualiProgetto = () => {
        if (cacheProgetto.sostegni === undefined) return 0
        return Object.keys(cacheProgetto.sostegni).reduce((acc, idImpresa) => {
            acc += cacheProgetto.sostegni[idImpresa].totale ?? 0
            return acc
        }, 0)
    }

    const liberali = getTotaleDonazioniLiberaliProgetto()
    const percentuali = getTotaleDonazioniPercentualiProgetto()
    return liberali + percentuali
}

export const getGiorniScadenzaProgetto = (id) => {
    const date1 = new Date(getDataScadenzaProgetto(id).seconds * 1000)
    const date2 = new Date()
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
}
export const getGiorniInizioProgetto = (id) => {
    const date1 = new Date(getDataCreazioneProgetto(id).seconds * 1000)
    const date2 = new Date()
    const diffTime = Math.abs(date1 - date2)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
}

export const getPercentualeSostenutaProgetto = (id) => {
    const progetto = getProgettoDaId(id)
    const totale = getTotaleRaccoltoProgetto(id)
    const budget = progetto.traguardi?.totale || 0
    const percentuale = Number(((totale * 100) / budget).toFixed(2))
    return percentuale > 100 ? 100 : percentuale
}

export const getBudgetTotaleProgettoDaId = (id) => {
    const progetto = getProgettoDaId(id)
    return progetto.traguardi?.totale || 0
}

export const isRaccoltaCompletata = (id) => {
    const totale = getProgettoDaId(id).traguardi?.totale || 0
    const raccolto = getTotaleRaccoltoProgetto(id)
    return raccolto >= totale
}

export const isRaccoltaValidata = (id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.isValidata === undefined) return false
    return progetto.isValidata
}

export const getDataScadenzaProgetto = (id) => {
    const progetto = getProgettoDaId(id)
    return progetto.dataFine || { seconds: 0 }
}
export const getDataCreazioneProgetto = (id) => {
    const progetto = getProgettoDaId(id)
    return progetto.dataCreazione || { seconds: 0 }
}

export const getLogoProgetto = () => {
    return getProgettoSelezionato().logo || ''
}
export const getListaImmaginiProgetto = () => {
    const immagini = getProgettoSelezionato().immagini
    if (immagini === undefined) return []
    return Object.keys(immagini)
}

export const getTutteImmaginiProgetto = () => {
    const progetto = getProgettoSelezionato()
    const logo = progetto.logo ?? ''
    if (logo === '' && progetto.immagini === undefined) return []
    if (logo !== '' && progetto.immagini === undefined) return [{ isLogo: true, id: logo }]
    var immagini = Object.keys(progetto.immagini).map((id) => {
        return { isLogo: false, id: id }
    })
    if (logo !== '') immagini.unshift({ isLogo: true, id: logo })
    return immagini
}

const getAggiornamentiConsumatore = () => {
    const state = store.getState()
    const aggiornamenti = state.reducerAggiornamentiProgetto || []
    return aggiornamenti.filter((aggiornamento) => aggiornamento.visibilita !== 'Imprese')
}
const getAggiornamentiImprese = () => {
    const state = store.getState()
    const aggiornamenti = state.reducerAggiornamentiProgetto || []
    return aggiornamenti.filter((aggiornamento) => aggiornamento.visibilita !== 'Consumatori')
}

export const getAggiornamentiProgetto = () => {
    const state = store.getState()
    const aggiornamenti = state.reducerAggiornamentiProgetto || []
    const progetto = getProgettoSelezionato()
    const profilo = getProfiloUtenteLoggato()
    if (profilo === '') return getAggiornamentiConsumatore()
    if (profilo === profiloConsumatore.tipo) return getAggiornamentiConsumatore()
    if (profilo === profiloEnte.tipo && progetto.idEnte !== getIdUtenteLoggato()) return getAggiornamentiConsumatore()
    if (profilo === profiloImpresa.tipo) return getAggiornamentiImprese()
    return aggiornamenti
}

export const getListaProgettiInAttesa = () => {
    return window.listaProgetti.filter((progetto) => {
        return isProgettoInAttesa(progetto.id)
    })
}
export const getListaProgettiCompletati = () => {
    return window.listaProgetti.filter((progetto) => {
        return isRaccoltaCompletata(progetto.id)
    })
}

export const isProgettoInAttesa = (id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.stato === undefined) return true
    if (progetto.stato === '') return true
    return progetto.stato === 'inAttesa'
}
export const isProgettoRifiutato = (id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.stato === undefined) return true
    if (progetto.stato === '') return true
    return progetto.stato === 'rifiutato'
}

export const getDatiImpattoProgettoSelezionato = () => {
    const progetto = getProgettoSelezionato()
    const impatto = progetto.impatto
    const idProgetto = getIdProgettoSelezionato()
    const isTerminato = isRaccoltaValidata(idProgetto)
    if (impatto === undefined) return []
    if (impatto.length === 0) return []
    return impatto.map((datiImpatto) => {
        return {
            descrizione: datiImpatto.descrizione,
            sdg: datiImpatto.sdg,
            titolo: datiImpatto.titolo,
            valore: datiImpatto.valore,
            colore: getColoreSdg(datiImpatto.sdg),
            isTerminato: isTerminato,
            idProgetto: idProgetto,
        }
    })
}

export const getImpattoProgettoDaId = (idProgetto) => {
    const progetto = getProgettoDaId(idProgetto)
    const isTerminato = isRaccoltaValidata(idProgetto)
    const impatto = progetto.impatto
    const budget = getBudgetTotaleProgettoDaId(idProgetto)
    if (impatto === undefined) return []
    if (impatto.length === 0) return []
    if (Object.keys(impatto).length === 0) return []
    return impatto.map((datiImpatto) => {
        return {
            descrizione: datiImpatto.descrizione,
            sdg: datiImpatto.sdg,
            titolo: datiImpatto.titolo,
            valore: datiImpatto.valore,
            colore: getColoreSdg(datiImpatto.sdg),
            isTerminato: isTerminato,
            idProgetto: idProgetto,
            budget: budget,
        }
    })
}

export const isProgettoAttivo = (id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.stato === undefined) return false
    if (progetto.stato !== 'accettato') return false
    const dataFine = getDataScadenzaProgetto(id)
    const isPassata = new Date(dataFine.seconds * 1000) < new Date()
    if (isPassata) return false
    if (isRaccoltaCompletata(id)) return false
    return true
}
export const isProgettoValidato = (id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.isValidata === undefined) return false
    return progetto.isValidata
}
export const isProgettoScaduto = (id) => {
    const progetto = getProgettoDaId(id)
    if (progetto.stato !== 'accettato') return false
    const dataFine = getDataScadenzaProgetto(id)
    const isPassata = new Date(dataFine.seconds * 1000) < new Date()
    return isPassata && !isRaccoltaCompletata(id)
}

export const getIdAggiornamentoSelezionato = () => {
    const state = store.getState()
    return state.url[6]
}
export const getAggiornamentoSelezionato = () => {
    const state = store.getState()
    const aggiornamenti = state.reducerAggiornamentiProgetto
    const idAggiornamento = getIdAggiornamentoSelezionato()
    const index = aggiornamenti.findIndex((aggiornamento) => aggiornamento.id === idAggiornamento)
    if (index === -1) return {}
    return aggiornamenti[index]
}

export const getDatiCardProgetto = (id) => {
    const progetto = getProgettoDaId(id)
    const totale = getTotaleRaccoltoProgetto(id)
    const budget = progetto.traguardi?.totale || 0
    const diff = getGiorniScadenzaProgetto(id)
    const impatti = progetto.impatto.map((impatto) => {
        const testo = impatto.testoCard || 'testo non inserito'
        return {
            numero: impatto.sdg,
            testo: `${impatto.valore} ${testo}`,
            colore: getColoreSdg(impatto.sdg),
        }
    })
    return {
        nome: progetto.nome,
        ente: getEnteDaId(progetto.idEnte).nome,
        totale: totale,
        percentuale: getPercentualeSostenutaProgetto(id),
        budget: budget,
        scadenza: diff,
        impatti: impatti,
    }
}

export const isDonazioniAutorizzateProgetto = (id) => {
    const isCompleta = isRaccoltaCompletata(id)
    const dataFine = getDataScadenzaProgetto(id)
    const isPassata = new Date(dataFine.seconds * 1000) < new Date()
    if (isCompleta || isPassata) return false
    return true
}

export const getListaTuttiProgetti = () => {
    var attivi = []
    var completati = []
    var rifiutati = []
    var inAttesa = []
    var scaduti = []
    window.listaProgetti.forEach((progetto) => {
        if (isProgettoAttivo(progetto.id)) attivi.push(progetto)
        if (isRaccoltaCompletata(progetto.id)) completati.push(progetto)
        if (isProgettoRifiutato(progetto.id)) rifiutati.push(progetto)
        if (isProgettoInAttesa(progetto.id)) inAttesa.push(progetto)
        if (isProgettoScaduto(progetto.id)) scaduti.push(progetto)
    })
    return { attivi, completati, rifiutati, inAttesa, scaduti }
}

export const getStatoProgetto = () => {
    const progetto = getProgettoSelezionato()
    const id = getIdProgettoSelezionato()
    const stato = progetto.stato
    const isValidata = progetto.isValidata
    if (isValidata !== undefined && isValidata) return 'Validato'
    if (isRaccoltaCompletata(id)) return 'Completata'
    if (isProgettoScaduto(id)) return 'Scaduto'
    if (stato === 'accettato') return 'Attivo'
    if (stato === 'rifiutato') return 'Rifiutato'
    if (stato === 'inAttesa') return 'In attesa'
}
