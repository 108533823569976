import MDBox from 'components/MDBox'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { profiloImpresa } from 'util'
import MDTypography from 'components/MDTypography'
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded'
import ThumbDownAltRoundedIcon from '@mui/icons-material/ThumbDownAltRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import MDButton from 'components/MDButton'
import { RIFIUTA_RICHIESTA_PAGAMENTO } from 'data/azioni/RIFIUTA_RICHIESTA_PAGAMENTO'
import { ACCETTA_RICHIESTA_ACQUISTO } from 'data/azioni/ACCETTA_RICHIESTA_ACQUISTO'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { getCreditiImpresa } from 'getter/getterImpresa'

export const elementoMostraRichiestaAcquisto = (props) => {
    const [totaleDonazione, setTotaleDonazione] = useState(0)

    useEffect(() => {
        setTotaleDonazione(Number(((props.richiesta.totale / 100) * props.richiesta.percentuale).toFixed(2)))
    }, [props.richiesta])

    const getStato = () => {
        switch (props.richiesta.stato) {
            case 'inAttesa':
                return 'In attesa'
            case 'rifiutato':
                return 'Rifiutato'
            case 'accettato':
                return 'Confermato'
            default:
                return ''
        }
    }

    const getIcona = () => {
        switch (props.richiesta.stato) {
            case 'inAttesa':
                return <PauseRoundedIcon color='white' sx={{ ml: 1 }} />
            case 'rifiutato':
                return <ThumbDownAltRoundedIcon color='white' sx={{ ml: 1 }} />
            case 'accettato':
                return <ThumbUpAltRoundedIcon color='white' sx={{ ml: 1 }} />
            default:
                return null
        }
    }

    const controlla = () => {
        if (props.crediti < totaleDonazione) errore()
        else conferma()
    }

    const errore = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: `Crediti insufficienti per poter accettare questa donazione. Effettua una ricarica` } })

    const conferma = async () => ACCETTA_RICHIESTA_ACQUISTO(props.richiesta.id)

    const rifiuta = async () => RIFIUTA_RICHIESTA_PAGAMENTO(props.richiesta.id)

    const renderIcona = <MDBox>{getIcona()}</MDBox>

    const renderRiga = (label, value, icona) => {
        return (
            <MDBox mb={1} lineHeight={0} sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <MDTypography variant='caption' color='white' align='center' sx={{ display: 'flex', alignItems: 'center' }}>
                    {label} :{' '}
                    <MDTypography variant='caption' fontWeight='medium' textTransform='capitalize' color='white' align='center' sx={{ display: 'flex', alignItems: 'center' }}>
                        {value}
                        {icona ? renderIcona : null}
                    </MDTypography>
                </MDTypography>
            </MDBox>
        )
    }

    const renderBottoni = () => {
        if (props.richiesta.stato !== 'inAttesa' || !props.isImpresa) return null
        return (
            <MDBox className='contenitore-bottoni-destra'>
                <MDButton variant='outlined' color='white' size='small' onClick={controlla}>
                    conferma
                </MDButton>
                <MDButton variant='outlined' color='white' size='small' onClick={rifiuta}>
                    rifiuta
                </MDButton>
            </MDBox>
        )
    }

    const bgColor = props.richiesta.stato === 'inAttesa' ? 'warning' : props.richiesta.stato === 'rifiutato' ? 'error' : 'success'
    return (
        <MDBox className='flex-verticale' p={2} bgColor={bgColor} shadow='lg' borderRadius='lg' sx={{ height: '100%', width: '100%' }}>
            {renderRiga('Totale', props.richiesta.totale)}
            {renderRiga('Donazione', totaleDonazione)}
            {renderRiga('Percentuale', `${props.richiesta.percentuale}%`)}
            {renderRiga('Progetto', props.richiesta.nomeProgetto)}
            {renderRiga('Impresa', props.richiesta.nomeImpresa)}
            {renderRiga('Data', props.richiesta.data)}
            {renderRiga('Stato', getStato(), true)}
            {renderBottoni()}
        </MDBox>
    )
}

function mapStateToProps(state) {
    const isImpresa = getProfiloUtenteLoggato() === profiloImpresa.tipo
    return {
        isImpresa: isImpresa,
        crediti: !isImpresa ? 0 : getCreditiImpresa(),
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(elementoMostraRichiestaAcquisto)
