/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import typography from 'assets/theme/base/typography'
import borders from 'assets/theme/base/borders'
import colors from 'assets/theme/base/colors'

// Material Dashboard 2 PRO React helper functions
import pxToRem from 'assets/theme/functions/pxToRem'

const { size, fontWeightRegular } = typography
const { borderRadius } = borders
const { dark, white, info } = colors

const tab = {
    styleOverrides: {
        root: {
            display: 'flex',
            alignItems: 'end',
            flexDirection: 'row',
            flex: '1 1 auto',
            textAlign: 'center',
            justifyContent: 'start',
            /* maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size.md,
      fontWeight: fontWeightRegular,
      textTransform: "none",
      lineHeight: "inherit",
      borderRadius: borderRadius.lg,
      padding: pxToRem(4),
      color: `${white.main} !important`,
      opacity: "1 !important", */
            //borderRadius : '28px',

            '&.Mui-selected': {
                color: `${info.main} !important`,
                fontWeight: 'bold',
            },
            '& .material-icons, .material-icons-round': {
                marginBottom: '0 !important',
                marginRight: pxToRem(6),
            },

            '& svg': {
                marginBottom: '0 !important',
                marginRight: pxToRem(6),
            },
        },

        labelIcon: {
            paddingTop: pxToRem(4),
        },
    },
}

export default tab
