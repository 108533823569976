import React, { useState } from 'react'
import './../galleria.css'
import MDBox from 'components/MDBox'

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MDTypography from 'components/MDTypography'

export default function elementoGalleria(props) {
    const [model, setModel] = useState(false)
    const [selezionata, setSelezionata] = useState('')

    const seleziona = (src) => {
        setModel(true)
        setSelezionata(src)
    }

    if (props.immagini.length === 0) {
        return <MDTypography color='text'>{props.testoErrore}</MDTypography>
    }

    return (
        <>
            <div className={model ? 'model open' : 'model'}>
                <MDBox component='img' src={selezionata} alt='img-selezionata'></MDBox>
                <CloseRoundedIcon sx={{ color: 'white' }} onClick={() => setModel(false)} />
            </div>
            <MDBox className='galleria'>
                {props.immagini.map((immagine) => {
                    //console.log('immagine galleria : ', immagine.thumbNail);
                    return (
                        <MDBox  p={1} className='immagine-galleria' key={immagine.alt} sx={{ color:'#d2d6da', width: '100%', border : 'solid 0.2px' }}>
                            <MDBox onClick={() => seleziona(immagine.src)} component='img' src={immagine.thumbNail || immagine.src} alt={immagine.alt} sx={{ width: '100%', height: '100%' }}></MDBox>
                            {immagine.icona ?? null}
                        </MDBox>
                    )
                })}
            </MDBox>
        </>
    )
}

elementoGalleria.defaultProps = {
    immagini : [],
    testoErrore : 'Nessuna immagine salvata'
}
