import React from 'react'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import { Icon } from '@mui/material'
import { formattaDataDaFirebase } from 'data/strumenti'

export default function elementoMostraAggiornamento(props) {
    const testo = props.aggiornamento?.testo || ''
    const strshortened = testo.length <= 140 ? testo : `${testo.slice(0, 200)}...`

    const vediAggiornamento = () => props.navigate(`/paginaVediProgetto/${props.idProgetto}/paginaVediAggiornamento/${props.aggiornamento.id}`)

    return (
        <MDBox p={2} px={5} className='flex-verticale' bgColor='info' variant='gradient' sx={{ justifyContent: 'end', alignItems: 'end', maxWidth: '100%' }}>
            <MDBox className='centrato' sx={{ height: 'max-content' }} mb={1} mt={2}>
                <MDTypography color='white' fontWeight='medium' variant='h2'>
                    {formattaDataDaFirebase(props.aggiornamento?.data || '')}
                </MDTypography>
            </MDBox>
            <MDBox className='flex-verticale' >
                <MDTypography color='white' variant='subtitle2' align='start'>
                    {strshortened}
                </MDTypography>
            </MDBox>
            <MDBox className='centrato' sx={{ justifyContent: 'end', alignItems: 'end', height: 'max-content' }}>
                <MDBox mt={3}>
                    <MDButton variant='text' color={'white'} onClick={vediAggiornamento}>
                        vedi
                        <Icon sx={{ fontWeight: 'bold' }}>arrow_forward</Icon>
                    </MDButton>
                </MDBox>
            </MDBox>
        </MDBox>
    )
}
