import { store } from 'data/store'
import { getIdUtenteLoggato, getUtenteLoggato } from './getterUtenteLoggato'
import { getImpattoProgettoDaId, getProgettoDaId, isRaccoltaCompletata } from './getterProgetti'
import { formattaDataDaFirebase } from 'data/strumenti'
import { getnetworkDaId } from './getterNetwork'

export const getDatiImpresa = (id) => {
    if (id !== undefined && id !== '') return getImpresaDaId(id)
    const state = store.getState()
    const url = state.url
    if (url[3] === 'paginaInserisciSpesa') return state.reducerImpresa
    if (url[3] === 'paginaVediImpresa') return state.reducerImpresa
    return getUtenteLoggato()
}

export const getIdImpresa = () => {
    const state = store.getState()
    if (state.url[3] === 'paginaMioProfilo') return getIdUtenteLoggato()
    return state.url[4]
}

export const isImpresaB2b = (id) => {
    const impresa = getDatiImpresa(id)
    return impresa.tipoBusiness === 'B2B (vendita ad altre imprese)'
}

export const getDistanzaImpresa = (lat1, lon1, lat2, lon2) => {
    function degToRad(degrees) {
        const toReturn = degrees * (Math.PI / 180)
        return toReturn
    }

    const earthRadius = 6371 // in km
    const dLat = degToRad(lat2 - lat1)
    const dLon = degToRad(lon2 - lon1)
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = earthRadius * c
    return distance
}

const ordinaPerDistanza = (lista) => {
    const successCallback = (position) => {
        return new Promise((resolve, reject) => {
            if (lista.length < 2) resolve(lista)
            const ordinata = lista.sort((a, b) => {
                window.permessoPosizione = true
                window.dispatchEvent(new CustomEvent('permessoPosizione'))
                const coordinateA = getImpresaDaId(a.id).location
                const coordinateB = getImpresaDaId(b.id).location
                const distanzaA = getDistanzaImpresa(coordinateA.lat, coordinateA.lng, position.coords.latitude, position.coords.longitude)
                const distanzaB = getDistanzaImpresa(coordinateB.lat, coordinateB.lng, position.coords.latitude, position.coords.longitude)
                return distanzaA - distanzaB
            })
            resolve(ordinata)
        })
    }

    const errorCallback = (error) => {
        return new Promise((resolve, reject) => {
            resolve([])
        })
    }
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            async function (position) {
                const ordinata = await successCallback(position)
                resolve(ordinata)
            },
            function (error) {
                resolve(lista)
            },
        )
    })
    //return lista
}

export const getListaImpreseFiltrata = async (filtri) => {
    const { nome, luogo, ordinaPer, b2b } = filtri

    var listaFiltrata = window.listaImprese.filter((impresa) => {
        const comparazioneNome = () => {
            if (nome === '') return true
            let nomeCacheLowerCase = impresa.nome.toLowerCase()
            let nomeDaCercareLowerCase = nome.toLowerCase()
            return nomeCacheLowerCase.indexOf(nomeDaCercareLowerCase) !== -1
        }
        const comparazioneLuogo = () => {
            if (luogo === '') return true
            const sede = !isImpresaB2b(impresa.id) ? 'puntoVendita' : 'sedeLegale'
            return impresa[sede].comune === luogo
        }

        const comparazioneB2b = () => {
            if (b2b) return isImpresaB2b(impresa.id)
            return !isImpresaB2b(impresa.id)
        }

        return comparazioneNome() && comparazioneLuogo() && comparazioneB2b()
    })

    if (ordinaPer === 3) return await ordinaPerDistanza(listaFiltrata)

    return listaFiltrata.sort((a, b) => {
        const alfabetico = () => {
            var valore = 0
            const nomeA = a.nome.trim().toUpperCase()
            const nomeB = b.nome.trim().toUpperCase()
            if (nomeA.toUpperCase() > nomeB.toUpperCase()) valore = 1
            if (nomeA.toUpperCase() < nomeB.toUpperCase()) valore = -1
            return valore
        }
        const alfabeticoInverso = () => {
            var valore = 0
            const nomeA = a.nome.trim().toUpperCase()
            const nomeB = b.nome.trim().toUpperCase()
            if (nomeA.toUpperCase() > nomeB.toUpperCase()) valore = -1
            if (nomeA.toUpperCase() < nomeB.toUpperCase()) valore = 1
            return valore
        }
        if (ordinaPer === 0) return alfabetico()
        if (ordinaPer === 1) return alfabeticoInverso()
        if (ordinaPer === 2) return getDatiCardImpresa(b.id).percentuale - getDatiCardImpresa(a.id).percentuale
    })

    //metodo ordinamento
}

export const getDatiImpresaDonazione = () => {
    const state = store.getState()
    const url = state.url
    const progetto = getProgettoAttualmenteSostenutoImpresa()
    const impresa = getImpresaDaId(url[4])
    const puntoVendita = impresa.puntoVendita ?? {}
    return {
        nomeImpresa: impresa.nome,
        indirizzoImpresa: `${puntoVendita.via} ${puntoVendita.numero} - ${puntoVendita.comune} (${puntoVendita.provincia})`,
        percentuale: progetto.percentuale,
        nomeProgetto: getProgettoDaId(progetto.id).nome,
        idProgetto: progetto.id,
    }
}

export const getCreditiImpresa = (id) => {
    return getDatiImpresa(id).crediti ?? 0
}

export const getProgettoAttualmenteSostenutoImpresa = (id) => {
    const impresa = getDatiImpresa(id)
    if (impresa.sostegni === undefined || Object.keys(impresa.sostegni).length === 0) return {}
    var progetto = {}
    Object.keys(impresa.sostegni).forEach((chiaveSostegno) => {
        const sostegno = impresa.sostegni[chiaveSostegno]
        if (sostegno.dataFine !== undefined) {
            const isPassata = new Date(sostegno.dataFine.seconds * 1000) < new Date()
            const isCompleta = isRaccoltaCompletata(chiaveSostegno)
            if (!isPassata && sostegno.attivo && !isCompleta) progetto = { id: chiaveSostegno, percentuale: sostegno.percentuale }
        }
    })
    return progetto
}

export const getImpresaDaId = (id) => {
    const index = window.listaImprese.findIndex((impresa) => impresa.id === id)
    if (index === -1) return {}
    return window.listaImprese[index]
}

export const getDonazioniLiberaliProgettoInImpresa = (idImpresa, idProgetto) => {
    const dati = getDatiImpresa(idImpresa)
    if (dati.donazioniLiberali === undefined) return 0
    if (dati.donazioniLiberali[idProgetto] === undefined) return 0
    return dati.donazioniLiberali[idProgetto]
}

export const getTotaleDonazioniLiberaliImpresa = (id) => {
    const dati = getDatiImpresa(id)
    if (dati.donazioniLiberali === undefined) return 0
    return Object.keys(dati.donazioniLiberali).reduce((acc, chiave) => {
        acc += getDonazioniLiberaliProgettoInImpresa(id, chiave)
        return acc
    }, 0)
}

export const getDonazioniPercentualiProgettoInImpresa = (id, idProgetto) => {
    const dati = getDatiImpresa(id)
    if (dati.sostegni === undefined) return 0
    if (dati.sostegni[idProgetto] === undefined) return 0

    const sostegno = dati.sostegni[idProgetto]
    if (sostegno.totale !== undefined) return sostegno.totale
    if (sostegno.consumatori === undefined) return 0
    const totale = Object.keys(sostegno.consumatori).reduce((acc, idConsumatore) => {
        //console.log('guarda : ', Number(sostegno.consumatori[idConsumatore]))
        //console.log('acc : ', acc)
        //console.log('somma : ', (acc + Number(sostegno.consumatori[idConsumatore])))
        acc += Number(sostegno.consumatori[idConsumatore])
        return acc
    }, 0)
    
    return totale
}

export const getTotaleSostegniImpresa = (id) => {
    const dati = getDatiImpresa(id)
    if (dati.sostegni === undefined) return 0
    return Object.keys(dati.sostegni).reduce((acc, idProgetto) => {
        const totale = getDonazioniPercentualiProgettoInImpresa(id, idProgetto)
        acc = Number((acc + totale).toFixed(2))
        return acc
    }, 0)
}

export const getTotaleDonatoImpresa = (id) => {
    const totaleDonazioniLiberali = getTotaleDonazioniLiberaliImpresa(id)
    const totaleSostegni = getTotaleSostegniImpresa(id)
    return totaleDonazioniLiberali + totaleSostegni
}

export const getInformazioniProgettoAttualmenteSostenuto = (id) => {
    const progettoSostenuto = getProgettoAttualmenteSostenutoImpresa(id)
    const donazioniLiberali = getDonazioniLiberaliProgettoInImpresa(id, progettoSostenuto.id)
    const donazioniPercentuali = getDonazioniPercentualiProgettoInImpresa(id, progettoSostenuto.id)
    const info = {
        idProgetto: progettoSostenuto.id,
        percentuale: progettoSostenuto.percentuale,
        donazioniLiberali: donazioniLiberali,
        donazioniPercentuali: donazioniPercentuali,
        totale: donazioniLiberali + donazioniPercentuali,
    }
    return info
}

export const getListaProgettiSostenutiNoAttuale = (id) => {
    var progetti = []
    var idProgetti = []
    const dati = getDatiImpresa(id)
    const idProgettoSostenuto = getProgettoAttualmenteSostenutoImpresa(id).id
    if (dati.sostegni !== undefined) {
        Object.keys(dati.sostegni).forEach((idProgetto) => {
            const donazioniLiberali = getDonazioniLiberaliProgettoInImpresa(id, idProgetto)
            const donazioniPercentuali = getDonazioniPercentualiProgettoInImpresa(id, idProgetto)
            if (donazioniLiberali !== 0 && donazioniPercentuali !== 0 && idProgetto !== idProgettoSostenuto) {
                const datiProgetto = {
                    idProgetto: idProgetto,
                    percentuale: dati.sostegni[idProgetto].percentuale,
                    donazioniLiberali: donazioniLiberali,
                    donazioniPercentuali: donazioniPercentuali,
                    totale: Number(donazioniLiberali) + Number(donazioniPercentuali),
                }
                idProgetti.push(idProgetto)
                progetti.push(datiProgetto)
            }
        })
    }
    if (dati.donazioniLiberali !== undefined) {
        Object.keys(dati.donazioniLiberali).forEach((idProgetto) => {
            if (idProgetto !== idProgettoSostenuto && !idProgetti.includes(idProgetto)) {
                const donazioniLiberali = getDonazioniLiberaliProgettoInImpresa(id, idProgetto)
                const datiProgetto = {
                    idProgetto: idProgetto,
                    percentuale: 0,
                    donazioniLiberali: donazioniLiberali,
                    donazioniPercentuali: 0,
                    totale: donazioniLiberali,
                }
                idProgetti.push(idProgetto)
                progetti.push(datiProgetto)
            }
        })
    }
    return progetti
}

export const getListaTuttiProgettiSupportatiImpresa = (id) => {
    const attuale = getInformazioniProgettoAttualmenteSostenuto(id)
    var lista = [...getListaProgettiSostenutiNoAttuale(id)]
    if (attuale.idProgetto !== undefined) lista.unshift(Object.assign({}, attuale))
    return lista
}

export const getTransazioniImpresa = () => {
    const state = store.getState()
    const transazioni = state.reducerTransazioni || []
    return transazioni.map((transazione) => {
        const isRicarica = transazione.tipo === 'ricarica'
        const segno = isRicarica ? '+' : '-'
        return {
            color: isRicarica ? 'success' : 'error',
            icon: isRicarica ? 'expand_less' : 'expand_more',
            name: isRicarica ? 'Ricarica credito' : `Donazione ${getProgettoDaId(transazione.idProgetto).nome}`,
            description: formattaDataDaFirebase(transazione.data, true),
            value: `${segno} € ${transazione.totale}`,
            id: transazione.id,
        }
    })
}

export const getUltimeCinqueTransazioniImpresa = () => {
    const transazioni = getTransazioniImpresa()
    return transazioni.filter((transazione, index) => {
        return index < 5
    })
}

export const getImmaginiImpresa = (id) => {
    const impresa = getDatiImpresa(id)
    const logo = impresa.logo ?? ''
    if (logo === '' && impresa.immagini === undefined) return
    if (logo !== '' && impresa.immagini === undefined) return [{ isLogo: true, id: logo }]
    var immagini = Object.keys(impresa.immagini).map((id) => {
        return { isLogo: false, id: id }
    })
    if (logo !== '') immagini.unshift({ isLogo: true, id: logo })
    return immagini
}

export const getListaImpattiImpresa = () => {
    const sostenuti = getListaTuttiProgettiSupportatiImpresa()
    var impatti = []
    sostenuti.forEach((progetto) => {
        var impattiProgetto = [...getImpattoProgettoDaId(progetto.idProgetto)]
        impattiProgetto.forEach((impatto) => {
            impatto.valore = Number(((impatto.valore * progetto.totale) / impatto.budget).toFixed(2))
        })
        impatti = impatti.concat(impattiProgetto)
    })
    return impatti
}

export const getIdNetworkImpresa = (id) => {
    const impresa = getDatiImpresa(id)
    return impresa.network || ''
}

export const getListaInvitiNetwork = (id) => {
    const impresa = getDatiImpresa(id)
    if (impresa.invitiNetwork === undefined) return []
    return impresa.invitiNetwork.map((idNetwork) => {
        return { id: idNetwork, nome: getnetworkDaId(idNetwork).nome }
    })
}

export const canInvitaImpresaInNetwork = () => {
    const impresa = getDatiImpresa()
    if (impresa.network !== undefined && impresa.network !== '') return false
    const utente = getUtenteLoggato()
    if (utente.network === undefined && utente.network === '') return false
    const network = getnetworkDaId(utente.network)
    if (network.inviti !== undefined && network.inviti[getIdImpresa()] === 'inAttesa') return false
    return network.capoFila === getIdUtenteLoggato()
}

export const isInvitoinCorso = () => {
    const idImpresa = getIdImpresa()
    const utente = getUtenteLoggato()
    if (utente.network === undefined && utente.network === '') return false
    const network = getnetworkDaId(utente.network)
    if (network.inviti === undefined) return false
    if (network.inviti[idImpresa] === undefined) return false
    return network.inviti[idImpresa] === 'inAttesa'
}

export const getDatiCardImpresa = (id) => {
    const impresa = getImpresaDaId(id)
    const sostenuto = getProgettoAttualmenteSostenutoImpresa(id)
    const sostegno = getTotaleDonatoImpresa(id)
    return {
        network: impresa.network === '' ? '' : getnetworkDaId(impresa.network).nome,
        percentuale: Object.keys(sostenuto).length === 0 ? 0 : sostenuto.percentuale,
        distanza: 2,
        sostegno: sostegno,
        nomeProgetto: Object.keys(sostenuto).length === 0 ? '' : getProgettoDaId(sostenuto.id).nome,
        isB2b: isImpresaB2b(id),
    }
}

export const getListaMailImprese = () => {
    return window.listaImprese.map((impresa) => {
        const sede = isImpresaB2b(impresa.id) ? 'sedeLegale' : 'puntoVendita'
        return { nome: impresa.nome, mail: impresa[sede].email, id: impresa.id }
    })
}
