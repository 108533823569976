import React, { useEffect, useState } from 'react'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import MDBox from 'components/MDBox'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedIn from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Icon } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CallIcon from '@mui/icons-material/Call'
import useForm from 'customHooks/useForm'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import { formContattiEtiqua } from 'formModel/formContattiEtiqua'
import MDButton from 'components/MDButton'
import ElementoFeedbackUtente from 'componenti/elementoFeedbackUtente'
import { CREA_RICHIESTA_CONTATTO } from 'data/azioni/CREA_RICHIESTA_CONTATTO'
import { connect } from 'react-redux'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getUtenteLoggato } from 'getter/getterUtenteLoggato'

const socials = [
    /*  {
        icon: <FacebookIcon />,
        link: 'https://www.facebook.com/physioarchive',
    },
    {
        icon: <InstagramIcon />,
        link: 'https://www.instagram.com/physioarchive/',
    }, */
    {
        icon: <LinkedIn />,
        link: 'https://it.linkedin.com/company/etiqua',
    },
    /*  {
        icon: <YouTubeIcon />,
        link: 'https://www.youtube.com/channel/UCJ8gdi4kyPl2qgvMyz6ltbw',
    }, */
]

export function paginaContatti(props) {
    const [dati, setDati] = useState({ nome: '' })
    const [stato, azioni, renderForm] = useForm(formContattiEtiqua(), dati, false, true)
    const [vediFeedBack, setVediFeedback] = useState(false)

    useEffect(() => {
        if (getIdUtenteLoggato() === '') return
        setDati({ nome: getUtenteLoggato().nome })
    }, [])

    const annulla = () => window.history.back()
    const handleVediFeedback = () => setVediFeedback(!vediFeedBack)

    const invia = async () => {
        try {
            await azioni.validaForm()
            await CREA_RICHIESTA_CONTATTO(stato.statoForm)
            azioni.reset()
            setVediFeedback(true)
        } catch (error) {
            return
        }
    }

    const renderSocials = (
        <MDBox className='flex-verticale'>
            {/*  <MDTypography variant='h6'>Etiqua</MDTypography> */}
            <MDBox display='flex' alignItems='center' mt={3}>
                {socials.map(({ icon, link }, key) => (
                    <MDTypography key={link} component='a' href={link} target='_blank' rel='noreferrer' variant='h5' color='dark' opacity={0.8} mr={key === socials.length - 1 ? 0 : 2.5}>
                        {icon}
                    </MDTypography>
                ))}
            </MDBox>
        </MDBox>
    )

    const renderListaContatti = (
        <MDBox className='flex-verticale'>
            <ElementoEtichettaForm testo='Contatti' />
            <MDBox component='ul' p={0} m={0} sx={{ listStyle: 'none' }} pt={2} pb={2}>
                <MDBox key={name} component='li' p={0} m={0} lineHeight={1.25}>
                    <MDBox display='flex' alignItems='center' color='dark' width='max-content' mb={1}>
                        <Icon sx={{ mr: 1 }} color='dark'>
                            email
                        </Icon>
                        <MDTypography variant='subtitle2' fontWeight='regular'>
                            info@etiqua.net
                        </MDTypography>
                    </MDBox>
                    <MDBox display='flex' alignItems='center' color='dark' width='max-content' mb={1}>
                        <CallIcon sx={{ mr: 1 }} />
                        <MDTypography variant='subtitle2' fontWeight='regular'>
                            +39 011 1883 8261
                        </MDTypography>
                    </MDBox>
                    <MDBox display='flex' alignItems='center' color='dark' width='max-content' maxWidth='100%' sx={{ boxSizing: 'border-box' }} mb={1}>
                        <LocationOnIcon sx={{ mr: 1 }} />
                        <MDTypography variant='subtitle2' fontWeight='regular' sx={{ whiteSpace: 'pre-line' }}>
                            {`Corso Castelfidardo, 30/a, 10129 Torino TO (Presso I3P)`}
                        </MDTypography>
                    </MDBox>
                    <MDBox display='flex' alignItems='center' color='dark' width='max-content' mb={1}>
                        <MDTypography variant='subtitle2' fontWeight='regular' textTransform='none' /* 'capitalize' */>
                            P.IVA : IT12142180012
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </MDBox>
    )

    const renderContatti = (
        <MDBox className='flex-verticale' pl={3}>
            {renderListaContatti}
            {renderSocials}
        </MDBox>
    )

    const renderFormContatti = (
        <MDBox className='flex-verticale'>
            <ElementoEtichettaForm testo='Contattaci' />
            <MDBox pt={2} pb={2}>
                {renderForm}
            </MDBox>
            <MDBox className='contenitore-bottoni-destra'>
                <MDButton variant='contained' color='error' onClick={annulla}>
                    annulla
                </MDButton>
                <MDButton variant='contained' color='success' onClick={invia}>
                    invia
                </MDButton>
            </MDBox>
        </MDBox>
    )

    const renderPagina = (
        <MDBox className='flex-verticale' bgColor='white' p={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    {renderContatti}
                </Grid>
                <Grid item xs={12} lg={6}>
                    {vediFeedBack ? <ElementoFeedbackUtente mt={0} testo='Grazie di averci contattato. Il team di etiqua ti risponderà appena possibile' testoBottone={'ok'} azione={handleVediFeedback} /> : renderFormContatti}
                </Grid>
            </Grid>
        </MDBox>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

function mapStateToProps(state) {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaContatti)
