import { store } from '../store'

export const LEGGI_RICHIESTE_CONTATTO = (dati) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'LEGGI_RICHIESTE_CONTATTO',
            payload: dati,
        })
    })
}
