import React from 'react'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Divider } from '@mui/material'

export default function elementoStatistica(props) {

    const check = () => {
        if(!props.azioneBox) return
        props.azione()
    }

    const renderParteSotto = () => {
        if (props.azione === undefined) return null
        return (
            <>
               {/*  <Divider light /> */}
                <MDBox pb={2} px={2} sx={{cursor : 'pointer'}}>
                    <MDTypography component='p' variant='button' color='text' display='flex' onClick={props.azione}>
                        <MDTypography component='span' variant='button' fontWeight='bold' color='info'>
                            {props.testoAzione}
                        </MDTypography>
                    </MDTypography>
                </MDBox>
            </>
        )
    }

    return (
        <Card sx={{height : '100%', display:'grid', gridTemplateColumns : '100%', gridTemplateRows : '1fr max-content max-content', cursor : props.azioneBox ? 'pointer' : ''}} onClick={check}>
            <MDBox display='flex' justifyContent='space-between' pt={1} px={2}>
                <MDBox variant='gradient' bgColor='info' color={'white'} coloredShadow='info' borderRadius='xl' display='flex' justifyContent='center' alignItems='center' width='4rem' height='4rem' mt={-3}>
                    {props.icona}
                </MDBox>
                <MDBox textAlign='right' lineHeight={1.25}>
                    <MDTypography variant='button' fontWeight='light' color='text'>
                        {props.label}
                    </MDTypography>
                    <MDTypography variant='h2'>{props.valore}</MDTypography>
                </MDBox>
            </MDBox>
            {renderParteSotto()}
        </Card>
    )
}

elementoStatistica.defaultProps = {
    azione: undefined,
    testoAzione: 'testoAzione',
    icona: null,
    label: 'label',
    valore: 'valore',
    azioneBox : false
}
