import { ref, uploadBytes } from 'firebase/storage'
import { doc, updateDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { newId } from 'data/strumenti'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'

export const NUOVO_LOGO_PROGETTO = async (thumbnail, immagine) => {
    MODIFICA_STATO_SPINNER(true)
    const idProgetto = getIdProgettoSelezionato()

    const caricaInStorage = async (file, path) => {
        return await uploadBytes(ref(storage, path), file)
    }

    const aggiornaDb = async () => {
        return await updateDoc(doc(db, 'progetti', idProgetto), { logo: newId() })
    }

    const pathThumbNail = `${idProgetto}/logo/thumbNail.png`
    const pathOriginale = `${idProgetto}/logo/originale.png`

    await Promise.all([caricaInStorage(thumbnail, pathThumbNail), caricaInStorage(immagine, pathOriginale)])
    await aggiornaDb()
    MODIFICA_STATO_SPINNER(false)
    return
}
