import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import { LOG_OUT } from 'data/azioni/LOG_OUT' // Material Dashboard 2 PRO React components
import MDAvatar from 'components/MDAvatar'
import profilePicture from 'assets/images/team-3.jpg'
import { profiloSuperAmministratore, profiloConsumatore, profiloImpresa, profiloEnte } from 'util'
import { getIdUtenteLoggato, getProfiloUtenteLoggato } from './getterUtenteLoggato'
import ElementoLogoProfilo from 'componenti/elementoLogoProfilo'
import { isImpresaB2b } from './getterImpresa'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { AGGIUNGI_A_SCHERMATA_HOME } from 'data/azioni/AGGIUNGI_A_SCHERMATA_HOME'

const elimina = ( ) => APRI_POP_UP({id : 'eliminaAccount', parametro : {}})

const link = [
    {
        name: 'Bacheca',
        key: 'paginaMioProfilo',
        route: '/paginaMioProfilo',
    },
    /* {
        name: 'Contattaci',
        key: 'paginaFeedBack',
        route: '/paginaFeedBack',
    }, */
    {
        name: 'Contattaci',
        key: 'paginaContatti',
        route: '/paginaContatti',
    },
    /* {
        name: 'Scarica app',
        key: 'scarica',
        route: '',
        azione: () => AGGIUNGI_A_SCHERMATA_HOME(),
    }, */
    {
        name: 'Esci',
        key: 'logout',
        route: '',
        azione: () => LOG_OUT(),
    },
    {
        name: 'Elimina account',
        key: 'elimina',
        route: '',
        azione: () => elimina(),
    },
]

const rottePagineDefault = [
    {
        type: 'collapse',
        name: 'Progetti',
        key: 'paginaProgetti',
        route: '/paginaProgetti',
        noCollapse: true,
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Imprese',
        key: 'paginaImprese',
        route: '/paginaImprese',
        noCollapse: true,
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Enti non profit',
        key: 'paginaEnti',
        route: '/paginaEnti',
        noCollapse: true,
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Network',
        key: 'paginaNetwork',
        route: '/paginaNetwork',
        noCollapse: true,
        nonAprire: false,
        nonAprire: true,
    },
]

const rotteProfiloEtiqua = [
    {
        type: 'collapse',
        name: 'Tutti i progetti',
        key: 'paginaListaProgettiEtiqua',
        route: '/paginaListaProgettiEtiqua',
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Richieste contatto',
        key: 'paginaRichiesteContatto',
        route: '/paginaRichiesteContatto',
        noCollapse: true,
    },
    /* {
        type: 'collapse',
        name: 'Progetti completati',
        key: 'paginaListaProgettiCompletatiEtiqua',
        route: '/paginaListaProgettiCompletatiEtiqua',
        noCollapse: true,
    }, */
    {
        type: 'collapse',
        name: 'Email',
        key: 'paginaListaEmailetiqua',
        route: '/paginaListaEmailetiqua',
        noCollapse: true,
    },
]
const rotteProfiloConsumatore = [
    {
        type: 'collapse',
        name: 'Richieste acquisto',
        key: 'paginaRichiesteAcquisto',
        route: '/paginaRichiesteAcquisto',
        noCollapse: true,
    },
]

const rotteProfiloImpresa = () => {
    var rotte = [
        {
            type: 'collapse',
            name: 'Portafoglio',
            key: 'paginaPortafoglioImpresa',
            route: '/paginaPortafoglioImpresa',
            noCollapse: true,
        },

        {
            type: 'collapse',
            name: 'Il mio network',
            key: 'paginaIlMioNetwork',
            route: '/paginaIlMioNetwork',
            noCollapse: true,
        },
    ]
    if (isImpresaB2b(getIdUtenteLoggato())) return rotte
    var b2c = [...rotte]
    b2c.push({
        type: 'collapse',
        name: 'Richieste acquisto',
        key: 'paginaRichiesteAcquisto',
        route: '/paginaRichiesteAcquisto',
        noCollapse: true,
    })
    return b2c
}
const rotteProfiloEnte = [
    {
        type: 'collapse',
        name: 'Crea progetto',
        key: 'paginaCreaProgetto',
        route: '/paginaCreaProgetto',
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Miei progetti',
        key: 'paginaMieiProgetti',
        route: '/paginaMieiProgetti',
        noCollapse: true,
    },
]

const getRotteProfilo = () => {
    var rotte = []
    switch (getProfiloUtenteLoggato()) {
        case profiloSuperAmministratore.tipo:
            rotte = rotteProfiloEtiqua
            break
        case profiloConsumatore.tipo:
            rotte = rotteProfiloConsumatore
            break
        case profiloImpresa.tipo:
            rotte = rotteProfiloImpresa()
            break
        case profiloEnte.tipo:
            rotte = rotteProfiloEnte
            break

        default:
            rotte = []
            break
    }
    if (rotte.findIndex((rotta) => rotta.key === 'divider-1') === -1) rotte.push({ type: 'divider', key: 'divider-1' })
    return rotte
}

export const getRotte = () => {
    const rotteProfilo = [
        {
            type: 'collapse',
            name: 'Mio profilo',
            key: 'paginaMioProfilo',
            icon: <ElementoLogoProfilo />,
            collapse: link,
        },
        { type: 'divider', key: 'divider-0' },
    ]
    const rotteProfiloEtiqua = [
        {
            type: 'collapse',
            name: 'Esci',
            key: 'logOut',
            route: 'sss',
            azione: () => LOG_OUT(),
            noCollapse: true,
        },
        { type: 'divider', key: 'divider-0' },
    ]
    const rotteUtente = getRotteProfilo()
    const rotteProfiloDaVedere = getProfiloUtenteLoggato() === profiloSuperAmministratore.tipo ? rotteProfiloEtiqua : rotteProfilo
    return rotteProfiloDaVedere.concat(rotteUtente).concat(rottePagineDefault)
}

export const rottePreLogin = [
    {
        type: 'collapse',
        name: 'Progetti',
        key: 'paginaProgetti',
        route: '/paginaProgetti',
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Imprese',
        key: 'paginaImprese',
        route: '/paginaImprese',
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Enti non profit',
        key: 'paginaEnti',
        route: '/paginaEnti',
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Network',
        key: 'paginaNetwork',
        route: '/paginaNetwork',
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Contatti',
        key: 'paginaContatti',
        route: '/paginaContatti',
        nonAprire: false,
        nonAprire: true,
    },
    { type: 'divider', key: 'divider-0' },
    {
        type: 'collapse',
        name: 'Accedi',
        key: 'accedi',
        route: '/logIn',
        nonAprire: false,
        nonAprire: true,
    },
    {
        type: 'collapse',
        name: 'Registrati',
        key: 'registrati',
        route: '/registrati',
        nonAprire: true,
        nonAprire: true,
    },
]
