import React from 'react'
import { Grid } from '@mui/material'
import ElementoVediImpatto from './elementoVediImpatto'
import colors from 'assets/theme/base/colors'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

export default function elementoVediListaImpatti(props) {
    if (props.lista.length === 0) return null

    return (
        <>
            <MDBox p={1} color='dark' sx={{ border: 'solid 0.5px'}} mb={2} width='100%' maxWidth='900px'> 
            <MDTypography className="centrato">
                            <a href="https://www.etiqua.net/impatto" target="_blank" rel="noopener noreferrer">Cos'è l'impatto sociale? </a>                                           
                        </MDTypography>
                        <br />
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} lg={4}>
                        <MDBox className='centrato' sx={{ justifyContent: {xs : 'center', sm : 'start'} }}>
                            <MDTypography color='dark' align='left'>
                                LEGENDA:               
                            </MDTypography>
                        </MDBox>

                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>

                        <MDBox className='centrato flex-orizzontale' sx={{ justifyContent: {xs : 'start', sm : 'center'} }}>

                            <MDBox className='centrato' p={1} sx={{ boxSizing : 'border-box', color: '#E5243B', border: 'solid', width: '40px', height: '40px' }}>
                                1.2
                            </MDBox>
                            <MDTypography variant='body2' color='dark' sx={{ ml: 1 }}>previsioni</MDTypography>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={4} lg={4}>
                        <MDBox className='centrato flex-orizzontale' sx={{ justifyContent: 'start' }}>
                            <MDBox className='centrato' p={1} sx={{ boxSizing : 'border-box', backgroundColor: '#E5243B',  color: 'white !important', width: '40px', height: '40px' }}>
                                1.2
                            </MDBox>
                            <MDBox sx={{ width: 'max-content' }}>
                                <MDTypography variant='body2' color='dark' align='start'  sx={{ ml: 1 }}>misurazioni a progetto attuato</MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
            <Grid container spacing={0.3} justifyContent={'center'}>
                {props.lista.map((impatto, index) => {
                    return (
                        <Grid item xs={12} md={4} key={`impatto-${index}`}>
                            <ElementoVediImpatto dati={impatto} />
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}
