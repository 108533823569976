import { store } from './../store'
import { baseWatcher } from './baseWatcher'
import { collection, query, orderBy, limit, onSnapshot, where } from 'firebase/firestore'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { profiloImpresa } from 'util'
import { LEGGI_RICHIESTE_ACQUISTO } from 'data/azioni/LEGGI_RICHIESTE_ACQUISTO'

export class watcherRichiesteAcquisto extends baseWatcher {
    constructor() {
        super()
        this.idDaGuardare = ''
        this.apriConnessione()
    }

    async apriConnessione() {
        const id = getIdUtenteLoggato()
        const attributo = getProfiloUtenteLoggato() === profiloImpresa.tipo ? 'idImpresa' : 'idConsumatore'
        //console.log('attributo : ', attributo);
        //console.log('idddddddd : ', id);
        /* var data = new Date()
        data.setDate(data.getDate() - 2) */
        var q = query(collection(db, 'richiesteAcquisti'), where(attributo, '==', id) /*, orderBy('attr', 'desc') , limit(5) */)
        if (getProfiloUtenteLoggato() === profiloImpresa.tipo) q = query(collection(db, 'richiesteAcquisti'), where(attributo, '==', id), where('stato', '==', 'inAttesa'))

        this.funzioneConnessione = onSnapshot(q, (querySnapshot) => {
            let lista = []
            querySnapshot.forEach((doc) => {
                const elemento = Object.assign({}, doc.data(), { id: doc.id })
                lista.push(elemento)
            })
            LEGGI_RICHIESTE_ACQUISTO(lista)
        })
    }

    chiudiConnessione() {
        if (this.funzioneConnessione !== undefined) {
            this.funzioneConnessione()
        }
    }
}
