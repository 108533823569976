import React from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import ElementoMostraRichiestaAcquisto from 'componenti/elementoMostraRichiestaAcquisto'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'
import { getListaRichiesteAcquisto } from 'getter/getterRichiesteAcquisto'

export const paginaRichiesteAcquisto = (props) => {

    const renderLista = (
        <Grid container spacing={2}>
            {props.lista.map((richiesta) => {
                return (
                    <Grid item xs={12} md={4} key={richiesta.id}>
                        <ElementoMostraRichiestaAcquisto richiesta={richiesta} />
                    </Grid>
                )
            })}
        </Grid>
    )

    const renderListaVuota = (
        <MDBox m='auto' px={5} py={2} className='centrato' bgColor='white' shadow='lg'>
            <MDTypography width='100%' color='text' fontWeight='medium' align='center'>
                Nessuna richiesta in attesa
            </MDTypography>
        </MDBox>
    )

    return props.lista.length === 0 ? renderListaVuota : renderLista
}

const mapStateToProps = (state) => ({
    lista: getListaRichiesteAcquisto(),
    /*  lista: [
        {
            data: '20/04/2023 15:37',
            nomeImpresa: 'Nome impresa',
            percentuale: '15',
            stato: 'inAttesa',
            id: 'aaa',
            nomeProgetto : 'Nome progetto',
            totale : 100
        },
        {
            data: '20/04/2023 15:37',
            nomeImpresa: 'Nome impresa',
            percentuale: '15',
            stato: 'rifiutato',
            id: 'aaaa',
            nomeProgetto : 'Nome progetto',
            totale : 100
        },
        {
            data: '20/04/2023 15:37',
            nomeImpresa: 'Nome impresa',
            percentuale: '15',
            stato: 'accettato',
            id: 'aa',
            nomeProgetto : 'Nome progetto',
            totale : 100
        },
    ],  */
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaRichiesteAcquisto)
