import { getImmaginiEnte, getIdEnte, getListaImpattiEnte, getDatiEnte, getInfoEnte } from 'getter/getterEnte'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import useCache from 'customHooks/useCache'
import useForm from 'customHooks/useForm'
import ElementoRenderPagina from 'componenti/elementoRenderPagina'
import MDBox from 'components/MDBox'
import { Grid } from '@mui/material'
import { getUrlDaStorage } from 'getter/getterimmagini'
import ElementoEtichettaForm from 'componenti/elementoEtichettaForm'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import MDTypography from 'components/MDTypography'
import { Divider, Icon, List } from '@mui/material'
import ElementoImmagineProfilo from 'componenti/elementoImmagineProfilo'
import ElementoCarosello from 'componenti/elementoCarosello'
import ElementoMostraProgetto from 'componenti/elementoMostraProgetto'
import { getProgettoDaId } from 'getter/getterProgetti'
import ElementoVediListaImpatti from 'componenti/elementoVediListaImpatti'
import MDButton from 'components/MDButton'
import ElementoMostraImpresaMappa from 'componenti/elementoMostraImpresaMappa'
import { getImpresaDaId } from 'getter/getterImpresa'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import ElementoBottoniForm from 'componenti/elementoBottoniForm'
import { formRiassuntoEnte } from 'formModel/formEnteNonProfit'
import { MODIFICA_DATI_UTENTE_LOGGATO } from 'data/azioni/MODIFICA_DATI_UTENTE_LOGGATO'
import ElementoContatti from 'componenti/elementoContatti'
import { formContattiEnte, formLinkContattiEnte } from 'formModel/formEnteNonProfit'

export const paginaVediEnte = (props) => {
    const [statoDati, azioniDati, renderFormDati] = useForm(formRiassuntoEnte, props.dati, false, false)
    const [listaProgettiAttivi, setListaProgettiAttivi] = useState([])
    const [listaProgettiCompletati, setListaProgettiCompletati] = useState([])
    const [listaAziendeSostenitrici, setListaAziendeSostenitrici] = useState([])
    const [totaleRaccolto, setTotaleRaccolto] = useState([])
    const { listaProgettiWindow, listaImpreseWindow } = useCache()
    const [listaImmagini, setListaImmagini] = useState([])
    const [formContatti, setFormContatti] = useState({parts : []})
    const [logo, setLogo] = useState('')
    
    useEffect(()=>{setFormContatti(formContattiEnte(true))},[])

    const aggiorna = async () => {
        try {
            await azioniDati.validaForm()
            await MODIFICA_DATI_UTENTE_LOGGATO(statoDati.statoForm)
            azioniDati.vediOutput()
        } catch (error) {
            return
        }
    }

    const aggiornaContatti = async (stato) => {
        await MODIFICA_DATI_UTENTE_LOGGATO(stato)
        return
    }

    const modificaImmagini = () => props.navigate('paginaModificaImmagini')
    const modificaCredenziali = (tipo) => APRI_POP_UP({ id: 'credenziali', parametro: tipo })
    const vediImpresa = (impresa) => props.navigate(`paginaVediImpresa/${impresa.id}`)

    useEffect(() => {
        setTimeout(() => {
            const dati = getInfoEnte()
            setListaProgettiAttivi(dati.progettiAttivi)
            setListaProgettiCompletati(dati.progettiCompletati)
            setListaAziendeSostenitrici(dati.impreseSostenitrici)
            setTotaleRaccolto(dati.totaleRaccolto)
        }, 1000)
    }, [listaProgettiWindow, props.dati, listaImpreseWindow])

    const getUrlImmagine = async (immagine) => {
        const pathThumbNail = !immagine.isLogo ? `${props.id}/immagini/${immagine.id}/thumbNail.png` : `${props.id}/logo/thumbNail.png`
        const pathOriginale = !immagine.isLogo ? `${props.id}/immagini/${immagine.id}/originale.png` : `${props.id}/logo/originale.png`

        const url = await Promise.all([getUrlDaStorage(pathThumbNail), getUrlDaStorage(pathOriginale)])
        return { thumbNail: url[0], originale: url[1], id: immagine.id, isLogo: immagine.isLogo }
    }
  
    useEffect(async () => {
        if (props.listaImmagini.length === 0) return

        const promesse = props.listaImmagini.map(async (immagine) => {
            return await getUrlImmagine(immagine)
        })

        const risultati = await Promise.all(promesse)
        var lista = []
        risultati.forEach((result) => {
            const immagine = {
                originale: result.originale,
                thumbnail: result.thumbNail,
                id: result.id,
            }
            if (result.isLogo) setLogo(immagine)
            if (!result.isLogo) lista.push(immagine)
            return
        })

        setListaImmagini(lista)
    }, [props.listaImmagini])

    const renderIndirizzo = () => {
        //const sede = props.isB2b ? 'sedeLegale' : 'puntoVendita'
        const sede = 'sedeLegale'
        const indirizzo = `${props.dati[sede]?.via || ''} ${props.dati[sede]?.numero || ''} ${props.dati[sede]?.comune || ''} ${props.dati[sede]?.provincia || ''}`
        return (
            <MDBox className='flex-verticale'>
                <MDTypography variant='body2'>{indirizzo}</MDTypography>
            </MDBox>
        )
    }

    const renderBottoneImmagini = () => {
        if (!props.isMioProfilo) return null
        return (
            <Grid container spacing={0.05} direction='column'>
            <Grid item>
                <MDButton fullWidth sx={{ mt: 1 }} variant='outlined' color='dark' onClick={modificaImmagini}>
                    <Icon sx={{ mr: 1 }}>edit</Icon>
                    immagini
                </MDButton>
            </Grid>
            <Grid item>
                <MDButton fullWidth sx={{ mt: 1 }} variant='outlined' color='dark' onClick={()=>modificaCredenziali("mail")}>
                    <Icon sx={{ mr: 1 }}>edit</Icon>
                    mail
                </MDButton>
            </Grid>
            <Grid item>
                <MDButton fullWidth sx={{ mt: 1 }} variant='outlined' color='dark' onClick={()=>modificaCredenziali("password")}>
                    <Icon sx={{ mr: 1 }}>edit</Icon>
                    password
                </MDButton>
            </Grid>
        </Grid>
        )
    }

    const immagini = (
        <MDBox mt={3}>
            <ElementoImmagineProfilo immagini={listaImmagini} />
        </MDBox>
    )

    const renderInfoAttivita = (label, valore) => {
        return (
            <MDBox className='flex-orizzontale' mt={2}>
                <MDTypography variant='body1' color='dark' sx={{ mr: 0.5 }}>
                    {label}
                </MDTypography>
                <MDTypography variant='body1' color='dark'>
                    {valore}
                </MDTypography>
            </MDBox>
        )
    }

    const infoProgettiImprese = (
        <Grid container>
            <Grid items xs={12} md={6}>
                {renderInfoAttivita('Raccolte fondi attive:', listaProgettiAttivi.length)}
            </Grid>
            <Grid items xs={12} md={6}>
                {renderInfoAttivita('Raccolte fondi completati:', listaProgettiCompletati.length)}
            </Grid>
            <Grid items xs={12} md={6}>
                {renderInfoAttivita('n° Imprese Sostenitrici:', listaAziendeSostenitrici.length)}
            </Grid>
            <Grid items xs={12} md={6}>
                {renderInfoAttivita('Fondi raccolti:', totaleRaccolto)}
            </Grid>
        </Grid>
    )

    const renderImmagineNome = (
        <MDBox bgColor='white' width='100%' className='flex-verticale' p={2} maxWidth='1000px' mx='auto'>
            <Grid container spacing={5}>
                <Grid item xs={12} lg='auto' sx={{ boxSizing: 'border=box', alignItems: 'end', display: 'flex' }}>
                    <MDBox className='flex-verticale'>
                        {logo === '' ? (
                            <MDBox className='centrato' height='130px' width='130px' bgColor='light' variant='gradient' sx={{ borderRadius: '50%' }}>
                                <MDTypography variant='h1' fontWeight='bold' color='white' align='center'>
                                    {(props.dati?.nome === undefined ? '' : props.dati.nome[0]).toUpperCase()}
                                </MDTypography>
                            </MDBox>
                        ) : (
                            <MDBox component='img' src={logo.originale} alt='logo' height='auto' width='130px'></MDBox>
                        )}
                        {renderBottoneImmagini()}
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={true} sx={{ boxSizing: 'border=box', alignItems: 'end', display: 'flex' }}>
                    <ElementoEtichettaForm testo={props.dati.nome} elementoSotto={renderIndirizzo()} />
                </Grid>
            </Grid>
            {/* <Divider /> */}
            <MDTypography sx={{ whiteSpace: 'pre-line', mt: 2 }}>{props.dati.brevedescrizione}</MDTypography>
            <MDBox>{infoProgettiImprese}</MDBox>
            {immagini}
            <MDBox pt={2}>
                {renderFormDati}
                {props.isMioProfilo ? <ElementoBottoniForm vista={statoDati.vista} annulla={azioniDati.annullaControlla} valida={aggiorna} vediInput={azioniDati.vediInput} /> : null}
            </MDBox>
        </MDBox>
    )

    const renderProgettiAttivi = (
        <MDBox sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <ElementoCarosello
                testo='Nessuna raccolta fondi attiva'
                lista={listaProgettiAttivi.map((progetto) => {
                    return { render: <ElementoMostraProgetto navigate={props.navigate} progetto={progetto} />, id: progetto.id }
                })}
            />
        </MDBox>
    )
    const renderProgettiCompletati = (
        <MDBox sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <ElementoCarosello
                testo='Nessuna raccolta fondi completata'
                lista={listaProgettiCompletati.map((progetto) => {
                    return { render: <ElementoMostraProgetto navigate={props.navigate} progetto={progetto} />, id: progetto.id }
                })}
            />
        </MDBox>
    )

    const renderProgetti = (
        <MDBox p={2}>
            <div className='gridDueDue' style={{ justifyContent: 'center', maxWidth: '800px' }}>
                <div className='etichettaUno'>
                    <ElementoEtichettaForm testo='Raccolte fondi attive' />
                </div>
                <div className='etichettaDue'>
                    <ElementoEtichettaForm testo='Raccolte fondi completate' />
                </div>
                {/*  <div className='etichettaTre'>
                    <ElementoEtichettaForm testo='Imprese Sostenitrici' />
                </div> */}
                <div className='elementoUno'>{renderProgettiAttivi}</div>
                <div className='elementoDue'>{renderProgettiCompletati}</div>
                {/* <div className='elementoTre'>{renderImprese}</div> */}
            </div>
        </MDBox>
    )

    const renderImpatti = () => {
        if (props.listaImpatti.length === 0) return null
        return (
            <MDBox mb={4} mx='auto' sx={{ width: '100%', maxWidth: '1000px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <MDTypography color='dark' fontWeight='bold' variant='h3' sx={{ mb: 2 }}>
                    IMPATTO
                </MDTypography>
                <ElementoVediListaImpatti lista={props.listaImpatti} />
            </MDBox>
        )
    }

    const renderImprese = () => {
        if (listaAziendeSostenitrici.length === 0) {
            return (
                <MDBox mx='auto' maxWidth='1000px' width='100%'>
                    <ElementoEtichettaForm align='center' testo='Imprese sostenitrici' />
                    <MDTypography align='center' color='text'>
                        Nessuna Impresa sostenitrice
                    </MDTypography>
                </MDBox>
            )
        }
        return (
            <MDBox mx='auto' maxWidth='1000px' width='100%'>
                <ElementoEtichettaForm align='center' testo='Imprese sostenitrici' />
                <List sx={{ width: '100%', mt: 2 }}>
                    {listaAziendeSostenitrici.map((impresa, index) => {
                        return (
                            <MDBox key={impresa.idImpresa} width='100%'>
                                <ElementoMostraImpresaMappa impresa={getImpresaDaId(impresa.idImpresa)} hanndleSelezionato={vediImpresa} />
                                {index === listaAziendeSostenitrici.length + 1 ? null : <Divider />}
                            </MDBox>
                        )
                    })}
                </List>
            </MDBox>
        )
    }

    const renderContatti = <MDBox width='100%' maxWidth='1000px' mx='auto'></MDBox>

    const renderPagina = (
        <>
            <MDBox bgColor='white' width='100%'>
                {renderImmagineNome}
            </MDBox>
            <MDBox className='flex-verticale' p={2}>
                <MDTypography sx={{ mb: 3 }} align='center' variant='h3' color='text'>
                    PROGETTI
                </MDTypography>
                {renderProgetti}
            </MDBox>
            <MDBox bgColor='white' width='100%'>
                {renderImpatti()}
            </MDBox>
            <MDBox bgColor='white' width='100%' mt={2} p={2} mb={2}>
                {renderImprese()}
            </MDBox>
            <ElementoContatti vediBottone={props.isMioProfilo} formLink={formLinkContattiEnte(true)} form={formContatti} dati={props.dati} aggiorna={aggiornaContatti} />
        </>
    )

    return <ElementoRenderPagina renderPagina={renderPagina} />
}

const mapStateToProps = (state) => ({
    dati: getDatiEnte(state.url[4]),
    listaImmagini: getImmaginiEnte(),
    id: getIdEnte(),
    listaImpatti: getListaImpattiEnte(),
    isMioProfilo : getIdEnte() === getIdUtenteLoggato()
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(paginaVediEnte)
