import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { Divider, Tooltip, IconButton } from '@mui/material'
import MDButton from 'components/MDButton'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded'

export default function elementoMostraEmailUtente(props) {
    const copia = () => {
        navigator.clipboard.writeText(props.utente.mail)
    }

    return (
        <>
            <ListItem>
                <ListItemText primary={props.utente.nome} secondary={props.utente.mail} />
                <ListItemIcon onClick={copia} sx={{ cursor: 'pointer' }}>
                    <Tooltip title='copia'>
                        <IconButton>
                            <ContentCopyRoundedIcon color='dark' />
                        </IconButton>
                    </Tooltip>
                </ListItemIcon>
            </ListItem>
            <Divider />
        </>
    )
}
