import React, {useEffect} from 'react'
import ElementoRiquadroPagine from './elementoRiquadroPagine'
import MDBox from 'components/MDBox'
import { Divider } from '@mui/material'
import MDTypography from 'components/MDTypography'
import brand from 'assets/logoEtiqua/ORIZZONTALE POSITIVO payoff.svg'
import MDButton from 'components/MDButton'
import { LOG_OUT } from 'data/azioni/LOG_OUT'
import { getAuth, sendEmailVerification } from 'firebase/auth'

export default function elmentoUtenteNonVerificato(props) {
    const inviaMail = async () => {
        const auth = getAuth()
        await sendEmailVerification(auth.currentUser)
        alert('Email inviata')
    }

    useEffect(() => {
        const auth = getAuth()

        let user = auth.currentUser
        const checkEmailVerification = setInterval(() => {
            user.reload()
                .then(() => {
                    if (user.emailVerified) {
                        // User's email is verified.
                        clearInterval(checkEmailVerification)
                        // Redirect to your app's main page.
                        console.log('userrrr : ', user.uid);
                        window.dispatchEvent(new CustomEvent("leggiUtente", {detail : user.uid}))
                        console.log('mail verificata')
                    } else {
                        // User's email is not verified yet.
                        console.log('Email not verified yet')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }, 4000)
    }, [])

    return (
        <ElementoRiquadroPagine propsBox={{ mx: 'auto', maxWidth: '330px', borderRadius: 'lg', shadow: 'lg', mt: { xs: 7, md: 20 } }}>
            <MDBox component='img' src={brand} alt='Brand' height='auto' width='100%' />
            <Divider />
            <MDTypography>Verificare la mail per proseguire, tramite il link inviato all'indirizzo inserito</MDTypography>
            <MDButton color='dark' variant='contained' sx={{ mt: 1 }} onClick={inviaMail}>
                invia di nuovo
            </MDButton>
            <MDButton color='dark' variant='contained' sx={{ mt: 1 }} onClick={LOG_OUT}>
                log out
            </MDButton>
        </ElementoRiquadroPagine>
    )
}
