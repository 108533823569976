import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { doc, setDoc } from 'firebase/firestore'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { newId } from 'data/strumenti'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { getAuth } from 'firebase/auth'

export const CREA_RICHIESTA_CONTATTO = async (dati) => {
    MODIFICA_STATO_SPINNER(true)
    const id = getIdUtenteLoggato()
    const profilo = getProfiloUtenteLoggato()
    dati.idUtente = id
    dati.letto = false
    dati.data = new Date()
    dati.profilo = profilo
    if (profilo !== '') {
        const mailAccesso = getAuth().currentUser.email

        dati.mailAccesso = mailAccesso
    }
    await setDoc(doc(db, 'richiesteContatto', newId()), dati)
    MODIFICA_STATO_SPINNER(false)
    return
}
