import React from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ElementoMostraLogoInLista from './elementoMostraLogoInLista'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded'
import ReadMoreRoundedIcon from '@mui/icons-material/ReadMoreRounded'
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded'
import { APRI_POP_UP } from 'data/azioni/APRI_POP_UP'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { httpsCallable } from 'firebase/functions'
import { updateDoc, doc } from 'firebase/firestore'
import { cacheNetwork } from 'util'
import { Tooltip } from '@mui/material'

export default function elementoMostraImpresaInNetwork(props) {
    const vediImpresa = () => props.navigate(`paginaVediImpresa/${props.impresa.id}`)

    const testoPopUpCambioCapofila = `Sei sicuro di voler rendere quest'impresa capofila di questo network ?
    Non lo sarai piu' tu.`

    const promuoviCapoFila = async () => {
        MODIFICA_STATO_SPINNER(true)
        await updateDoc(doc(db, cacheNetwork, props.idNetwork), { capoFila: props.impresa.id })
        //window.location.reload()
        MODIFICA_STATO_SPINNER(false)
    }
    const eliminaDalNetwork = async () => {
        MODIFICA_STATO_SPINNER(true)
        const eliminaImpresaDaNetwork = httpsCallable(functions, 'eliminaImpresaDaNetwork')
        await eliminaImpresaDaNetwork({ idImpresa: props.impresa.id, idNetwork: props.idNetwork })
        //window.location.reload()
        MODIFICA_STATO_SPINNER(false)
    }

    const modificaCapofila = () => APRI_POP_UP({ id: 'richiediConferma', parametro: { testo: testoPopUpCambioCapofila, azione: promuoviCapoFila } })
    const rimuovi = () => APRI_POP_UP({ id: 'elimina', parametro: { testo: `Sei sicuro di eliminare quest'impresa dal network?`, azione: eliminaDalNetwork } })

    const renderBottoni = () => {
        if (!props.isCapofila || props.capofila === props.impresa.id || !props.vediBottoni) {
            return (
                <MDBox className='contenitore-bottoni-destra' sx={{ marginTop: '0px !important', paddingTop: '0px !important' }}>
                    <Tooltip title='Vedi profilo'>
                        <MDButton color='dark' variant='contained' size='small' iconOnly onClick={vediImpresa}>
                            <ReadMoreRoundedIcon sx={{ height: '20px', width: '20px' }} />
                        </MDButton>
                    </Tooltip>
                </MDBox>
            )
        }
        return (
            <MDBox className='contenitore-bottoni-destra' sx={{ marginTop: '0px !important', paddingTop: '0px !important' }}>
                <Tooltip title='Vedi profilo'>
                    <MDButton color='dark' variant='contained' size='small' iconOnly onClick={vediImpresa}>
                        <ReadMoreRoundedIcon sx={{ height: '20px', width: '20px' }} />
                    </MDButton>
                </Tooltip>
                <Tooltip title='promuovi a capofila'>
                    <MDButton color='success' variant='contained' size='small' iconOnly onClick={modificaCapofila}>
                        <ArrowCircleUpRoundedIcon sx={{ height: '20px', width: '20px' }} />
                    </MDButton>
                </Tooltip>
                <Tooltip title='Espelli'>
                    <MDButton color='error' variant='contained' size='small' iconOnly onClick={rimuovi}>
                        <PersonRemoveRoundedIcon sx={{ height: '20px', width: '20px' }} />
                    </MDButton>
                </Tooltip>
            </MDBox>
        )
    }

    return (
        <ListItem sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <MDBox className='flex-orizzontale' mb={1}>
                <MDBox className='flex-orizzontale'>
                    <ListItemIcon>
                        <ElementoMostraLogoInLista dati={props.impresa} id={props.impresa.id} />
                    </ListItemIcon>
                    <ListItemText primary={props.impresa.nome} />
                </MDBox>
                {renderBottoni()}
            </MDBox>
        </ListItem>
    )
}
