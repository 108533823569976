import { collection, addDoc, Timestamp } from 'firebase/firestore'
import { getIdUtenteLoggato } from 'getter/getterUtenteLoggato'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'
import { getIdProgettoSelezionato } from 'getter/getterProgetti'

export const NUOVA_DONAZIONE_LIBERALE = async (totale) => {
    MODIFICA_STATO_SPINNER(true)
    const transazione = {
        idImpresa: getIdUtenteLoggato(),
        totale: totale,
        tipo: 'donazioneLiberale',
        idProgetto: getIdProgettoSelezionato(),
        data: Timestamp.fromDate(new Date()),
    }

    await addDoc(collection(db, 'transazioni'), transazione)
    MODIFICA_STATO_SPINNER(false)
    return
}
