import { store } from '../store'

export const LEGGI_RICHIESTE_ACQUISTO = (dati) => {
    return new Promise((resolve, reject) => {
        store.dispatch({
            type: 'LEGGI_RICHIESTE_ACQUISTO',
            payload: dati,
        })
    })
}
