import React, { useState, useEffect } from 'react'
import ElementoRiquadroPagine from './elementoRiquadroPagine'
import MDBox from 'components/MDBox'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ElementoImmagineProfilo from 'componenti/elementoImmagineProfilo'
import { Grid, Icon } from '@mui/material'
import ElementoEtichettaForm from './elementoEtichettaForm'
import { formCambioCredenziali } from 'formModel/formCambioCredenziali'
import useForm from 'customHooks/useForm'
import ElementoBottoniForm from './elementoBottoniForm'
import { MODIFICA_STATO_SPINNER } from 'data/azioni/MODIFICA_STATO_SPINNER'
import { MODIFICA_CREDENZIALI } from 'data/azioni/MODIFICA_CREDENZIALI'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'

export default function elementoMostraProfilo(props) {
    const [selezionato, setSelezionato] = useState(0)
    const [tabs, setTabs] = useState([])
    const [statoCredenziali, azioniCredenziali, renderFormCredenziali] = useForm(formCambioCredenziali(false), undefined, false, true)
    const handleSelezionato = (e, valore) => setSelezionato(valore)
    const reset = () => handleSelezionato(0)
    const modificaImmagini = () => props.navigate('paginaModificaImmagini')

    const aggiornaCredenziali = async () => {
        MODIFICA_STATO_SPINNER(true)
        try {
            await azioniCredenziali.validaForm()
            await MODIFICA_CREDENZIALI(selezionato === tabs.length - 1, statoCredenziali.statoForm.nuovo)
            MODIFICA_STATO_SPINNER(false)
            reset()
        } catch (error) {
            MODIFICA_STATO_SPINNER(false)
        }
    }

    useEffect(() => {
        if (!props.isMioProfilo) {
            setTabs(props.tabs)
            return
        }
        setTabs([...props.tabs].concat(['Cambia password', 'Cambia email']))
    }, [props.tabs])
    useEffect(() => {
        azioniCredenziali.setForm(formCambioCredenziali(selezionato === tabs.length - 1))
    }, [selezionato])

    const renderTabs = (
        <Tabs orientation={'vertical'} value={selezionato} centered={false} onChange={handleSelezionato} color='info' sx={{ width: 'max-content' }}>
            {tabs.map((elemento, index) => {
                return <Tab label={<span sx={{ display: 'flex', justifyContent: 'flex-start' }}>{elemento}</span>} key={`tab-${elemento}`} />
            })}
        </Tabs>
    )

    const rendercambiaCredenziali = (
        <MDBox sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
            {renderFormCredenziali}
            <ElementoBottoniForm vista={statoCredenziali.vista} annulla={reset} valida={aggiornaCredenziali} />
        </MDBox>
    )

    const renderBottoneImmagini = () => {
        if (props.bottoneInvita !== null) return props.bottoneInvita
        if (!props.isMioProfilo || props.nacondiBottoneImmagini) return null
        return (
            <MDButton variant='outlined' color='dark' onClick={modificaImmagini}>
                <Icon sx={{ mr: 1 }}>edit</Icon>
                immagini
            </MDButton>
        )
    }

    const elementoSotto = <MDTypography variant='caption'>{props.dati.breveDescrizione || ''} </MDTypography>

    return (
        <MDBox py={3}>
            <MDBox p={3}>
                {/* <MDBox mb={3}>
                        <MDButton>bottone foto</MDButton>
                    </MDBox> */}
                <ElementoEtichettaForm testo={props.nome} secondoElemento={renderBottoneImmagini()} elementoSotto={elementoSotto} noCentrato={true} />

                <Grid container spacing={3}>
                    <Grid container item xs={12} lg={6} xl={5}>
                        <Grid item xs={12}>
                            <ElementoImmagineProfilo immagini={props.immagini ?? []} nome={props.nome} />
                        </Grid>

                        <Grid item xs={12}>
                            {renderTabs}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={5} sx={{ mx: 'auto' }}>
                        <MDBox sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
                            <ElementoEtichettaForm testo={tabs[selezionato]} />
                            {selezionato < props.viste.length ? props.viste[selezionato] : rendercambiaCredenziali}
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    )

    return (
        <ElementoRiquadroPagine>
            <Grid container spacing={2}>
                <Grid container item spacing={1} sm={12} lg={4}>
                    <Grid item xs={12}>
                        <ElementoImmagineProfilo immagini={props.listaImmagini ?? []} />
                    </Grid>

                    <Grid item xs={12}>
                        {renderTabs}
                    </Grid>
                </Grid>
                <Grid item sm={12} lg={8}>
                    <MDBox sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
                        <ElementoEtichettaForm testo={tabs[selezionato]} />
                        {selezionato < props.viste.length ? props.viste[selezionato] : rendercambiaCredenziali}
                    </MDBox>
                </Grid>
            </Grid>
        </ElementoRiquadroPagine>
    )
}

elementoMostraProfilo.defaultProps = {
    nome: 'metti il nome',
    immagini: [],
    nacondiBottoneImmagini: false,
    bottoneInvita: null,
}
