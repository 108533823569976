import { store } from 'data/store'
//import { reducerStatoModifica } from './../data/reducers/reducerStatoModifica'
import { getAuth, signOut } from 'firebase/auth'
import { getProfiloUtenteLoggato } from 'getter/getterUtenteLoggato'
import { profiloImpresa } from 'util'
import { profiloConsumatore } from 'util'

//watchers
import { watcherRichiesteAcquisto } from 'data/watchers/watcherRichiesteAcquisto'
import { watcherTransazioni } from 'data/watchers/watcherTransazioni'

//reducers
import { reducerRichiesteAcquisti } from 'data/reducers/reducerRichiesteAcquisti'
import { reducerRichiesteContatto } from 'data/reducers/reducerRichiesteContatto'
import { profiloSuperAmministratore } from 'util'
import { watcherRichiesteContatto } from 'data/watchers/watcherRichiesteContatto'

export class inizializzatore {
    constructor() {
        this.utente = {}
        this.permessi = {}
        this.watchers - []
        this.daCaricare = true

        //window.versioneAttuale = '2.3.4'
        /* store.dispatch({
            type: 'AGGIORNA_VERSIONE_ATTUALE',
        }) */

        store.addReducers({
            //reducerStatoModifica,
        })
        this.aggiorna()
    }

    caricaModuli() {
        if (!this.daCaricare) {
            return
        }
        /* window.dispatchEvent(
            new CustomEvent('carica', {
                detail: 'node_modules/signature_pad/dist/signature_pad.min.js',
            }),
        )
        window.dispatchEvent(
            new CustomEvent('carica', {
                detail: 'node_modules/pdfmake/build/pdfmake.min.js',
            }),
        )

        setTimeout(() => {
            window.dispatchEvent(
                new CustomEvent('carica', {
                    detail: 'node_modules/pdfmake/build/vfs_fonts.js',
                }),
            )
        }, 1000) */
        this.daCaricare = false
    }

    aggiorna(utente) {
        this.utente = utente
        //this.permessi = getPermessiUtente(utente)
        //this.watchers = this.getWatchers()
        const profilo = getProfiloUtenteLoggato()
        if (profilo === profiloImpresa.tipo) {
            this.watchers = [new watcherRichiesteAcquisto() /* new watcherTransazioni() */]
            store.addReducers({ reducerRichiesteAcquisti /* reducerTransazioni */ })
        }
        if (profilo === profiloConsumatore.tipo) {
            this.watchers = [new watcherRichiesteAcquisto()]
            store.addReducers({ reducerRichiesteAcquisti })
        }
        if (profilo === profiloSuperAmministratore.tipo) {
            this.watchers = [new watcherRichiesteContatto()]
            store.addReducers({ reducerRichiesteContatto })
        }
        this.caricaModuli()
    }

    logOut() {
        if (this.watchers === undefined) {
            this.watchers = []
            signOut(getAuth())
            return
        }
        Promise.all(
            this.watchers.map((classeWatchers) => {
                return classeWatchers.logOut()
            }),
        )
            .then(() => {
                this.watchers = []
                signOut(getAuth())
            })
            .catch((err) => {
                console.log(err)
            })
    }
}
