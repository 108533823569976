import { httpsCallable } from 'firebase/functions'
import { MODIFICA_STATO_SPINNER } from './MODIFICA_STATO_SPINNER'

export const ACCETTA_RICHIESTA_ACQUISTO = async (id) => {
    MODIFICA_STATO_SPINNER(true)
    const accettaPagamento = httpsCallable(functions, 'accettaPagamento')
    await accettaPagamento({ idRichiesta: id })
    MODIFICA_STATO_SPINNER(false)
    console.log('guarda id : ', id );
    return
}
