const initialState = []

export const reducerAggiornamentiProgetto = (state = initialState, action) => {
    switch (action.type) {
        case 'LEGGI_AGGIORNAMENTI_PROGETTO':
            var lista = [...action.payload]
            lista.sort((a, b) => {
                let dataA = a.data.seconds
                let dataB = b.data.seconds
                return dataB - dataA
            })
            return lista
        
        default:
            return state
    }
}
