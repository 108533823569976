import React from 'react'
import ElementoMostraProgetto from 'componenti/elementoMostraProgetto'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import MDBox from 'components/MDBox'

export default function elementoListaProgetti(props) {
    const renderLista = (
        <Grid container /* spacing={2} */ width='100%' rowGap={{xs : 2, sm : 0}} spacing={{xs : 0, sm : 2}}  >
            {props.lista.map((progetto) => {
                return (
                    <Grid item xs={12} sm={6} lg={4} xxl={4} key={progetto.id} >
                        <ElementoMostraProgetto progetto={progetto} {...props} />
                    </Grid>
                )
            })}
           
        </Grid>
    )

    const renderErrore = (
        <MDBox width='100%' bgColor='white' p={2} shadow={props.noOmbra ? '' : 'lg'} borderRadius='lg'>
            <MDTypography color='text'>{props.testoErrore}</MDTypography>
        </MDBox>
    )
    return props.lista.length === 0 ? renderErrore : renderLista
}

elementoListaProgetti.defaultProps = {
    lista: [],
    testoErrore: '',
    noOmbra: false,
}
